import AnswerPerformanceView from 'models/location/answer_performance_view';
import { getUrl } from 'scripts/adapters/lib/url';
import OpenAnswerPerformance from 'actions/answer_performance/open_answer_performance';
import { registerUrlConverter } from './location_url';
import UnloadAnswerPerformance from 'actions/answer_performance/unload_answer_performance';

registerUrlConverter(AnswerPerformanceView, {
  generateUrl: (path, params) => {
    return getUrl(path, params, { arrayFormat: 'comma' });
  },
  getPath: () => '/answer-performance',
  getQueryParams: location => {
    const params = {};
    if (location.audienceId) {
      params.a = location.audienceId;
    }
    if (location.dateRange) {
      params.d = location.dateRange;
    }
    return params;
  },
});

export default function(router, { runnerContext }) {
  return {
    on: () => {
      const queryParams = router.parseQueryParameters().toJS();
      let attr = {};
      if (queryParams.a) {
        attr.audienceId = queryParams.a;
      }
      if (queryParams.d) {
        attr.dateRange = queryParams.d;
      }

      runnerContext.executeAction(OpenAnswerPerformance, attr);
    },
    after() {
      runnerContext.executeAction(UnloadAnswerPerformance);
    },
  };
}
