import _ from 'lodash';
import createModel, { prop } from '../lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import ProfileCardDataSources from 'models/configuration/profile_card_data_sources';
import ProfileCardType from 'models/configuration/profile_card_type';

const ProfileCardSubgroupDef = createModel({
  modelName: 'ProfileCardSubgroup',
  properties: {
    label: prop(String),
    attrs: prop([String]),
  },
});

const ProfileCardProperties = createModel({
  modelName: 'ProfileCardProperties',
  properties: {
    title: prop(String).default(''),
    collapsible: Boolean,
    expanded: prop(Boolean).default(true),
    hideEmpty: Boolean,
    sections: [ProfileCardSubgroupDef],
    cardParameters: Object,
    dataSources: ProfileCardDataSources,
  },
});

const ProfileCardDef = createModel({
  modelName: 'ProfileCardDef',

  properties: {
    id: prop(String).default(() => IdGenerator.newId()),
    type: prop(String).oneOf(..._.values(ProfileCardType)).isRequired,
    label: prop(String),
    attrs: prop([String]),
    subgroups: prop([ProfileCardSubgroupDef]),
    properties: prop(ProfileCardProperties),
  },

  statics: {
    create(props) {
      return new this({
        id: IdGenerator.newId(),
        ...props,
      });
    },
  },
});

export { ProfileCardProperties, ProfileCardSubgroupDef };
export default ProfileCardDef;
