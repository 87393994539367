import _ from 'lodash';
import escapeStringRegexp from 'escape-string-regexp';

import createTakenError from 'scripts/domain/factories/error/create_taken_error';
import HtmlToText from 'html-to-text';
import { convertAllUploadsToAttachments, getAllAttachments } from 'models/answers/snippet_helpers';
import Snippet, { SnippetContentType } from 'models/answers/snippet';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import { validateAttachments, validateTotalSize } from 'models/answers/snippet_validators';

export default class PutSnippetBase {
  constructor(context) {
    this.context = context;
  }

  run({ attrs, referencedAnswerId }) {
    if (this.context.stores.snippetComposition.isPending()) {
      return;
    }
    let snippetComposition = this.context.stores.snippetComposition.get();

    attrs = this._trimWhitespace(attrs);
    attrs = this._removeUnusedContentTypes(attrs, snippetComposition);
    attrs = this._removeUnusedLanguages(attrs);

    let validationErrors = this._getValidationErrors(attrs, snippetComposition);
    if (validationErrors.length) {
      this.context.stores.snippetComposition.setErrors(validationErrors);
    } else {
      snippetComposition.update(attrs);
      let snippet = this.context.stores.snippets.findBy({ id: snippetComposition.id });
      if (snippet) {
        this._removeRemovedAttachments(snippet, snippetComposition);
      }

      convertAllUploadsToAttachments(snippetComposition);
      let snippetJs = snippetComposition.toJs();

      this.context.stores.snippetComposition.setPending(snippetComposition);

      // temporary until SN moves subject to content
      let subject = _.get(
        _.find(snippetJs.contents, c => _.get(c, 'anyChannel.subject')),
        'anyChannel.subject'
      );
      if (subject) snippetJs.subject = subject;

      return this.runAction(_.omit(snippetJs, ['plaintext']), referencedAnswerId);
    }
  }

  showSnippetSuccessToast(snippet, referencedAnswerId) {
    const queryParams = referencedAnswerId ? `?ref=${referencedAnswerId}` : '';
    this.context.executeAction(ShowToast, {
      type: ToastType.SUCCESS,
      message: `"${snippet.name}" has been saved. Click here to view.`,
      link: `/kb-admin/answer/${snippet.id}${queryParams}`,
    });
  }

  _trimWhitespace(attrs) {
    _.forEach(attrs, function(value, key) {
      if (typeof value === 'string') {
        attrs[key] = value.trim();
      }
    });

    _.forEach(attrs.contents, content =>
      _.forEach(content, (channelContent, channel) => {
        if (!channelContent || !_.find(SnippetContentType, type => type === channel)) {
          return;
        }
        content[channel].bodyHtml = channelContent.bodyHtml.trim();
        if (content[channel].subject) {
          content[channel].subject = channelContent.subject.trim();
        }
      })
    );

    return attrs;
  }

  _removeUnusedContentTypes(attrs, snippetComposition) {
    _.forEach(attrs.contents, channels => {
      let composition = snippetComposition.findContentByLanguage(channels.language);
      if (
        this._isChannelEmpty({ content: channels.anyChannel, composition, channel: SnippetContentType.ANY_CHANNEL })
      ) {
        channels.anyChannel = null;
      }
      if (this._isChannelEmpty({ content: channels.info, composition, channel: SnippetContentType.INFO })) {
        channels.info = null;
      }
      if (this._isChannelEmpty({ content: channels.message, composition, channel: SnippetContentType.MESSAGE })) {
        channels.message = null;
      }
      if (this._isSelfServiceTypeEmpty({ content: channels.selfService, composition })) {
        channels.selfService = null;
      }
    });
    return attrs;
  }

  _removeUnusedLanguages(attrs) {
    attrs.contents = _.filter(
      attrs.contents,
      content => content.anyChannel || content.info || content.message || content.selfService
    );
    return attrs;
  }

  _isChannelEmpty({ content, composition, channel }) {
    let hasBodyHtml =
      content && content.bodyHtml && HtmlToText.fromString(content.bodyHtml, { wordwrap: null }).trim().length;

    let attachments = composition.getAttachmentsByType(channel);
    let hasAttachments = attachments && attachments.length;

    return !hasAttachments && !hasBodyHtml;
  }

  _isSelfServiceTypeEmpty({ content, composition }) {
    let hasName = content && content.name;
    let hasBodyHtml =
      content && content.bodyHtml && HtmlToText.fromString(content.bodyHtml, { wordwrap: null }).trim().length;
    return !hasName && !hasBodyHtml;
  }

  _removeRemovedAttachments(snippet, snippetComposition) {
    let currentAttachmentIds = getAllAttachments(snippetComposition).map(a => a.id);
    let newAttachmentsIds = getAllAttachments(snippet).map(a => a.id);
    let removedAttachmentIds = _.difference(newAttachmentsIds, currentAttachmentIds);
    removedAttachmentIds.forEach(aid => {
      let path = snippetComposition.getUploadPath(aid);
      this.context.gateways.snippetUpload.remove(path);
    }, this);
  }

  _getValidationErrors(attrs, snippetComposition) {
    let validationErrors = Snippet.getValidationErrors(attrs);

    if (attrs.name) {
      let filterRegExp = new RegExp(`^${escapeStringRegexp(attrs.name.replace(/\s/g, ''))}$`, 'i');
      let namingConflict = !!this.context.stores.snippets.findAll().filter(function(snippet) {
        return snippet.name && filterRegExp.test(snippet.name.replace(/\s/g, '')) && snippet.id !== attrs.id;
      }).length;

      if (namingConflict) {
        validationErrors.push(createTakenError('name', attrs.name));
      }
    }

    if (!_.some(attrs.contents, channel => channel.anyChannel)) {
      return validationErrors;
    }

    // Need to validate attachments upload status / size limits for email and reference answers
    _.forEach(snippetComposition.contents, channels => {
      if (_.get(channels, 'anyChannel.bodyHtml')) {
        let attachments = channels.getAttachmentsByType(SnippetContentType.ANY_CHANNEL);
        validationErrors = validationErrors.concat(validateAttachments(attachments));
        validationErrors = validationErrors.concat(validateTotalSize(channels.anyChannel.bodyHtml, attachments));
      } else if (_.get(channels, 'info.bodyHtml')) {
        let attachments = channels.getAttachmentsByType(SnippetContentType.INFO);
        validationErrors = validationErrors.concat(validateAttachments(attachments));
        validationErrors = validationErrors.concat(validateTotalSize(channels.info.bodyHtml, attachments));
      }
    });

    return validationErrors;
  }
}
