import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import FbMessageOutgoing, { FbMessageRequest, FbMessageSent } from 'models/fb_message_outgoing';

export default function createAgentFbMessage({
  agentProfile,
  attachment,
  conversationId,
  customerId,
  messageText,
  recipient,
  relatedSnippetIds,
  sender,
  snippetIds,
  translation,
}) {
  return ConversationItem.create({
    conversationId,
    content: FbMessageOutgoing.create({
      request: FbMessageRequest.create({
        message: FbMessageSent.create({
          text: messageText,
          attachment,
        }),
        sender,
        recipient,
      }),
    }),
    customerId,
    initiator: {
      id: agentProfile.id,
      type: Communicator.AGENT,
    },
    snippetIds,
    relatedSnippetIds,
    translation,
  });
}
