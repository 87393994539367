import AgentActivity from 'models/agent_activity';
import AnswerPerformanceView from 'models/location/answer_performance_view';
import changeCurrentLocation from 'actions/lib/change_current_location';
import RecommendedAnswers from 'models/location/recommended_answers';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class OpenRecommendedAnswers {
  constructor(context) {
    this.context = context;
  }

  run({ referencedAnswerId }) {
    const appFeatures = this.context.stores.appFeatures.get();
    if (!appFeatures.isEnabled('viewAnswerPerformance') || !appFeatures.isEnabled('answerPerformance')) {
      this.context.router.navigateHome();
      return;
    }

    if (!referencedAnswerId) {
      this.context.router.navigateTo(AnswerPerformanceView.create());
      return;
    }

    changeCurrentLocation(this.context, RecommendedAnswers.create({ referencedAnswerId }));
    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.RECOMMENDED_ANSWERS });

    this.context.stores.recommendedAnswers.setLoading();
    this.context.gateways.recommendedAnswers.requestAll({ answerId: referencedAnswerId });
  }
}
