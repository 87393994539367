import { AnswerPerformanceDateRange } from 'models/answer_performance';
import createLocationModel, { prop } from './lib/create_location_model';

export default createLocationModel({
  modelName: 'AnswerPerformanceView',
  properties: {
    audienceId: String,
    dateRange: prop()
      .oneOf(...Object.values(AnswerPerformanceDateRange))
      .default(AnswerPerformanceDateRange.LAST_1_DAY),
  },

  statics: {
    breadcrumb: 'Answer Performance',
  },
});
