import createModel, { prop } from './lib/create_model';

const RecommendedAnswerCount = createModel({
  modelName: 'RecommendedAnswerCount',
  properties: {
    id: prop(String).isRequired,
    count: prop(Number).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default RecommendedAnswerCount;
