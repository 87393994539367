import createModel, { prop } from './lib/create_model';

export const Translation = createModel({
  modelName: 'Translation',

  properties: {
    contentLanguage: prop(String).isRequired,
    content: prop(String).isRequired,
    parentLanguage: prop(String).isRequired,
    model: String,
    provider: String,
    translatedAt: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});
export default Translation;
