import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import Button from 'components/common/button';
import InformationIconStroke from 'components/common/icons/stroke/information-stroke';
import SearchInput from 'components/common/search_input';
import Spinner from 'scripts/presentation/components/common/spinner_two';
import Tooltip from 'components/common/tooltip';

const InteractiveTable = ({
  columns,
  controlComponents,
  data,
  defaultSortColumn,
  enableSearch,
  enableSorting,
  isLoading,
  isRowClickable,
  onRowClick,
  reorderRows,
  rowsPerPage,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState(
    defaultSortColumn
      ? {
          key: defaultSortColumn,
          direction: columns.find(c => c.accessor === defaultSortColumn)?.sortDescendingFirst
            ? 'descending'
            : 'ascending',
        }
      : { key: null, direction: 'ascending' }
  );
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting logic (only if sorting is enabled)
  const sortedData = useMemo(() => {
    if (!enableSorting) {
      return data;
    }

    if (!sortConfig.key) {
      return reorderRows ? reorderRows(data) : data;
    }

    const sorted = [...data].sort((a, b) => {
      const column = columns.find(c => c.accessor === sortConfig.key);
      const getValue = item => column?.sortValue(item) ?? item[sortConfig.key];

      const aValue = getValue(a);
      const bValue = getValue(b);

      if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
    return reorderRows ? reorderRows(sorted) : sorted;
  }, [enableSorting, sortConfig.key, sortConfig.direction, data, columns, reorderRows]);

  // Filtering logic (only if search is enabled)
  const filteredData = useMemo(() => {
    setCurrentPage(1);
    if (!enableSearch || !searchTerm) return sortedData;
    return sortedData.filter(item =>
      columns.some(column => {
        const value = item[column.accessor];
        let searchValue = value;
        if (column.sortValue) {
          searchValue = column.sortValue(item);
        } else if (React.isValidElement(value)) {
          if (typeof value.props.children === 'string') {
            searchValue = value.props.children;
          } else if (value.props.value !== undefined) {
            searchValue = value.props.value;
          }
        }
        return searchValue
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      })
    );
  }, [searchTerm, sortedData, columns, enableSearch]);

  // Pagination logic (only if pagination is enabled).
  const paginatedData = useMemo(() => {
    if (!rowsPerPage) return filteredData;
    const startIndex = (currentPage - 1) * rowsPerPage;
    return filteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredData, currentPage, rowsPerPage]);

  const requestSort = key => {
    if (!enableSorting) return;

    const column = columns.find(c => c.accessor === key);
    let direction = 'ascending';

    if (sortConfig.key === key) {
      direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    } else {
      direction = column.sortDescendingFirst ? 'descending' : 'ascending';
    }

    setSortConfig({ key, direction });
  };

  const totalPages = rowsPerPage ? Math.max(1, Math.ceil(filteredData.length / rowsPerPage)) : 1;

  const renderSortIcon = columnKey => {
    if (!enableSorting) return null;

    const isColumnSorted = sortConfig.key === columnKey;
    const classes = classnames('icon', {
      'ion-arrow-up-c': isColumnSorted && sortConfig.direction === 'ascending',
      'ion-arrow-down-c': !isColumnSorted || sortConfig.direction === 'descending',
    });
    return (
      <SortIconWrapper className={isColumnSorted ? 'sorted' : ''}>
        <i className={classes} />
      </SortIconWrapper>
    );
  };

  const renderInfoIcon = infoText => {
    if (!infoText) return null;

    return (
      <InfoIconWrapper>
        <Tooltip message={infoText}>
          <InformationIconStroke width="12px" />
        </Tooltip>
      </InfoIconWrapper>
    );
  };

  return (
    <div>
      <ControlsWrapper>
        {enableSearch && (
          <SearchWrapper>
            <SearchInput
              data-aid="interactiveTable-searchInput"
              disabled={isLoading}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search..."
              type="text"
              value={searchTerm}
            />
          </SearchWrapper>
        )}
        {controlComponents && <ControlComponentsWrapper>{controlComponents}</ControlComponentsWrapper>}
      </ControlsWrapper>

      <TableWrapper columns={columns}>
        <HeaderRow>
          {columns.map(column => (
            <HeaderCell
              align={column.align}
              data-aid="interactiveTable-columnHeader"
              key={column.accessor}
              onClick={() => requestSort(column.accessor)}
              width={column.width}
            >
              <THContent align={column.align}>
                {column.align === 'right' && (
                  <HeaderText align={column.align}>
                    {enableSorting && renderSortIcon(column.accessor)}
                    <p>{column.header}</p>
                    {renderInfoIcon(column.infoText)}
                  </HeaderText>
                )}
                {column.align !== 'right' && (
                  <HeaderText align={column.align}>
                    {renderInfoIcon(column.infoText)}
                    <p>{column.header}</p>
                    {enableSorting && renderSortIcon(column.accessor)}
                  </HeaderText>
                )}
              </THContent>
            </HeaderCell>
          ))}
        </HeaderRow>

        {!isLoading && (
          <RowsWrapper>
            {paginatedData.map((row, rowIndex) => {
              const isClickable = isRowClickable ? isRowClickable(row) : !!onRowClick;
              return (
                <Row
                  className={isClickable ? 'clickable' : ''}
                  data-aid="interactiveTable-row"
                  key={rowIndex}
                  onClick={() => isClickable && onRowClick(row)}
                >
                  {columns.map(column => (
                    <Cell align={column.align} data-aid="interactiveTable-cell" key={column.accessor}>
                      {row[column.accessor]}
                    </Cell>
                  ))}
                </Row>
              );
            })}
          </RowsWrapper>
        )}
      </TableWrapper>

      {isLoading && (
        <SpinnerContainer data-aid={`interactiveTable-loadingSpinner`}>
          <Spinner size={48} stroke={3} />
        </SpinnerContainer>
      )}

      {/* Pagination Controls (Only if enabled). */}
      {!isLoading && rowsPerPage && (
        <Pagination>
          <Button
            data-aid="interactiveTable-previousButton"
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          >
            Previous
          </Button>
          <span data-aid="interactiveTable-paginationText">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            data-aid="interactiveTable-nextButton"
            disabled={currentPage >= totalPages}
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          >
            Next
          </Button>
        </Pagination>
      )}
    </div>
  );
};

InteractiveTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['left', 'right', 'center']),
      sortValue: PropTypes.func,
      infoText: PropTypes.string,
      width: PropTypes.string,
      sortDescendingFirst: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSortColumn: PropTypes.string,
  enableSearch: PropTypes.bool,
  enableSorting: PropTypes.bool,
  enablePagination: PropTypes.bool,
  isLoading: PropTypes.bool,
  reorderRows: PropTypes.func,
  rowsPerPage: PropTypes.number,
  controlComponents: PropTypes.node,
  onRowClick: PropTypes.func,
  isRowClickable: PropTypes.func,
};

export const SortIconWrapper = styled.span`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  opacity: 0;
  padding: ${p => p.theme.spacing.small};
  transition: opacity 0.2s ease;

  &.sorted {
    opacity: 1;
  }
`;

const TableWrapper = styled.div`
  border: none;
  padding: ${p => p.theme.spacing.medium};
  display: grid;
  grid-template-columns: ${p => p.columns.map(col => col.width || 'auto').join(' ')};
`;

const HeaderRow = styled.div`
  display: contents;
`;

const RowsWrapper = styled.div`
  display: contents;
  background-color: ${p => p.theme.colors.white};
`;

export const Row = styled.div`
  display: contents;

  &:hover > * {
    background-color: ${p => p.theme.colors.gray300};
  }

  &:nth-child(even) > * {
    background-color: ${p => p.theme.colors.gray100};
  }

  &.clickable {
    cursor: pointer;
  }
`;

const BaseCell = styled.div`
  padding: ${p => p.theme.spacing.medium};
  text-align: ${p => p.align || 'left'};
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${p => p.theme.colors.white};
`;

export const HeaderCell = styled(BaseCell)`
  color: ${p => p.theme.colors.black};
  cursor: pointer;
  font-weight: bold;

  &:hover ${SortIconWrapper} {
    opacity: 1;
  }
`;

export const Cell = styled(BaseCell)``;

export const THContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: ${p => (p.align === 'right' ? 'flex-end' : 'flex-start')};
  padding: ${p => p.theme.spacing.small};
  width: auto;
`;

export const HeaderText = styled.div`
  align-items: center;
  display: flex;
  gap: ${p => p.theme.spacing.xsmall};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${p => p.theme.spacing.medium};
  padding-top: ${p => p.theme.spacing.medium};
`;

const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 300px;
  justify-content: center;
  width: 100%;
`;

export const ControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${p => p.theme.spacing.inlineMedium};
  margin-bottom: ${p => p.theme.spacing.medium};
`;

const SearchWrapper = styled.div`
  flex-shrink: 0;
  width: 300px;
`;

const ControlComponentsWrapper = styled.div`
  align-items: left;
  display: flex;
`;

export const InfoIconWrapper = styled.span`
  padding: ${p => p.theme.spacing.small};
`;

export default InteractiveTable;
