import _ from 'lodash';
import MqttGateway from './lib/mqtt_gateway';

export default class VoiceRecordingGateway {
  constructor(backend, requestorId) {
    this._gateways = {
      call: new PhoneCallRecordingGateway(backend, requestorId),
      voiceai: new VoiceAIRecordingRecordingGateway(backend, requestorId),
      voicemail: new VoicemailRecordingGateway(backend, requestorId),
    };
  }

  init(opts) {
    this._callMethodOnGateways('init', opts);
  }

  _callMethodOnGateways(methodName, ...params) {
    _.forIn(this._gateways, gateway => gateway[methodName].apply(gateway, params));
  }

  deleteCallRecording(customerId, conversationItemId) {
    return this._gateways.call.delete(customerId, conversationItemId);
  }

  deleteVoiceAIRecording(customerId, conversationItemId) {
    return this._gateways.voiceai.delete(customerId, conversationItemId);
  }

  deleteVoicemailRecording(customerId, conversationItemId) {
    return this._gateways.voicemail.delete(customerId, conversationItemId);
  }
}

class PhoneCallRecordingGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer/+/conversation-items/+/call-recording';
  }

  delete(customerId, conversationItemId) {
    return this._delete([customerId, conversationItemId]);
  }
}

class VoicemailRecordingGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer/+/conversation-items/+/voicemail-recording';
  }

  delete(customerId, conversationItemId) {
    return this._delete([customerId, conversationItemId]);
  }
}

class VoiceAIRecordingRecordingGateway extends MqttGateway {
  get version() {
    return '1';
  }

  get topicPattern() {
    return 'customer/+/conversation-items/+/voice-ai-recording';
  }

  delete(customerId, conversationItemId) {
    return this._delete([customerId, conversationItemId]);
  }
}
