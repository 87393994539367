import _ from 'lodash';

import AgentAssistanceConfigurationService from './fake_backend/agent_assistance_configuration_service';
import AgentReadService from './fake_backend/agent_read_service';
import AgentUserService from './fake_backend/agent_user_service';
import AnswerSuggestionService from './fake_backend/answer_suggestion_service';
import ApiTokenService from './fake_backend/api_token_service';
import AttachmentService from './fake_backend/attachment_service';
import CompositionService from './fake_backend/composition_service';
import Conversation from 'models/conversation';
import ConversationCountService from './fake_backend/conversation_count_service';
import ConversationItemsService from './fake_backend/conversation_items_service';
import ConversationsService from './fake_backend/conversations_service';
import CustomerAssigneeService from './fake_backend/customer_assignee_service';
import CustomerExtensionService from './fake_backend/customer_extension_service';
import ExternalCustomerLookupService from './fake_backend/external_customer_lookup_service';
import InboxServiceV3 from './fake_backend/inbox_service_v3';
import IncomingCallService from './fake_backend/incoming_call_service';
import RoutingEventType from 'models/routing_event/routing_event_type';
import RoutingGroupService from './fake_backend/routing_group_service';
import RuleService from './fake_backend/rule_service';
import SharedUploadService from './fake_backend/shared_upload_service';
import SnippetSearchService from './fake_backend/snippet_search_service';
import SnippetService from './fake_backend/snippet_service';
import UploadService from './fake_backend/upload_service';
import UserService from './fake_backend/user_service';
import VoiceRecordingService from './fake_backend/voice_recording_service';
import { agentSearch, searchCustomers, searchAll } from './fake_backend/fake_search';

export default class FakeBackend {
  constructor(pubsub, getDatabase, fakeS3) {
    this._pubsub = pubsub;

    this.agentAssistanceConfigurationService = new AgentAssistanceConfigurationService(this._pubsub, getDatabase);
    this.agentReadService = new AgentReadService(this._pubsub, getDatabase);
    this.answerSuggestionService = new AnswerSuggestionService(this._pubsub, getDatabase);
    this.apiTokenService = ApiTokenService.create(pubsub, getDatabase);
    this.attachmentService = new AttachmentService({ getDatabase });
    this.compositionService = new CompositionService(this._pubsub, getDatabase);
    this.conversationCountService = new ConversationCountService(this.publishResponse.bind(this));
    this.conversationItemsService = ConversationItemsService.create(pubsub, getDatabase);
    this.conversationsService = ConversationsService.create(pubsub, this.conversationItemsService, getDatabase);
    this.customerAssigneeService = new CustomerAssigneeService(this._pubsub, getDatabase);
    this.getDatabase = getDatabase;
    this.inboxServiceV3 = new InboxServiceV3(this.publishResponse.bind(this), getDatabase);
    this.incomingCallService = new IncomingCallService(this._pubsub, getDatabase);
    this.routingGroupService = new RoutingGroupService(this._pubsub, getDatabase);
    this.ruleService = new RuleService(this._pubsub, getDatabase);
    this.sharedUploadService = new SharedUploadService(fakeS3);
    this.snippetSearchService = new SnippetSearchService(this._pubsub, getDatabase);
    this.snippetService = new SnippetService(this._pubsub, getDatabase);
    this.uploadService = new UploadService(fakeS3);
    this._userService = new UserService(this._pubsub, getDatabase);
    this.voiceRecordingService = new VoiceRecordingService(this._pubsub, getDatabase);

    this.externalCustomerLookupService = new ExternalCustomerLookupService(getDatabase);
    this.customerExtensionService = new CustomerExtensionService(this._pubsub, getDatabase);
    this.agentUserService = new AgentUserService(
      this.agentService,
      this.routingGroupService,
      this._userService,
      this._pubsub,
      getDatabase
    );
  }

  /**
   * Each handler is subscribed to the specified MQTT topic `pattern`. When its `pattern` matches a publication topic
   * the handler is invoked with the actual publication parameters and the publication payload.
   * The number of handler arguments must match number of wildcards in the topic pattern plus one for the payload.
   * The `callbackTopicBuilder` function returns a string (that's actually a topic)
   * from parameters matched from the MQTT topic pattern and then is used to invoke any subscribers to
   * that topic with the response from the handler.
   * If callbackTopicBuilder is not specified, it is assumed that the handler (most likely
   * the service that implements the handler) will do the required publishing.
   */
  getTopicHandlers() {
    return [
      /* agent-users */
      {
        pattern: 'v3/requestor/+/orgs/+/agent-users/command/add',
        handler: this.agentUserService.add.bind(this.agentUserService),
      },
      {
        pattern: 'v3/requestor/+/orgs/+/agent-users/+/command/update',
        handler: this.agentUserService.update.bind(this.agentUserService),
      },

      /* Inbox V3 */

      {
        pattern: 'v3/requestor/+/orgs/+/routing-groups/+/inbox/command/fetch',
        handler: (requestorId, orgId, groupId, { payload }) =>
          this.inboxServiceV3.fetchGroupInbox(orgId, groupId, payload),
        responseTopicBuilder: (requestorId, orgId, groupId) =>
          `v3/requestor/${requestorId}/orgs/${orgId}/routing-groups/${groupId}/inbox`,
      },

      /* Conversation Counts */
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/conversation-counts/command/fetch',
        handler: (requestorId, orgId, agentId) => this.conversationCountService.fetchAgentCounts(orgId, agentId),
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/conversation-counts`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/routing-groups-conversation-counts/command/fetch',
        handler: (requestorId, orgId) => this.conversationCountService.fetchGroupCounts(orgId),
        responseTopicBuilder: (requestorId, orgId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/routing-groups-conversation-counts`,
      },

      /* Agent Read */
      {
        pattern: 'v1/requestor/+/orgs/+/conversation/+/agent-reads/+/command/fetch',
        handler: this.agentReadService.fetch,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/conversation/+/agent-reads/+/command/update',
        handler: this.agentReadService.update,
      },

      /* Customer Extension */
      {
        pattern: 'v1/requestor/+/orgs/+/customer-extensions/+/command/update',
        handler: (requestorId, orgId, customerId, envelope) => {
          this.customerExtensionService.update(orgId, envelope.payload);
        },
        responseTopicBuilder: (requestorId, orgId, customerId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-extensions/${customerId}`,
      },
      /* Compositions */
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/customer-composition-ids/command/fetch',
        handler: (requestorId, orgId, agentId, envelope) =>
          this.compositionService.findCustomerCompositionIds(orgId, agentId, envelope.payload.customerIds),
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/customer-composition-ids`,
      },

      /* Conversations */
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversations/command/add',
        handler: (requestorId, orgId, customerId, envelope) =>
          this.conversationsService.add(orgId, customerId, envelope),
        responseTopicBuilder: (requestorId, orgId, customerId, { payload }) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversations`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversations/command/fetch',
        handler: (requestorId, orgId, customerId) => this.conversationsService.findByCustomerId(orgId, customerId),
        responseTopicBuilder: (requestorId, orgId, customerId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversations`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversations/+/command/update',
        handler: (requestorId, orgId, customerId, conversationId, envelope) =>
          this.conversationsService.update(orgId, customerId, conversationId, envelope),
        responseTopicBuilder: (requestorId, orgId, customerId, conversationId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversations/${conversationId}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversations/+/topicIds/command/update',
        handler: (requestorId, orgId, customerId, conversationId, envelope) =>
          this.conversationsService.updateTopicIds(orgId, customerId, conversationId, envelope),
        responseTopicBuilder: (requestorId, orgId, customerId, conversationId, { payload }) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversations/${conversationId}`,
      },
      /* Conversation Item */
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversation-items/+/command/fetch',
        handler: (requestorId, orgId, customerId, conversationItemId) =>
          this.conversationItemsService.find(orgId, customerId, conversationItemId),
        responseTopicBuilder: (requestorId, orgId, customerId, conversationItemId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversation-items/${conversationItemId}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversation-items/+/command/delete',
        handler: (requestorId, orgId, customerId, conversationItemId) =>
          this.conversationItemsService.delete(orgId, customerId, conversationItemId),
        responseTopicBuilder: (requestorId, orgId, customerId, conversationItemId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversation-items/${conversationItemId}`,
      },
      /* Conversation Items */
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversation-items/command/fetch',
        handler: (requestorId, orgId, customerId, { correlationId, payload }) => {
          if (payload) {
            return this.conversationItemsService.findByQuery(orgId, customerId, payload);
          }
          return this.conversationItemsService.findByCustomerId(orgId, customerId);
        },
        responseTopicBuilder: (requestorId, orgId, customerId, conversationItem) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversation-items`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversation-items/command/add',
        handler: (requestorId, orgId, customerId, envelope) =>
          this.conversationItemsService.add(orgId, customerId, envelope),
        responseTopicBuilder: (requestorId, orgId, customerId, { payload }) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversation-items/${payload.id}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversation-items/+/command/update',
        handler: (requestorId, orgId, customerId, conversationItemId, envelope) =>
          this.conversationItemsService.update(orgId, customerId, conversationItemId, envelope),
        responseTopicBuilder: (requestorId, orgId, customerId, conversationItemId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversation-items/${conversationItemId}`,
      },
      /* Item Ids */
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/item-ids/command/fetch',
        handler: (requestorId, orgId, customerId, { correlationId, payload }) => {
          return this.conversationItemsService.findItemIdsByCustomerId(orgId, customerId);
        },
        responseTopicBuilder: (requestorId, orgId, customerId, conversationItem) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/item-ids`,
      },
      /* Attachment Redaction */
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/conversation-items/+/attachments/+/command/update',
        handler: (requestorId, orgId, customerId, itemId, attachmentId, { correlationId, payload }) => {
          return this.conversationItemsService.redactAttachment(correlationId, orgId, customerId, itemId, attachmentId);
        },
        responseTopicBuilder: (requestorId, orgId, customerId, conversationItemId, attachmentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/conversation-items/${conversationItemId}/attachments/${attachmentId}`,
      },
      /* Current items */
      {
        pattern: 'v1/requestor/+/orgs/+/customer-history/+/current-items/+/command/fetch',
        handler: (requestorId, orgId, customerId, conversationId) =>
          this.conversationItemsService.fetchCurrentItems(orgId, conversationId),
        responseTopicBuilder: (requestorId, orgId, customerId, conversationId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-history/${customerId}/current-items/${conversationId}`,
      },
      /* External Customer Lookup */
      {
        pattern: 'v1/requestor/+/orgs/+/external-customer-lookup/command/fetch',
        handler: (requestorId, orgId, envelope) => {
          return { results: this.externalCustomerLookupService.fetch(orgId, envelope.payload) };
        },
        responseTopicBuilder: (requestorId, orgId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/external-customer-lookup`,
      },
      /* External Customer Lookup Status */
      {
        pattern: 'v1/requestor/+/orgs/+/external-customer-lookup-status/command/fetch',
        handler: (requestorId, orgId) => this.customerLookupConfigurationService.getStatus(orgId),
        responseTopicBuilder: (requestorId, orgId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/external-customer-lookup-status`,
      },
      /* Answer Suggestions */
      {
        pattern: 'v1/requestor/+/orgs/+/customers/+/conversations/+/answer-suggestions/command/fetch',
        handler: (requestorId, orgId, customerId, conversationId, envelope) =>
          this.answerSuggestionService.fetch(requestorId, orgId, customerId, conversationId, envelope),
        responseTopicBuilder: (requestorId, orgId, customerId, conversationId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customers/${customerId}/conversations/${conversationId}/answer-suggestions`,
      },

      /* Topics */
      {
        pattern: 'v1/requestor/+/orgs/+/topics/command/fetch',
        handler: (requestorId, orgId) => this.getDatabase(orgId).topics,
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/topics`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/topics/command/add',
        handler: (requestorId, orgId, { correlationId, payload }) => {
          this.getDatabase(orgId).topics.push(payload);
          this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/topics`, {
            correlationId,
            status: 'success',
          });

          this.publishResponse(`v1/orgs/${orgId}/topics/${payload.id}`, payload);
        },
      },
      {
        pattern: 'v1/requestor/+/orgs/+/topics/+/command/update',
        handler: (requestorId, orgId, topicId, { correlationId, payload }) => {
          let topic = _.find(this.getDatabase(orgId).topics, { id: topicId });
          _.merge(topic, payload);
          this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/topics/${topicId}`, {
            correlationId,
            status: 'success',
          });

          this.publishResponse(`v1/orgs/${orgId}/topics/${topicId}`, topic);
        },
      },
      /* Routing Groups */
      {
        pattern: 'v1/requestor/+/orgs/+/routing-groups/command/fetch',
        handler: this.routingGroupService.findAll.bind(this.routingGroupService),
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/routing-groups`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/routing-groups/+/command/delete',
        handler: this.routingGroupService.delete.bind(this.routingGroupService),
        callbackTopicBuilder: (requestorId, orgId, routingGroupId) =>
          `v1/orgs/${orgId}/routing-groups/${routingGroupId}/event/delete`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/routing-groups/command/add',
        handler: this.routingGroupService.add.bind(this.routingGroupService),
      },
      {
        pattern: 'v1/requestor/+/orgs/+/routing-groups/+/command/update',
        handler: this.routingGroupService.update.bind(this.routingGroupService),
      },
      /* Rules */
      {
        pattern: 'v1/requestor/+/orgs/+/rules/command/fetch',
        handler: this.ruleService.fetch,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/rules/command/add',
        handler: this.ruleService.add,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/rules/+/command/update',
        handler: this.ruleService.update,
      },
      /* Configuration */
      {
        pattern: 'v1/requestor/+/orgs/+/feature-set/command/fetch',
        handler: (requestorId, orgId) => this.getDatabase(orgId).featureSet || {},
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/feature-set`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/configuration/agent-assistance/command/fetch',
        handler: (requestorId, orgId) => this.agentAssistanceConfigurationService.get(orgId),
        responseTopicBuilder: (requestorId, orgId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/configuration/agent-assistance`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/configuration/customer-profile-def/command/fetch',
        handler: (requestorId, orgId) => this.getDatabase(orgId).customerProfileDef,
        responseTopicBuilder: (requestorId, orgId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/configuration/customer-profile-def`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/configuration/company-profile/command/fetch',
        handler: (requestorId, orgId) => this.getDatabase(orgId).companyProfile,
        responseTopicBuilder: (requestorId, orgId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/configuration/company-profile`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/configuration/communication-queues/command/fetch',
        handler: (requestorId, orgId) => this.getDatabase(orgId).communicationQueues || [],
        responseTopicBuilder: (requestorId, orgId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/configuration/communication-queues`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/configuration/voice/command/fetch',
        handler: (requestorId, orgId) => this.getDatabase(orgId).voiceConfiguration,
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/configuration/voice`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/configuration/endpoints/+/command/update',
        handler: (requestorId, orgId, endpointId, { correlationId, payload }) => {
          let endpoint = _.find(this.getDatabase(orgId).channelConfiguration.endpoints, {
            id: endpointId,
          });
          _.assign(endpoint, payload);
          this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/configuration/endpoints/${endpointId}`, {
            correlationId,
            status: 'success',
          });

          this.publishResponse(`v1/orgs/${orgId}/configuration/endpoints/${endpointId}`, endpoint);
        },
      },
      {
        pattern: 'v1/requestor/+/orgs/+/configuration/communication-queues/command/add',
        handler: (requestorId, orgId, { correlationId, payload }) => {
          let response = { correlationId };

          // find matching commQueues based on endpointId, routingSelector and routingGroupId (and rm any undefined/null attrs)
          // (the equivalent of a unique RDBMS index on those 3 columns)
          let commQueueToSearchFor = _(payload)
            .pick(['endpointId', 'routingSelector', 'routingGroupId'])
            .omitBy(_.isUndefined)
            .omitBy(_.isNull)
            .value();
          let matchingCommunicationQueue = _.find(this.getDatabase(orgId).communicationQueues, commQueueToSearchFor);

          if (!matchingCommunicationQueue) {
            this.getDatabase(orgId).communicationQueues.push(payload);
            _.extend(response, { status: 'success' });
            this._pubsub.publish(
              `v1/requestor/${requestorId}/orgs/${orgId}/configuration/communication-queues`,
              response
            );
            this.publishResponse(`v1/orgs/${orgId}/configuration/communication-queues/${payload.id}`, payload);
          } else {
            let error = {
              code: 'taken',
              detail: `communicationQueue with attrs [${_.toArray(
                commQueueToSearchFor
              ).join()}] already taken in fake backend org [${orgId}]`,
            };
            _.extend(response, { status: 'error', payload: { errors: [error] } });
            this._pubsub.publish(
              `v1/requestor/${requestorId}/orgs/${orgId}/configuration/communication-queues`,
              response
            );
          }
        },
      },
      {
        pattern: 'v1/requestor/+/orgs/+/configuration/communication-queues/+/command/update',
        handler: (requestorId, orgId, communicationQueueId, { correlationId, payload }) => {
          let communicationQueue = _.find(this.getDatabase(orgId).communicationQueues, {
            id: communicationQueueId,
          });
          _.assign(communicationQueue, payload);
          this._pubsub.publish(
            `v1/requestor/${requestorId}/orgs/${orgId}/configuration/communication-queues/${communicationQueueId}`,
            {
              correlationId,
              status: 'success',
            }
          );

          this.publishResponse(
            `v1/orgs/${orgId}/configuration/communication-queues/${communicationQueueId}`,
            communicationQueue
          );
        },
      },
      {
        pattern: 'v1/requestor/+/orgs/+/configuration/voice/command/update',
        handler: (requestorId, orgId, { correlationId, payload }) => {
          let config = this.getDatabase(orgId).voiceConfiguration;
          _.merge(config, payload);
          this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/configuration/voice`, {
            correlationId,
            status: 'success',
          });

          this.publishResponse(`v1/orgs/${orgId}/configuration/voice`, config);
        },
      },
      {
        pattern: 'v1/requestor/+/orgs/+/kb-variables/command/fetch',
        handler: (_, orgId) => this.getDatabase(orgId).kbVariables,
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/kb-variables`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/kb-variables/+/command/update',
        handler: (requestorId, orgId, kbVariableId, { correlationId, payload }) => {
          let kbVariable = _.find(this.getDatabase(orgId).kbVariables, { id: kbVariableId });
          _.merge(kbVariable, payload);
          this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/kb-variables/${kbVariableId}`, {
            correlationId,
            status: 'success',
          });

          this.publishResponse(`v1/orgs/${orgId}/kb-variables/${kbVariableId}`, kbVariable);
        },
      },
      {
        pattern: 'v1/requestor/+/orgs/+/search/command/fetch',
        handler: (requestorId, orgId, envelope) =>
          searchCustomers(this.getDatabase(orgId).customers, this.getDatabase(orgId).topics, orgId, envelope),
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/search`,
      },
      {
        pattern: 'v3/requestor/+/orgs/+/search/command/fetch',
        handler: (requestorId, orgId, envelope) =>
          searchAll(
            this.getDatabase(orgId).customers,
            this.getDatabase(orgId).agents,
            this.getDatabase(orgId).routingGroups,
            this.getDatabase(orgId).topics,
            orgId,
            envelope
          ),
        responseTopicBuilder: (requestorId, orgId) => `v3/requestor/${requestorId}/orgs/${orgId}/search`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/search/agents/command/fetch',
        handler: (requestorId, orgId, envelope) =>
          agentSearch(this.getDatabase(orgId).agents, this.getDatabase(orgId).routingGroups, orgId, envelope),
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/search/agents`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/search/snippets/command/fetch',
        handler: (requestorId, orgId, envelope) => this.snippetSearchService.request(orgId, envelope),
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/search/snippets`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/email/attachments/+/command/fetch',
        handler: (requestorId, orgId, attachmentId, envelope) =>
          this.attachmentService.getAttachmentUrl(orgId, attachmentId),
        responseTopicBuilder: (requestorId, orgId, attachmentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/email/attachments/${attachmentId}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/snippets/command/fetch',
        handler: (_, orgId, envelope) => this.snippetService.fetch(orgId, envelope),
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/snippets`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/snippets/command/add',
        handler: (requestorId, orgId, envelope) => this.snippetService.add(requestorId, orgId, envelope),
      },
      {
        pattern: 'v1/requestor/+/orgs/+/snippets/+/command/delete',
        handler: (_, orgId, snippetId) => this.snippetService.delete(orgId, snippetId),
        responseTopicBuilder: (requestorId, orgId, snippetId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/snippets/${snippetId}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/snippets/+/command/update',
        handler: (_, orgId, snippetId, envelope) => this.snippetService.update(orgId, snippetId, envelope),
        responseTopicBuilder: (requestorId, orgId, snippetId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/snippets/${snippetId}`,
      },
      /* Users */
      {
        pattern: 'v1/requestor/+/orgs/+/users/command/fetch',
        handler: this._userService.findAll.bind(this._userService),
        responseTopicBuilder: (requestorId, orgId) => `v1/requestor/${requestorId}/orgs/${orgId}/users`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/users/command/add',
        handler: this._userService.add.bind(this._userService),
      },
      {
        pattern: 'v1/requestor/+/orgs/+/users/+/command/update',
        handler: this._userService.update.bind(this._userService),
      },
      {
        pattern: 'v1/requestor/+/orgs/+/users/+/activation-token/command/update',
        handler: this._userService.updateActivationToken.bind(this._userService),
      },
      /* User API Tokens */
      {
        pattern: 'v1/requestor/+/orgs/+/users/+/tokens/command/add',
        handler: (requestorId, orgId, userId, envelope) => this.apiTokenService.add(orgId, userId, envelope),
        responseTopicBuilder: (requestorId, orgId, userId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/users/${userId}/tokens`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/users/+/tokens/+/command/delete',
        handler: (requestorId, orgId, userId, apiTokenId, { correlationId }) =>
          this.apiTokenService.remove(orgId, userId, apiTokenId, { correlationId }),
      },
      {
        pattern: 'v1/requestor/+/orgs/+/users/+/tokens/command/fetch',
        handler: (requestorId, orgId, userId, envelope) => this.apiTokenService.findAll(orgId, userId),
        responseTopicBuilder: (requestorId, orgId, userId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/users/${userId}/tokens`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/users/+/tokens/+/command/update',
        handler: (requestorId, orgId, userId, apiTokenId, envelope) =>
          this.apiTokenService.update(orgId, userId, apiTokenId, envelope),
        responseTopicBuilder: (requestorId, orgId, userId, apiTokenId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/users/${userId}/tokens/${apiTokenId}`,
      },
      /* Routing */
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/routing-action/command/add',
        handler: (requestorId, orgId, agentId, { correlationId, payload }) => {
          let eventId = payload.eventId;
          let event = this.getDatabase(orgId).events[eventId];
          delete this.getDatabase(orgId).events[eventId];
          if (!event) {
            return;
          }

          let customer = _.find(this.getDatabase(orgId).customers, { id: event.customerId });
          let conversation = _.find(customer.conversations, { id: event.conversationId });
          conversation.assignee.agentId = agentId;
        },
      },
      /* Routing Events */
      {
        pattern: 'v1/orgs/+/agents/+/routing-event',
        handler: (orgId, agentId, { correlationId, payload }) => {
          if (
            payload &&
            (payload.type === RoutingEventType.DECLINED_OFFERED_CALL ||
              payload.type === RoutingEventType.DECLINED_WARM_TRANSFER)
          ) {
            this.incomingCallService.declineIncomingCall(agentId);
          }
        },
      },
      /* Outgoing Communications */
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/outgoing/chats/command/add',
        handler: (requestorId, orgId, agentId, { correlationId, payload }) =>
          this.conversationsService.update(orgId, payload.customerId, payload.conversation.id, {
            correlationId,
            payload: {
              status: Conversation.Status.WAITING,
              newConversationItems: [payload.conversationItem],
            },
          }),
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/outgoing/chats`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/outgoing/chat-sessions/+/command/update',
        handler: (requestorId, orgId, agentId, conversationItemId, { correlationId, payload }) =>
          this.conversationItemsService.update(orgId, payload.customerId, conversationItemId, {
            correlationId,
            payload: {
              content: payload.conversationItem.content,
            },
          }),
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/outgoing/chats`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/outgoing/emails/command/add',
        handler: (requestorId, orgId, agentId, { correlationId, payload }) =>
          this.conversationsService.update(orgId, payload.customerId, payload.conversation.id, {
            correlationId,
            payload: {
              status: Conversation.Status.WAITING,
              newConversationItems: [payload.conversationItem],
            },
          }),
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/outgoing/emails`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/outgoing/sms/command/add',
        handler: (requestorId, orgId, agentId, { correlationId, payload }) =>
          this.conversationsService.update(orgId, payload.customerId, payload.conversation.id, {
            correlationId,
            payload: {
              status: Conversation.Status.WAITING,
              newConversationItems: [payload.conversationItem],
            },
          }),
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/outgoing/sms`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/outgoing/facebook-message/command/add',
        handler: (requestorId, orgId, agentId, { correlationId, payload }) =>
          this.conversationsService.update(orgId, payload.customerId, payload.conversation.id, {
            correlationId,
            payload: {
              status: Conversation.Status.WAITING,
              newConversationItems: [payload.conversationItem],
            },
          }),
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/outgoing/facebook-message`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/outgoing/notes/command/add',
        handler: (requestorId, orgId, agentId, { correlationId, payload }) =>
          this.conversationsService.update(orgId, payload.customerId, payload.conversation.id, {
            correlationId,
            payload: {
              status: Conversation.Status.WAITING,
              newConversationItems: [payload.conversationItem],
            },
          }),
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/outgoing/notes`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/outgoing/tasks/command/add',
        handler: (requestorId, orgId, agentId, { correlationId, payload }) => null,
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/outgoing/tasks`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/outgoing/tasks/+/command/update',
        handler: (requestorId, orgId, agentId, conversationItemId, { correlationId, payload }) => {
          this.conversationItemsService.update(orgId, payload.customerId, conversationItemId, {
            correlationId,
            payload: {
              content: payload.conversationItem.content,
              responder: { type: 'AGENT', id: agentId },
            },
          });
          this._pubsub.publish(`v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/outgoing/tasks`, {
            correlationId,
            status: 'success',
          });
        },
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/outgoing/no-reply-needed/command/add',
        handler: (requestorId, orgId, agentId, { correlationId, payload }) =>
          this.conversationsService.update(orgId, payload.customerId, payload.conversation.id, {
            correlationId,
            payload: {
              status: Conversation.Status.WAITING,
              newConversationItems: [payload.conversationItem],
              sla: () => {
                return undefined;
              },
            },
          }),
        responseTopicBuilder: (requestorId, orgId, agentId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/outgoing/no-reply-needed`,
      },
      /* Agent Uploads */
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/upload-auth/+/command/fetch',
        handler: (requestorId, orgId, agentId, path, { payload }) =>
          this.uploadService.createUploadAuth(orgId, agentId, decodeURIComponent(path), payload),
        responseTopicBuilder: (requestorId, orgId, agentId, path) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/upload-auth/${path}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/upload-urls/+/get/+/command/fetch',
        handler: (requestorId, orgId, agentId, path, urlId) =>
          this.uploadService.createGetUrl(orgId, agentId, { id: urlId, path: decodeURIComponent(path) }),
        responseTopicBuilder: (requestorId, orgId, agentId, path, urlId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/agents/${agentId}/upload-urls/${path}/get/${urlId}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/agents/+/uploads/+/command/delete',
        handler: (requestorId, orgId, agentId, path) =>
          this.uploadService.remove(orgId, agentId, decodeURIComponent(path)),
      },
      /* Shared Uploads */
      {
        pattern: 'v1/requestor/+/orgs/+/upload-auth/+/command/fetch',
        handler: (requestorId, orgId, path, { payload }) =>
          this.sharedUploadService.createUploadAuth(orgId, decodeURIComponent(path), payload),
        responseTopicBuilder: (requestorId, orgId, path) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/upload-auth/${path}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/upload-urls/+/get/+/command/fetch',
        handler: (requestorId, orgId, path, urlId) =>
          this.sharedUploadService.createGetUrl(orgId, { id: urlId, path: decodeURIComponent(path) }),
        responseTopicBuilder: (requestorId, orgId, path, urlId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/upload-urls/${path}/get/${urlId}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/uploads/+/command/delete',
        handler: (requestorId, orgId, path) => this.sharedUploadService.remove(orgId, decodeURIComponent(path)),
      },
      /* Voice Recordings */
      {
        pattern: 'v1/requestor/+/orgs/+/voice/call-recordings/+/urls/+/command/fetch',
        handler: (requestorId, orgId, path, id, envelope) =>
          this.voiceRecordingService.getRecordingUrl(orgId, { id, path }),
        responseTopicBuilder: (requestorId, orgId, path, id) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/voice/call-recordings/${path}/urls/${id}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/voice/voicemail-recordings/+/urls/+/command/fetch',
        handler: (requestorId, orgId, path, id, envelope) =>
          this.voiceRecordingService.getRecordingUrl(orgId, { id, path }),
        responseTopicBuilder: (requestorId, orgId, path, id) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/voice/voicemail-recordings/${path}/urls/${id}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer/+/conversation-items/+/call-recording/command/delete',
        handler: (requestorId, orgId, customerId, conversationItemId) =>
          this.voiceRecordingService.deleteRecording(orgId, customerId, conversationItemId),
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer/+/conversation-items/+/voicemail-recording/command/delete',
        handler: (requestorId, orgId, customerId, conversationItemId) =>
          this.voiceRecordingService.deleteRecording(orgId, customerId, conversationItemId),
      },
      /* Customer Chat */
      {
        pattern: 'v1/requestor/+/orgs/+/customers/+/active/chat/command/fetch',
        handler: (requestorId, orgId, customerId) => {
          return {};
        },
        responseTopicBuilder: (requestorId, orgId, customerId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customers/${customerId}/active/chat`,
      },
      /* Customer Assignee */
      {
        pattern: 'v1/requestor/+/orgs/+/customer-assignee/+/command/fetch',
        handler: (requestorId, orgId, customerId) => this.customerAssigneeService.find(orgId, customerId),
        responseTopicBuilder: (requestorId, orgId, customerId) =>
          `v1/requestor/${requestorId}/orgs/${orgId}/customer-assignee/${customerId}`,
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer-assignee/+/command/update',
        handler: this.customerAssigneeService.update.bind(this.customerAssigneeService),
      },
      {
        pattern: 'v1/requestor/+/orgs/+/customer-assignee/+/command/delete',
        handler: this.customerAssigneeService.remove.bind(this.customerAssigneeService),
      },
    ];
  }

  subscribeServiceHandlers() {
    _.forEach(this.getTopicHandlers(), this.subscribeServiceHandler.bind(this));
  }

  subscribeServiceHandler({ pattern, handler, callbackTopicBuilder, responseTopicBuilder }) {
    this._pubsub.subscribe(pattern, (envelope, actualTopic) => {
      let matches = actualTopic.match(`^${pattern.replace(/\+/g, '([^/]+)')}$`);
      let handlerArgs = matches.slice(1).concat(envelope);

      if (responseTopicBuilder) {
        let responseTopic = responseTopicBuilder.apply(this, handlerArgs);
        let responseEnvelope = { correlationId: envelope.correlationId };
        let responsePayload = handler.apply(this, handlerArgs);
        if (responsePayload) {
          responseEnvelope.payload = responsePayload;
        }
        responseEnvelope.status = responsePayload && responsePayload.errors ? 'error' : 'success';
        this._pubsub.publish(responseTopic, responseEnvelope);
      } else if (callbackTopicBuilder) {
        let responsePayload = handler.apply(this, handlerArgs);
        let responseTopic = callbackTopicBuilder.apply(this, matches.slice(1).concat(responsePayload));
        this.publishResponse(responseTopic, responsePayload);
      } else {
        handler.apply(this, handlerArgs);
      }
    });
  }

  publishResponse(topic, payload) {
    this._pubsub.publish(topic, (payload && { payload }) || {});
  }

  activate() {
    this.subscribeServiceHandlers();
  }
}
