import CancelAITextCompletion from 'actions/ai_text_completions/cancel_ai_text_completion';
import qconsole from 'scripts/lib/qconsole';
import TranslateComposition from 'actions/composition/translate_composition';

export default class TranslateAgainAITextCompletion {
  constructor(context) {
    this.context = context;
  }

  run({ composition, customerId, requestorId, targetLanguage }) {
    const { aiTextCompletions } = this.context.stores.customers.storesFor(customerId);
    const completion = aiTextCompletions.findBy({ id: requestorId });

    if (!completion) {
      return;
    }

    if (composition.translation?.parentLanguage === targetLanguage) {
      return; // No need to translate again if the target language is the same
    }

    this.context.analytics.track('Composition Language Changed', {
      customerId,
      conversationId: composition.conversationId,
      compositionId: composition.id,
      contentType: composition.contentType(),
      rewriteId: completion.rewriteId,
      originalLanguage: composition.translation?.contentLanguage,
      rewriteLanguage: composition.translation?.parentLanguage,
      targetLanguage,
    });

    if (composition.translation?.contentLanguage === targetLanguage) {
      // Selecting the original language effectively means canceling the translation.
      this.context.executeAction(CancelAITextCompletion, { composition, customerId, requestorId, source: 'user' });
      return;
    }

    const text = completion?.data?.text;
    if (!text) {
      qconsole.error('No text exists in text completion for translation into different language');
      return;
    }

    this.context.executeAction(TranslateComposition, {
      composition,
      customerId,
      requestorId,
      targetLanguage,
      text: completion?.data?.initialText,
    });
  }
}
