import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';

export default function setRecommendedAnswerDisposition(ctx, { outcome, recommendedAnswerId }) {
  return ctx.gateways.recommendedAnswerDisposition
    .add({ recommendedAnswerId }, { outcome })
    .then(response => {
      ctx.stores.recommendedAnswers.remove(recommendedAnswerId);
    })
    .catch(err => {
      ctx.executeAction(ShowToast, {
        type: ToastType.ERROR,
        message: 'Recommended answer could not be updated. Try again later.',
      });
    });
}
