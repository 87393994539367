import ErrorReporter from 'scripts/infrastructure/error_reporter';
import { forceUpdateAgentRoutingPreferences } from 'actions/routing/lib/agent_routing_preferences';
import { RoutingChannel } from 'models/agent_routing_preferences';

export default class DeviceStatusObserver {
  constructor(context) {
    this.context = context;
  }

  handleUpdate({ microphone }) {
    let appFeatures = this.context.stores.appFeatures.get();
    let currentMicrophonePermissions = appFeatures.isEnabled('microphone');
    if (currentMicrophonePermissions === microphone) {
      return;
    }

    updateAppFeatures(this.context, 'browserCapabilities', { microphone });
    const currentAgentId = this.context.stores.currentAgent.get().id;
    if (microphone) {
      this.context.gateways.phoneGatewayHttp.registerAgent(currentAgentId);
    } else {
      this.context.gateways.phoneGatewayHttp.deregisterAgent();
      const routingPreferences = this.context.stores.agentRoutingPreferences.get();
      if (routingPreferences.isPreferredOnChannel(RoutingChannel.VOICE)) {
        ErrorReporter.reportMessage('agent preferred for voice with no microphone permission', {});
        forceUpdateAgentRoutingPreferences(this.context, { [RoutingChannel.VOICE]: false });
      }
    }
  }
}

function updateAppFeatures(context, source, updates) {
  let appFeatures = context.stores.appFeatures.get();
  context.stores.appFeatures.set(appFeatures.replace(source, { ...appFeatures[source], ...updates }));
}
