import { noop } from 'lodash';
import NoopOnReconnectGateway from 'scripts/adapters/gateways/noop_on_reconnect_gateway';

export default class ExternalAppCardDataGateway extends NoopOnReconnectGateway {
  constructor(backend) {
    super(backend, {
      addUrl: '/api/v1/orgs/:orgId/external-app-card/data',
      broadcastDeleteTopic: 'v1/orgs/:orgId/external-data/gladly-entity/:gladlyEntityType/:gladlyEntityId/event/delete',
    });
  }

  requestData(params, payload, httpOptions) {
    return this.subscribe(params)
      .then(() => this.add(params, payload, httpOptions))
      .catch(noop);
  }
}
