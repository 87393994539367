export default class UnloadAnswerPerformance {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.answerPerformances.set([]);
    this.context.gateways.recommendedAnswersCount.unsubscribe();
  }
}
