import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function useTranslatedContent(item, getOriginalText, displayOriginal = false) {
  return useShouldTranslate(item, displayOriginal) ? item.translation.content : getOriginalText();
}

export function useShouldTranslate(item, displayOriginal = false) {
  const isTranslationAIEnabled = useIsFeatureEnabled()('translationAI');
  return isTranslationAIEnabled && item.translation && item.translation.content && !displayOriginal;
}
