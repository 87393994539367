import React from 'react';
import Icon from 'components/lib/icons/icon';

/**
 * @visibleName Information Stroke
 */

export default function InformationStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M15 8A7 7 0 111 8a7 7 0 0114 0zm1 0A8 8 0 110 8a8 8 0 0116 0zM9 3v2H7V3h2zm-2 9V7h2v5H7z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
