import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Communicator from 'models/communicator';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import { getLatestConversationAttributes } from 'actions/conversation/lib/conversation_helpers';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';

// Finding the last inbound item is a relatively expensive operation if we have lots of items and we don't want to be
// performing it each time we render an item. So we'll provide them in a context at a higher level so that
// we only need to perform the operation once per items rendering cycle.
export const DEFAULT_CONTEXT = {
  anchorId: undefined,
  anchorUpdatedAt: undefined,
  lastCustomerItem: undefined,
  latestConversation: undefined,
};
const ItemsContext = React.createContext(DEFAULT_CONTEXT);
export default ItemsContext;

export class ItemsContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.anchorId !== state.anchorId ||
      props.anchorUpdatedAt !== state.anchorUpdatedAt ||
      props.lastCustomerItem !== state.lastCustomerItem ||
      !_.isEqual(props.latestConversation, state.latestConversation)
    ) {
      return _.pick(props, ['anchorId', 'anchorUpdatedAt', 'lastCustomerItem', 'latestConversation']);
    }
    return null;
  }

  render() {
    return <ItemsContext.Provider value={this.state}>{this.props.children}</ItemsContext.Provider>;
  }
}

ItemsContextProvider.propTypes = {
  children: PropTypes.node,
  lastCustomerItem: PropTypes.instanceOf(ConversationItem),
  latestConversation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sla: PropTypes.shape({
      dueAt: PropTypes.string,
    }),
  }),
};

export const ItemsContextProviderContainer = connect(mapStateToItemsContextProviderProps)(ItemsContextProvider);

function mapStateToItemsContextProviderProps({ getProvider }) {
  const conversationHistory = getProvider('conversationHistory');

  const latestConversation = getLatestConversationAttributes(getProvider('conversations'), ['id', 'sla']);

  const lastCustomerItem =
    latestConversation &&
    getLatestManualItem({
      conversationHistory,
      conversationId: latestConversation.id,
      filter: item => item.initiator?.type === Communicator.CUSTOMER,
    });

  const currentLocation = getProvider('currentLocation').get();
  return {
    anchorId: currentLocation.currentConversationItemId,
    anchorUpdatedAt: currentLocation.lastRoutedAt,
    lastCustomerItem,
    latestConversation,
  };
}
