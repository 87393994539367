import createModel, { prop } from 'models/lib/create_model';
import Err from 'models/err';

const ExternalAppCardData = createModel({
  modelName: 'ExternalAppCardData',
  properties: {
    id: prop(String).isRequired,
    externalAppCardId: prop(String).isRequired,
    data: prop().oneOf(Array, Boolean, Number, String, Object),
    errors: prop([Err]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    EntityType: Object.freeze({
      CUSTOMER: 'CUSTOMER',
    }),
  },
});

export default ExternalAppCardData;
