import LANGUAGES from './languages';

const TranslationLanguages = Object.freeze({
  af: LANGUAGES.af,
  ar: LANGUAGES.ar,
  hy: LANGUAGES.hy,
  az: LANGUAGES.az,
  be: LANGUAGES.be,
  bs: LANGUAGES.bs,
  bg: LANGUAGES.bg,
  ca: LANGUAGES.ca,
  'zh-hans': LANGUAGES['zh-hans'],
  hr: LANGUAGES.hr,
  cs: LANGUAGES.cs,
  da: LANGUAGES.da,
  nl: LANGUAGES.nl,
  en: LANGUAGES.en,
  et: LANGUAGES.et,
  fil: LANGUAGES.fil,
  fi: LANGUAGES.fi,
  fr: LANGUAGES.fr,
  gl: LANGUAGES.gl,
  ka: LANGUAGES.ka,
  de: LANGUAGES.de,
  el: LANGUAGES.el,
  he: LANGUAGES.he,
  hi: LANGUAGES.hi,
  hu: LANGUAGES.hu,
  is: LANGUAGES.is,
  id: LANGUAGES.id,
  it: LANGUAGES.it,
  ja: LANGUAGES.ja,
  kn: LANGUAGES.kn,
  kk: LANGUAGES.kk,
  ko: LANGUAGES.ko,
  lv: LANGUAGES.lv,
  lt: LANGUAGES.lt,
  mk: LANGUAGES.mk,
  ms: LANGUAGES.ms,
  mr: LANGUAGES.mr,
  mi: LANGUAGES.mi,
  ne: LANGUAGES.ne,
  no: LANGUAGES.no,
  fa: LANGUAGES.fa,
  pl: LANGUAGES.pl,
  pt: LANGUAGES.pt,
  ro: LANGUAGES.ro,
  ru: LANGUAGES.ru,
  sr: LANGUAGES.sr,
  sidekick: LANGUAGES.sidekick,
  sk: LANGUAGES.sk,
  sl: LANGUAGES.sl,
  es: LANGUAGES.es,
  sw: LANGUAGES.sw,
  sv: LANGUAGES.sv,
  tl: LANGUAGES.tl,
  ta: LANGUAGES.ta,
  th: LANGUAGES.th,
  tr: LANGUAGES.tr,
  uk: LANGUAGES.uk,
  ur: LANGUAGES.ur,
  vi: LANGUAGES.vi,
  cy: LANGUAGES.cy,
});

export default TranslationLanguages;
