import React, { useContext, useMemo, useState } from 'react';

import connect from 'components/lib/connect';
import CustomerContext from 'components/customer/customer_context';
import ItemsContext from 'components/customer/conversation_history/conversation_items_v2/items_context';

export const DEFAULT_AI_TEXT_COMPLETION_CONTEXT = {
  // The current completion for a given requestorId
  completion: null,
  isLoading: false,
  // When an agent uses a completion, this function will be invoked, setting textToInsert, which
  // will be inserted into the editor after the editor exits readOnly mode.
  setTextToInsert: () => {},
  targetLanguage: null,
  textToInsert: null,
  // A unique ID for any given text completion context. Theoretically you could have multiple
  // simultaneous completions active on a page; maybe one for a note composer and one for a task
  // comment composer. But we never need more than one active completion at a time per context.
  requestorId: null,
};

const AITextCompletionContext = React.createContext(DEFAULT_AI_TEXT_COMPLETION_CONTEXT);

export function AiTextEditContextProviderBase({ children, completion, isLoading, requestorId }) {
  const { customerLanguage } = useContext(CustomerContext);
  const { lastCustomerItem } = useContext(ItemsContext);
  const [textToInsert, setTextToInsert] = useState(null);

  const targetLanguage = customerLanguage?.code || lastCustomerItem?.translation?.parentLanguage;

  const value = useMemo(
    () => ({
      completion,
      isLoading,
      requestorId,
      setTextToInsert,
      targetLanguage,
      textToInsert,
    }),
    [completion, requestorId, isLoading, targetLanguage, textToInsert]
  );

  return <AITextCompletionContext.Provider value={value}>{children}</AITextCompletionContext.Provider>;
}

const AITextCompletionContextProvider = connect(mapStateToProps)(AiTextEditContextProviderBase);

function mapStateToProps({ getProvider }, { requestorId }) {
  const completion = getProvider('aiTextCompletions').findBy({ id: requestorId });
  return {
    completion,
    isLoading: getProvider('aiTextCompletions').isLoading({ id: requestorId }),
  };
}

export default AITextCompletionContext;
export { AITextCompletionContextProvider };
