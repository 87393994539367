import createModel, { prop } from 'models/lib/create_model';
import Err from 'models/err.js';

const ExternalAppCardTemplate = createModel({
  modelName: 'ExternalAppCardTemplate',
  properties: {
    id: prop(String).isRequired,
    externalAppCardId: prop(String).isRequired,
    template: prop(String).isRequired,
    errors: prop([Err]),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default ExternalAppCardTemplate;
