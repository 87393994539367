import { Factory } from 'rosie';
import '../conversation_item_factory';

Factory.define('VoiceAIRecording').attr('type', 'VOICE_AI_RECORDING');

Factory.define('VoiceAIRecordingDefaults')
  .extend('VoiceAIRecording')
  .attr('automationSessionId', 'automation-session-id')
  .attr('callId', 'call-id')
  .attr('companyNumber', '+14153334444')
  .attr('customerNumber', '+6013299800')
  .attr('recordingUrl', 'http://example.com/recording.mp3')
  .attr('recordingDuration', 30)
  .attr('recordingId', 'recording-id')
  .attr('recordingStatus', 'AVAILABLE');

Factory.define('VoiceAIRecordingItem')
  .extend('ConversationItem')
  .attr('content', ['content'], content => Factory.attributes('VoiceAIRecording', content));

Factory.define('VoiceAIRecordingItemWithDefaults')
  .extend('ConversationItemWithDefaults')
  .attr('content', ['content'], content => Factory.attributes('VoiceAIRecordingDefaults', content));
