// USAGE: import this file to access Factory and all factories:
//        `import Factory from 'factories/all';`

import { Factory } from 'rosie';

/*GMOINSERT*/
import './account_factory';
import './active_call_factory';
import './active_session_factory';
import './agent_activity_factory';
import './agent_assistance_config';
import './agent_factory';
import './agent_inbox_item_factory';
import './agent_profile_factory';
import './agent_read_factory';
import './agent_routing_preferences_factory';
import './agent_search_hit_factory';
import './agent_search_result_factory';
import './agent_status_factory';
import './agent_status_reason_factory';
import './agent_voice_configuration_factory';
import './ai_text_completion';
import './conversation_items/ai_activity_item_factory';
import './answer_panel_factory';
import './answer_panel_state';
import './answer_performance_factory';
import './answer_preview_factory';
import './answer_suggestions_factory';
import './api_token';
import './api_token_demo';
import './app_features_factory';
import './assignee_factory';
import './attachment_factory';
import './audience_factory';
import './auth_factory';
import './auto_accepted_session_notification_factory';
import './available_language_factory';
import './channel_configuration_factory';
import './chat_widget_configuration_factory';
import './communication_queue_factory';
import './company_profile_factory';
import './composition_content_factory';
import './composition_factory';
import './conversation_factory';
import './conversation_item_factory';
import './conversation_item_search_hit_factory';
import './conversation_items';
import './conversation_message_attachment_factory';
import './conversation_notification_factory';
import './conversation_preview_factory';
import './conversation_workflow_config_factory';
import './embed_tokens_report_factory';
import './custom_attribute_factory';
import './custom_data_object_factory';
import './customer_assignee_factory';
import './customer_email_address_factory';
import './customer_factory';
import './customer_lookup_config_factory';
import './customer_match_factory';
import './customer_note_factory';
import './customer_profile_search_hit_factory';
import './customer_username_address_factory';
import './custom_channel_factory';
import './conversation_items/custom_channel_message_factory';
import './custom_channel_message_incoming_attachment_factory';
import './custom_channel_message_outgoing_attachment_factory';
import './declined_reassigned_session_notification_factory';
import './disconnect_notification';
import './email_suggestion_factory';
import './embedded_report_factory';
import './endpoint_factory';
import './error_notification_factory';
import './external_customer_lookup_results_factory';
import './fb_message_incoming_attachment_factory';
import './fb_message_outgoing_attachment_factory';
import './fb_page_account_content_factory';
import './focus_mode_disabled_notification_factory';
import './greeting_suggestion_factory';
import './inbound_communication_session_notification_factory';
import './inbound_message_notification_factory';
import './inbox_count_factory';
import './integration_factory';
import './item_id_factory';
import './ivr_factory';
import './ivr_node_factory';
import './kb_variable_factory';
import './keyword_group_factory';
import './language_factory';
import './lib/id';
import './lib/uuid';
import './liveboard_factory';
import './location';
import './location/answer_performance_view_factory';
import './location_history_factory';
import './messaging_configuration_factory';
import './offered_call_notification_factory';
import './offered_warm_transfer_notification_factory';
import './optional_upgrade_notification';
import './phone_call_observer_notification_factory';
import './phone_number_factory';
import './phrase_suggestion_factory';
import './recommended_answer_count_factory';
import './relationship_factory';
import './recommended_answer_factory';
import './relationship_label_factory';
import './report_builder_config_factory';
import './report_config_factory';
import './routable_counts_factory';
import './routing_group_factory';
import './routing_queue_item_factory';
import './rule_factory';
import './search_aggregations_factory';
import './search_query_factory';
import './search_result_factory';
import './secure_data_request_factory';
import './selected_kb_item';
import './selected_suggested_reply_factory';
import './session_ended_factory';
import './sidekick_orgconfiguration_factory';
import './sla_factory';
import './sms_message_attachment_factory';
import './snippet_factory';
import './snippet_revision_factory';
import './snippet_search_result_factory';
import './station_configuration_factory';
import './suggested_reply_factory';
import './system_configuration_factory';
import './task_comment_factory';
import './task_comment_api_factory';
import './task_follower_factory';
import './task_info_factory';
import './task_notification_factory';
import './task_preview_factory';
import './team_factory';
import './toast_factory';
import './topic_factory';
import './topic_ancestry_factory';
import './topic_suggestions_factory';
import './transaction_dto_factory';
import './transaction_factory';
import './translation_factory';
import './unoffered_call_notification_factory';
import './unoffered_warm_transfer_notification_factory';
import './upload_factory';
import './user_factory';
import './voice_configuration_factory';
import './vgs_account_content_factory';
import './webhook_factory';
import './widget_factory';

export default Factory;
