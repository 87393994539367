import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import BubbleContent from '../content/bubble_content';
import CircleIcon from 'components/lib/icons/circle_icon';
import CollapsibleItem from '../content/collapsible_item';
import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import { formatPhoneNumber } from 'models/phone_number';
import { HandleHeightChange } from 'components/customer/conversation_history/virtualized/list_item';
import ItemContent from '../item_content';
import ItemMetadata from '../item_metadata';
import PauseOverlay from 'components/customer/conversation_history/conversation_items_v2/phone/pause_overlay';
import PhoneCall from 'models/phone_call';
import PhoneCallWavePlayerContainer from './recordings/phone_call_wave_player_container';
import RecordingStatus from 'models/recording_status';
import Timer from 'components/lib/date/timer';
import RecordingDetails from './recording_details';

export function PhoneCallItem({ className, isCollapsed, item, onToggleCollapse }) {
  // Do not render phone call items until they have registered callbacks so we can determine
  // whether or not a call with the Receiving status is abandoned or incoming
  if (item.content.isReceiving()) {
    return null;
  }

  let contentClasses = classnames(
    'phoneCallItem-content',
    {
      'phoneCallItem-content-abandoned': item.content.isAbandonedCall(),
    },
    className
  );

  return (
    <ItemContent isCollapsed={isCollapsed} item={item}>
      <ItemMetadata
        isCollapsed={isCollapsed}
        item={item}
        onToggleCollapse={onToggleCollapse}
        tooltip={getCallTooltip()}
      />
      <BubbleContent
        className={contentClasses}
        data-aid={`phoneCallItem-${item?.id}`}
        isCollapsed={isCollapsed}
        item={item}
      >
        <PhoneCallContent item={item} />
        <RecordingDetails recordingSummary={item.content.recordingSummary} />
      </BubbleContent>
    </ItemContent>
  );

  function getCallTooltip() {
    const isOutgoing = item.initiator.type === Communicator.AGENT;
    const fromNumber = isOutgoing ? item.content.companyNumber : item.content.customerNumber;
    const toNumber = isOutgoing ? item.content.customerNumber : item.content.companyNumber;

    return `called ${formatPhoneNumber(toNumber)} from ${formatPhoneNumber(fromNumber)}`;
  }
}

PhoneCallItem.propTypes = {
  className: PropTypes.string,
  isCollapsed: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onToggleCollapse: PropTypes.func,
};

const PhoneCallStatusLabels = Object.freeze({
  [PhoneCall.Status.ABANDONED]: 'Abandoned Call',
  [PhoneCall.Status.COMPLETED]: 'Abandoned Call',
  [PhoneCall.Status.ERROR]: 'Call Error',
  [PhoneCall.Status.INCOMING]: 'Incoming call',
  [PhoneCall.Status.NO_ANSWER]: 'No Answer',
  [PhoneCall.Status.OFFERING]: 'Offered to agent',
  [PhoneCall.Status.OUTGOING]: 'Outgoing call',
  [PhoneCall.Status.QUEUED]: 'Queued',
});

export function PhoneCallContent({ item }) {
  const phoneCall = item.content;

  switch (phoneCall.status) {
    case PhoneCall.Status.INCOMING:
    case PhoneCall.Status.OFFERING:
    case PhoneCall.Status.QUEUED:
      return renderIncomingCall();
    case PhoneCall.Status.IN_PROGRESS:
    case PhoneCall.Status.WARM_TRANSFER: {
      return phoneCall.isInteractiveCall() ? renderLiveInteractiveCall() : renderLiveNonInteractiveCall();
    }
    case PhoneCall.Status.OUTGOING:
      return renderOutgoingCall();
    case PhoneCall.Status.COMPLETED:
      if (!phoneCall.isAbandonedCall()) {
        return renderFinishedCall();
      }
      return renderFailedCall();
    case PhoneCall.Status.ABANDONED:
    case PhoneCall.Status.NO_ANSWER:
    case PhoneCall.Status.ERROR:
      return renderFailedCall();
    default:
      return null;
  }

  function renderIncomingCall() {
    return <div className="phoneCallItem-incoming">{PhoneCallStatusLabels[phoneCall.status]}…</div>;
  }

  function renderLiveInteractiveCall() {
    let recordingStatus = phoneCall.getLatestRecordingEventStatus();
    let isRecordingEnabled = recordingStatus !== PhoneCall.RecordingStatus.NOT_RECORDING;
    let isPaused = recordingStatus === PhoneCall.RecordingStatus.PAUSED;

    return (
      <div className="voiceItem-callWave">
        <div
          className={classnames('voiceItem-waveForm', 'voiceItem-waveForm-playAnimation', {
            'voiceItem-waveForm-animation-pause': isPaused,
          })}
        >
          <div className="voiceItem-waveForm-progress">
            <div
              className={classnames('voiceItem-waveForm-animationBlocker', {
                'voiceItem-waveForm-animation-pause': isPaused,
              })}
            />
          </div>
        </div>
        {isRecordingEnabled ? renderCallTimer(isPaused) : null}
        <Timer className="voiceItem-timer" startTime={phoneCall.answeredAt || phoneCall.startedAt} />
        <PauseOverlay isPaused={isPaused} />
      </div>
    );
  }

  function renderLiveNonInteractiveCall() {
    return (
      <div className="voiceItem-stationCall voiceItem-inProgress">
        <div className="voiceItem-stationCallStatus">Live call</div>
        <Timer className="voiceItem-timer" startTime={phoneCall.answeredAt || phoneCall.startedAt} />
      </div>
    );
  }

  function renderCallTimer(isPaused) {
    let recordingIconClassNames = classnames('voiceItem-timer-recordingStatus', {
      'recordingIcon-inactive': isPaused,

      'recordingIcon-active': !isPaused,
    });
    return <CircleIcon className={recordingIconClassNames} key="voiceItem-recordingStatus" />;
  }

  function renderOutgoingCall() {
    return <div className="phoneCallItem-outgoing">{PhoneCallStatusLabels[phoneCall.status]}…</div>;
  }

  function renderFinishedCall() {
    if (!phoneCall.isInteractiveCall()) {
      return (
        <div className="voiceItem-stationCall voiceItem-completed">
          <div className="voiceItem-stationCallStatus">Call ended</div>
          <div className="voiceItem-duration">{getCallDuration()}</div>
        </div>
      );
    }
    if (
      phoneCall.getCustomerParticipantStatus() === PhoneCall.ParticipantStatus.NO_ANSWER ||
      phoneCall.getCustomerParticipantStatus() === PhoneCall.ParticipantStatus.CANCELED
    ) {
      return <div className="phoneCallItem-noAnswer">{PhoneCallStatusLabels[PhoneCall.Status.NO_ANSWER]}</div>;
    }

    if (phoneCall.recordingStatus === RecordingStatus.AVAILABLE) {
      return <PhoneCallWavePlayerContainer item={item} />;
    }

    if (phoneCall.recordingStatus === RecordingStatus.DELETED) {
      return <div className="voiceItem-recordingDeleted">Recording has been deleted</div>;
    }

    if (phoneCall.recordingStatus === RecordingStatus.ERROR) {
      return <div>Recording not available due to error</div>;
    }

    return (
      <div className="voiceItem-callWave">
        <div className="voiceItem-waveForm" />
        <div className="voiceItem-duration">{getCallDuration()}</div>
      </div>
    );
  }

  function renderFailedCall() {
    return <div className="phoneCallItem-failed">{PhoneCallStatusLabels[phoneCall.status]}</div>;
  }

  function getCallDuration() {
    return Timer.displayDuration(phoneCall.calculateDuration() * 1000 || 0);
  }
}
PhoneCallContent.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

const CollapsiblePhoneCallItem = CollapsibleItem(PhoneCallItem);
export { CollapsiblePhoneCallItem };

function CollapsedByDefaultPhoneCallItem(props) {
  return (
    <HandleHeightChange isManual>
      {({ onHeightChange }) => (
        <CollapsiblePhoneCallItem
          collapseByDefault={
            props.item.content.hasVoicemail &&
            props.item.content.recordingStatus !== RecordingStatus.DELETED &&
            _.isEmpty(props.item.linkedItems)
          }
          isCollapsible={
            props.item.content.hasVoicemail && props.item.content.recordingStatus !== RecordingStatus.DELETED
          }
          {...props}
          onHeightChange={onHeightChange}
        />
      )}
    </HandleHeightChange>
  );
}
CollapsedByDefaultPhoneCallItem.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onHeightChange: PropTypes.func,
};

export default CollapsedByDefaultPhoneCallItem;
