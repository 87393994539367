import createEnum from 'scripts/lib/create_enum';
import LANGUAGES from './languages';

const AnswerLanguages = Object.freeze({
  'zh-hans': LANGUAGES['zh-hans'],
  'zh-hant': LANGUAGES['zh-hant'],
  da: LANGUAGES.da,
  nl: LANGUAGES.nl,
  'nl-be': LANGUAGES['nl-be'],
  'en-au': LANGUAGES['en-au'],
  'en-ca': LANGUAGES['en-ca'],
  'en-150': LANGUAGES['en-150'],
  'en-gb': LANGUAGES['en-gb'],
  'en-ie': LANGUAGES['en-ie'],
  'en-nz': LANGUAGES['en-nz'],
  'en-us': LANGUAGES['en-us'],
  et: LANGUAGES.et,
  fil: LANGUAGES.fil,
  fi: LANGUAGES.fi,
  fr: LANGUAGES.fr,
  'fr-be': LANGUAGES['fr-be'],
  'fr-ca': LANGUAGES['fr-ca'],
  ka: LANGUAGES.ka,
  el: LANGUAGES.el,
  de: LANGUAGES.de,
  he: LANGUAGES.he,
  hi: LANGUAGES.hi,
  hu: LANGUAGES.hu,
  is: LANGUAGES.is,
  id: LANGUAGES.id,
  it: LANGUAGES.it,
  ja: LANGUAGES.ja,
  ko: LANGUAGES.ko,
  lv: LANGUAGES.lv,
  lt: LANGUAGES.lt,
  ms: LANGUAGES.ms,
  no: LANGUAGES.no,
  pl: LANGUAGES.pl,
  'pt-br': LANGUAGES['pt-br'],
  'pt-pt': LANGUAGES['pt-pt'],
  ro: LANGUAGES.ro,
  ru: LANGUAGES.ru,
  sr: LANGUAGES.sr,
  'sr-me': LANGUAGES['sr-me'],
  sidekick: LANGUAGES.sidekick,
  sk: LANGUAGES.sk,
  sl: LANGUAGES.sl,
  'es-419': LANGUAGES['es-419'],
  'es-mx': LANGUAGES['es-mx'],
  'es-es': LANGUAGES['es-es'],
  'es-us': LANGUAGES['es-us'],
  sv: LANGUAGES.sv,
  th: LANGUAGES.th,
  tr: LANGUAGES.tr,
  uk: LANGUAGES.uk,
  vi: LANGUAGES.vi,
});

export const RTL_LANGUAGES = createEnum('ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi');

export const lngDirection = language => {
  if (RTL_LANGUAGES[language]) {
    return 'rtl';
  }
  return 'ltr';
};

export default AnswerLanguages;
