import _ from 'lodash';

import AgentRead from 'models/agent_read';
import AITextCompletion from 'models/ai_text_completion';
import AISuggestedReply from 'models/ai_suggested_reply';
import Composition from 'models/composition';
import ConversationHistoryStore from './conversation_history_store';
import ConversationItem from 'models/conversation_item';
import ConversationsStore from './conversations_store';
import createCollectionStoreClass from './lib/create_collection_store_class';
import createConverter from 'scripts/adapters/immutable_converters/lib/create_converter';
import createDocumentStoreClass from './lib/create_document_store_class';
import createMultiDocumentStoreClass from 'scripts/adapters/stores/lib/create_multidocument_store_class';
import CustomerConverter from '../immutable_converters/customer_converter';
import ExternalAppCardData from 'models/external_apps/external_app_card_data';
import ExternalDataObjectEnvelope from 'models/external_data_objects/external_data_object_envelope';
import CustomerAssignee from 'models/customer_assignee';
import CustomerMatch from 'models/customer_match';
import CustomerNote from 'models/customer_note';
import CustomerProfileStore from './customer_profile_store';
import { ExternalCustomerLookupResult } from 'models/external_customer_profile';
import ItemIdsStore from './item_ids_store';
import Language from 'models/language';
import Relationship from 'models/relationship';
import SuggestedReply from 'models/suggested_reply';
import Transaction from 'models/transaction';

const AgentReadStore = createDocumentStoreClass({ converter: createConverter(AgentRead) });
const AITextCompletionsStore = createMultiDocumentStoreClass({ converter: createConverter(AITextCompletion) });
const AISuggestedReplyStore = createDocumentStoreClass({ converter: createConverter(AISuggestedReply) });
const CustomerLanguageStore = createDocumentStoreClass({ converter: createConverter(Language) });
const CustomerAssigneeStore = createDocumentStoreClass({ converter: createConverter(CustomerAssignee) });
const CustomerNoteStore = createDocumentStoreClass({ converter: createConverter(CustomerNote) });
const CustomerMatchStore = createDocumentStoreClass({ converter: createConverter(CustomerMatch) });
const ExternalLookupSuggestionsStore = createDocumentStoreClass({
  converter: createConverter(ExternalCustomerLookupResult),
});
const ActiveSecureDataRequestsStore = createCollectionStoreClass({ converter: createConverter(ConversationItem) });
const RelationshipsStore = createCollectionStoreClass({ converter: createConverter(Relationship) });
const SuggestedReplyStore = createDocumentStoreClass({ converter: createConverter(SuggestedReply) });
const TransactionsStore = createCollectionStoreClass({ converter: createConverter(Transaction) });
const CompositionsStore = createCollectionStoreClass({ converter: createConverter(Composition) });
const ExternalAppCardDataStore = createMultiDocumentStoreClass({
  converter: createConverter(ExternalAppCardData),
});
const ExternalDataObjectStore = createMultiDocumentStoreClass({
  converter: createConverter(ExternalDataObjectEnvelope),
});

export default class CustomersStore extends createCollectionStoreClass({ converter: CustomerConverter }) {
  constructor(...args) {
    super(...args);
    this.staleCustomerIds = new Set();
  }

  unmarkCustomerAsStale(customerId) {
    this.staleCustomerIds.delete(customerId);
  }

  isCustomerStale(customerId) {
    return this.staleCustomerIds.has(customerId);
  }

  markAllCustomersAsStale() {
    this.getAllCustomerIds().forEach(customerId => {
      this.staleCustomerIds.add(customerId);
    });
  }

  getAllCustomerIds() {
    return this.immutableStore.binding
      .get()
      .keySeq()
      .toArray();
  }

  storesFor(customerId) {
    if (!this._customerStores) {
      this._customerStores = {};
    }

    let customerStore = this._customerStores[customerId]; // Check cache before more costly ops
    if (customerStore) {
      return customerStore;
    }

    this.immutableStore.find(customerId); // Throws if customer not yet in store.

    const binding = this.binding.sub(customerId);
    const conversationHistoryStore = new ConversationHistoryStore(binding.sub('conversationHistory'));
    customerStore = {
      activeSecureDataRequests: new ActiveSecureDataRequestsStore(binding.sub('activeSecureDataRequests')),
      agentRead: new AgentReadStore(binding.sub('agentRead')),
      aiSuggestedReply: new AISuggestedReplyStore(binding.sub('aiSuggestedReply')),
      aiTextCompletions: new AITextCompletionsStore(binding.sub('aiTextCompletions')),
      assignee: new CustomerAssigneeStore(binding.sub('assignee')),
      // Use "customer" qualifier to avoid a possible conflict with other kinds of languages when customer stores are flattened
      customerLanguage: new CustomerLanguageStore(binding.sub('language')),
      customerMatch: new CustomerMatchStore(binding.sub('customerMatch')),
      compositions: new CompositionsStore(binding.sub('compositions')),
      conversationHistory: conversationHistoryStore,
      conversations: new ConversationsStore(binding.sub('conversations'), conversationHistoryStore),
      externalAppCardData: new ExternalAppCardDataStore(binding.sub('externalAppCardData')),
      externalDataObjects: new ExternalDataObjectStore(binding.sub('externalDataObjects')),
      externalLookupSuggestions: new ExternalLookupSuggestionsStore(binding.sub('externalLookupSuggestions')),
      itemIds: new ItemIdsStore(binding.sub('itemIds')),
      note: new CustomerNoteStore(binding.sub('note')),
      profile: new CustomerProfileStore(binding.sub('profile')),
      relationships: new RelationshipsStore(binding.sub('relationships')),
      suggestedReply: new SuggestedReplyStore(binding.sub('suggestedReply')),
      transactions: new TransactionsStore(binding.sub('transactions')),
    };
    this._customerStores[customerId] = customerStore;
    return customerStore;
  }

  clearCachedStoresFor(customerId) {
    if (this._customerStores && customerId) {
      delete this._customerStores[customerId];
    }
  }
}

export class CustomersProvider {
  constructor(customersStore) {
    this._customersStore = customersStore;
  }

  get bindings() {
    return [this._customersStore.binding.meta('newEntity.pending')]; // We never want to subscribe to all customers.. bad for perfomance
  }

  has(customerId) {
    return this._customersStore.has({ id: customerId });
  }

  getProvider(customerId) {
    return new CustomerProvider(this._customersStore, customerId);
  }

  isPendingNew() {
    return this._customersStore.isPendingNew();
  }
}

export class CustomerProvider {
  constructor(customersStore, customerId) {
    this._customerStores = customersStore.storesFor(customerId);
    _.forEach(this._customerStores, (store, name) => {
      this[name] = store.getProvider();
    });
  }

  get bindings() {
    return _(this._customerStores)
      .values()
      .map(store => store.getProvider().bindings)
      .flatten()
      .compact();
  }

  getProvider(storeName) {
    return this._customerStores[storeName].getProvider();
  }
}
