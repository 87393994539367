import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FlippableChevron from '../content/flippable_chevron';
import Linkifier from 'components/lib/linkifier';
import RelativeDateTime from 'components/lib/date/relative_date_time';
import TimestampFormatter from 'components/lib/date/lib/timestamp_formatter';
import Tooltip from 'components/common/tooltip';
import useWindowSize from 'components/hooks/use_window_size';
import AITranslateIcon from 'components/lib/icons/ai_translate';

export default function Metadata({
  className,
  hasError,
  icon,
  iconClass,
  translationIcon,
  translationIconClass,
  isCollapsed,
  isInbound,
  object,
  onToggleCollapse,
  prepositionalPhrases,
  status,
  subject,
  text,
  language,
  timestamp,
  tooltip,
}) {
  const classes = classnames('itemMetadata', className, {
    'itemMetadata-outbound': !isInbound,
  });
  icon = icon || (iconClass && <i className={classnames('itemMetadata-icon', iconClass)} />);
  const renderedSubject = subject && <span className={'itemMetadata-subject'}>{subject} </span>;
  const renderedText = text && <Linkifier className="itemMetadata-text">{text}</Linkifier>;
  const renderedObject = object && <span className={'itemMetadata-object'}>{object}</span>;

  return (
    <span className={classes} title={tooltip}>
      {icon}
      {renderTranslationIcon()}
      {renderedSubject}
      {renderedText}
      {renderedObject}
      {prepositionalPhrases}
      {renderLanguage()} – <TimestampMemo timestamp={timestamp} />
      {renderStatus()}
      {renderCollapseExpandIcon()}
    </span>
  );

  function renderStatus() {
    if (!status) {
      return null;
    }
    const classNames = classnames('itemMetadata-status', {
      'itemMetadata-status-error': hasError,
    });
    return (
      <StatusStyled className={classNames}>
        {` ${status}`}
        {status === 'sending' && (
          <>
            <span className="itemMetadata-status-sending">.</span>
            <span className="itemMetadata-status-sending">.</span>
            <span className="itemMetadata-status-sending">.</span>
          </>
        )}
      </StatusStyled>
    );
  }

  function renderLanguage() {
    if (!language) {
      return null;
    }
    return (
      <span className={'itemMetadata-languagePhrase'}>
        {' '}
        in <StyledStrong className={'itemMetadata-language'}>{language}</StyledStrong>
      </span>
    );
  }

  function renderTranslationIcon() {
    if (!language) {
      return null;
    }
    return (
      <span className={classnames('itemMetadata-translationIcon', 'itemMetadata-fontawesome', translationIconClass)}>
        {translationIcon || (
          <TranslationIconStyled>
            <AITranslateIcon />
          </TranslationIconStyled>
        )}
      </span>
    );
  }

  function renderCollapseExpandIcon() {
    if (!onToggleCollapse) {
      return null;
    }

    return <FlippableChevron isDownwards={isCollapsed} onClick={onToggleCollapse} />;
  }
}

// NOTE: Grammar example for below – "Tom quickly walks the dog to the park.""
Metadata.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  icon: PropTypes.node, // will override icon class
  iconClass: PropTypes.string, // classes (if any) to give the font-awesome icon
  translationIcon: PropTypes.node, // will override translation icon class
  translationIconClass: PropTypes.string, // optional classes to apply to the translation icon
  isCollapsed: PropTypes.bool,
  isInbound: PropTypes.bool,
  object: PropTypes.string, // the entity that is acted upon in the subject – THE DOG
  onToggleCollapse: PropTypes.func,
  prepositionalPhrases: PropTypes.node,
  status: PropTypes.string,
  subject: PropTypes.node, // the noun in the sentence which is being acted upon by the verb – TOM
  text: PropTypes.node, // the verb, adverbs, etc which come between the subject and object of the sentence – QUICKLY WALKS
  language: PropTypes.string, // language of the transmitted message
  timestamp: PropTypes.string.isRequired,
  tooltip: PropTypes.string, // string to render in a tooltip (currently uses title)
};

function Timestamp({ timestamp }) {
  let tf = new TimestampFormatter(timestamp);
  let fullTime = tf.full();

  const { windowHeight, windowWidth } = useWindowSize();
  const bounds = {
    // Bottom bar is 60 px height, 8px for padding
    bottom: windowHeight - 68,
    left: 8,
    right: windowWidth - 8,
    // Top bar is 60 px height, 8 px for padding
    top: 68,
  };

  return (
    <Tooltip
      bound={bounds}
      delay={300}
      hoverableTooltip
      margin={5}
      message={<span>{fullTime}</span>}
      position="top"
      style={{ padding: '4px 8px' }}
    >
      <RelativeStyled className="itemMetadata-timestamp" timestamp={timestamp} title="" />
    </Tooltip>
  );
}

const RelativeStyled = styled(RelativeDateTime)`
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.gray700};
    text-decoration: underline;
  }
`;

const StatusStyled = styled.span`
  display: inline-block;
  margin-left: 12px;
`;

const StyledStrong = styled.span`
  font-weight: bold;
`;

const TranslationIconStyled = styled.span`
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  color: ${p => p.theme.colors.gray800};
  svg {
    width: 14px;
    height: 14px;
  }
`;

const TimestampMemo = React.memo(Timestamp);
export { TimestampMemo };
