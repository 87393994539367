import _ from 'lodash';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';

export const CommunicationSessionStatus = createEnum('ACTIVE', 'ENDED');
export const TaskStatus = createEnum('OPEN', 'CLOSED');

const AgentActivity = createModel({
  modelName: 'AgentActivity',

  properties: {
    id: prop(String).isRequired,
    type: prop(String).isRequired,
    agentId: prop(String),
    availabilityChannels: prop([String]),
    communicationSessionId: prop(String),
    communicationSessionChannel: prop(String),
    communicationSessionStatus: prop().oneOf(..._.values(CommunicationSessionStatus)),
    taskId: prop(String),
    taskStatus: prop().oneOf(..._.values(TaskStatus)),
    timestamp: String,
    customerId: String,
    routingGroupId: String,
    conversationId: String,
    channel: String,
    reasonId: String,
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ id: IdGenerator.newId() }, attrs));
    },
    // if you add a new type, don't forget to update the corresponding file in supernova
    Type: createEnum(
      'ANSWER_PERFORMANCE',
      'API_TOKENS',
      'AWAY',
      'BUSY',
      'COMPOSITION_CLOSE',
      'COMPOSITION_OPEN',
      'CUSTOM_CHANNEL',
      'CUSTOMER',
      'FACEBOOK_MESSAGE',
      'INBOX',
      'IVRS_ADMIN',
      'KEYWORD_GROUPS_ADMIN',
      'KNOWLEDGE_BASE',
      'LIVEBOARDS',
      'LOG_IN',
      'LOG_OUT',
      'OFFLINE',
      'PRESENCE',
      'READY',
      'RECOMMENDED_ANSWERS',
      'REPORTS',
      'RULES_ADMIN',
      'SEARCH',
      'TASK_COMMENT_PANEL_CLOSE',
      'TASK_COMMENT_PANEL_OPEN',
      'TOPICS_ADMIN',
      'USERS_ADMIN'
    ),
  },
});

export default AgentActivity;
