import DtoConverter from 'scripts/application/dto_converters/answer_performance_converter';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';

export default class AnswerPerformanceGatewayObserver {
  constructor(context) {
    this.context = context;
    this.errorHandler = new GatewayErrorSilentHandler(this.context);
  }

  get store() {
    return this.context.stores.answerPerformances;
  }

  onFetchAllSuccess(responseDto) {
    this.store.resetLoading();
    if (responseDto) {
      this.store.set(responseDto.map(dto => DtoConverter.fromDto(dto)));
    } else {
      this.store.set([]);
    }
  }

  onFetchAllError(error) {
    this.store.resetLoading();
    this.errorHandler.handleCommonErrors(this.context, error);
  }
}
