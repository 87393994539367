import analytics from 'scripts/lib/analytics';
import Basic from 'scripts/domain/models/toast/content/basic';
import { DomainError } from 'scripts/application/lib/error';
import Err from 'models/err';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';

export function translateText(customerId, context, translationRequest) {
  return context.gateways.translation
    .add(translationRequest.toJs())
    .then(responseDto => {
      const { text, targetLanguage, sourceLanguage } = responseDto;
      analytics.track('Translation Completed', {
        customerId,
        targetLanguage,
        sourceLanguage,
        totalLength: text.length,
      });

      return responseDto;
    })
    .catch(err => {
      if (!(err instanceof DomainError)) {
        handleCommonErrors(context, err);
        return;
      }
      throw err;
    });
}

export function getErrorToastParams(err) {
  let message = 'There was a problem translating. Please try again later.';

  if (err && err.errors && Array.isArray(err.errors) && err.errors.length > 0) {
    const firstErr = err.errors[0];
    if (firstErr.code === Err.Code.BLANK) {
      if (firstErr.attr === 'targetLanguage') {
        message = "You didn't select a language to translate into.";
      } else if (firstErr.attr === 'text') {
        message = "There's no content to translate.";
      }
    } else if (firstErr.code === Err.Code.TOO_LONG) {
      message = 'Your content is too long. Try again with less text.';
    }
  }

  return {
    content: new Basic({
      basicType: Basic.Type.ERROR,
      message,
    }),
  };
}
