import { some, forEach } from 'lodash';
import React from 'react';

import connect from 'components/lib/connect';
import FeatureNotification from './feature_notification';
import FeatureNotificationTypes from 'scripts/domain/models/agent_notification/feature_notification_types';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

const sidekickConversationsClassroom = {
  feature: FeatureNotificationTypes.IN_APP_NOTIFICATION,
  roleFlags: ['teamManagement'],
  key: 'sidekick-conversations-classroom',
  text: 'Classroom Session: Review Sidekick Conversations for Peak Performance',
  subtext:
    "Join us for this Classroom Session to learn about QA best practices and how to keep Sidekick in top shape. Whether you're a seasoned Gladly user or just curious about Sidekick, this session is a great opportunity to sharpen your skills and explore how Sidekick can enhance your workflow.",
  url: 'https://gladly.zoom.us/meeting/register/oFrlT--kR9qD3EX2UyPtHQ#/registration',
  urlText: 'REGISTER',
  endAt: 1743429600000,
};

const CURRENT_FEATURES = [sidekickConversationsClassroom];

export function FeatureNotificationList({ agentPreferences, organization }) {
  const isFeatureEnabled = useIsFeatureEnabled();

  let featureNotifications = [];
  forEach(CURRENT_FEATURES, featureProps => {
    if (shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization)) {
      featureNotifications.push(<FeatureNotification {...featureProps} />);
    }
  });

  if (!featureNotifications.length) {
    return null;
  }

  return <React.Fragment>{featureNotifications}</React.Fragment>;
}

const FeatureNotificationListContainer = connect(mapStateToProps)(FeatureNotificationList);

function mapStateToProps({ getProvider }) {
  const agentPreferences = getProvider('agentPreferences').get();
  const organization = getProvider('auth').get()?.claims;

  return {
    agentPreferences,
    organization,
  };
}

export function useHasFeaturesToShow(agentPreferences, organization) {
  const isFeatureEnabled = useIsFeatureEnabled();
  return some(CURRENT_FEATURES, featureProps => {
    return shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization);
  });
}

function shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization) {
  const roleRestricted = featureProps.roleFlags && featureProps.roleFlags.length;
  const restrictedAndHasRole = some(featureProps.roleFlags, roleFlag => isFeatureEnabled(roleFlag));
  const flagRestricted = featureProps.flag;

  const orgRestricted = !!featureProps.orgIds;
  const belongsToOrg = some(featureProps.orgIds, orgId => orgId === organization?.orgId);

  const currentTime = Date.now();
  const startRestricted = !!featureProps.startAt;
  const afterStart = startRestricted ? currentTime > featureProps.startAt : false;
  const endRestricted = !!featureProps.endAt;
  const beforeEnd = endRestricted ? currentTime < featureProps.endAt : false;

  return (
    (!orgRestricted || belongsToOrg) &&
    (!roleRestricted || restrictedAndHasRole) &&
    (!flagRestricted || isFeatureEnabled(featureProps.flag)) &&
    (!startRestricted || afterStart) &&
    (!endRestricted || beforeEnd) &&
    !agentPreferences.hasSeenFeatureNotification(featureProps.feature)
  );
}

export default FeatureNotificationListContainer;
