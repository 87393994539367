import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AnswerPerformanceFilters from 'components/answer_performance/answer_performance_filters';
import connect from 'components/lib/connect';
import InteractiveTable from 'components/common/interactive_table';
import NavigateToRecommendedAnswers from 'actions/recommended_answers/navigate_to_recommended_answers';
import { NO_ANSWER_REFERENCED } from 'scripts/domain/models/answer_performance';
import { Number } from 'components/common/number';
import RecommendedAnswersIcon from 'components/common/icons/recommended_answers_icon';
import Tooltip from 'components/common/tooltip';

export function AnswerPerformanceTable({ data, isLoading, onOpenRecommendedAnswers }) {
  const showRecommendedAnswers = data?.some(row => row.recommendedAnswersCount);
  const transformedData = data?.map(row => ({
    id: row.id,
    recommendedAnswersIcon: showRecommendedAnswers && (
      <IconCell>
        {row.recommendedAnswersCount && (
          <Tooltip data-aid="recommendedAnswersCount-tooltip" message={`${row.recommendedAnswersCount}`}>
            <RecommendedAnswersIcon width="16px" />
          </Tooltip>
        )}
      </IconCell>
    ),
    name: <NameCell>{row.name}</NameCell>,
    references: <Number value={row.references} />,
    handoffs: <Number value={row.handoffs} />,
    resolved: <Number value={row.resolved} />,
    resolutionRate: (
      <span>
        <Number value={row.resolutionRate} />%
      </span>
    ),
  }));

  const columns = [
    ...(showRecommendedAnswers
      ? [
          {
            header: '',
            accessor: 'recommendedAnswersIcon',
            align: 'center',
            width: '24px',
            sortValue: item => data.find(d => d.id === item.id).recommendedAnswersCount ?? -1,
          },
        ]
      : []),
    {
      header: 'Answer',
      accessor: 'name',
      align: 'left',
      width: '1fr',
      sortValue: item => data.find(d => d.id === item.id).name,
    },
    {
      header: 'Count',
      accessor: 'references',
      align: 'right',
      width: 'min-content',
      sortValue: item => data.find(d => d.id === item.id).references,
      infoText: 'The number of times an answer was referenced.',
      sortDescendingFirst: true,
    },
    {
      header: 'Handed off',
      accessor: 'handoffs',
      align: 'right',
      width: 'min-content',
      sortValue: item => data.find(d => d.id === item.id).handoffs,
      sortDescendingFirst: true,
    },
    {
      header: 'Resolved',
      accessor: 'resolved',
      align: 'right',
      width: 'min-content',
      sortValue: item => data.find(d => d.id === item.id).resolved,
      sortDescendingFirst: true,
    },
    {
      header: 'Resolution rate',
      accessor: 'resolutionRate',
      align: 'right',
      width: 'min-content',
      sortValue: item => data.find(d => d.id === item.id).resolutionRate,
      sortDescendingFirst: true,
    },
  ];

  const isRowClickable = row => {
    return data.find(d => d.id === row.id)?.recommendedAnswersCount > 0;
  };

  const handleRowClick = row => {
    if (isRowClickable(row)) {
      onOpenRecommendedAnswers({ referencedAnswerId: row.id });
    }
  };

  const reorderRows = tableData => {
    return [
      ...tableData.filter(row => row.id === NO_ANSWER_REFERENCED),
      ...tableData.filter(row => row.id !== NO_ANSWER_REFERENCED),
    ];
  };

  return (
    <InteractiveTable
      columns={columns}
      controlComponents={<AnswerPerformanceFilters />}
      data={transformedData || []}
      defaultSortColumn="references"
      enableSearch
      enableSorting
      isLoading={isLoading}
      isRowClickable={isRowClickable}
      onRowClick={handleRowClick}
      reorderRows={reorderRows}
      rowsPerPage={25}
    />
  );
}

AnswerPerformanceTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      references: PropTypes.number.isRequired,
      handoffs: PropTypes.number.isRequired,
      resolved: PropTypes.number.isRequired,
      resolutionRate: PropTypes.number.isRequired,
      recommendedAnswersCount: PropTypes.number,
    })
  ),
  isLoading: PropTypes.bool,
  onOpenRecommendedAnswers: PropTypes.func,
};

const NameCell = styled.div`
  align-items: center;
  display: flex;
  gap: ${p => p.theme.spacing.small};
`;

const IconCell = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: ${p => p.theme.spacing.small};
`;

function mapStateToProps({ getProvider }) {
  const answerPerformancesProvider = getProvider('answerPerformances');
  const data = answerPerformancesProvider.findAll();

  const recommendedAnswersCountsProvider = getProvider('recommendedAnswersCounts');
  const recommendedAnswerCounts = recommendedAnswersCountsProvider.findAll();
  const combinedData = data.map(row => {
    const recommendedAnswerCount = recommendedAnswerCounts.find(count => count.id === row.id)?.count;
    return {
      ...row,
      recommendedAnswersCount: recommendedAnswerCount,
    };
  });

  return {
    data: combinedData,
    isLoading: answerPerformancesProvider.isLoading() || recommendedAnswersCountsProvider.isLoading(),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onOpenRecommendedAnswers: ({ referencedAnswerId }) => {
      executeAction(NavigateToRecommendedAnswers, { referencedAnswerId });
    },
  };
}

const AnswerPerformanceTableContainer = connect(mapStateToProps, mapExecuteToProps)(AnswerPerformanceTable);

export default AnswerPerformanceTableContainer;
