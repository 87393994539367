import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import Modal from 'components/lib/modal';
import ModalButton, { ModalButtonDivider } from 'components/modals/modal_button';
import ModalFooter from 'components/modals/modal_footer';
import PortalModalWrapper from 'components/lib/portal_modal_wrapper';

export default function CancelAnswerEditorModal({ onReturn, onProceed }) {
  return (
    <PortalModalWrapper>
      <div>
        <ReactCSSTransitionGroup
          transitionAppear
          transitionAppearTimeout={1000}
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1}
          transitionName="Modal-backdrop"
        >
          <div className="Modal-backdrop" />
        </ReactCSSTransitionGroup>
        <StyleCancelAnswerEditorModal data-aid="cancelAnswerEditorModal">
          <CancelAnswerEditorModalHeader data-aid="cancelAnswerEditorModal-header">
            Discard Changes?
          </CancelAnswerEditorModalHeader>
          <CancelAnswerEditorModalBody data-aid="cancelAnswerEditorModal-body">
            Changes will not be saved if you navigate away from this page.
            <br />
            Are you sure you want to cancel editing this Answer?
          </CancelAnswerEditorModalBody>
          <ModalFooter>
            <ModalButton onClick={onReturn}>Keep Editing</ModalButton>
            <ModalButtonDivider />
            <ModalButton onClick={onProceed}>Yes, Cancel</ModalButton>
          </ModalFooter>
        </StyleCancelAnswerEditorModal>
      </div>
    </PortalModalWrapper>
  );
}

CancelAnswerEditorModal.propTypes = {
  onReturn: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
};

const StyleCancelAnswerEditorModal = styled(Modal)`
  background: ${p => p.theme.colors.white};
  border-radius: ${p => p.theme.borderRadius.small};
  min-width: 380px;
`;

const CancelAnswerEditorModalHeader = styled.div`
  color: ${p => p.theme.colors.gray900};
  font-size: ${p => p.theme.fontSize.large};
  padding-top: ${p => p.theme.spacing.insetXXLarge};
  text-align: center;
`;

const CancelAnswerEditorModalBody = styled.div`
  font-size: ${p => p.theme.fontSize.medium};
  padding: ${p => p.theme.spacing.insetXXLarge};
  padding-top: ${p => p.theme.spacing.insetSmall};
  text-align: center;
`;
