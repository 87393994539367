import BeginLogOut from 'actions/user/begin_log_out';

/**
 * Common implementation of unauthorized error handler
 *
 * Compose in a gateway observer as usual or mix in. If mixing in ensure the context property is defined.
 */
export default class UnauthorizedHandler {
  constructor(context) {
    this.context = context;
  }

  handleUnauthorized({ correlationId }, err) {
    if (arguments.length === 1 || !err) {
      err = arguments[0];
      correlationId = err.correlationId;
    }

    let auth = this.context.stores.auth.get();
    if (auth && auth.isLoggedIn()) {
      this.context.analytics.track('Request Made Without Authentication', { detail: err.message });
    }

    this.context.executeAction(BeginLogOut);
  }
}
