import ConversationItemDtoConverter from 'scripts/application/dto_converters/conversation_item_converter';
import CreateToast from 'scripts/application/actions/toast/create_toast';
import { getErrorToastParams, translateText } from 'scripts/application/actions/lib/ai_translate';
import Language from 'models/language';
import qconsole from 'scripts/lib/qconsole';
import Translation from 'scripts/domain/models/translation';
import TranslationRequest from 'scripts/domain/models/translation_request';

export default class TranslateConversationItem {
  constructor(context) {
    this.context = context;
  }

  run(params) {
    const { conversationItem, targetLanguage, customerId } = params;
    const { conversationHistory, customerLanguage } = this.context.stores.customers.storesFor(customerId);

    if (!targetLanguage) {
      qconsole.error('Target language not specified');
      return;
    }

    const text = conversationItem.content.getMessageText();
    const translationRequest = TranslationRequest.create({
      customerId,
      targetLanguage,
      text,
    });

    return translateText(customerId, this.context, translationRequest)
      .then(responseDto => {
        if (!responseDto) {
          return;
        }

        const translation = Translation.create({
          content: responseDto.text,
          contentLanguage: responseDto.targetLanguage,
          parentLanguage: responseDto.sourceLanguage,
          model: responseDto.model,
          provider: responseDto.provider,
          translatedAt: responseDto.translatedAt,
        });

        customerLanguage.set(Language.create({ id: 'customer-language', code: responseDto.sourceLanguage }));

        conversationItem.replaceTranslation(translation);
        conversationItem.incrementVersion();
        conversationHistory.addOrReplace(conversationItem);

        this.context.gateways.customerHistory.updateConversationItem(
          customerId,
          conversationItem.id,
          ConversationItemDtoConverter.toDto(conversationItem)
        );

        return translation;
      })
      .catch(err => {
        const toastParams = getErrorToastParams(err);
        this.context.executeAction(CreateToast, toastParams);
      });
  }
}
