import Account from 'models/account';
import ActiveCallStore from 'scripts/adapters/stores/active_call_store';
import ActiveSession from 'models/active_session';
import Agent from 'models/agent';
import AgentActivity from 'models/agent_activity';
import AgentAssistanceConfig from 'models/agent_assistance_config';
import AgentExperienceConfig from 'models/agent_experience_config';
import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import AgentInboxMeta from 'models/agent_inbox/agent_inbox_meta';
import AgentPreferences from 'models/agent_preferences';
import AgentPreferencesLocalStore from 'scripts/adapters/stores/agent_preferences_local_store';
import AgentProfile from 'models/agent_profile';
import AgentNotification from 'models/agent_notification';
import AgentNotificationMeta from 'models/agent_notification/agent_notification_meta';
import AgentRoutingPreferences from 'models/agent_routing_preferences';
import AgentSearchResult from 'models/search/agent_search_result';
import AgentStatus from 'models/agent_status';
import AgentStatusReason from 'models/agent_status_reason';
import AnswerPanel from 'models/answer_panel';
import AnswerPanelState from 'models/answer_panel_state';
import AnswerPerformance from 'models/answer_performance';
import AnswerSuggestions from 'models/answer_suggestions';
import ApiToken from 'models/api_token';
import AppFeatures from 'models/app_features';
import Audience from 'models/audience';
import AuthStore from 'scripts/adapters/stores/auth_store';
import ChannelConfigurationStore from 'scripts/adapters/stores/channel_configuration_store';
import CommentPanel from 'models/comment_panel';
import CommunicationQueue from 'models/communication_queue';
import CompanyProfile from 'models/company_profile';
import ConnectionStates from 'models/connection_states';
import ConversationWorkflow from 'models/conversation_workflow';
import ConversationWorkflowConfig from 'models/conversation_workflow_config';
import createCollectionStoreClass from 'scripts/adapters/stores/lib/create_collection_store_class';
import createConverter from 'scripts/adapters/immutable_converters/lib/create_converter';
import createDocumentStoreClass from 'scripts/adapters/stores/lib/create_document_store_class';
import CustomAttribute from 'models/custom_attribute';
import CustomChannel from 'models/custom_channel';
import CustomChannelsConfig from 'models/custom_channels_config';
import CustomerAccessHistory from 'models/customer_access_history';
import CustomerMerge from 'models/customer_merge';
import CustomerProfileDef from 'models/customer_profile_def';
import CustomerUnlink from 'models/customer_unlink';
import CustomersStore from 'scripts/adapters/stores/customers_store';
import EditableUser from 'models/editable_user';
import EmailSuggestion from 'models/email_suggestion';
import Endpoint from 'models/endpoint';
import { ExternalCustomerLookupResult } from 'models/external_customer_profile';
import EmbeddedReport from 'models/reports/embedded_report';
import EmbedTokensReport from 'models/reports/embed_tokens_report';
import ExternalActionForm from 'models/external_action_form';
import ExternalAppCardTemplate from 'models/external_apps/external_app_card_template';
import GreetingSuggestion from 'models/greeting_suggestion';
import HighlightedMessages from 'models/highlighted_messages';
import InboxCount from 'models/inbox_count';
import InboxStore from 'scripts/adapters/stores/inbox_store';
import Integration from 'models/integration';
import Ivr from 'models/ivr/ivr';
import KbVariable from 'models/kb_variable';
import Language from 'models/language';
import LiveboardStore from 'scripts/adapters/stores/liveboard_store';
import LocalCompositionsStore from 'scripts/adapters/stores/local_compositions_store';
import LocalGreetingSuggestionsStore from 'scripts/adapters/stores/local_greeting_suggestions_store';
import LocalCoBrowsingSessionStore from 'scripts/adapters/stores/local_cobrowsing_sesson_store';
import LocalEmailSuggestionsStore from 'scripts/adapters/stores/local_email_suggestions_store';
import LocalRecentCustomerIdsStore from 'scripts/adapters/stores/local_recent_customer_ids_store';
import LocalRecentlyMentionedAgentIdsStore from 'scripts/adapters/stores/local_recently_mentioned_agent_ids_store';
import LocationHistoryStore from 'scripts/adapters/stores/location_history_store';
import LocationStore from 'scripts/adapters/stores/location_store';
import MessagingConfiguration from 'models/messaging_configuration';
import ModalStore from 'scripts/adapters/stores/modal_store';
import NotificationsStore from 'scripts/adapters/stores/notifications_store';
import Organization from 'models/organization';
import PerformExternalAction from 'models/perform_external_action';
import PhraseSuggestion from 'models/phrase_suggestion';
import RecommendedAnswerCount from 'models/recommended_answer_count';
import RecentCustomer from 'models/recent_customer';
import RecentlyMentionedAgentId from 'models/recently_mentioned_agent_id';
import RecommendedAnswer from 'models/recommended_answer';
import RelationshipLabel from 'models/relationship_label';
import ReportBuilderConfig from 'models/report_builder_config';
import ReportStore from 'scripts/adapters/stores/report_store';
import ReportConfigs from 'scripts/domain/models/reports/report_configs';
import RoutableCounts from 'models/routable_counts';
import RoutingGroupsStore from 'scripts/adapters/stores/routing_groups_store';
import ScheduledReport from 'models/reports/scheduled_report';
import SearchResult from 'models/search/search_result';
import SelectedKbItem from 'models/selected_kb_item';
import SelectedSuggestedReply from 'models/selected_suggested_reply';
import Snippet from 'models/answers/snippet';
import SnippetRevision from 'models/snippet_revision';
import SnippetSearchResult from 'models/search/snippet_search_result';
import StationConfiguration from 'models/station_configuration';
import SystemConfiguration from 'models/system_configuration';
import Team from 'models/team';
import TaskInfo from 'models/task_info';
import Toast from 'models/toast';
import ToastDeprecated from 'models/toast_deprecated';
import Topic from 'models/topic';
import TopicAncestry from 'models/topic_ancestry';
import TopicSuggestions from 'models/topic_suggestions';
import UserCredential from 'models/user_credential';
import UsersStore from 'scripts/adapters/stores/users_store';
import UsersUpload from 'models/users_upload';
import VoiceConfiguration from 'models/voice_configuration';
import Webhook from 'models/webhook';
import WidgetConfiguration from 'models/widget_configuration';

export default function configureStores(rootBinding) {
  const agentPreferencesLocal = new AgentPreferencesLocalStore();
  const auth = new AuthStore(window.localStorage);

  return {
    activeCall: new ActiveCallStore(rootBinding.sub('activeCall')),
    activeSessions: createCollectionStoreForModel(ActiveSession, 'activeSessions'),
    accounts: createCollectionStoreForModel(Account, 'accounts'),
    agentActivity: createDocumentStoreForModel(AgentActivity, 'agentActivity'),
    agentAssistanceConfig: createDocumentStoreForModel(
      AgentAssistanceConfig,
      'agentAssistanceConfig',
      AgentAssistanceConfig.create()
    ),
    agentExperienceConfig: createDocumentStoreForModel(
      AgentExperienceConfig,
      'agentExperienceConfig',
      AgentExperienceConfig.create()
    ),
    agentInboxItems: createCollectionStoreForModel(AgentInboxItem, 'agentInboxItems'),
    agentInboxItemsNew: createCollectionStoreForModel(AgentInboxItem, 'agentInboxItemsNew'),
    agentInboxMeta: createDocumentStoreForModel(AgentInboxMeta, 'agentInboxMeta', new AgentInboxMeta()),
    agentNotifications: createCollectionStoreForModel(AgentNotification, 'agentNotifications'),
    agentNotificationMeta: createDocumentStoreForModel(
      AgentNotificationMeta,
      'agentNotificationMeta',
      new AgentNotificationMeta()
    ),
    agentPreferences: createDocumentStoreForModel(AgentPreferences, 'agentPreferences', agentPreferencesLocal.get()),
    agentPreferencesLocal,
    agentProfiles: createCollectionStoreForModel(AgentProfile, 'agentProfiles'),
    agents: createCollectionStoreForModel(Agent, 'agents'),
    agentRoutingPreferences: createDocumentStoreForModel(
      AgentRoutingPreferences,
      'agentRoutingPreferences',
      AgentRoutingPreferences.create()
    ),
    agentSearchResults: createDocumentStoreForModel(AgentSearchResult, 'agentSearchResults'),
    agentStatus: createDocumentStoreForModel(AgentStatus, 'agentStatus'),
    agentStatuses: createCollectionStoreForModel(AgentStatus, 'agentStatuses'),
    agentStatusReasons: createCollectionStoreForModel(AgentStatusReason, 'agentStatusReasons'),
    answerPanel: createDocumentStoreForModel(
      AnswerPanel,
      'answerPanel',
      AnswerPanel.create({
        lastOpenedAt: agentPreferencesLocal.get().answerPanelLastOpenedAt,
        searchLanguage: agentPreferencesLocal.get().answerSearchLanguage,
        searchAudiences: agentPreferencesLocal.get().answerSearchAudiences,
      })
    ),
    answerPanelState: createDocumentStoreForModel(AnswerPanelState, 'answerPanelState'),
    answerPerformances: createCollectionStoreForModel(AnswerPerformance, 'answerPerformances'),
    answerSuggestions: createCollectionStoreForModel(AnswerSuggestions, 'answerSuggestions'),
    apiTokens: createCollectionStoreForModel(ApiToken, 'apiTokens'),
    appFeatures: createDocumentStoreForModel(AppFeatures, 'appFeatures', AppFeatures.create()),
    audiences: createCollectionStoreForModel(Audience, 'audience'),
    auth,
    channelConfiguration: new ChannelConfigurationStore(rootBinding.sub('channelConfiguration')),
    commentPanel: createDocumentStoreForModel(CommentPanel, 'commentPanel', CommentPanel.create()),
    communicationQueues: createCollectionStoreForModel(CommunicationQueue, 'communicationQueues'),
    companyProfile: createDocumentStoreForModel(CompanyProfile, 'companyProfile'),
    connectionState: createDocumentStoreForModel(ConnectionStates, 'connectionState', ConnectionStates.create()),
    conversationMerge: createDocumentStoreForModel(CustomerMerge, 'conversationMerge'),
    conversationWorkflow: createDocumentStoreForModel(
      ConversationWorkflow,
      'conversationWorkflow',
      ConversationWorkflow.create()
    ),
    conversationWorkflowConfig: createDocumentStoreForModel(
      ConversationWorkflowConfig,
      'conversationWorkflowConfig',
      ConversationWorkflowConfig.create()
    ),
    currentAgent: createDocumentStoreForModel(AgentProfile, 'currentAgent'),
    currentAgentSnippets: createCollectionStoreForModel(Snippet, 'currentAgentSnippets'),
    currentLocation: new LocationStore(rootBinding.sub('currentLocation')),
    customAttributes: createCollectionStoreForModel(CustomAttribute, 'customAttributes'),
    customChannels: createCollectionStoreForModel(CustomChannel, 'customChannels'),
    customChannelsConfig: createDocumentStoreForModel(
      CustomChannelsConfig,
      'customChannelsConfig',
      CustomChannelsConfig.create()
    ),
    customerAccessHistory: createDocumentStoreForModel(
      CustomerAccessHistory,
      'customerAccessHistory',
      CustomerAccessHistory.create()
    ),
    customerMerge: createDocumentStoreForModel(CustomerMerge, 'customerMerge'),
    customerUnlink: createDocumentStoreForModel(CustomerUnlink, 'customerUnlink'),
    customerProfileDef: createDocumentStoreForModel(CustomerProfileDef, 'customerProfileDef'),
    customers: new CustomersStore(rootBinding.sub('customers')),
    emailSuggestions: createCollectionStoreForModel(EmailSuggestion, 'emailSuggestions'),
    embedTokensReport: createDocumentStoreForModel(EmbedTokensReport, 'embedTokensReport'),
    embeddedReport: createDocumentStoreForModel(EmbeddedReport, 'embeddedReport'),
    endpoints: createCollectionStoreForModel(Endpoint, 'endpoints'),
    externalActionForm: createDocumentStoreForModel(ExternalActionForm, 'externalActionForm'),
    externalAppCardTemplates: createCollectionStoreForModel(ExternalAppCardTemplate, 'externalAppCardTemplates'),
    externalCustomerSearchResults: createDocumentStoreForModel(
      ExternalCustomerLookupResult,
      'externalCustomerSearchResults'
    ),
    favoriteAnswers: createCollectionStoreForModel(Snippet, 'favoriteAnswers'),
    greetingSuggestions: createCollectionStoreForModel(GreetingSuggestion, 'greetingSuggestions'),
    inbox: new InboxStore(rootBinding.sub('inbox')),
    integrations: createCollectionStoreForModel(Integration, 'integrations'),
    ivrs: createCollectionStoreForModel(Ivr, 'ivrs'),
    kbVariables: createCollectionStoreForModel(KbVariable, 'kbVariables'),
    languages: createCollectionStoreForModel(Language, 'language'),
    liveboard: new LiveboardStore(rootBinding.sub('liveboard')),
    localCoBrowsingSessions: new LocalCoBrowsingSessionStore(window.sessionStorage),
    localCompositions: new LocalCompositionsStore(window.sessionStorage),
    localEmailSuggestions: new LocalEmailSuggestionsStore(window.localStorage),
    localGreetingSuggestions: new LocalGreetingSuggestionsStore(window.localStorage),
    locationHistory: new LocationHistoryStore(rootBinding.sub('locationHistory')),
    messagingConfiguration: createDocumentStoreForModel(MessagingConfiguration, 'messagingConfiguration'),
    modal: new ModalStore(rootBinding.sub('modal')),
    notifications: new NotificationsStore(rootBinding.sub('notifications')),
    organization: createDocumentStoreForModel(Organization, 'organization'),
    performExternalAction: createDocumentStoreForModel(PerformExternalAction, 'performExternalAction'),
    personalInboxCounts: createDocumentStoreForModel(InboxCount, 'personalInboxCounts'),
    phraseSuggestions: createCollectionStoreForModel(PhraseSuggestion, 'phraseSuggestions'),
    recommendedAnswersCounts: createCollectionStoreForModel(RecommendedAnswerCount, 'recommendedAnswersCounts'),
    recentCustomers: createCollectionStoreForModel(RecentCustomer, 'recentCustomers'),
    localRecentCustomerIds: new LocalRecentCustomerIdsStore(window.localStorage),
    recentlyMentionedAgentIds: createCollectionStoreForModel(RecentlyMentionedAgentId, 'recentlyMentionedAgentIds'),
    localRecentlyMentionedAgentIds: new LocalRecentlyMentionedAgentIdsStore(window.localStorage, auth),
    recommendedAnswers: createCollectionStoreForModel(RecommendedAnswer, 'recommendedAnswers'),
    relationshipLabels: createCollectionStoreForModel(RelationshipLabel, 'relationshipLabel'),
    report: new ReportStore(rootBinding.sub('report')),
    reportBuilderConfig: createDocumentStoreForModel(
      ReportBuilderConfig,
      'reportBuilderConfig',
      ReportBuilderConfig.create()
    ),
    reportConfigs: createDocumentStoreForModel(ReportConfigs, 'reportConfigs', ReportConfigs.create({ configs: [] })),
    routableCounts: createDocumentStoreForModel(RoutableCounts, 'routableCounts'),
    routingGroups: new RoutingGroupsStore(rootBinding.sub('routingGroups')),
    scheduledReport: createDocumentStoreForModel(ScheduledReport, 'scheduledReport'),
    selectedKbItem: createDocumentStoreForModel(SelectedKbItem, 'selectedKbItem'),
    selectedSuggestedReply: createDocumentStoreForModel(SelectedSuggestedReply, 'selectedSuggestedReply'),
    highlightedMessages: createDocumentStoreForModel(HighlightedMessages, 'highlightedMessages'),
    sharedReportConfigs: createDocumentStoreForModel(
      ReportConfigs,
      'sharedReportConfigs',
      ReportConfigs.create({ configs: [] })
    ),
    snippetComposition: createDocumentStoreForModel(Snippet, 'snippetComposition'),
    snippetLinks: createCollectionStoreForModel(Snippet, 'snippetLinks'),
    snippets: createCollectionStoreForModel(Snippet, 'snippets'),
    snippetRevisions: createCollectionStoreForModel(SnippetRevision, 'snippetRevisions'),
    snippetSearchResult: createDocumentStoreForModel(SnippetSearchResult, 'snippetSearchResult'),
    snippetView: createDocumentStoreForModel(Snippet, 'snippetView'),
    stationConfigurations: createCollectionStoreForModel(StationConfiguration, 'stationConfigurations'),
    systemConfiguration: createDocumentStoreForModel(SystemConfiguration, 'systemConfiguration'),
    taskInfo: createCollectionStoreForModel(TaskInfo, 'taskInfo'),
    teamInboxCounts: createCollectionStoreForModel(InboxCount, 'teamInboxCounts'),
    teams: createCollectionStoreForModel(Team, 'team'),
    toasts: createCollectionStoreForModel(Toast, 'toasts'),
    toastsDeprecated: createCollectionStoreForModel(ToastDeprecated, 'toastsDeprecated'),
    topics: createCollectionStoreForModel(Topic, 'topics'),
    topicHierarchy: createCollectionStoreForModel(TopicAncestry, 'topicHierarchy'),
    topicSuggestions: createCollectionStoreForModel(TopicSuggestions, 'topicSuggestions'),
    universalSearchResults: createCollectionStoreForModel(SearchResult, 'universalSearchResults'),
    userCredential: createDocumentStoreForModel(UserCredential, 'userCredential'),
    userEditor: createDocumentStoreForModel(EditableUser, 'userEditor'),
    users: new UsersStore(rootBinding.sub('users')),
    usersUpload: createDocumentStoreForModel(UsersUpload, 'usersUpload'),
    voiceConfiguration: createDocumentStoreForModel(
      VoiceConfiguration,
      'voiceConfiguration',
      VoiceConfiguration.create()
    ),
    webhooks: createCollectionStoreForModel(Webhook, 'webhook'),
    widgets: createCollectionStoreForModel(WidgetConfiguration, 'widgets'),
  };

  function createCollectionStoreForModel(Model, bindingPath) {
    const StoreClass = createCollectionStoreClass({ converter: createConverter(Model) });
    return new StoreClass(rootBinding.sub(bindingPath));
  }

  function createDocumentStoreForModel(Model, bindingPath, defaultValue) {
    const converter = createConverter(Model);
    const StoreClass = createDocumentStoreClass({
      converter,
      defaultImmutable: defaultValue && converter.toImmutable(defaultValue),
    });
    return new StoreClass(rootBinding.sub(bindingPath));
  }
}
