import { Factory } from 'rosie';

Factory.define('AnswerPerformance');

Factory.define('AnswerPerformanceWithDefaults')
  .extend('AnswerPerformance')
  .attr('id', 'default-answer-id')
  .attr('name', 'Default Answer Name')
  .attr('references', 150)
  .attr('handoffs', 43)
  .attr('resolved', 107)
  .attr('resolutionRate', 71.3);
