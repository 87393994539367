import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import ItemContext from '../item_context';
import SafeHtml from 'components/common/safe_html';
import { useShouldTranslate } from 'components/hooks/use_translated_content';
import { typeReflect } from 'models/conversation_item/content_from_js';

export default function EmailBody({ emailPlainTextFromHtml, item }) {
  const email = item.content;

  const { isViewOriginal } = useContext(ItemContext);
  const shouldTranslate = useShouldTranslate(item, isViewOriginal);
  if (
    (!emailPlainTextFromHtml && !email.bodyPlain) ||
    (emailPlainTextFromHtml && !email.getNonQuotedPlainTextFromHtml() && !email.bodyPlain)
  ) {
    return null;
  }

  if (shouldTranslate) {
    return (
      <div dir="auto">
        <InlineAnnotatedContentContainer
          hasRedactedPaymentCard={email.hasRedactedPaymentCardNumber}
          id={item.id}
          text={item.translation.content}
        />
      </div>
    );
  }

  if (shouldRenderHtml(item)) {
    return <SafeHtml className="emailItem-body-content-rich" html={htmlBodyForEmail(email)} setDirection />;
  }

  let emailBody;
  if (emailPlainTextFromHtml) {
    emailBody = email.getNonQuotedPlainTextFromHtml() || email.getNonQuotedPlainText();
  } else {
    emailBody = email.getNonQuotedPlainText();
  }

  return (
    <div dir="auto">
      <InlineAnnotatedContentContainer
        hasRedactedPaymentCard={email.hasRedactedPaymentCardNumber}
        id={item.id}
        text={emailBody || ''}
      />
    </div>
  );
}

function shouldRenderHtml(item) {
  const email = item.content;
  if (typeReflect.instanceToType(email) === ConversationItemType.EMAIL_CAMPAIGN_OUTREACH) {
    return true;
  }

  return email.bodyHtmlStripped && (item.isAgentItem() || item.isApiItem() || item.isAutomatedItem());
}

function htmlBodyForEmail(email) {
  if (typeReflect.instanceToType(email) === ConversationItemType.EMAIL_CAMPAIGN_OUTREACH) {
    return email.bodyHtml;
  }

  return email.bodyHtmlStripped;
}

EmailBody.propTypes = {
  emailPlainTextFromHtml: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
