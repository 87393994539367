import _ from 'lodash';
import { Device } from '@twilio/voice-sdk';

import { after } from 'scripts/infrastructure/backends/http_client';
import createEnum from 'scripts/lib/create_enum';
import Err from 'models/err';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import mixin from '../../lib/mixin';
import Observable from '../../lib/observable_mixin';
import qconsole from 'scripts/lib/qconsole';
import { TwilioDeviceStatus } from 'models/connection_states';
import getGladlyCdn from 'scripts/lib/gladly_cdn';

const TwilioWarnings = createEnum(
  'low-mos',
  'high-rtt',
  'high-jitter',
  'high-packet-loss',
  'high-packets-lost-fraction'
);

// Maximum duration the Twilio connection should be in the 'connecting' status
const MAX_CONNECTING_DURATION = 20000;

const TOKEN_FETCH_INTERVAL = 10000;

// Base CDN path for voice assets
const VOICE_ASSET_PATH_BASE = '/assets/voice/agent-desktop';

export default class TwilioPhoneHttpGateway {
  constructor(backend, twilio) {
    this.backend = backend;
    this.twilio = twilio;
    this.device = null;
    this.registerAgent = _.throttle(this._registerAgent, TOKEN_FETCH_INTERVAL);
  }

  url() {
    return `/api/v1/orgs/${this.orgId}/configuration/telephony-token/${this._registeredAgentId}`;
  }

  init({ orgId }) {
    this.orgId = orgId;
  }

  get registeredAgentId() {
    if (this._registeredAgentId) {
      return this._registeredAgentId;
    }
    qconsole.log('No registeredAgentId for the twilio phone gateway. Ensure you called registerAgent first.');
    return null;
  }

  get _http() {
    return this.backend.axios();
  }

  _registerAgent(agentId) {
    if (!agentId) {
      ErrorReporter.reportError(new Error('TwilioPhoneGateway error'), {
        message: 'missing agentId - phone gateway will not start up',
      });
      return;
    }
    this._registeredAgentId = agentId;

    this.notifyObservers('handleTwilioEnabled', null);
    let twilioConnectingStatus = this._callbacksRegistered
      ? TwilioDeviceStatus.RECONNECTING
      : TwilioDeviceStatus.CONNECTING;
    this.notifyObservers('handlePhoneStatus', twilioConnectingStatus);

    after(this._http.get(this.url()), this.onFetch.bind(this));
  }

  onFetch(err, res) {
    if (err || res.status !== 200) {
      this.notifyObservers('handleRequestError', res.data);
      // Do not retry if the org does not have a configured telephony gateway
      let unconfiguredGatewayError = _.find(res.data.errors, { code: Err.Code.INVALID_STATE });
      if (!unconfiguredGatewayError) {
        ErrorReporter.reportError(new Error('twilio token error'), {
          message: 'failed to fetch twilio auth token',
          tags: { errors: JSON.stringify(res.data) },
        });
        this.registerAgent(this.registeredAgentId);
      }
      return;
    }

    this.configureTwilio(res.data.capabilityToken);
  }

  deregisterAgent() {
    if (this.device) {
      this.device.destroy();
      this.device = null;
    }

    this._callbacksRegistered = false;
    this._registeredAgentId = null;
    this.notifyObservers('handleTwilioDisabled', null);
  }

  configureTwilio(capabilityToken) {
    let codecPreferences = ['opus', 'pcmu'];
    if (this.device) {
      this.device.updateToken(capabilityToken);
    } else {
      this.device = this.twilio.createDevice(capabilityToken, {
        allowIncomingWhileBusy: true,
        logLevel: 'INFO',
        closeProtection: true,
        codecPreferences,
        sounds: this._getSoundFiles(),
      });
    }
    if (this.device.state === Device.State.Unregistered) {
      this.device.register();
    }
    this._registerCallbacks();
    this.notifyObservers('handleConnect', null);
  }

  _registerCallbacks() {
    qconsole.log(`[twilio] register the twilio callbacks`);
    // Unable to register callbacks in constructor because Twilio breaks capybara-webkit
    if (this._callbacksRegistered) {
      qconsole.log(`[twilio] twilio callbacks already registered. skipping registration`);
      return;
    }

    this.device.on('tokenWillExpire', () => {
      this.registerAgent(this._registeredAgentId);
    });

    this.device.on('registered', () => {
      qconsole.log(`[twilio] device on ready`);
      this.notifyObservers('handleRegisteredAgent', {});
      this.notifyObservers('handlePhoneStatus', TwilioDeviceStatus.READY);
    });

    this.device.on('error', () => {
      this.notifyObservers('handleDeviceError', {});
    });

    this.device.on('incoming', connection => {
      qconsole.log(`[twilio] device on incoming`);
      this.connection = connection;
      this.connection.on('cancel', () => {
        this.connection = null;
        this.notifyObservers('handleHangup', {});
      });
      this.connection.on('disconnect', () => {
        this.connection = null;
        this.notifyObservers('handleHangup', {});
      });
      this.connection.on('error', err => {
        let callSid = this.connection.parameters.CallSid;
        ErrorReporter.reportError(new Error('twilio connection error'), {
          message: err.message,
          tags: { message: err.message, code: err.code, callSid, deviceStatus: this.getDeviceStatus() },
        });
      });
      this.notifyObservers('handleActiveCall', {});
    });

    this.device.on('unregistered', () => {
      qconsole.log(`[twilio] device on unregistered`);
      this.notifyObservers('handlePhoneStatus', TwilioDeviceStatus.OFFLINE);
    });

    this._callbacksRegistered = true;
  }

  accept() {
    // There should always be an active connection when accepting a webRTC call.
    // Direct Dial will not use this accept method as the call is answered via physical phone
    if (this.connection) {
      this.connection.accept();
      this.notifyObservers('handleAccept', {});
      this.connection.on('warning', warningName => {
        if (_.keys(TwilioWarnings).indexOf(warningName) !== -1) {
          this.notifyObservers('handleCallQualityWarning', warningName);
        }
      });
      this.connection.on('warning-cleared', warningName => {
        if (_.keys(TwilioWarnings).indexOf(warningName) !== -1) {
          this.notifyObservers('handleCallQualityWarningCleared', warningName);
        }
      });
      let connection = this.connection;
      this._checkConnectionStatus(connection);

      // Device is busy once it has an active call
      this.notifyObservers('handlePhoneStatus', TwilioDeviceStatus.BUSY);
    } else {
      this.notifyObservers('handleAcceptError', 'could not accept call without an active connection');
    }
  }

  call() {
    this.notifyObservers('handleCall', {});
  }

  decline() {
    if (this.connection) {
      this.connection.reject();
    }
  }

  hangup() {
    if (this.connection) {
      this.connection.disconnect();
    } else {
      this.notifyObservers('handleHangup', {});
    }
  }

  sendDigits(digits) {
    if (this.connection) {
      this.connection.sendDigits(digits);
    }
  }

  disconnectActiveConnection() {
    if (this.connection && this.device) {
      // reference: https://www.twilio.com/docs/voice/client/javascript/device#disconnect-all
      this.device.disconnectAll();
    }
  }

  getConnectionStatus() {
    if (this.connection) {
      return this.connection.status();
    }
    return null;
  }

  getDeviceStatus() {
    const statusMap = {
      [Device.State.Busy]: TwilioDeviceStatus.BUSY,
      [Device.State.Destroyed]: TwilioDeviceStatus.OFFLINE,
      [Device.State.Registered]: TwilioDeviceStatus.READY,
      [Device.State.Registering]: TwilioDeviceStatus.CONNECTING,
      [Device.State.Unregistered]: TwilioDeviceStatus.OFFLINE,
    };
    let status;
    try {
      status = statusMap[this.device.state];
    } catch (err) {
      status = TwilioDeviceStatus.OFFLINE;
    }
    return status;
  }

  isDeviceReady() {
    return this.getDeviceStatus() === TwilioDeviceStatus.READY;
  }

  // checks the Twilio connection status after a set amount of time. Twilio connections
  // should either 'closed' or 'open' after the agent has accepted the incoming call.
  // Calls that fail to connect, typically fail within 10s. Report a message to sentry
  // if the connection does not update within the maximum connecting duration
  _checkConnectionStatus(connection) {
    setTimeout(function() {
      let connectionStatus = connection.status();
      if (connectionStatus !== 'connecting') {
        return;
      }
      ErrorReporter.reportMessage('twilio webRTC call failed to connect within 20s', {
        extra: {
          connectionState: this.context.stores.connectionState.get(),
          status: connectionStatus,
          callID: connection.parameters.CallSid,
        },
      });
    }, MAX_CONNECTING_DURATION);
  }

  _getSoundFiles() {
    const gladlyCdn = getGladlyCdn();
    const voiceFileBase = gladlyCdn + VOICE_ASSET_PATH_BASE;
    return {
      disconnect: `${voiceFileBase}/disconnect.mp3`,
      dtmf0: `${voiceFileBase}/dtmf-0.mp3`,
      dtmf1: `${voiceFileBase}/dtmf-1.mp3`,
      dtmf2: `${voiceFileBase}/dtmf-2.mp3`,
      dtmf3: `${voiceFileBase}/dtmf-3.mp3`,
      dtmf4: `${voiceFileBase}/dtmf-4.mp3`,
      dtmf5: `${voiceFileBase}/dtmf-5.mp3`,
      dtmf6: `${voiceFileBase}/dtmf-6.mp3`,
      dtmf7: `${voiceFileBase}/dtmf-7.mp3`,
      dtmf8: `${voiceFileBase}/dtmf-8.mp3`,
      dtmf9: `${voiceFileBase}/dtmf-9.mp3`,
      dtmfh: `${voiceFileBase}/dtmf-hash.mp3`,
      dtmfs: `${voiceFileBase}/dtmf-star.mp3`,
      incoming: `${voiceFileBase}/incoming.mp3`,
      outgoing: `${voiceFileBase}/outgoing.mp3`,
    };
  }
}

mixin(TwilioPhoneHttpGateway.prototype, Observable);
