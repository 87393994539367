import CompositionContentType from 'models/composition/composition_content_type';
import ConversationItemType from 'models/conversation_item_type';
import InteractionType from 'models/interaction_type';
import { MessageChannelType } from 'models/conversation_message';

export default {
  CONVERSATION: 'fa-check',

  [ConversationItemType.ABANDONED_CALL]: 'icon-abandonedCall',
  [ConversationItemType.CALL_FORWARD]: 'icon-callForward',
  [ConversationItemType.CHAT]: 'icon-chat',
  [ConversationItemType.CHAT_CUSTOMER_URL]: 'icon-chat',
  [ConversationItemType.CHAT_SELF_SERVICE_METADATA]: 'icon-chat',
  [ConversationItemType.CHAT_ENDED]: 'icon-chat',
  [ConversationItemType.CHAT_MESSAGE]: 'icon-chat',
  [ConversationItemType.CONVERSATION_CUSTOM_ATTRIBUTE_CHANGE]: 'fa-rss',
  [ConversationItemType.CONVERSATION_NOTE]: 'icon-note',
  [ConversationItemType.CONVERSATION_STATUS_CHANGE]: 'fa fa-check-square-o',
  [ConversationItemType.CUSTOMER_ASSIGNEE_CHANGE]: 'fa fa-exchange',
  [ConversationItemType.CREDIT_CARD_VIEWED]: 'fa fa-credit-card',
  [ConversationItemType.EMAIL]: 'icon-email',
  [ConversationItemType.EMAIL_CAMPAIGN_OUTREACH]: 'icon-email',
  [ConversationItemType.EMAIL_PREVIEW]: 'icon-email',
  [ConversationItemType.EMAIL_SEND_FAILURE]: 'icon-email',
  [ConversationItemType.FB_MESSAGE_INCOMING]: 'icon-fb-messenger',
  [ConversationItemType.FB_MESSAGE_OUTGOING]: 'icon-fb-messenger',
  [ConversationItemType.HOTEL_RESERVATION]: 'fa-bed',
  [ConversationItemType.HELP_APP_CONTEXT]: 'fa fa-file',
  [ConversationItemType.SECURE_DATA_REQUEST]: 'fa fa-credit-card',
  [ConversationItemType.ITEM_LINK]: 'fa-link',
  [ConversationItemType.MARKETING_EMAIL]: 'fa-marketing-email',
  [ConversationItemType.MESSAGING_SESSION]: 'icon-chat',
  [ConversationItemType.MOBILE_CONTEXT]: 'fa-mobile-phone',
  [ConversationItemType.PAYMENT_COMPLETED]: 'fa fa-credit-card',
  [ConversationItemType.PAYMENT_STATUS_EVENT]: 'fa fa-credit-card',
  [ConversationItemType.PHONE_CALL]: 'icon-phone',
  [ConversationItemType.PHONE_CALLBACK]: 'fa-calendar-o',
  [ConversationItemType.PLACEHOLDER]: 'fa-question-circle',
  [ConversationItemType.PROACTIVE_CHAT_CONTENT]: 'ion-ios-world-outline',
  [ConversationItemType.ROUTING_ITEM]: 'fa-rss',
  [ConversationItemType.RULE_DISABLED_LOOP]: 'icon-stop',
  [ConversationItemType.SESSION_ENDED]: 'icon-stop',
  [ConversationItemType.SMS]: 'icon-sms',
  [ConversationItemType.SMS_CAMPAIGN_OUTREACH]: 'icon-sms',
  [ConversationItemType.TASK]: 'icon-task',
  [ConversationItemType.TASK_COMPLETION]: 'icon-task',
  [ConversationItemType.TASK_DUE]: 'icon-task',
  [ConversationItemType.TASK_EDITED]: 'icon-task',
  [ConversationItemType.TASK_ROUTING]: 'icon-task',
  [ConversationItemType.TASK_REOPENED]: 'icon-task',
  [ConversationItemType.TOPIC_CHANGE]: 'fa-rss',
  [ConversationItemType.TWITTER]: 'fa fa-twitter',
  [ConversationItemType.VOICE_AI_RECORDING]: 'icon-phone',
  [ConversationItemType.VOICEMAIL]: 'icon-voicemail',
  [ConversationItemType.VOICE_CAMPAIGN_OUTREACH]: 'icon-phone',
  [ConversationItemType.VOICE_CAMPAIGN_OUTREACH_RESULT]: 'icon-phone',
  [ConversationItemType.VOICE_RECORDING_DELETED]: 'fa-rss',
  [ConversationItemType.WEB_CONTEXT]: 'fa fa-globe',
  [ConversationItemType.WEB_FORM]: 'fa fa-globe',
  [ConversationItemType.WIDGET_ACTIVITY]: 'fa fa-tv',
  [ConversationItemType.CUSTOM_CHANNEL_MESSAGE]: '',

  [CompositionContentType.CREDIT_CARD_REQUEST]: 'fa fa-credit-card',

  [MessageChannelType.WHATSAPP]: 'fa fa-whatsapp',
  [MessageChannelType.INSTAGRAM_DIRECT]: 'fa fa-instagram',
};

export function iconClassFromSessionType(sessionType) {
  switch (sessionType) {
    case InteractionType.ABANDONED_CALL:
      return 'icon-abandonedCall';
    case InteractionType.CHAT:
      return 'icon-chat';
    case InteractionType.EMAIL:
      return 'icon-email';
    case InteractionType.FB_MESSENGER:
      return 'icon-fb-messenger';
    case InteractionType.INSTAGRAM_DIRECT:
      return 'fa fa-instagram';
    case InteractionType.SMS:
      return 'icon-sms';
    case InteractionType.TWITTER:
      return 'fa fa-twitter';
    case InteractionType.VOICEMAIL:
      return 'icon-voicemail';
    case InteractionType.WHATSAPP:
      return 'fa fa-whatsapp';
    default:
      return '';
  }
}
