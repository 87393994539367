import _ from 'lodash';

import Attachment from './attachment';
import attachmentsFromJs, { typeReflect as attachmentTypeReflect } from './attachment/attachment_from_js';
import ChatCompositionContent from './composition/chat_composition_content';
import CoBrowseCompositionContent from 'models/composition/cobrowse_composition_content';
import compositionContentFromJs, {
  typeReflect as compositionContentTypeReflect,
} from './composition/composition_content_from_js';
import CompositionContentType from 'models/composition/composition_content_type';
import ConversationMessageCompositionContent from './composition/conversation_message_composition_content';
import createModel, { prop } from './lib/create_model';
import CreditCardCompositionContent from 'models/composition/credit_card_composition_content';
import CustomChannelCompositionContent from './composition/custom_channel_composition_content';
import EmailCompositionContent from './composition/email_composition_content';
import ExternalFormCompositionContent from './composition/external_form_composition_content';
import Err from 'models/err';
import FbMessageCompositionContent from './composition/fb_message_composition_content';
import getClassName from 'scripts/lib/class_name';
import IdGenerator from '../contracts/id_generator';
import NoteCompositionContent from './composition/note_composition_content';
import RelatedSnippetId from './related_snippet_id';
import PhoneCallCompositionContent from './composition/phone_call_composition_content';
import PhoneCallbackCompositionContent from './composition/phone_callback_composition_content';
import ServerClock from 'scripts/application/lib/server_clock';
import SmsCompositionContent from './composition/sms_composition_content';
import SuggestedReply from 'models/suggested_reply';
import TaskCompositionContent from './composition/task_composition_content';
import TaskEditCompositionContent from './composition/task_composition_edit_content';
import { Translation } from './translation';
import Upload from './upload';

const MB = 1 << (10 * 2);
export const AttachmentErrorCodes = Object.freeze({
  UPLOAD_FAILED: 'upload_failed',
  UPLOADING: 'uploading',
});

const Composition = createModel({
  modelName: 'Composition',

  properties: {
    appliedSuggestedReply: prop(SuggestedReply),
    attachments: prop([Attachment, Upload])
      .default([])
      .fromJs(attachmentsFromJs),
    content: prop()
      .oneOf(
        ChatCompositionContent,
        CoBrowseCompositionContent,
        CreditCardCompositionContent,
        EmailCompositionContent,
        ExternalFormCompositionContent,
        FbMessageCompositionContent,
        NoteCompositionContent,
        PhoneCallbackCompositionContent,
        PhoneCallCompositionContent,
        SmsCompositionContent,
        TaskEditCompositionContent,
        TaskCompositionContent,
        ConversationMessageCompositionContent,
        CustomChannelCompositionContent
      )
      .fromJs(compositionContentFromJs),
    conversationId: String,
    customerId: prop(String).isRequired,
    id: prop(String).isRequired,
    persisted: Boolean,
    snippetIds: [String], // Use relatedSnippetIds going forward to track the language and channel type used
    relatedSnippetIds: prop([RelatedSnippetId]).default([]),
    translation: prop(Translation),
    updatedAt: String,
    _version: prop(Number).default(0),
  },

  contentType() {
    return compositionContentTypeReflect.instanceToType(this.content);
  },

  findAttachmentById(id) {
    return this.attachments.find(attachment => attachment.id === id);
  },

  getUploads() {
    return this.attachments.filter(a => a instanceof Upload);
  },

  allowAttachments() {
    const contentType = this.contentType();
    switch (contentType) {
      case CompositionContentType.CONVERSATION_NOTE:
      case CompositionContentType.EMAIL:
      case CompositionContentType.TASK:
      case CompositionContentType.TASK_EDIT:
        return true;
      default:
        return false;
    }
  },

  hasAttachments() {
    return !!this.attachments.length;
  },

  getUploadPath(attachmentId) {
    return Composition.getUploadPath(this.id, attachmentId);
  },

  detachUploads() {
    let attachments = this.attachments;
    this.attachments = [];
    return attachments;
  },

  validateSingleAttachment() {
    let errors = [];
    let attachment = _.head(this.attachments);

    if (this.attachments.length > 1) {
      errors.push(
        new Err({
          attr: 'attachments',
          code: Err.Code.GREATER_THAN,
          detail: 'Cannot have more than one attachment',
        })
      );
    }

    if (attachment) {
      errors = errors.concat(this.validateSingleAttachmentSize(attachment));
    }

    return errors;
  },

  validateSingleAttachmentSize(attachment) {
    if (attachment.fileDescriptor().contentLength > this.getMaxSingleAttachmentMb() * MB) {
      return [
        new Err({
          attr: 'attachments',
          code: Err.Code.TOO_LONG,
          detail: this.getSingleAttachmentSizeError(attachment),
        }),
      ];
    }
    return [];
  },

  getMaxSingleAttachmentMb() {
    return this.content.constructor.MAX_SINGLE_ATTACHMENT_SIZE_MB || this.getMaxTotalAttachmentMb();
  },

  getSingleAttachmentSizeError(attachment) {
    let maxSingleSize = this.getMaxSingleAttachmentMb();
    return `Size of attachment ${attachment.fileDescriptor().filename} exceeds ${maxSingleSize}MB`;
  },

  validateTotalAttachmentSize() {
    if (this.getTotalAttachmentSize() > this.getMaxTotalAttachmentMb() * MB) {
      return [
        new Err({
          attr: 'attachments',
          code: Err.Code.TOO_LONG,
          detail: this.getTotalAttachmentSizeError(),
        }),
      ];
    }
    return [];
  },

  validateAttachmentCount() {
    const errors = [];
    const maxAllowedAttachmentCount = this.getMaxAttachmentCount();

    // If maxAllowedAttachmentCount is falsy, that means we do not impose any limits on the attachment count (or
    // the checking is done elsewhere)
    if (maxAllowedAttachmentCount && this.attachments.length > maxAllowedAttachmentCount) {
      errors.push(
        new Err({
          attr: 'attachments',
          code: Err.Code.TOO_LONG,
          detail: `There is a maximum of ${maxAllowedAttachmentCount} attachments`,
        })
      );
    }
    return errors;
  },

  getTotalAttachmentSize() {
    return _.reduce(
      this.attachments,
      (sum, attachment) => {
        return attachment.fileDescriptor().contentLength + sum;
      },
      0
    );
  },

  getMaxAttachmentMb() {
    return this.content.constructor.MAX_ATTACHMENT_SIZE_MB;
  },

  getMaxTotalAttachmentMb() {
    return this.content.constructor.MAX_TOTAL_ATTACHMENT_SIZE_MB;
  },

  getMaxAttachmentCount() {
    return this.content.constructor.MAX_ATTACHMENT_COUNT;
  },

  getTotalAttachmentSizeError() {
    let maxTotalSize = this.getMaxTotalAttachmentMb();

    switch (this.contentType()) {
      default:
        return `Total size of attachments exceeds ${maxTotalSize}MB`;
      case CompositionContentType.CONVERSATION_NOTE:
        return `Total size of note attachments exceeds ${maxTotalSize}MB`;
      case CompositionContentType.FB_MESSAGE_OUTGOING:
        return `Total size of Facebook message attachments exceeds ${maxTotalSize}MB`;
      case CompositionContentType.SMS:
        return `Total size of SMS attachments exceeds ${maxTotalSize}MB`;
      case CompositionContentType.TASK:
        return `Total size of task attachments exceeds ${maxTotalSize}MB`;
      case CompositionContentType.CHAT:
        return `Total size of chat attachments exceeds ${maxTotalSize}MB`;
    }
  },

  validateAttachmentStatus() {
    let errors = [];

    if (_.find(this.attachments, u => u instanceof Upload && u.status === Upload.Status.FAILED)) {
      errors.push(
        new Err({
          attr: 'attachments',
          code: Err.Code.INVALID,
          detail: this.getErrorDetail(AttachmentErrorCodes.UPLOAD_FAILED),
        })
      );
    }

    if (
      _.find(
        this.attachments,
        u => (u instanceof Upload && u.status === Upload.Status.NEW) || u.status === Upload.Status.STARTED
      )
    ) {
      errors.push(
        new Err({
          attr: 'attachments',
          code: Err.Code.INVALID,
          detail: this.getErrorDetail(AttachmentErrorCodes.UPLOADING),
        })
      );
    }
    return errors;
  },

  getErrorDetail(errorCode) {
    let compositionContentType = this.contentType();

    switch (errorCode) {
      default:
        return 'Cannot send message due to a problem with one or more attachments';
      case AttachmentErrorCodes.UPLOAD_FAILED:
        switch (compositionContentType) {
          default:
            return 'Cannot send message with failed attachments';
          case CompositionContentType.CONVERSATION_NOTE:
            return 'Cannot add note with failed attachments';
          case CompositionContentType.EMAIL:
            return 'Cannot send email message with failed attachments';
          case CompositionContentType.FB_MESSAGE:
            return 'Cannot send Facebook message with failed attachments';
          case CompositionContentType.SMS:
            return 'Cannot send SMS message with failed attachments';
          case CompositionContentType.TASK:
            return 'Cannot add task with failed attachments';
          case CompositionContentType.TASK_EDIT:
            return 'Cannot edit task with failed attachments';
          case CompositionContentType.CHAT:
            return 'Cannot send chat message with failed attachments';
        }
      case AttachmentErrorCodes.UPLOADING:
        switch (compositionContentType) {
          default:
            return 'Cannot send message while attachments are being uploaded';
          case CompositionContentType.CONVERSATION_NOTE:
            return 'Cannot add note while attachments are being uploaded';
          case CompositionContentType.EMAIL:
            return 'Cannot send email while attachments are being uploaded';
          case CompositionContentType.FB_MESSAGE:
            return 'Cannot send Facebook message while attachments are being uploaded';
          case CompositionContentType.SMS:
            return 'Cannot send SMS message while attachments are being uploaded';
          case CompositionContentType.TASK:
            return 'Cannot add task while attachments are being uploaded';
          case CompositionContentType.TASK_EDIT:
            return 'Cannot edit task while attachments are being uploaded';
          case CompositionContentType.CHAT:
            return 'Cannot send chat message while attachments are being uploaded';
        }
    }
  },

  /* Mutators */

  addAttachments(attachments) {
    this.attachments = this.attachments.concat(attachments);
    this._incrementVersion();
  },

  removeAttachment(id) {
    let removed = _.remove(this.attachments, { id })[0];
    if (removed) {
      this._incrementVersion();
    }

    return removed;
  },

  mergeIncompleteUploads(attachments) {
    attachments
      .filter(a => a instanceof Upload && a.status !== Upload.Status.COMPLETED)
      .forEach(u => {
        if (!this.findAttachmentById(u.id)) {
          this.attachments.push(u);
        }
      });
  },

  addSnippetId(snippetId) {
    if (!this.snippetIds) {
      this.snippetIds = [];
    }
    if (this.snippetIds.indexOf(snippetId) === -1) {
      this.snippetIds = this.snippetIds.concat(snippetId);
    }
  },

  addRelatedSnippetId({ snippetId, snippetLanguage, snippetChannelType }) {
    if (!_.find(this.relatedSnippetIds, { snippetId, snippetLanguage, snippetChannelType })) {
      this.relatedSnippetIds = this.relatedSnippetIds.concat(
        RelatedSnippetId.create({ snippetId, snippetLanguage, snippetChannelType })
      );
    }
  },

  replaceConversationId(conversationId) {
    this.conversationId = conversationId;
  },

  replaceContent(newContent) {
    if (!(newContent instanceof this.content.constructor)) {
      throw new Error(`expected [${this.content.constructor.name}] content but got [${getClassName(newContent)}]`);
    }
    this.updatedAt = ServerClock.toISOString();
    this.content = newContent;
    this._incrementVersion();
  },

  replaceTranslation(newTranslation) {
    this.updatedAt = ServerClock.toISOString();
    this.translation = newTranslation;
    this._incrementVersion();
  },

  updateUpload(upload) {
    let index = _.findIndex(this.attachments, { id: upload.id });
    this.attachments[index] = upload;
    this._incrementVersion();
  },

  markAsPersisted() {
    this.persisted = true;
    this._incrementVersion();
  },

  setAppliedSuggestedReply(suggestedReply) {
    this.appliedSuggestedReply = suggestedReply;
  },

  setCustomerId(customerId) {
    this.customerId = customerId;
  },

  _incrementVersion() {
    this._version = this._version + 1;
  },

  isEmpty() {
    return this.content.isEmpty ? this.content.isEmpty() : false;
  },

  overrideToJs(toJs) {
    return () => {
      let obj = toJs();
      if (obj.content) {
        obj.content.type = this.contentType();
      }
      if (obj.attachments) {
        obj.attachments.forEach((a, i) => {
          a.type = attachmentTypeReflect.instanceToType(this.attachments[i]);
        });
      }
      return obj;
    };
  },

  statics: {
    create(attrs) {
      return new this(
        _.merge(attrs, {
          id: IdGenerator.newId(),
          updatedAt: ServerClock.toISOString(),
        })
      );
    },

    getUploadPath(compositionId, uploadId) {
      return `attachments/${compositionId}/${uploadId}`;
    },

    getIdsFromPath(path) {
      let pathComponents = path.split('/');
      return {
        compositionId: pathComponents[1],
        uploadId: pathComponents[2],
      };
    },
  },
});

export default Composition;
