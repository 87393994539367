import _ from 'lodash';
import createModel, { prop } from '../lib/create_model';
import createEnum from 'scripts/lib/create_enum';

export const Types = createEnum(
  'make_friendly',
  'make_professional',
  'rephrase',
  'make_shorter',
  'make_longer',
  'conversation_summary',
  'translate'
);

const AITextCompletion = createModel({
  modelName: 'AITextCompletion',

  properties: {
    id: prop(String).isRequired,
    type: prop(String).oneOf(..._.values(Types)).isRequired,
    data: prop(Object).isRequired,
    rewriteId: prop(String),
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    Types,
  },
});

export default AITextCompletion;
