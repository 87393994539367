import AccountGatewayObserver from 'actions/channels/account_gateway_observer';
import ActiveCallAgentObserver from 'actions/phone_call/active_call_agent_observer';
import ActiveCallConversationItemObserver from 'actions/phone_call/active_call_conversation_item_observer';
import ActiveCallCustomerHistoryMergeObserver from 'actions/phone_call/active_call_customer_history_merge_observer';
import ActiveCallGatewayObserver from 'actions/phone_call/active_call_gateway_observer';
import ActiveSecureDataRequestsObserver from 'actions/customer/active_secure_data_requests_observer';
import ActiveSessionCustomerHistoryObserver from 'actions/communication_session/active_session_customer_history_observer';
import AgentAssignmentGatewayObserver from 'actions/inbox/agent_assignment_gateway_observer';
import AgentAssistanceConfigGatewayObserver from 'actions/configuration/agent_assistance_config_gateway_observer';
import AgentExperienceConfigGatewayObserver from 'actions/configuration/agent_experience_config_gateway_observer';
import AgentGatewayObserver from 'actions/agents/agent_gateway_observer';
import AgentNotificationGatewayObserver from 'actions/agent_notifications/agent_notification_gateway_observer';
import AgentProfileGatewayObserver from 'actions/users/agent_profile_gateway_observer';
import AgentReadGatewayObserver from 'actions/conversation/agent_read_gateway_observer';
import AgentRoutingPreferencesGatewayObserver from 'actions/routing/agent_routing_preferences_gateway_observer';
import AgentStatusGatewayObserver from 'actions/agent_status/agent_status_gateway_observer';
import AgentStatusesGatewayObserver from 'actions/agent_status/agent_statuses_gateway_observer';
import AgentStatusReasonGatewayObserver from 'actions/agent_status/agent_status_reason_gateway_observer';
import AgentTaskReadGatewayObserver from 'actions/task/agent_task_read_gateway_observer';
import AgentUserGatewayObserver from 'actions/users/agent_user_gateway_observer';
import AIConversationSummariesGatewayObserver from 'actions/ai_conversation_summary/ai_conversation_summaries_gateway_observer';
import AnswerPerformanceGatewayObserver from 'actions/answer_performance/answer_performance_gateway_observer';
import AnswerSuggestionGatewayObserver from 'actions/answer_suggestions/answer_suggestion_gateway_observer';
import ApiTokenGatewayObserver from 'actions/api_tokens/api_token_gateway_observer';
import AudienceGatewayObserver from 'actions/audience/audience_gateway_observer';
import AuthenticationGatewayObserver from 'actions/user/authentication_gateway_observer';
import CommunicationQueueGatewayObserver from 'actions/configuration/communication_queue_gateway_observer';
import CommunicationSessionRoutingEventObserver from 'actions/communication_session/communication_session_routing_event_observer';
import CompanyProfileGatewayObserver from 'actions/configuration/company_profile_gateway_observer';
import CompositionGatewayObserver from 'actions/composition/composition_gateway_observer';
import CompositionSubscriberGatewayObserver from 'actions/composition/composition_subscriber_gateway_observer';
import CompositionUploadGatewayObserver from 'actions/composition/composition_upload_gateway_observer';
import ConversationCountGatewayObserver from 'actions/conversation/conversation_count_gateway_observer';
import ConversationMessageGatewayObserver from 'actions/conversation_message/conversation_message_gateway_observer';
import ConversationWorkflowConfigGatewayObserver from 'actions/conversation_workflow_config/conversation_workflow_config_gateway_observer';
import EmbedTokensReportGatewayObserver from 'actions/reporting/embed_tokens_report_gateway_observer';
import CreditCardGatewayObserver from 'actions/secure_data/credit_card_gateway_observer';
import CurrentAgentProfileGatewayObserver from 'actions/current_agent/current_agent_profile_gateway_observer';
import CustomAttributesGatewayObserver from 'actions/custom_attributes/custom_attributes_gateway_observer';
import CustomChannelGatewayObserver from 'actions/custom_channels/custom_channels_gateway_observer';
import CustomChannelsConfigGatewayObserver from 'actions/custom_channels/custom_channels_config_gateway_observer';
import CustomReportingGatewayObserver from 'actions/custom_reporting/custom_reporting_gateway_observer';
import CustomerAssigneeGatewayObserver from 'actions/customer/customer_assignee_gateway_observer';
import CustomerAutoExtensionGatewayObserver from 'actions/customer_profile/customer_auto_extension_gateway_observer';
import CustomerComplianceGatewayObserver from 'actions/compliance/customer_compliance_gateway_observer';
import CustomerExtensionGatewayObserver from 'actions/customer_profile/customer_extension_gateway_observer';
import CustomerHistoryGatewayObserver from 'actions/customer/customer_history_gateway_observer';
import CustomerHistoryRedactionGatewayObserver from 'actions/compliance/conversation_item_compliance_gateway_observer';
import CustomerMatchGatewayObserver from 'actions/customer_match/customer_match_gateway_observer';
import CustomerMergeGatewayObserver from 'actions/customer/customer_merge_gateway_observer';
import CustomerNoteGatewayObserver from 'actions/customer/customer_note_gateway_observer';
import CustomerProfileCompoundGatewayObserver from 'actions/customer_profile/customer_profile_compound_gateway_observer';
import CustomerProfileDefGatewayObserver from 'actions/configuration/customer_profile_def_gateway_observer';
import CustomerTransactionGatewayObserver from 'actions/customer/customer_transaction_gateway_observer';
import CustomerTypingGatewayObserver from 'actions/communication_session/customer_typing_state_observer';
import DeviceStatusObserver from 'actions/configuration/device_status_observer';
import DiagnosticsConfigurationGatewayObserver from 'actions/configuration/diagnostics_configuration_gateway_observer';
import EmbeddedReportGatewayObserver from 'actions/reporting/embedded_report_gateway_observer';
import EndpointGatewayObserver from 'actions/configuration/endpoint_gateway_observer';
import ExternalAppCardTemplateGatewayObserver from 'actions/external_apps/external_app_card_template_observer';
import ExternalCustomerLookupActionsGatewayObserver from 'actions/external_customer_lookup/external_customer_lookup_actions_gateway_observer';
import ExternalCustomerLookupGatewayObserver from 'actions/external_customer_lookup/external_customer_lookup_gateway_observer';
import ExternalDataGatewayObserver from 'actions/external_data/external_data_gateway_observer';
import FeatureSetGatewayObserver from 'actions/configuration/feature_set_gateway_observer';
import InboxGatewayObserver from 'actions/inbox/inbox_gateway_observer';
import IntegrationsGatewayObserver from 'actions/external_customer_lookup/integrations_gateway_observer';
import ItemIdsObserver from 'actions/customer/item_ids_observer';
import IvrGatewayObserver from 'actions/ivr/ivr_gateway_observer';
import KbVariableGatewayObserver from 'actions/knowledge_base/kb_variable_gateway_observer';
import LanguageGatewayObserver from 'actions/language/language_gateway_observer';
import MessagingConfigurationGatewayObserver from 'actions/configuration/messaging_configuration_gateway_observer';
import NoReplyNeededGatewayObserver from 'actions/conversation/no_reply_needed_gateway_observer';
import NotificationGatewayObserver from 'actions/notification/notification_gateway_observer';
import OutgoingCallGatewayObserver from 'actions/phone_call/outgoing_call_gateway_observer';
import OutgoingEmailGatewayObserver from 'actions/composition/outgoing_email_gateway_observer';
import OutgoingFbMessageGatewayObserver from 'actions/composition/outgoing_fb_message_gateway_observer';
import OutgoingSmsGatewayObserver from 'actions/composition/outgoing_sms_gateway_observer';
import OutgoingChatGatewayObserver from 'actions/composition/outgoing_chat_gateway_observer';
import OutgoingChatHttpGatewayObserver from 'actions/composition/outgoing_chat_http_gateway_observer';
import PhoneCallRoutingEventObserver from 'actions/phone_call/phone_call_routing_event_observer';
import PhoneGatewayObserver from 'actions/phone_call/phone_gateway_observer';
import PhraseSuggestionsGatewayObserver from 'actions/phrase_suggestions/phrase_suggestions_gateway_observer';
import PublicAccountGatewayObserver from 'actions/channels/public_account_gateway_observer';
import PublicAnswerUploadGatewayObserver from 'actions/knowledge_base/public_answer_upload_gateway_observer';
import RecommendedAnswersCountGatewayObserver from 'actions/recommended_answers/recommended_answers_count_gateway_observer';
import ReactionGatewayObserver from 'actions/conversation_item/reaction/reaction_gateway_observer';
import RecommendedAnswersGatewayObserver from 'actions/recommended_answers/recommended_answers_gateway_observer';
import RelationshipGatewayObserver from 'actions/relationship/relationship_gateway_observer';
import RelationshipLabelGatewayObserver from 'actions/relationship/relationship_label_gateway_observer';
import ReportBuilderConfigGatewayObserver from 'actions/report_builder_config/report_builder_config_gateway_observer';
import ReportGatewayObserver from 'actions/reporting/report_gateway_observer';
import RoutableInboxCountsGatewayObserver from 'actions/conversation/routable_inbox_counts_gateway_observer';
import RoutingEventGatewayObserver from 'actions/conversation/routing_event_gateway_observer';
import RoutingGroupGatewayObserver from 'actions/routing_groups/routing_group_gateway_observer';
import RoutingQueueItemGatewayObserver from 'actions/communication_session/routing_queue_item_gateway_observer';
import SecureDataRequestHistoryGatewayObserver from 'actions/customer/secure_data_request_history_gateway_observer';
import ServerClockUpdater from 'actions/server_clock/server_clock_updater';
import ServerInfoObserver from 'actions/version/server_info_observer';
import ScheduledReportGatewayObserver from 'actions/reporting/scheduled_report_gateway_observer';
import SnippetGatewayObserver from 'actions/knowledge_base/snippet_gateway_observer';
import SnippetRevisionGatewayObserver from 'actions/knowledge_base/snippet_revision_gateway_observer';
import SnippetSearchResultHttpGatewayObserver from 'actions/knowledge_base/snippet_search_result_http_gateway_observer';
import SnippetUploadGatewayObserver from 'actions/knowledge_base/snippet_upload_gateway_observer';
import StationConfigurationGatewayObserver from 'actions/station_configuration/station_configuration_gateway_observer';
import SystemConfigurationGatewayObserver from 'actions/configuration/system_configuration_gateway_observer';
import TaskCommentsGatewayObserver from 'actions/task/task_comments_gateway_observer';
import TaskFollowerGatewayObserver from 'actions/task_follower/task_follower_gateway_observer';
import TaskGatewayObserver from 'actions/conversation_item/task/task_gateway_observer';
import TeamGatewayObserver from 'actions/teams/team_gateway_observer';
import TopicGatewayObserver from 'actions/topics/topic_gateway_observer';
import TopicHierarchyGatewayObserver from 'actions/topics/topic_hierarchy_gateway_observer';
import TopicSuggestionsGatewayObserver from 'actions/topic_suggestions/topic_suggestions_gateway_observer';
import UserPasswordGatewayObserver from 'actions/user/user_password_gateway_observer';
import UserGatewayObserver from 'actions/users/user_gateway_observer';
import UsersUploadGatewayObserver from 'actions/users/users_upload_gateway_observer';
import VoiceConfigurationGatewayObserver from 'actions/configuration/voice_configuration_gateway_observer';
import WebhookGatewayObserver from 'actions/webhook/webhook_gateway_observer';
import WidgetConfigGatewayObserver from 'actions/widgets/widget_config_gateway_observer';
import ExternalAppCardDataGatewayObserver from 'actions/external_apps/external_app_card_data_observer';

export default function(context) {
  let compositionGatewayObserver = new CompositionGatewayObserver(context);
  let customerTypingStateObserver = new CustomerTypingGatewayObserver(context);

  context.gateways.account.setObserver(new AccountGatewayObserver(context));
  context.gateways.activeCall.addObserver(new ActiveCallGatewayObserver(context));
  context.gateways.agent.setObserver(new AgentGatewayObserver(context));
  context.gateways.agentAssignment.setObserver(new AgentAssignmentGatewayObserver(context));
  context.gateways.agentAssistanceConfig.addObserver(new AgentAssistanceConfigGatewayObserver(context));
  context.gateways.agentExperienceConfig.setObserver(new AgentExperienceConfigGatewayObserver(context));
  context.gateways.agentNotification.setObserver(new AgentNotificationGatewayObserver(context));
  context.gateways.agentProfile.setObserver(new AgentProfileGatewayObserver(context));
  context.gateways.agentRead.setObserver(new AgentReadGatewayObserver(context));
  context.gateways.agentRoutingPreferences.setObserver(new AgentRoutingPreferencesGatewayObserver(context));
  context.gateways.agentStatus.setObserver(new AgentStatusGatewayObserver(context));
  context.gateways.agentStatuses.setObserver(new AgentStatusesGatewayObserver(context));
  context.gateways.agentStatusReason.setObserver(new AgentStatusReasonGatewayObserver(context));
  context.gateways.agentTaskRead.addObserver(new AgentTaskReadGatewayObserver(context));
  context.gateways.agentUser.addObserver(new AgentUserGatewayObserver(context));
  context.gateways.aiConversationSummary.setObserver(new AIConversationSummariesGatewayObserver(context));
  context.gateways.answerPerformance.setObserver(new AnswerPerformanceGatewayObserver(context));
  context.gateways.answerSuggestion.addObserver(new AnswerSuggestionGatewayObserver(context));
  context.gateways.apiToken.addObserver(new ApiTokenGatewayObserver(context));
  context.gateways.audience.setObserver(new AudienceGatewayObserver(context));
  context.gateways.authentication.addObserver(new AuthenticationGatewayObserver(context));
  context.gateways.communicationQueue.setObserver(new CommunicationQueueGatewayObserver(context));
  context.gateways.companyProfile.addObserver(new CompanyProfileGatewayObserver(context));
  context.gateways.complianceCustomer.setObserver(new CustomerComplianceGatewayObserver(context));
  context.gateways.composition.addObserver(compositionGatewayObserver);
  context.gateways.compositionSubscriber.setObserver(new CompositionSubscriberGatewayObserver(context));
  context.gateways.compositionUpload.addObserver(new CompositionUploadGatewayObserver(context));
  context.gateways.conversationCount.addObserver(new ConversationCountGatewayObserver(context));
  context.gateways.conversationItem.addObserver(new ActiveCallConversationItemObserver(context));
  context.gateways.customerHistoryRedaction.setObserver(new CustomerHistoryRedactionGatewayObserver(context));
  context.gateways.customAttributes.setObserver(new CustomAttributesGatewayObserver(context));
  context.gateways.customChannels.setObserver(new CustomChannelGatewayObserver(context));
  context.gateways.customChannelsConfig.setObserver(new CustomChannelsConfigGatewayObserver(context));
  context.gateways.conversationMessage.addObserver(new ConversationMessageGatewayObserver(context));
  context.gateways.conversationWorkflowConfig.addObserver(new ConversationWorkflowConfigGatewayObserver(context));
  context.gateways.embedTokensReport.setObserver(new EmbedTokensReportGatewayObserver(context));
  context.gateways.creditCard.addObserver(new CreditCardGatewayObserver(context));
  context.gateways.currentAgentProfile.addObserver(new CurrentAgentProfileGatewayObserver(context));
  context.gateways.currentAgentProfile.addObserver(new ActiveCallAgentObserver(context));
  context.gateways.customerActiveSecureDataRequests.setObserver(new ActiveSecureDataRequestsObserver(context));
  context.gateways.customerAssignee.addObserver(new CustomerAssigneeGatewayObserver(context));
  context.gateways.customerAutoExtension.addObserver(new CustomerAutoExtensionGatewayObserver(context));
  context.gateways.customerExtension.addObserver(new CustomerExtensionGatewayObserver(context));
  context.gateways.customerExternalDataObjects.setObserver(new ExternalDataGatewayObserver(context));
  context.gateways.customerHistory.addObserver(new ActiveSessionCustomerHistoryObserver(context));
  context.gateways.customerHistory.addObserver(new CustomerHistoryGatewayObserver(context));
  context.gateways.customerHistory.addObserver(new SecureDataRequestHistoryGatewayObserver(context));
  context.gateways.customerHistory.addObserver(customerTypingStateObserver);
  context.gateways.customerHistoryMerge.addObserver(new ActiveCallCustomerHistoryMergeObserver(context));
  context.gateways.customerMatch.addObserver(new CustomerMatchGatewayObserver(context));
  context.gateways.customerNote.setObserver(new CustomerNoteGatewayObserver(context));
  context.gateways.customerProfile.setObserver(new CustomerProfileCompoundGatewayObserver(context));
  context.gateways.customerProfileDef.setObserver(new CustomerProfileDefGatewayObserver(context));
  context.gateways.customerTransaction.setObserver(new CustomerTransactionGatewayObserver(context));
  context.gateways.customerProfileMerge.setObserver(new CustomerMergeGatewayObserver(context));
  context.gateways.customerTypingState.addObserver(customerTypingStateObserver);
  context.gateways.customReporting.addObserver(new CustomReportingGatewayObserver(context));
  context.gateways.deviceStatus.addObserver(new DeviceStatusObserver(context));
  context.gateways.embeddedDataset.setObserver(new EmbeddedReportGatewayObserver(context));
  context.gateways.embeddedReport.setObserver(new EmbeddedReportGatewayObserver(context));
  context.gateways.embedTokens.setObserver(new EmbedTokensReportGatewayObserver(context));
  context.gateways.endpoint.setObserver(new EndpointGatewayObserver(context));
  context.gateways.externalAppCardData.setObserver(new ExternalAppCardDataGatewayObserver(context));
  context.gateways.externalAppCardTemplates.setObserver(new ExternalAppCardTemplateGatewayObserver(context));
  context.gateways.externalCustomerLookup.addObserver(new ExternalCustomerLookupGatewayObserver(context));
  context.gateways.externalCustomerLookupActions.addObserver(new ExternalCustomerLookupActionsGatewayObserver(context));
  context.gateways.fbMessengerAccount.setObserver(new PublicAccountGatewayObserver(context));
  context.gateways.featureSet.addObserver(new FeatureSetGatewayObserver(context));
  context.gateways.inboxHttp.addObserver(new InboxGatewayObserver(context));
  context.gateways.integrations.setObserver(new IntegrationsGatewayObserver(context));
  context.gateways.itemIds.setObserver(new ItemIdsObserver(context));
  context.gateways.ivr.setObserver(new IvrGatewayObserver(context));
  context.gateways.kbVariable.addObserver(new KbVariableGatewayObserver(context));
  context.gateways.language.addObserver(new LanguageGatewayObserver(context));
  context.gateways.messagingConfiguration.setObserver(new MessagingConfigurationGatewayObserver(context));
  context.gateways.noReplyNeeded.addObserver(new NoReplyNeededGatewayObserver(context));
  context.gateways.notification.setObserver(new NotificationGatewayObserver(context));
  context.gateways.outgoingCall.addObserver(new OutgoingCallGatewayObserver(context));
  context.gateways.outgoingEmailHttp.setObserver(new OutgoingEmailGatewayObserver(context));
  context.gateways.outgoingFbMessage.addObserver(new OutgoingFbMessageGatewayObserver(context));
  context.gateways.outgoingSms.addObserver(new OutgoingSmsGatewayObserver(context));
  context.gateways.outgoingChat.addObserver(new OutgoingChatGatewayObserver(context));
  context.gateways.outgoingChatHttp.setObserver(new OutgoingChatHttpGatewayObserver(context));
  context.gateways.phoneControlsHttp.addObserver(new PhoneGatewayObserver(context));
  context.gateways.phoneGatewayHttp.addObserver(new PhoneGatewayObserver(context));
  context.gateways.phraseSuggestions.addObserver(new PhraseSuggestionsGatewayObserver(context));
  context.gateways.publicAnswerUpload.addObserver(new PublicAnswerUploadGatewayObserver(context));
  context.gateways.reactions.setObserver(new ReactionGatewayObserver(context));
  context.gateways.recommendedAnswers.setObserver(new RecommendedAnswersGatewayObserver(context));
  context.gateways.recommendedAnswersCount.setObserver(new RecommendedAnswersCountGatewayObserver(context));
  context.gateways.relationship.setObserver(new RelationshipGatewayObserver(context));
  context.gateways.relationshipLabels.setObserver(new RelationshipLabelGatewayObserver(context));
  context.gateways.report.addObserver(new ReportGatewayObserver(context));
  context.gateways.reportBuilderConfig.setObserver(new ReportBuilderConfigGatewayObserver(context));
  context.gateways.routableInboxCounts.addObserver(new RoutableInboxCountsGatewayObserver(context));
  context.gateways.routingEvent.addObserver(new CommunicationSessionRoutingEventObserver(context));
  context.gateways.routingEvent.addObserver(new PhoneCallRoutingEventObserver(context));
  context.gateways.routingEvent.addObserver(new RoutingEventGatewayObserver(context));
  context.gateways.routingGroup.addObserver(new RoutingGroupGatewayObserver(context));
  context.gateways.routingQueueItem.addObserver(new RoutingQueueItemGatewayObserver(context));
  context.gateways.scheduledReport.setObserver(new ScheduledReportGatewayObserver(context));
  context.gateways.sharedReport.setObserver(new EmbeddedReportGatewayObserver(context));
  context.gateways.serverInfo.addObserver(new ServerClockUpdater(context));
  context.gateways.serverInfo.addObserver(new ServerInfoObserver(context));
  context.gateways.snippet.setObserver(new SnippetGatewayObserver(context));
  context.gateways.snippetRevision.setObserver(new SnippetRevisionGatewayObserver(context));
  context.gateways.snippetSearchResultHttp.setObserver(new SnippetSearchResultHttpGatewayObserver(context));
  context.gateways.snippetUpload.addObserver(new SnippetUploadGatewayObserver(context));
  context.gateways.stationConfiguration.addObserver(new StationConfigurationGatewayObserver(context));
  context.gateways.diagnosticsConfiguration.setObserver(new DiagnosticsConfigurationGatewayObserver(context));
  context.gateways.systemConfiguration.addObserver(new SystemConfigurationGatewayObserver(context));
  context.gateways.task.addObserver(new TaskGatewayObserver(context));
  context.gateways.taskComments.setObserver(new TaskCommentsGatewayObserver(context));
  context.gateways.taskFollowers.setObserver(new TaskFollowerGatewayObserver(context));
  context.gateways.team.addObserver(new TeamGatewayObserver(context));
  context.gateways.topic.addObserver(new TopicGatewayObserver(context));
  context.gateways.topicHierarchy.setObserver(new TopicHierarchyGatewayObserver(context));
  context.gateways.topicSuggestions.addObserver(new TopicSuggestionsGatewayObserver(context));
  context.gateways.user.addObserver(new UserGatewayObserver(context));
  context.gateways.userPassword.addObserver(new UserPasswordGatewayObserver(context));
  context.gateways.usersUpload.addObserver(new UsersUploadGatewayObserver(context));
  context.gateways.vgsAccount.setObserver(new PublicAccountGatewayObserver(context));
  context.gateways.voiceConfiguration.addObserver(new VoiceConfigurationGatewayObserver(context));
  context.gateways.webhook.setObserver(new WebhookGatewayObserver(context));
  context.gateways.widgets.setObserver(new WidgetConfigGatewayObserver(context));
}
