import React from 'react';

export default function StackedBooksImage(props) {
  return (
    <svg fill="none" height="160" viewBox="0 0 274 160" width="274" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M273.179 122.696C275.719 132.517 262.957 142.534 243.495 149.557C225.365 156.099 198.247 157.638 172.692 158.955C150.927 160.077 128.941 159.563 108.7 156.504C89.7502 153.64 78.6728 147.282 63.2778 142.418C42.1085 135.73 7.9104 132.523 1.69522 122.696C-4.55966 112.807 14.8764 102.952 33.4555 95.2664C51.0105 88.0047 76.6656 84.7604 101.77 81.3001C128.502 77.6153 156.819 70.8676 183.52 74.5817C210.133 78.2836 214.681 90.729 230.471 99.2023C245.319 107.171 270.716 113.176 273.179 122.696Z"
        fill="#F6F6F6"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M149.474 100.638C166.381 100.08 183.208 101.26 198.263 103.291C215.258 105.583 233.504 108.272 240.101 112.86C246.773 117.5 237.969 122.44 232.628 127.19C227.063 132.14 227.035 138.858 208.511 140.954C189.323 143.125 169.48 138.225 149.474 136.598C136.734 135.562 125.292 134.336 112.734 133.166C93.6407 131.388 64.3289 132.77 56.8437 127.971C49.5284 123.281 73.9869 119.268 84.3406 114.962C91.8844 111.825 98.1668 108.641 109.111 106.234C120.988 103.623 133.89 101.153 149.474 100.638Z"
        fill="#E3E3E3"
        fillRule="evenodd"
      />
      <g clipPath="url(#clip0_964_24102)">
        <path
          d="M193.227 43.7332C193.227 48.5148 189.371 52.3664 184.584 52.3664C184.517 52.3664 184.517 52.3664 184.451 52.3664H99.6191V48.8465H184.717C187.576 48.7137 189.769 46.3227 189.637 43.5338C189.503 40.8111 187.243 38.6195 184.518 38.6195C184.451 38.6195 184.384 38.6195 184.318 38.6195H99.6191V35.0334H184.517H184.584C189.371 35.1001 193.227 38.9517 193.227 43.7332Z"
          fill="#9BD79B"
        />
        <path
          d="M98.0899 112.867C98.0899 115.59 100.284 117.848 103.01 117.981H188.107V121.5H103.209H103.143C98.3558 121.5 94.5 117.648 94.5 112.867C94.5 108.085 98.3558 104.234 103.143 104.234H188.174V107.754H103.475C103.409 107.754 103.342 107.754 103.276 107.754C100.417 107.687 98.0899 110.011 98.0899 112.867Z"
          fill="#9BD79B"
        />
        <path
          d="M110.788 78.3996C110.788 81.1223 112.982 83.3805 115.708 83.5133H200.34V86.9665H115.442C110.656 86.8999 106.8 82.9817 106.866 78.2002C106.933 73.4848 110.722 69.6998 115.442 69.6332H200.407V73.2858H116.173C116.107 73.2858 116.04 73.2858 115.974 73.2858C113.115 73.2858 110.788 75.544 110.788 78.3996Z"
          fill="#B3B3B3"
        />
        <path
          d="M144.162 60.6016V60.9338C144.162 63.6565 146.356 65.9147 149.082 66.0475H149.281H191.697V69.5674H106.733C101.946 69.5674 98.0898 65.6492 98.0898 60.8676C98.0898 56.0861 101.946 52.2345 106.733 52.2345H191.631V55.7544H149.414C149.348 55.7544 149.281 55.7544 149.215 55.7544C149.082 55.7544 149.016 55.7544 148.883 55.7544C146.489 55.8872 144.561 57.7466 144.162 60.0709V60.6016Z"
          fill="#919191"
        />
        <path
          d="M152.672 8.66863V9.19976C152.672 11.9225 154.866 14.1807 157.591 14.3135H188.173V17.8334H108.395C103.608 17.8334 99.752 13.9151 99.752 9.1336C99.752 4.35206 103.608 0.566618 108.395 0.5H188.107V4.01988H157.924C157.857 4.01988 157.791 4.01988 157.725 4.01988C157.659 4.01988 157.525 4.01988 157.459 4.01988C155.065 4.15266 153.005 5.94591 152.672 8.33645C152.672 8.46923 152.672 8.60201 152.672 8.66863Z"
          fill="#9BD79B"
        />
        <path
          d="M200.939 95.5335C200.939 100.315 197.083 104.167 192.296 104.167C192.296 104.167 192.229 104.167 192.163 104.167H107.265V100.647H153.603C156.329 100.514 158.523 98.2557 158.523 95.533C158.523 95.4002 158.523 95.2675 158.523 95.2008C158.323 92.6771 156.329 90.6187 153.736 90.4193C153.603 90.4193 153.537 90.4193 153.404 90.4193C153.337 90.4193 153.27 90.4193 153.204 90.4193H107.331V86.8994H192.229H192.362C197.016 86.9003 200.939 90.7519 200.939 95.5335Z"
          fill="#919191"
        />
        <path
          d="M198.678 26.4665C198.678 31.248 194.822 35.0996 190.035 35.0996H189.902H104.938V31.5797H189.57C192.429 31.4469 194.623 29.0559 194.49 26.2671C194.357 23.5443 192.097 21.3527 189.371 21.3527C189.304 21.3527 189.238 21.3527 189.172 21.3527H104.938V17.8329H189.836H189.969C194.756 17.7667 198.678 21.6849 198.678 26.4665Z"
          fill="#919191"
        />
        <path
          d="M158.457 95.5335C158.457 98.2562 156.263 100.514 153.537 100.647H110.655V90.4198H153.67C156.196 90.6192 158.257 92.6114 158.456 95.2013C158.457 95.268 158.457 95.4007 158.457 95.5335Z"
          fill="#E3E3E3"
        />
        <path
          d="M194.556 26.4665C194.623 23.7438 192.429 21.4194 189.703 21.3528C189.636 21.3528 189.57 21.3528 189.504 21.3528H108.793V31.5802H189.636H189.835C192.561 31.3808 194.556 29.1231 194.556 26.4665Z"
          fill="#CECECE"
        />
        <path
          d="M189.57 43.7332C189.637 40.9439 187.443 38.6861 184.65 38.6195C184.584 38.6195 184.517 38.6195 184.451 38.6195H103.01V48.8465H184.65H184.85C187.576 48.6476 189.637 46.3898 189.57 43.7332Z"
          fill="#D8F4D8"
        />
        <path
          d="M188.174 55.8862V66.1132H149.016C146.29 65.9804 144.096 63.7222 144.096 60.9995C144.096 60.8667 144.096 60.7339 144.096 60.6673C144.096 60.5345 144.096 60.4017 144.163 60.269C144.495 57.878 146.489 56.0852 148.883 55.9524H188.174V55.8862H188.174Z"
          fill="url(#paint0_linear_964_24102)"
        />
        <path
          d="M185.182 107.753H103.276C103.209 107.753 103.142 107.753 103.076 107.753C100.217 107.886 98.0235 110.21 98.1566 113.066C98.2232 115.656 100.284 117.781 102.877 117.98H103.076H185.182V107.753Z"
          fill="#D8F4D8"
        />
        <path
          d="M184.916 4.08606V14.3131H157.525C154.799 14.1803 152.605 11.9221 152.605 9.19933C152.605 8.99993 152.605 8.86715 152.605 8.6682C152.605 8.60158 152.605 8.4688 152.605 8.40263C153.004 6.01164 154.999 4.21884 157.392 4.08606H184.916Z"
          fill="#D8F4D8"
        />
        <path
          d="M196.351 73.2858H115.841C115.774 73.2858 115.707 73.2858 115.641 73.2858C112.782 73.4185 110.655 75.8757 110.788 78.6651C110.921 81.1888 112.916 83.2472 115.442 83.5132H115.641H196.284V73.2858H196.351Z"
          fill="#F1F1F1"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_964_24102"
          x1="191.109"
          x2="128.581"
          y1="62.2001"
          y2="59.0469"
        >
          <stop offset="0.0898" stopColor="#EEEEEE" />
          <stop offset="0.8178" stopColor="#D6D7D8" />
        </linearGradient>
        <clipPath id="clip0_964_24102">
          <rect fill="white" height="121" transform="translate(94.5 0.5)" width="106.438" />
        </clipPath>
      </defs>
    </svg>
  );
}
