import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import AnswerChannelComposition from './answer_channel_composition';
import Button, { ButtonTypes } from 'components/common/button';
import ChatMessage from 'models/chat_message';
import DeleteAnswerCardModal from 'components/modals/delete_answer_card_modal';
import EmailMessage from 'models/email_message';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { faExpandAlt } from '@fortawesome/pro-light-svg-icons/faExpandAlt';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullSerializer from 'components/text_editor/serializers/full_serializer';
import { getSnippetUploadUrl } from 'scripts/domain/services/attachments';
import { lngDirection } from 'models/languages/answer_languages';
import SelfServiceModal from 'components/modals/self_service_modal';
import Snippet, {
  ChannelFieldName,
  SnippetChannel,
  SnippetContent,
  SnippetContentType,
  SnippetChannels,
} from 'models/answers/snippet';
import SnippetAttachments from './snippet_attachments';
import SlateCharacterCount from 'components/text_editor/character_count';
import SlateEditor from 'components/customer/composition/lib/slate/slate_editor';
import Tooltip from 'components/common/tooltip';
import TrashIcon from 'components/lib/icons/trash_icon';

class AnswerContentEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answerMenuData: [],
      isInternalFocused: false,
      isPreviewingSelfService: false,
      isDescriptionHidden: true,
      isLanguageDirRtl: lngDirection(this.props.language) === 'rtl',
    };

    _.bindAll(this, [
      'changeSelectedChannel',
      'focusCard',
      'hasChannel',
      'onAttachBlur',
      'onAttachInputChange',
      'onCancelDelete',
      'onCloseSelfServicePreview',
      'onConfirmDelete',
      'onDescriptionBlur',
      'onDescriptionChange',
      'onDropFiles',
      'onExpandAnswer',
      'onInsertImage',
      'onInternalCheckboxBlur',
      'onInternalCheckboxFocus',
      'onOpenSelfServicePreview',
      'onSelectLanguage',
      'onSelfServiceNameChange',
      'onSubjectChange',
      'openDeleteModal',
      'scrollToCard',
      'setScrollTop',
      'toggleDescriptionVisibility',
      'toggleInternalCheckbox',
      'unsetFocus',
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.answerMenuData !== this.props.answerMenuData) {
      // remove current answer from answerMenuData to prevent answer linking of the same answer
      const answerMenuData = _.filter(nextProps.answerMenuData, answer => {
        const snippetId = this.props.snippet && this.props.snippet.id;
        return answer.id !== snippetId;
      });
      this.setState({ answerMenuData });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.existingChannelTypes.length) {
      return;
    }
    const channelTypes = this.props.existingChannelTypes;
    if (prevProps.language === this.props.language && prevProps.existingChannelTypes.length !== channelTypes.length) {
      const channel = channelTypes[channelTypes.length - 1];
      this.focusCard(channel);
    }
  }

  /* Getters */

  getNewModified(field) {
    return _.merge({}, this.props.hasModified, { [field]: true });
  }

  getAttachmentRefFn(channel) {
    if (channel === ChannelFieldName.INFO) {
      return button => (this.infoAttachmentButton = button);
    }

    return button => (this.emailAttachmentButton = button);
  }

  getAttachmentRef(channel) {
    return this[`${channel.toLowerCase()}Composition`].attachmentButton;
  }

  getEditorRef(channel) {
    switch (channel) {
      case ChannelFieldName.INFO:
        return this.infoEditor;
      case ChannelFieldName.MESSAGE:
        return this.messageEditor;
      case ChannelFieldName.ANY_CHANNEL:
        return this.emailEditor;
      case ChannelFieldName.SELF_SERVICE:
        return this.selfServiceEditor;
      default:
        return null;
    }
  }

  getCompositionRef(channel) {
    let channelFieldName = ChannelFieldName[channel];
    return this[`${channelFieldName.toLowerCase()}Composition`].slate;
  }

  /* Setters */
  unsetFocus() {
    this.props.setFocus(null);
  }

  setScrollTop(channel) {
    const editor = this.getEditorRef(channel);
    const cards = this.answerEditorCards;
    if (editor && cards) {
      cards.scrollTop = cards.scrollHeight - editor.scrollHeight;
    }
  }

  /* Handlers */

  onSelfServiceNameChange(evt) {
    const onChange = this.props.getOnChange('selfServiceName');
    onChange(evt.target);
    this.props.markModified('selfServiceName');
  }

  onDescriptionChange(evt) {
    this.props.setDescription(evt.target.value);
    this.props.markModified('description');
  }

  onSubjectChange(evt) {
    const onChange = this.props.getOnChange('subject');
    onChange(evt);
    this.props.markModified('subject');
  }

  onCloseSelfServicePreview() {
    this.setState({ isPreviewingSelfService: false });
  }

  onOpenSelfServicePreview() {
    this.setState({ isPreviewingSelfService: true });
  }

  openDeleteModal(channel) {
    this.setState({ deletingChannelCard: true });
  }

  onCancelDelete() {
    this.setState({ deletingChannelCard: null });
  }

  onConfirmDelete() {
    this.props.onConfirmDelete();
    this.setState({ deletingChannelCard: null });
  }

  onExpandAnswer() {
    let composition = Snippet.create({
      name: this.props.name,
      contents: [
        SnippetChannels.create({
          info: SnippetContent.create({
            attachments: this.props.snippet
              .findContentByLanguage(this.props.language)
              .getAttachmentsByType(SnippetContentType.INFO),
            bodyHtml: FullSerializer.serialize(this.props.infoBodyHtml),
            internal: this.props.isInternal,
          }),
        }),
      ],
    });
    this.props.onExpandAnswer(composition);
  }

  onDescriptionBlur(evt) {
    evt.target.scrollTop = 0;
    this.unsetFocus();
  }

  // Attachment Handlers

  onAttachBlur() {
    this.props.setModified(this.getNewModified('attachments'));
  }

  onAttachInputChange(channel) {
    const attachmentButton = this.getAttachmentRef(channel);
    for (let i = 0; i < attachmentButton.input.files.length; i++) {
      this.props.onStartUpload(attachmentButton.input.files[i], ChannelContentType[channel]);
    }
    attachmentButton.input.value = '';
    this.setScrollTop(channel);
  }

  onDropFiles(files, channel) {
    this.changeSelectedChannel({
      selectedChannelType: ChannelSnippetContentType[channel],
      callback: () => {
        const imageTypes = ['image/gif', 'image/png', 'image/jpeg'];
        const attachmentButton = this.getAttachmentRef(channel);
        attachmentButton.focus(); // so that we can use onAttachBlur to acknowledge errors
        files.forEach(file => {
          if (
            ChannelSnippetContentType[channel] === SnippetChannel.INFO &&
            file.type &&
            imageTypes.includes(file.type.toLowerCase())
          ) {
            this.props.onStartUpload(file, ChannelContentType[channel], this.onInsertImage, true);
          } else {
            this.props.onStartUpload(file, ChannelContentType[channel]);
          }
        });
        this.props.setModified(this.getNewModified('attachments'));
        this.setScrollTop(channel);
      },
    });
  }

  onInsertImage(orgId, image) {
    let selectedChannelFieldName = ChannelFieldName[this.props.selectedChannelType];
    const src = getSnippetUploadUrl(orgId, this.props.snippet.id, image);

    const composition = this.getCompositionRef(this.props.selectedChannelType);
    const editor = composition && composition.editor;
    const onChange = this.props.getOnChange(selectedChannelFieldName);
    onChange(editor.insertImage({ src, attachmentId: image.id }));
  }

  onInternalCheckboxFocus() {
    if (!this.props.readOnly) {
      this.setState({ isInternalFocused: true });
    }
  }

  onInternalCheckboxBlur() {
    if (!this.props.readOnly) {
      this.setState({ isInternalFocused: false });
    }
  }

  toggleInternalCheckbox() {
    if (!this.props.readOnly) {
      this.props.setInternal(!this.props.isInternal);
    }
  }

  toggleDescriptionVisibility(channel) {
    this.setState({ isDescriptionHidden: !this.state.isDescriptionHidden });

    if (!this.state.isDescriptionHidden) {
      const composition = this.getCompositionRef(ChannelSnippetContentType[channel]);
      if (composition) {
        const onChange = this.props.getOnChange(channel);
        onChange(composition.editor.focusAtEnd());
      }
    }
  }

  changeSelectedChannel({ selectedChannelType, callback }) {
    this.props.changeSelectedChannel(selectedChannelType, callback);
  }

  focusCard(channel) {
    this.changeSelectedChannel({ selectedChannelType: channel });
    const composition = this.getCompositionRef(channel);
    this.scrollToCard(channel);
    if (composition) {
      const onChange = this.props.getOnChange(ChannelFieldName[channel]);
      onChange(composition.editor.focusAtEnd());
    }
  }

  scrollToCard(channel) {
    const editor = this.getEditorRef(ChannelFieldName[channel]);
    const cards = this.answerEditorCards;
    if (editor && cards) {
      cards.scrollTop = 0;

      if (editor === this.infoEditor) {
        // for some reason, IE never scrolls all the way to the left
        cards.scrollLeft = 0;
      } else {
        cards.scrollLeft = editor.offsetLeft;
      }
    }
  }

  onSelectLanguage(language) {
    this.props.setLanguage(language);
    this.setState({ isLanguageDirRtl: lngDirection(language) === 'rtl' });
  }

  /* Render */
  render() {
    return (
      <div className="answerEditor-editingPanel">
        <div className="answerEditor-cards" ref={node => (this.answerEditorCards = node)}>
          {this.hasChannel(SnippetChannel.INFO) ? (
            <div
              className="answerEditor-card answerEditor-card-info"
              onClick={this.changeSelectedChannel.bind(this, { selectedChannelType: SnippetChannel.INFO })}
              ref={node => (this.infoEditor = node)}
            >
              <div className="answerEditor-header">
                <FontAwesomeIcon className="answerEditor-header-icon" icon={faBook} />
                {ChannelLabel.INFO}
                {this.renderInternalCheckbox()}
                {this.renderExpandButton()}
                {this.renderTrashIcon(ChannelLabel.INFO)}
              </div>
              {this.renderChannelComposition(ChannelFieldName.INFO)}
              {this.renderCharacterCount(ChannelFieldName.INFO)}
              <SnippetAttachments
                contentChannelType={SnippetContentType.INFO}
                isRemoveable={!this.props.readOnly}
                language={this.props.language}
                snippet={this.props.snippet}
              />
            </div>
          ) : null}
          {this.hasChannel(SnippetChannel.ANY_CHANNEL) ? (
            <div
              className="answerEditor-card answerEditor-card-email"
              onClick={this.changeSelectedChannel.bind(this, { selectedChannelType: SnippetChannel.ANY_CHANNEL })}
              ref={node => (this.emailEditor = node)}
            >
              <div className="answerEditor-header">
                <i className="answerEditor-header-icon icon-email" />
                {ChannelLabel.ANY_CHANNEL}
                {this.renderTrashIcon(ChannelLabel.ANY_CHANNEL)}
              </div>
              {this.renderEmailSubject()}
              {this.renderDescription(ChannelFieldName.ANY_CHANNEL)}
              {this.renderChannelComposition(ChannelFieldName.ANY_CHANNEL)}
              {this.renderCharacterCount(ChannelFieldName.ANY_CHANNEL)}
              <SnippetAttachments
                contentChannelType={SnippetContentType.ANY_CHANNEL}
                isRemoveable={!this.props.readOnly}
                language={this.props.language}
                snippet={this.props.snippet}
              />
            </div>
          ) : null}
          {this.hasChannel(SnippetChannel.MESSAGE) ? (
            <div>
              <div
                className="answerEditor-card answerEditor-card-message"
                onClick={this.changeSelectedChannel.bind(this, { selectedChannelType: SnippetChannel.MESSAGE })}
                ref={node => (this.messageEditor = node)}
              >
                <div className="answerEditor-header">
                  <FontAwesomeIcon className="answerEditor-header-icon" icon={faComment} />
                  {ChannelLabel.MESSAGE}
                  {this.renderTrashIcon(ChannelLabel.MESSAGE)}
                </div>
                {this.renderDescription(ChannelFieldName.MESSAGE)}
                {this.renderChannelComposition(ChannelFieldName.MESSAGE)}
                {this.renderCharacterCount(ChannelFieldName.MESSAGE)}
              </div>
            </div>
          ) : null}
          {this.hasChannel(SnippetChannel.SELF_SERVICE) ? (
            <div>
              <div
                className="answerEditor-card answerEditor-card-selfService"
                onClick={this.changeSelectedChannel.bind(this, { selectedChannelType: SnippetChannel.SELF_SERVICE })}
                ref={node => (this.selfServiceEditor = node)}
              >
                <div className="answerEditor-header">
                  <FontAwesomeIcon className="answerEditor-header-icon" icon={faGlobe} />
                  {ChannelLabel.SELF_SERVICE}
                  {this.renderTrashIcon(ChannelLabel.SELF_SERVICE)}
                </div>
                {this.renderSelfServiceName()}
                {this.renderChannelComposition(ChannelFieldName.SELF_SERVICE)}
                {this.renderCharacterCount(ChannelFieldName.SELF_SERVICE)}
                {this.renderSelfServicePreviewButton()}
              </div>
            </div>
          ) : null}
          {this.state.deletingChannelCard ? (
            <DeleteAnswerCardModal onCancel={this.onCancelDelete} onConfirm={this.onConfirmDelete} />
          ) : null}
          {this.state.isPreviewingSelfService ? (
            <SelfServiceModal
              direction={lngDirection(this.props.language)}
              name={this.props.selfServiceName}
              onClose={this.onCloseSelfServicePreview}
              value={this.props.selfServiceBodyHtml}
            />
          ) : null}
        </div>
      </div>
    );
  }

  renderTrashIcon(channelLabel) {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <div
        className={classnames(
          'answerEditor-trashIcon',
          `answerEditor-delete-${_.camelCase(channelLabel.toLowerCase())}`
        )}
        onClick={this.openDeleteModal}
      >
        <TrashIcon />
      </div>
    );
  }

  renderEmailSubject() {
    return (
      <div>
        <div className="answerEditor-subject answerEditor-label">Email Subject</div>
        {this.renderSubjectComposition()}
      </div>
    );
  }

  renderExpandButton() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <div className="answerEditor-expandButton" onClick={this.onExpandAnswer}>
        <FontAwesomeIcon icon={faExpandAlt} />
      </div>
    );
  }

  renderInternalCheckbox() {
    let isInternal = this.props.isInternal;
    let isInternalFocused = this.state.isInternalFocused;

    let iconClassNames = classnames('answerEditor-internalCheckbox-icon', {
      'answerEditor-internalCheckbox-unselect': !isInternal,
      'answerEditor-internalCheckbox-select': isInternal || isInternalFocused,
    });
    let checkbox =
      isInternal || isInternalFocused ? (
        <FontAwesomeIcon className={iconClassNames} icon={faCheckSquare} />
      ) : (
        <FontAwesomeIcon className={iconClassNames} icon={faSquare} />
      );

    let textClassNames = classnames('answerEditor-internalCheckbox-text', {
      'answerEditor-internalCheckbox-text-selected': isInternal || isInternalFocused,
    });

    return (
      <div
        className="answerEditor-internalCheckbox-container"
        onClick={this.toggleInternalCheckbox}
        onMouseEnter={this.onInternalCheckboxFocus}
        onMouseLeave={this.onInternalCheckboxBlur}
      >
        <div className="answerEditor-internalCheckbox-separator-wrapper" key="separator">
          <div className="draftStylesMenu-item-separator" />
        </div>
        {checkbox}
        <div className={textClassNames}>Internal</div>
      </div>
    );
  }

  renderSelfServiceName() {
    let classNames = classnames('answerEditor-selfService-name', {
      hasError: this.props.getError('selfServiceName'),
      'answerEditor-selfService-name-readOnly': this.props.readOnly,
      directionRtl: this.state.isLanguageDirRtl,
    });
    return (
      <React.Fragment>
        <div className="answerEditor-label">Title</div>
        <ReactTextareaAutosize
          className={classNames}
          onBlur={this.unsetFocus}
          onChange={this.onSelfServiceNameChange}
          onFocus={this.getFocusFn('selfServiceName')}
          placeholder="Customer facing name"
          readOnly={this.props.readOnly}
          ref={input => (this.selfServiceName = input)}
          value={this.props.selfServiceName}
        />
      </React.Fragment>
    );
  }

  renderSelfServicePreviewButton() {
    return (
      <Button
        buttonType={ButtonTypes.SECONDARY}
        className="answerEditor-selfService-previewButton"
        onClick={this.onOpenSelfServicePreview}
      >
        Preview in Glad App
      </Button>
    );
  }

  renderDescription(channel) {
    const shouldDescriptionBeHidden = this.state.isDescriptionHidden;
    let classNames = classnames('answerEditor-descriptionComposition', {
      hasError: this.props.getError('description'),
      focused: this.props.focusField === 'description' && channel === ChannelFieldName[this.props.selectedChannelType],
      'answerEditor-descriptionComposition-expanded': this.props.focusField === 'description',
      'answerEditor-descriptionComposition-hidden': shouldDescriptionBeHidden,
      'answerEditor-descriptionComposition-readOnly': this.props.readOnly,
    });

    return (
      <div className="answerEditor-description">
        <div
          className="answerEditor-label answerEditor-label-description"
          onClick={this.toggleDescriptionVisibility.bind(this, channel)}
        >
          Internal Note
          <span className="answerEditor-label-note"> - This will be the same for all outgoing written responses</span>
          {shouldDescriptionBeHidden ? (
            <div className="answerEditor-description-chevron-down" />
          ) : (
            <div className="answerEditor-description-chevron-up" />
          )}
        </div>
        <ReactTextareaAutosize
          className={classNames}
          onBlur={this.onDescriptionBlur}
          onChange={this.onDescriptionChange}
          onFocus={this.getFocusFn('description')}
          placeholder={this.props.readOnly ? 'There is no internal note' : 'Enter internal note'}
          readOnly={this.props.readOnly}
          ref={node => (this.descriptionComposition = node)}
          value={this.props.description}
        />
      </div>
    );
  }

  renderSubjectComposition() {
    let classNames = classnames('answerEditor-subjectComposition', 'answerEditor-slateComposition', {
      hasError: this.props.getError('subject'),
      focused: this.props.focusField === 'subject',
      directionRtl: this.state.isLanguageDirRtl,
    });

    return (
      <div className={classNames}>
        <SlateEditor
          channel="subject"
          onChange={this.onSubjectChange}
          placeholder={this.props.readOnly ? 'There is no email subject' : 'Enter email subject'}
          readOnly={this.props.readOnly}
          setFocus={this.getFocusFn('subject')}
          unsetFocus={this.unsetFocus}
          value={this.props.subject}
          variableMenuData={this.props.mentionMenuData}
        />
      </div>
    );
  }

  renderCharacterCount(channel) {
    if (this.props.readOnly) {
      return null;
    }

    let maxCharacters =
      channel === ChannelFieldName.MESSAGE ? ChatMessage.MAX_MESSAGE_SIZE : EmailMessage.MAX_BODY_LENGTH;

    const tooltipEnabled = channel === ChannelFieldName.MESSAGE;
    const tooltipMessage = `2048 character limit for Chat \n 2048 character limit for Twitter \n 2048 character limit for WhatsApp \n 1600 character limit for SMS \n 640 character limit for FB`;

    return (
      <div className="answerEditor-characterCount-container">
        <SlateCharacterCount
          className="answerEditor-characterCount"
          maxCharacters={maxCharacters}
          multiline={false}
          value={this.props[channel]}
        />
        {tooltipEnabled && (
          <Tooltip message={tooltipMessage} position="top">
            <div>
              <FontAwesomeIcon className="answerEditor-message-tooltip" icon={faInfoCircle} />
            </div>
          </Tooltip>
        )}
      </div>
    );
  }

  renderChannelComposition(channel) {
    return (
      <AnswerChannelComposition
        answerMenuData={this.props.answerMenuData}
        channel={channel}
        focusField={this.props.focusField}
        hasError={this.props.getError(channel)}
        html={this.props[channel]}
        language={this.props.language}
        mentionMenuData={this.props.mentionMenuData}
        onAttachBlur={this.onAttachBlur}
        onAttachInputChange={this.onAttachInputChange}
        onChange={this.props.getOnChange(channel)}
        onDropFiles={this.onDropFiles}
        onSearchAnswerMenu={this.props.onSearchAnswerMenu}
        readOnly={this.props.readOnly}
        ref={node => (this[`${channel.toLowerCase()}Composition`] = node)}
        setFocus={this.props.setFocus}
        snippetLinks={this.props.snippetLinks}
      />
    );
  }

  /* Helpers */

  getOnChange(channel) {
    return this.props.getOnChange(channel);
  }

  hasChannel(channel) {
    return _.includes(this.props.existingChannelTypes, channel);
  }

  getFocusFn(target) {
    return () => {
      this.props.setFocus(target);
    };
  }
}

const ChannelContentType = Object.freeze({
  anyChannelBodyHtml: 'anyChannel',
  infoBodyHtml: 'info',
  messageBodyHtml: 'message',
  selfServiceBodyHtml: 'selfService',
});

const ChannelSnippetContentType = {
  anyChannelBodyHtml: SnippetChannel.ANY_CHANNEL,
  infoBodyHtml: SnippetChannel.INFO,
  messageBodyHtml: SnippetChannel.MESSAGE,
  selfServiceBodyHtml: SnippetChannel.SELF_SERVICE,
};

export const ChannelLabel = Object.freeze({
  ANY_CHANNEL: 'Email',
  INFO: 'Reference',
  MESSAGE: 'Messaging',
  SELF_SERVICE: 'Public',
});

AnswerContentEditor.propTypes = {
  // passed through props
  answerMenuData: PropTypes.array,
  errors: PropTypes.array,
  mentionMenuData: PropTypes.array.isRequired,
  onExpandAnswer: PropTypes.func,
  onSearchAnswerMenu: PropTypes.func,
  onStartUpload: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  snippet: PropTypes.instanceOf(Snippet).isRequired,
  snippetLinks: PropTypes.array,

  // from answer editor state/methods
  anyChannelBodyHtml: PropTypes.object.isRequired,
  changeSelectedChannel: PropTypes.func.isRequired,
  description: PropTypes.string,
  existingChannelTypes: PropTypes.array.isRequired,
  focusField: PropTypes.string,
  getError: PropTypes.func.isRequired,
  getOnChange: PropTypes.func.isRequired,
  hasModified: PropTypes.object.isRequired,
  infoBodyHtml: PropTypes.object.isRequired,
  isInternal: PropTypes.bool,
  language: PropTypes.string.isRequired,
  markModified: PropTypes.func.isRequired,
  messageBodyHtml: PropTypes.object.isRequired,
  name: PropTypes.string,
  onConfirmDelete: PropTypes.func.isRequired,
  selectedChannelType: PropTypes.string,
  selfServiceBodyHtml: PropTypes.object.isRequired,
  selfServiceName: PropTypes.string,
  setDescription: PropTypes.func.isRequired,
  setFocus: PropTypes.func.isRequired,
  setInternal: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  setModified: PropTypes.func.isRequired,
  subject: PropTypes.object,
};

export default AnswerContentEditor;
