import createEnum from 'scripts/lib/create_enum';

// IMPORTANT
//
// If your new ProfileCardType will be used in production,
// it needs to be added to Supernova as well as Agent Desktop.
// Example PR adding 'TASKS' to Supernova's customer profile def:
// https://github.com/sagansystems/supernova/pull/6976
//
// To enable the new card for orgs using a customer profile to layout
// profile cards in a customized way, the new profile card key needs to be added
// to an orgs profile definition in the database, via either Gladmin or
// the Professional Services team's Org Manager app.
// See the below doc for more details and context, especially the "PS executed changes" section:
// https://www.notion.so/gladly/Investigation-of-customer-profile-cards-config-Week-of-2019-04-0-0223aa91c9414c32ae381aef59ca7d98
const ProfileCardType = createEnum(
  'BASIC_PROFILE',
  'BULK_EVENTS',
  'COMPANY_CARDS',
  'CONTACT_INFO',
  'CONVERSATION_LIST',
  'CUSTOM_ATTRS',
  'DETAILS',
  'EVENTS',
  'EXTERNAL_APP_CARD',
  'FLEXIBLE_CARD',
  'FLIGHT_DEMO',
  'PAYOUTS',
  'RECENT_EVENTS',
  'RELATIONSHIPS',
  'SUBSCRIPTIONS',
  'TASKS',
  'TRANSACTIONS',
  'UPCOMING_EVENTS',
  'VERIFICATION_QUESTIONS',
  'DEMO_RELATIONSHIPS',
  'SAMPLE_EXTERNAL_DATA'
);

export default ProfileCardType;
