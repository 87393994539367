import createModel, { prop } from './lib/create_model';

const TranslationRequest = createModel({
  modelName: 'TranslationRequest',

  properties: {
    targetLanguage: prop(String).isRequired,
    text: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default TranslationRequest;
