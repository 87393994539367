import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import connect from 'components/lib/connect';
import ProfileCardDef from 'models/configuration/profile_card_def';
import withStaticId from 'components/customer/lib/with_static_id';
import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';

export function ExternalAppCardBase({ data, isLoading, profileCardDef, uiTemplate }) {
  // TODO get parameters from the config (profileCardDef)
  const namespace = 'gladly.com/external-app/v2';
  return (
    <ExpandableProfileCard
      data-aid={`external-app-card-${_.kebabCase(namespace)}`}
      isEmpty={false}
      isLoading={isLoading}
      title="External App Card"
    >
      <div>Coming soon...</div>
    </ExpandableProfileCard>
  );
}

ExternalAppCardBase.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool,
  profileCardDef: PropTypes.instanceOf(ProfileCardDef),
  uiTemplate: PropTypes.string,
};

// TODO: this is a stub, implement the real mapper when we have the stores
// SC-203939
function mapStateToProps({ getProvider }, { profileCardDef, staticCardId }) {
  return {
    isLoading: false,
    profileCardDef,
    uiTemplate: '',
  };
}

const ConnectedExternalAppCard = connect(mapStateToProps)(ExternalAppCardBase);
export default withStaticId(ConnectedExternalAppCard, 'staticCardId');
