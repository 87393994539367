import React, { useMemo, useState } from 'react';

const ItemContext = React.createContext({
  itemId: null,
});
export default ItemContext;

export function ItemContextProvider({ children, item }) {
  const [isViewOriginal, setViewOriginal] = useState(false);
  const toggleViewOriginal = () => {
    setViewOriginal(!isViewOriginal);
  };

  const value = useMemo(
    () => ({
      item,
      isViewOriginal,
      toggleViewOriginal,
    }),
    [item, isViewOriginal, toggleViewOriginal]
  );

  return <ItemContext.Provider value={value}>{children}</ItemContext.Provider>;
}
