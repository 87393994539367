import _ from 'lodash';
import TopicPattern from 'scripts/infrastructure/topic_pattern';

export function statusText(status) {
  switch (status) {
    case 200:
      return 'OK';
    case 202:
      return 'Accepted';
    case 204:
      return 'No Content';
    case 400:
      return 'Bad Request';
    case 401:
      return 'Unauthorized';
    case 404:
      return 'Not Found';
    case 500:
      return 'Internal Server Error';

    default:
      return `${status}`;
  }
}

export function bindCallbacks(routes, that) {
  return _.mapValues(routes, (actionHandlers, route) => {
    return _.mapValues(actionHandlers, handler => {
      return extractedParamHandler(route, handler.bind(that));
    });
  });
}

function extractedParamHandler(route, handler) {
  const pattern = new TopicPattern(route);
  return (payload, callback, url) => {
    const params = pattern.extractParams(url);
    const query = pattern.parseQuery(url);
    handler(payload, callback, url, params, query);
  };
}
