import _ from 'lodash';

import { DomainError } from 'scripts/application/lib/error';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import KbAdmin from 'models/location/kb_admin';
import PutSnippetBase from './put_snippet_base';
import RecommendedAnswers from 'models/location/recommended_answers';

export default class UpdateSnippet extends PutSnippetBase {
  runAction(attrs, referencedAnswerId) {
    this.context.gateways.snippet
      .update({ snippetId: attrs.id }, _.omit(attrs, 'id'))
      .then(() => this.onUpdateSuccess(attrs.id, referencedAnswerId))
      .catch(errorDto => this.onUpdateError(attrs.id, errorDto));
  }

  onUpdateSuccess(snippetId, referencedAnswerId) {
    if (this._hasPendingUpdate(snippetId)) {
      const snippetCompositionStore = this.context.stores.snippetComposition;
      const snippet = snippetCompositionStore.getPending();
      this.showSnippetSuccessToast(snippet, referencedAnswerId);

      snippetCompositionStore.remove();
      if (referencedAnswerId) {
        this.context.router.navigateTo(RecommendedAnswers.create({ referencedAnswerId }));
      } else {
        this.context.router.navigateTo(KbAdmin.create());
      }
    }
  }

  onUpdateError(snippetId, err) {
    if (!(err instanceof DomainError)) {
      handleCommonErrors(this.context, err);
      return;
    }

    if (this._hasPendingUpdate(snippetId)) {
      const snippetCompositionStore = this.context.stores.snippetComposition;
      snippetCompositionStore.setErrors(err.errors);
      snippetCompositionStore.resetPending();
    }
  }

  _hasPendingUpdate(snippetId) {
    const snippetCompositionStore = this.context.stores.snippetComposition;
    return snippetCompositionStore.isPending() && snippetCompositionStore.getPending().id === snippetId;
  }
}
