import _ from 'lodash';

import createModel, { prop } from 'models/lib/create_model';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import { default as Languages } from 'models/languages/answer_languages';
import { parseIdFromLinkedAnswers } from './snippet_helpers';
import SnippetContent from './snippet_content';
import { SnippetContentType } from './snippet';

export { Languages };

const SnippetChannels = createModel({
  modelName: 'SnippetChannels',

  properties: {
    id: prop(String).isRequired,
    anyChannel: SnippetContent,
    info: SnippetContent,
    message: SnippetContent,
    selfService: SnippetContent,
    language: prop(String).default('en-us'),
  },

  getChannels() {
    return _.pick(this, _.values(SnippetContentType));
  },

  getAttachments() {
    let results = [];
    _.forEach(this.getChannels(), snippetContent => {
      let attachments = _.get(snippetContent, 'attachments');
      if (attachments) {
        results.push(attachments);
      }
    });
    return _.flatten(results);
  },

  getAttachmentsByType(channel) {
    return _.get(this, `${channel}.attachments`) || [];
  },

  getBodyByType(channel) {
    return _.get(this, `${channel}.bodyHtml`);
  },

  getSubjectByType(channel) {
    return _.get(this, `${channel}.subject`);
  },

  getLinkedAnswerIds() {
    if (!this.info) {
      return [];
    }

    return _.chain(parseIdFromLinkedAnswers(this.info.bodyHtml))
      .flatten()
      .uniq()
      .value();
  },

  findAttachmentById(id) {
    let match;
    _.find(this.getChannels(), snippetContent => {
      if (!snippetContent) {
        return false;
      }
      let test = snippetContent.findAttachmentById(id);
      if (test) {
        match = test;
      }
      return test;
    });
    return match;
  },

  addContentOfType(contentType) {
    if (!this[contentType]) {
      this[contentType] = SnippetContent.create();
    }
  },

  addAllContentTypes() {
    let newSnippetContent = _.cloneDeep(this);

    _.forEach(this.getChannels(), (value, key) => {
      if (!value) {
        newSnippetContent[key] = SnippetContent.create();
      } else {
        newSnippetContent[key] = SnippetContent.create(value);
      }
    });

    _.merge(this, newSnippetContent);
  },

  overrideToJs(toJs) {
    return () => {
      let channelTypes = {};
      _.forEach(this.getChannels(), (value, key) => {
        if (value) {
          channelTypes[key] = this[key].toJs();
        }
      });

      return {
        ...toJs(),
        ...channelTypes,
      };
    };
  },

  update(attrs) {
    let newAttr = {};

    _.forEach(attrs, (value, field) => {
      if (!value) {
        newAttr[field] = null;
      } else if (!(field in this.getChannels())) {
        this[field] = value;
      } else if (this[field]) {
        this[field].update(value);
      } else {
        this[field] = SnippetContent.create(value);
      }
    });

    _.assign(this, newAttr);
  },

  statics: {
    create(attrs = {}) {
      return new this(_.assign({ id: IdGenerator.newId() }, attrs));
    },
    createBlank(attrs = {}) {
      return new this(
        _.assign(
          {
            id: IdGenerator.newId(),
            anyChannel: SnippetContent.create(),
            info: SnippetContent.create(),
            message: SnippetContent.create(),
            selfService: SnippetContent.create(),
          },
          attrs
        )
      );
    },
  },
});

export default SnippetChannels;
