import _ from 'lodash';
import moment from 'moment';

import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import RecordingStatus from 'models/recording_status';
import VoiceRecordingDeleted from 'models/voice_recording_deleted';

export default class VoiceRecordingService {
  constructor(pubsub, database = getDatabase) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  getRecordingUrl(orgId, { id, path }) {
    let recording = _(this.getDatabase(orgId).customers)
      .map('conversationHistory')
      .flatten()
      .map('content')
      .filter(
        c =>
          c.type === ConversationItemType.PHONE_CALL ||
          c.type === ConversationItemType.VOICEMAIL ||
          c.type === ConversationItemType.VOICE_AI_RECORDING
      )
      .find({ recordingId: path });

    let url = recording ? recording.recordingUrl || `recording-${path}-has-no-url` : `recording-${path}-not-found`;

    return { id, url };
  }

  deleteRecording(orgId, customerId, conversationItemId) {
    const customer = _.find(this.getDatabase(orgId).customers, { id: customerId });
    const conversationItem = _.find(customer.conversationHistory, { id: conversationItemId });
    conversationItem.content.recordingStatus = RecordingStatus.DELETED;
    conversationItem.version++;

    this._pubsub.publish(`v1/orgs/${orgId}/customer-history/${customerId}/conversation-items/${conversationItemId}`, {
      payload: conversationItem,
    });

    let currentAgentId = this.getDatabase(orgId).currentAgent.id;
    let recordingDeletedAuditItem = ConversationItem.create({
      type: ConversationItemType.VOICE_RECORDING_DELETED,
      initiator: {
        type: Communicator.AGENT,
        id: currentAgentId,
      },
      content: VoiceRecordingDeleted.create({
        agentId: currentAgentId,
        conversationItemId,
      }),
      customerId,
    });
    this._pubsub.publish(
      `v1/orgs/${orgId}/customer-history/${customerId}/conversation-items/${recordingDeletedAuditItem.id}`,
      {
        payload: recordingDeletedAuditItem.toJs(),
      }
    );

    let conversation = _.last(customer.conversations);
    conversation.updatedAt = moment().toISOString();
    this._pubsub.publish(`v1/orgs/${orgId}/customer-history/${customerId}/conversations/${conversation.id}`, {
      payload: conversation,
    });
  }
}
