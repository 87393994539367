import _ from 'lodash';

import ConversationItemConverter from 'scripts/application/dto_converters/conversation_item_converter';
import hasLiveConnection from './lib/has_live_connection';
import NavigateToConversation from 'actions/conversation/navigate_to_conversation';
import PhoneCall from 'models/phone_call';
import qconsole from 'scripts/lib/qconsole';
import ShowPhoneCallObserverNotification from 'actions/notification/show_phone_call_observer_notification';
import SystemNotificationGenerator from 'scripts/domain/contracts/system_notification_generator';
import { unsubscribeFromActiveCall } from './lib/active_call';
import { PhoneCallObserverNotificationRecipientType } from 'models/notification/phone_call_observer_notification';

export default class ActiveCallConversationItemObserver {
  constructor(context) {
    this.context = context;
  }

  handleFetchItemError(customerId, conversationItemId, errorDto) {
    qconsole.log(`Could not fetch active call conversation item ${JSON.stringify(errorDto)}`);
  }

  handleFetchItemSuccess({ conversationItemDto }) {
    this.updateActiveCall(conversationItemDto);
  }

  handleItemUpdate({ conversationItemDto }) {
    this.updateActiveCall(conversationItemDto);
  }

  updateActiveCall(conversationItemDto) {
    let activeCall = this.context.stores.activeCall.get();
    if (!activeCall || activeCall.conversationItem.id !== conversationItemDto.id) {
      return;
    }

    let activeCallStoreConversationItemVersion = this.getActiveCallConversationItemVersion(activeCall);
    if (activeCallStoreConversationItemVersion >= conversationItemDto.version) {
      return;
    }

    let receivedPhoneCallItem = ConversationItemConverter.fromDto(conversationItemDto);
    let hasLivePhoneConnection = hasLiveConnection(this.context, { activeCall });
    activeCall.setHasLiveConnection(hasLivePhoneConnection);

    // If the current browser tab has a live phone connection and the agent status changed from `DIALING` to `ACTIVE`,
    // navigate to the customer profile if the customer is a verified customer
    if (
      this.hasAgentTransitionedFromDialingToActive(activeCall.conversationItem, receivedPhoneCallItem) &&
      hasLivePhoneConnection
    ) {
      activeCall.setAgentHasAcceptedCall();
      this.redirectToProfile(activeCall);
    }

    // Check the `transferee` state transition if set to clear the local state in the active call
    if (this.hasAgentTransitionedFromActiveToInactive(activeCall.conversationItem, receivedPhoneCallItem)) {
      activeCall.clearTransferee();
    }

    let currentAgent = this.context.stores.currentAgent.get();
    let currentAgentStatus = this.getCurrentAgentStatus(conversationItemDto);

    let newlyVisibleObservers = this.getNewlyVisibleObservers(activeCall.conversationItem, receivedPhoneCallItem);
    if (newlyVisibleObservers.length > 0 && receivedPhoneCallItem.content.isParticipantAgent(currentAgent.id)) {
      let observerNames = newlyVisibleObservers.map(observer => {
        let agentProfile = this.context.stores.agents.findBy({ id: observer.participantId });
        return (agentProfile && agentProfile.getDisplayName()) || 'An observer';
      });

      observerNames.forEach(name => {
        let message = `${name} has joined the call`;
        this.context.executeAction(ShowPhoneCallObserverNotification, {
          message,
          recipient: PhoneCallObserverNotificationRecipientType.AGENT,
        });
      });
    }

    if (this.isActiveParticipant(currentAgentStatus)) {
      activeCall.updateConversationItem(receivedPhoneCallItem);
      this.context.stores.activeCall.isPending()
        ? this.context.stores.activeCall.setPending(activeCall)
        : this.context.stores.activeCall.set(activeCall);
      return;
    }

    unsubscribeFromActiveCall(this.context);
    SystemNotificationGenerator.closeNotification(activeCall.conversationItem.id);
    this.context.stores.activeCall.remove();

    if (currentAgentStatus === PhoneCall.ParticipantStatus.HUNG_UP && currentAgent.isConfiguredForInteractiveVoice()) {
      this.context.capacityManager.startCallWrapup();
      this.context.gateways.phoneGatewayHttp.disconnectActiveConnection();
    }
  }

  isActiveParticipant(currentAgentStatus) {
    return (
      [
        PhoneCall.ParticipantStatus.ACTIVE,
        PhoneCall.ParticipantStatus.DIALING,
        PhoneCall.ParticipantStatus.MUTED,
        PhoneCall.ParticipantStatus.ON_HOLD,
        PhoneCall.ParticipantStatus.TERMINATING,
      ].indexOf(currentAgentStatus) >= 0
    );
  }

  // It is possible to receive conversation item updates when the active call is in the pending state. For example,
  // when performing a phone call operation, we mark the active call as pending till we receive a response from the
  // server for the requested operation. If we get any conversation item updates in the interim, the pending active
  // call is updated with the latest conversation item received from the server. To get the latest conversation item
  // version from the active call store, we need to check both the pending active call as well as the committed version.
  getActiveCallConversationItemVersion(activeCall) {
    let activeCallStoreConversationItemVersion = activeCall ? activeCall.conversationItem.version : -1;
    let isPending = this.context.stores.activeCall.isPending();
    if (isPending) {
      let pendingActiveCallVersion = this.context.stores.activeCall.getPending().conversationItem.version;
      if (activeCallStoreConversationItemVersion < pendingActiveCallVersion) {
        activeCallStoreConversationItemVersion = pendingActiveCallVersion;
      }
    }
    return activeCallStoreConversationItemVersion;
  }

  hasAgentTransitionedFromDialingToActive(currentConversationItem, receivedConversationItem) {
    let currentAgentId = this.context.stores.currentAgent.get().id;

    let currentParticipant = currentConversationItem.content.findParticipantById(currentAgentId);
    if (!currentParticipant) {
      return false;
    }
    let currentParticipantStatus = currentParticipant.status;
    let receivedParticipantStatus = receivedConversationItem.content.findParticipantById(currentAgentId).status;

    return (
      currentParticipantStatus === PhoneCall.ParticipantStatus.DIALING &&
      receivedParticipantStatus === PhoneCall.ParticipantStatus.ACTIVE
    );
  }

  hasAgentTransitionedFromActiveToInactive(currentConversationItem, receivedConversationItem) {
    let currentAgentId = this.context.stores.currentAgent.get().id;
    let agentParticipants = currentConversationItem.content
      .getAgentParticipants()
      .filter(participant => participant.id !== currentAgentId);

    return _.some(
      agentParticipants.map(currentParticipant =>
        hasParticipantTransitionedFromActiveToInactive(
          currentParticipant,
          receivedConversationItem.content.findParticipantById(currentParticipant.participantId)
        )
      )
    );
  }

  getNewlyVisibleObservers(currentConversationItem, receivedConversationItem) {
    let receivedVisibleObservers = receivedConversationItem.content.getVisibleObserverParticipants();
    if (receivedVisibleObservers.length === 0) {
      return false;
    }
    let currentVisibleObservers = currentConversationItem.content.getVisibleObserverParticipants();

    let currentVisibleObserverStatus = _.map(currentVisibleObservers, observer =>
      _.pick(observer, 'participantId', 'observerVisibility')
    );
    let receivedVisibleObserverStatus = _.map(receivedVisibleObservers, observer =>
      _.pick(observer, 'participantId', 'observerVisibility')
    );
    return _.differenceWith(receivedVisibleObserverStatus, currentVisibleObserverStatus, _.isEqual);
  }

  redirectToProfile(activeCall) {
    this.context.executeAction(NavigateToConversation, {
      customerId: activeCall.customer.id,
      conversationId: activeCall.conversationId,
    });
  }

  getCurrentAgentStatus(conversationItem) {
    const currentAgentId = this.context.stores.currentAgent.get().id;
    const currentAgentParticipant = _.find(conversationItem.content.participants, { participantId: currentAgentId });
    return currentAgentParticipant && currentAgentParticipant.status;
  }
}

function hasParticipantTransitionedFromActiveToInactive(currentParticipant, receivedParticipant) {
  if (!currentParticipant || !receivedParticipant) {
    return false;
  }

  return (
    currentParticipant.status === PhoneCall.ParticipantStatus.ACTIVE &&
    (receivedParticipant.status === PhoneCall.ParticipantStatus.HUNG_UP ||
      receivedParticipant.status === PhoneCall.ParticipantStatus.TERMINATING)
  );
}
