import _ from 'lodash';

import AgentRead from './agent_read';
import AISuggestedReply from './ai_suggested_reply';
import Composition from './composition';
import Conversation from './conversation';
import ConversationItem from './conversation_item';
import createModel, { prop } from './lib/create_model';
import CustomerNote from './customer_note';
import CustomerProfile from './customer_profile';
import ExternalCustomerAddress from './customer_profile/external_customer_address';
import { ExternalCustomerLookupResult } from './external_customer_profile';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import ItemId from 'models/item_id';
import Language from 'models/language';
import Transaction from './transaction';

export default createModel({
  modelName: 'Customer',

  properties: {
    id: prop(String).isRequired,
    agentRead: AgentRead,
    aiSuggestedReply: AISuggestedReply,
    compositions: prop([Composition]).default([]),
    conversationHistory: prop([ConversationItem]).default([]),
    conversations: prop([Conversation]).default([]),
    externalCustomerAddresses: prop([ExternalCustomerAddress]).default([]),
    externalLookupSuggestions: ExternalCustomerLookupResult,
    itemIds: prop([ItemId]).default([]),
    language: Language,
    note: CustomerNote,
    profile: CustomerProfile,
    transactions: prop([Transaction]).default([]),
  },

  getActiveConversation() {
    return getActiveConversation(this.conversations);
  },

  getClosedConversations() {
    return _.filter(this.conversations, c => c.status === Conversation.Status.CLOSED);
  },

  getLatestConversation() {
    return getLatestConversation(this.conversations);
  },

  getSortedConversations() {
    return sortConversations(this.conversations);
  },

  mergeHistory(sourceCustomer) {
    this.conversations = mergeLists(this.conversations, sourceCustomer.conversations);
    this.conversationHistory = mergeLists(this.conversationHistory, sourceCustomer.conversationHistory);
  },

  statics: {
    create(attrs = {}) {
      let id = attrs.id || IdGenerator.newId();
      let profile = attrs.profile || new CustomerProfile({ id });
      return new this({ ...attrs, id, profile });
    },

    overrideFromJs(fromJs) {
      return attrs => {
        let customer = fromJs(_.omit(attrs, 'conversations'));
        return new this({
          ...customer,
          conversations:
            attrs.conversations && attrs.conversations.map(c => Conversation.fromJs(c, customer.conversationHistory)),
        });
      };
    },

    newId() {
      return IdGenerator.newId();
    },
  },
});

function mergeLists(targetList, sourceList) {
  let targetNotInSource = _.filter(targetList, item => !_.find(sourceList, { id: item.id }));
  return sourceList.concat(targetNotInSource);
}

export function getLatestConversation(conversations) {
  let activeConversation = getActiveConversation(conversations);
  if (activeConversation) {
    return activeConversation;
  }
  return _.maxBy(conversations, c => c.getTimestamp());
}

export function sortConversations(conversations) {
  let sortedClosed = _(conversations)
    .filter({ status: Conversation.Status.CLOSED })
    .sortBy(c => c.getTimestamp())
    .value();

  let activeConversation = getActiveConversation(conversations);
  if (activeConversation) {
    sortedClosed.push(activeConversation);
  }
  return sortedClosed;
}

export function getActiveConversation(conversations) {
  return _.findLast(conversations, c => c.status !== Conversation.Status.CLOSED);
}
