import { DomainError } from 'scripts/application/lib/error';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import KbAdmin from 'models/location/kb_admin';
import { Outcome } from 'models/recommended_answer';
import PutSnippetBase from './put_snippet_base';
import RecommendedAnswers from 'models/location/recommended_answers';
import setRecommendedAnswerDisposition from 'actions/recommended_answers/lib/set_recommended_answer_disposition';

export default class CreateSnippet extends PutSnippetBase {
  runAction(attrs, referencedAnswerId) {
    return this.context.gateways.snippet
      .add(attrs)
      .then(() => this.onAddSuccess(attrs.id, referencedAnswerId))
      .catch(errorDto => this.onAddError(errorDto));
  }

  onAddSuccess(recommendedAnswerId, referencedAnswerId) {
    const snippetCompositionStore = this.context.stores.snippetComposition;
    if (snippetCompositionStore.get() && snippetCompositionStore.isPending()) {
      const snippet = snippetCompositionStore.getPending();
      this.showSnippetSuccessToast(snippet, referencedAnswerId);

      snippetCompositionStore.remove();
      if (referencedAnswerId) {
        setRecommendedAnswerDisposition(this.context, { outcome: Outcome.ACCEPTED, recommendedAnswerId });
        this.context.router.navigateTo(RecommendedAnswers.create({ referencedAnswerId }));
      } else {
        this.context.router.navigateTo(KbAdmin.create());
      }
    }
  }

  onAddError(err) {
    if (!(err instanceof DomainError)) {
      handleCommonErrors(this.context, err);
      return;
    }

    const snippetCompositionStore = this.context.stores.snippetComposition;
    const snippetComposition = snippetCompositionStore.get();
    if (
      snippetComposition &&
      snippetCompositionStore.isPending() &&
      !this.context.stores.snippets.findBy({ id: snippetComposition.id })
    ) {
      snippetCompositionStore.setErrors(err.errors);
      snippetCompositionStore.resetPending();
    }
  }
}
