import { isEmpty } from 'lodash';

const AGENT_ROLE = Object.freeze(['externalAgentActions', 'internalAgentActions', 'viewLiveboards']);
const AGENT_PLUS_ROLE = Object.freeze([...AGENT_ROLE, 'customerAssigneeManagement', 'listenToCalls']);

const TEAM_MANAGER = Object.freeze([
  ...AGENT_PLUS_ROLE,
  'adminSettings',
  'viewAnswerPerformance',
  'contactSupport',
  'customAttributeManagement',
  'groupInboxes',
  'inboxManagement',
  'kbManagement',
  'scheduledReportsManagement',
  'sidekickConversationManagement',
  'teamManagement',
  'topicManagement',
  'viewAllGroupInboxes',
  'viewReports',
]);

const SYSTEM_ADMIN_ROLE = Object.freeze([
  ...TEAM_MANAGER,
  'agentConfigurationManagement',
  'agentStatusManagement',
  'campaignsManagement',
  'channelManagement',
  'debuggingView',
  'emailDomainsManagement',
  'facebookAdminSettings',
  'integrationsManagement',
  'ivrConfigurationManagement',
  'mobileNumberLookupManagement',
  'relationshipLabelsManagement',
  'rulesManagement',
  'sidekickConfigurationManagement',
  'skillManagement',
  'ssoConfigurationManagement',
  'userManagement',
]);

const COMPLIANCE_ADMIN_ROLE = Object.freeze([
  ...SYSTEM_ADMIN_ROLE,
  'complianceManagement',
  'manageAttachments',
  'manageVoiceRecordings',
]);

const ROLE_FEATURES = Object.freeze({
  agent: AGENT_ROLE,
  'agent-plus': AGENT_PLUS_ROLE,
  analyst: ['viewReports', 'reportBuilder'],
  'answers-admin': ['adminSettings', 'kbManagement'],
  'api-user': ['devManagement'],
  'compliance-admin': COMPLIANCE_ADMIN_ROLE,
  developer: ['debuggingView', 'integrationsManagement'],
  liveboards: ['viewLiveboards'],
  'task-user': ['internalAgentActions', 'viewLiveboards'],
  'team-manager': TEAM_MANAGER,
  'system-admin': SYSTEM_ADMIN_ROLE,
});

export default function createRoleFeatures(claims) {
  if (isEmpty(claims)) {
    return {};
  }

  let roleFeatures = (claims.roleIds || [])
    .filter(roleId => ROLE_FEATURES[roleId])
    .reduce((acc, roleId) => ROLE_FEATURES[roleId].reduce((memo, feature) => (memo[feature] = true) && memo, acc), {});
  if (claims.ssoEnabled) {
    roleFeatures['sso'] = true;
  }

  return roleFeatures;
}
