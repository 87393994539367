import { Outcome } from 'models/recommended_answer';
import ShowToast from 'actions/toast_deprecated/show_toast';
import { ToastType } from 'models/toast_deprecated';
import setRecommendedAnswerDisposition from './lib/set_recommended_answer_disposition';

export default class DismissRecommendedAnswer {
  constructor(context) {
    this.context = context;
  }

  run({ recommendedAnswerId }) {
    setRecommendedAnswerDisposition(this.context, { outcome: Outcome.REJECTED, recommendedAnswerId }).then(response => {
      this.context.executeAction(ShowToast, {
        type: ToastType.SUCCESS,
        message: 'Recommended answer was dismissed.',
      });
    });
  }
}
