import createModel, { prop } from './lib/create_model';

export const AnswerPerformanceDateRange = Object.freeze({
  LAST_1_DAY: '1d',
  LAST_7_DAYS: '7d',
  LAST_30_DAYS: '30d',
  LAST_90_DAYS: '90d',
});

export const NO_ANSWER_REFERENCED = 'NO_ANSWER_REFERENCED';

const AnswerPerformance = createModel({
  modelName: 'AnswerPerformance',
  properties: {
    id: prop(String).isRequired,
    name: prop(String).isRequired,
    references: prop(Number).isRequired,
    handoffs: prop(Number).isRequired,
    resolved: prop(Number).isRequired,
    resolutionRate: prop(Number).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default AnswerPerformance;
