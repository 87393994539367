import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { CardTemplateReader, renderTemplateNodes } from '@gladly/card-renderer';
import connect from 'components/lib/connect';
import ExpandableProfileCard from 'components/customer/profile/expandable_profile_card';
import ExternalDataObjectEnvelope from 'models/external_data_objects/external_data_object_envelope';
import { filterArray, sortArray } from 'components/customer/flexible_profile_card_v2/lib/data_helpers';
import { getCurrentCustomerState } from 'components/customer/summary/lib/store_helpers';
import {
  REQUEST_STATES,
  IGNORE_ERROR_CODES,
  REQUEST_DEFAULTS,
} from 'components/customer/flexible_profile_card_v2/lib/constants';
import ProfileErrorCard from 'components/customer/profile/profile_error_card';
import Spinner from 'components/common/spinner_two';
import withStaticId from 'components/customer/lib/with_static_id';
import RequestExternalData from 'actions/external_data/request_external_data';
import { useOnUnmount } from 'components/hooks/lifecycle_hooks';

const DEFAULT_EXPAND_THRESHOLD = 5;

export function FlexibleProfileCardBase({
  cardConfiguration,
  customerId,
  data,
  dataRequestState,
  isCustomerLoaded,
  requesterId,
  onRequestData,
}) {
  const rendererState = useRef({});
  useOnUnmount(() => {
    rendererState.current = {};
  });

  const requestedNamespace = _.get(cardConfiguration, 'dataConfiguration.namespace');
  const dataRequestTimeout = _.get(cardConfiguration, 'dataConfiguration.requestTimeout') || REQUEST_DEFAULTS.TIMEOUT;

  // Load the data if it has not been loaded yet
  useEffect(() => {
    if (dataRequestState !== REQUEST_STATES.NOT_STARTED || !isCustomerLoaded || !customerId) return;

    onRequestData &&
      onRequestData(
        {
          customerId,
          extraParams: {
            page: REQUEST_DEFAULTS.PAGE,
            pageSize: REQUEST_DEFAULTS.PAGE_SIZE,
          },
          namespace: requestedNamespace,
          parentEntityId: customerId,
          parentEntityType: ExternalDataObjectEnvelope.ParentEntityType.CUSTOMER,
          requestorId: requesterId,
        },
        dataRequestTimeout
      );
  }, [
    customerId,
    dataRequestState,
    dataRequestTimeout,
    isCustomerLoaded,
    onRequestData,
    requestedNamespace,
    requesterId,
  ]);

  if (!customerId || !isCustomerLoaded || dataRequestState === REQUEST_STATES.NOT_STARTED) return null;

  // If the card configuration is missing, render "Error card" instead
  if (_.isEmpty(cardConfiguration)) {
    return <ProfileErrorCard data-aid={`flexible_card_configuration_error`} />;
  }

  // Are we still waiting for the data?
  if (dataRequestState === REQUEST_STATES.IN_PROGRESS) {
    return (
      <ExpandableProfileCard data-aid={`flexible-card-${_.kebabCase(requestedNamespace)}`} isEmpty isLoading>
        <Spinner key="loading-spinner" />
      </ExpandableProfileCard>
    );
  }

  // Data ingesting/processing error(s). Not to be confused with the HTTP request error, which is loadingError
  const dataErrors = _.filter(_.get(data, 'errors') || [], err => !!err);
  const hasDataErrors = !_.isEmpty(dataErrors);

  // If we tried and could not load the data, render "Error card" instead
  if (dataRequestState === REQUEST_STATES.ERROR || hasDataErrors) {
    const diff = _.difference(dataErrors, Object.values(IGNORE_ERROR_CODES));
    if (hasDataErrors && _.isEmpty(diff)) return null; // We only have "ignorable" errors

    return (
      <ProfileErrorCard
        data-aid={`flexible_card_loading_error_${requestedNamespace}`}
        reason="Unable to load the required data."
      />
    );
  }

  // If we are here, the data request state must be "SUCCESS"
  // Hide the card that has no data and is not actively loading
  if (_.isEmpty(data)) return null;

  // Get data transforms and apply them
  const rowLimit = _.get(cardConfiguration, 'dataConfiguration.dataRowLimit') || 0;
  const dataFilter = _.get(cardConfiguration, 'dataConfiguration.dataFilter') || {};
  const dataSort = _.get(cardConfiguration, 'dataConfiguration.dataSort') || {};
  const dataItemTransform = _.get(cardConfiguration, 'dataConfiguration.dataItemTransform');

  // Build our dataset for rendering. This step is only necessary for pre-GraphQL data API
  const dataItems = transformDataForRendering(data, dataFilter, dataSort, rowLimit, dataItemTransform);
  if (_.isEmpty(dataItems)) return null;

  // Now try getting the card title and other template meta
  let expanderLimit = DEFAULT_EXPAND_THRESHOLD;
  let cardTitle = '';
  let renderedNodes = [];
  try {
    const reader = new CardTemplateReader({ preprocessTemplate: true });
    const { title, expandThreshold } = reader.extractCardParameters(cardConfiguration.template, dataItems);
    const threshold = Number(expandThreshold);

    cardTitle = _.trim(title);
    expanderLimit = _.isNaN(threshold) || threshold <= 0 ? DEFAULT_EXPAND_THRESHOLD : threshold;
    renderedNodes = renderTemplateNodes({
      template: cardConfiguration.template,
      data: dataItems,
      hostConfig: {},
      options: {
        preprocessTemplate: true,
      },
      stateStore: rendererState.current,
    });

    // Do we have anything to render? In not, do not display the card
    if (_.isEmpty(renderedNodes)) return null;
  } catch {
    return <ProfileErrorCard data-aid={`flexible_card_template_error`} reason="The card configuration is incorrect." />;
  }

  return (
    <ExpandableProfileCard
      data-aid={`flexible-card-${_.kebabCase(requestedNamespace)}`}
      isEmpty={false}
      isLoading={false}
      limit={expanderLimit || undefined}
      title={cardTitle}
    >
      {renderedNodes}
    </ExpandableProfileCard>
  );
}

FlexibleProfileCardBase.propTypes = {
  cardConfiguration: PropTypes.shape({ template: PropTypes.string.isRequired }).isRequired,
  customerId: PropTypes.string.isRequired,
  data: PropTypes.object,
  isCustomerLoaded: PropTypes.bool.isRequired,
  dataRequestState: PropTypes.string.isRequired,
  requesterId: PropTypes.string.isRequired,
  onRequestData: PropTypes.func.isRequired,
};

/**
 * Extracts the data items from the payload and prepares for rendering. Applies sorting, filtering and
 * row limit if necessary
 */
function transformDataForRendering(externalData, dataFilter, dataSort, dataRowLimit, dataItemTransform) {
  if (_.isEmpty(externalData)) return [];

  // First, extract the items from the payload
  const rawList = _.get(externalData, 'externalDataItems', []);
  const rawDataItems = _.map(rawList, element => _.get(element, 'dataItem'));
  const dataItems = _.filter(rawDataItems, item => !!item);

  // Second, filter and sort if necessary. The order of transformations:
  // Filtering is first, then sorting the results, then applying the row limit (0 means "no limit")
  const rowLimit = Math.max(dataRowLimit, 0);
  const filtered = _.isEmpty(dataFilter) ? dataItems : filterArray(dataFilter, dataItems);
  const sorted = _.isEmpty(dataSort) ? filtered : sortArray(dataSort, filtered);
  const transformed = _.isFunction(dataItemTransform) ? sorted.map(dataItemTransform) : sorted;

  return rowLimit ? transformed.slice(0, rowLimit) : transformed;
}

function mapStateToProps({ getProvider }, { cardConfiguration, requesterId }) {
  const { customerId, isCustomerLoaded } = getCurrentCustomerState(getProvider);
  const storeProvider = getProvider('externalDataObjects');
  let dataRequestState = REQUEST_STATES.NOT_STARTED;

  // In case the customer stores are not fully loaded, bail out and wait for the next round of re-renderings
  if (!isCustomerLoaded) {
    return {
      customerId,
      isCustomerLoaded,
      dataRequestState,
    };
  }

  const dataEntityKey = {
    namespace: _.get(cardConfiguration, 'dataConfiguration.namespace'),
    parentEntityId: customerId,
    parentEntityType: ExternalDataObjectEnvelope.ParentEntityType.CUSTOMER,
    requestorId: requesterId,
  };
  const isLoading = storeProvider.isLoading(dataEntityKey);
  const loadingError = storeProvider.getErrorForLoading(dataEntityKey);
  const envelope = isLoading || loadingError ? null : storeProvider.findBy(dataEntityKey); // We use "findBy" to get the first match

  if (isLoading) {
    dataRequestState = REQUEST_STATES.IN_PROGRESS;
  } else if (loadingError) {
    dataRequestState = REQUEST_STATES.ERROR;
  } else if (!_.isNil(envelope)) {
    dataRequestState = REQUEST_STATES.SUCCESS;
  }

  return {
    customerId,
    isCustomerLoaded,
    data: envelope?.data,
    dataRequestState,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onRequestData: (requestParams, timeout) => {
      executeAction(RequestExternalData, requestParams, { timeout });
    },
  };
}

// These two steps MUST happen in that exact order so that `mapStateToProps` has access to `requestorId`
const ConnectedFlexibleDataCard = connect(mapStateToProps, mapExecuteToProps)(FlexibleProfileCardBase);
export default withStaticId(ConnectedFlexibleDataCard, 'requesterId');
