import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import connect from 'components/lib/connect';
import ForgotPassword from 'models/location/forgot_password';
import { default as PattyWithCheese } from 'components/lib/icons/hamburger';
import NavigationMenu from 'components/root/navigation_menu';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import UserActivation from 'models/location/user_activation';
import UserLogin from 'models/location/user_login';
import Floorboard from 'models/location/floorboard';
import UserResetPassword from 'models/location/user_reset_password';

const NO_HAMBURGER_LOCATIONS = [ForgotPassword, UserActivation, UserLogin, UserResetPassword, Floorboard];

export function NavigationBase({
  areInternalActionsEnabled,
  hasCurrentAgent,
  isKbManagementEnabled,
  locationNeedsHamburger,

  isOpen,
  setIsOpen,
}) {
  const toggle = useCallback(
    evt => {
      evt.preventDefault();
      setIsOpen(!isOpen);
    },
    [setIsOpen, isOpen]
  );
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  if (!hasCurrentAgent || !locationNeedsHamburger) {
    return null;
  }
  if (!areInternalActionsEnabled && !isKbManagementEnabled) {
    return null;
  }
  return (
    <OutsideClickHandler onClickOutside={close}>
      <span>
        <NavigationMenu onClose={close} />
        <Cheeseburger isOpen={isOpen} toggleOpen={toggle} />
      </span>
    </OutsideClickHandler>
  );
}

NavigationBase.propTypes = {
  areInternalActionsEnabled: PropTypes.bool,
  hasCurrentAgent: PropTypes.bool,
  isKbManagementEnabled: PropTypes.bool,
  locationNeedsHamburger: PropTypes.bool,

  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
};

const Navigation = connect(mapStateToProps)(NavigationBase);
export default Navigation;

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const currentLocation = getProvider('currentLocation').get();

  return {
    areInternalActionsEnabled: isFeatureEnabled('internalAgentActions'),
    hasCurrentAgent: !!getProvider('currentAgent').get(),
    isKbManagementEnabled: isFeatureEnabled('kbManagement'),
    locationNeedsHamburger: !_.find(NO_HAMBURGER_LOCATIONS, location => currentLocation instanceof location),
  };
}

export function Cheeseburger({ isOpen, toggleOpen }) {
  return (
    <Bun isOpen={isOpen}>
      <PattyWithCheese isOpen={isOpen} onClick={toggleOpen} />
    </Bun>
  );
}

const Bun = styled.div`
  align-items: center;
  display: flex;
  left: 20px;
  position: fixed;
  top: 22px;
  transition: transform 0.38s ease;
  z-index: 10;

  ${p => p.isOpen && toastedBun};
`;

const toastedBun = css`
  top: 16px;
  width: 17px;
  opacity: 0.7;
  transform: translate3d(260px, 0, 0);
`;
