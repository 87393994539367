import DtoConverter from 'scripts/application/dto_converters/recommended_answer_converter';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';

export default class RecommendedAnswersGatewayObserver {
  constructor(context) {
    this.context = context;
    this.errorHandler = new GatewayErrorSilentHandler(this.context);
  }

  get store() {
    return this.context.stores.recommendedAnswers;
  }

  onBroadcastDelete(payload, { recommendedAnswerId }) {
    this.store.remove(recommendedAnswerId);
  }

  onFetchAllSuccess(responseDto) {
    this.store.resetLoading();
    if (responseDto) {
      this.store.set(responseDto.map(dto => DtoConverter.fromDto(dto)));
    } else {
      this.store.set([]);
    }
  }

  onFetchAllError(error) {
    this.store.resetLoading();
    this.errorHandler.handleCommonErrors(this.context, error);
  }
}
