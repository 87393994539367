import Audience from './audience';
import createEnum from 'scripts/lib/create_enum';
import createModel, { prop } from './lib/create_model';

const RecommendedAnswer = createModel({
  modelName: 'RecommendedAnswer',
  properties: {
    id: prop(String).isRequired,
    name: prop(String).isRequired,
    answer: prop({
      id: prop(String).isRequired,
      name: prop(String).isRequired,
    }).default({}).isRequired,
    audiences: prop([Audience]).default([]),
    content: prop(String).isRequired,
    createdAt: prop(String).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default RecommendedAnswer;

export const Outcome = createEnum('ACCEPTED', 'REJECTED');
