import classnames from 'classnames';
import React, { useState } from 'react';

import { MenuItem } from 'components/lib/menu_deprecated';
import PopoverMenu from 'components/common/menu';
import TranslateIntoSearchMenuContainerWrapper from './translate_into_search_menu_container_wrapper';
import TranslationLanguages from 'models/languages/translation_languages';
import styled from 'styled-components';

export const STORAGE_KEY = 'translation-selected-language';

export default function TranslateIntoMenuItem({ onSelect, onClose, position, targetRef }) {
  const [lastSelectedLanguage, setLastSelectedLanguage] = useState(() => {
    return localStorage.getItem(STORAGE_KEY) || 'en';
  });
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSelectLanguage = code => {
    localStorage.setItem(STORAGE_KEY, code);
    setLastSelectedLanguage(code);
    onSelect?.(code);
    onClose?.();
  };

  const handleOpenSearch = () => {
    setIsSearchOpen(true);
  };

  return (
    <PopoverMenu
      boundByWindow
      className="translateMenu"
      data-aid="translate-into-menu"
      isOpen
      onClose={onClose}
      position={position ?? 'bottom'}
      targetRef={targetRef}
    >
      {isSearchOpen ? (
        <TranslateIntoSearchMenuContainerWrapper
          availableLanguages={TranslationLanguages}
          onSelectLanguage={handleSelectLanguage}
        />
      ) : (
        <>
          {lastSelectedLanguage &&
            TranslationLanguages[lastSelectedLanguage] &&
            lastSelectedLanguage !== 'en' &&
            lastSelectedLanguage !== 'es' && (
              <StyledMenuItem
                className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-language')}
                data-aid={`translate-into-${lastSelectedLanguage}`}
                onSelect={() => handleSelectLanguage(lastSelectedLanguage)}
              >
                <span>{TranslationLanguages[lastSelectedLanguage]}</span>
              </StyledMenuItem>
            )}
          <StyledMenuItem
            className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-language')}
            data-aid={`translate-into-en`}
            onSelect={() => handleSelectLanguage('en')}
          >
            <span>English</span>
          </StyledMenuItem>
          <StyledMenuItem
            className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-language')}
            data-aid={`translate-into-es`}
            onSelect={() => handleSelectLanguage('es')}
          >
            <span>Spanish</span>
          </StyledMenuItem>
          <StyledMenuItem
            className={classnames('conversationItemMenu-menuItem')}
            data-aid={`translate-into-see-more`}
            onSelect={handleOpenSearch}
          >
            <span>See more</span>
          </StyledMenuItem>
        </>
      )}
    </PopoverMenu>
  );
}

const StyledMenuItem = styled(MenuItem)`
  padding: 8px 36px;
  position: relative;

  &:focus {
    padding: 8px 36px;

    svg {
      fill: ${p => p.theme.colors.green400};
    }
  }
`;
