import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Agent from 'models/agent';
import { CHANNEL_REPLY_TEXT } from 'components/lib/channel_action_text';
import connect from 'components/lib/connect';
import ConversationItem, { getOutgoingCommunicationStatus } from 'models/conversation_item';
import ConversationItemType from 'models/conversation_item_type';
import { iconClassNameForItem } from './metadata_icon_classes';
import ItemPrepositionalPhrases from './item_prepositional_phrases';
import Metadata from './metadata';
import TranslationLanguages from 'models/languages/translation_languages';
import { typeReflect } from 'models/conversation_item/content_from_js';
import { getBrandIconByName } from 'components/common/custom_channels';

export function AgentMetadata({
  agent,
  currentAgentId,
  customChannels,
  hasError,
  icon,
  iconClass,
  isCollapsed,
  isTranslationEnabled,
  item,
  object,
  prepositionalPhrases,
  status,
  subject,
  text,
  tooltip,
}) {
  hasError = hasError != null ? hasError : item.content.hasErrorStatus && item.content.hasErrorStatus();
  iconClass = iconClass != null ? iconClass : iconClassNameForItem(item);
  status = status != null ? status : getOutgoingCommunicationStatus(item);
  subject = subject != null ? subject : getAgentName(agent, currentAgentId);

  const contentType = typeReflect.instanceToType(item.content);

  let repliedViaChannel;
  if (ConversationItemType.CUSTOM_CHANNEL_MESSAGE === contentType) {
    const { customChannelId } = item.content;
    const customChannel = _.find(customChannels, { id: customChannelId });

    repliedViaChannel = customChannel?.name;
    icon = <>{getBrandIconByName(customChannel?.icon, { letter: customChannel?.name, stroke: true }).icon}</>;
  } else {
    repliedViaChannel = CHANNEL_REPLY_TEXT[contentType];
  }

  let metaText = text != null ? text : `replied via ${repliedViaChannel}`;
  let language =
    isTranslationEnabled && item.translation ? TranslationLanguages[item.translation.parentLanguage] : undefined;
  return (
    <Metadata
      hasError={hasError}
      icon={icon}
      iconClass={iconClass}
      isCollapsed={isCollapsed}
      isInbound={false}
      language={language}
      object={object}
      prepositionalPhrases={prepositionalPhrases || <ItemPrepositionalPhrases item={item} />}
      status={status}
      subject={subject}
      text={metaText}
      timestamp={item.timestamp}
      tooltip={tooltip}
    />
  );
}

export function getAgentName(agent, currentAgentId) {
  if (!agent) {
    return 'An agent';
  } else if (currentAgentId === agent.id) {
    return 'You';
  }
  return agent.name;
}

AgentMetadata.propTypes = {
  agent: PropTypes.instanceOf(Agent),
  currentAgentId: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  icon: PropTypes.node,
  iconClass: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isTranslationEnabled: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  object: PropTypes.string,
  prepositionalPhrases: PropTypes.node,
  status: PropTypes.string,
  subject: PropTypes.node,
  text: PropTypes.any,
  tooltip: PropTypes.string,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, props) {
  const currentAgentId = getProvider('currentAgent').get().id;
  const agent = getProvider('agents').findBy({ id: _.get(props, 'item.initiator.id') });
  const customChannels = getProvider('customChannels').findAll();
  let isTranslationEnabled = isFeatureEnabled('translationAI');

  return {
    ...props,
    agent,
    currentAgentId,
    customChannels,
    isTranslationEnabled,
  };
}

const AgentMetadataContainer = connect(mapStateToProps)(AgentMetadata);
AgentMetadataContainer.propTypes = {
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export default AgentMetadataContainer;
