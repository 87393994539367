import _ from 'lodash';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CustomerContextProvider } from 'components/customer/customer_context';
import { getLatestConversationId } from 'actions/conversation/lib/conversation_helpers';
import AnswerPanel from 'components/customer/answer_panel';
import AnswerPanelToggleButton from 'components/customer/answer_panel/answer_panel_toggle_button';
import Composition from './customer/composition';
import connect from 'components/lib/connect';
import ConversationHistory from 'components/customer/conversation_history/conversation_history';
import CustomerFooter from 'components/customer/customer_footer';
import CustomerNotAssignedToCurrentAgentBanner from 'components/customer/customer_not_assigned_to_current_agent_banner';
import CustomerNotFound from 'components/customer/customer_not_found';
import CustomerPageViewedTracker from 'components/customer/customer_page_viewed_tracker';
import CustomerSummary from 'components/customer/customer_summary';
import CustomerWasCombined from 'components/customer/customer_was_combined';
import DemoSuggestedReplyContextProvider from 'components/composer/contexts/demo_suggested_replies_context';
import GreetingSuggestionsProvider from 'components/composer/contexts/greeting_suggestions_context';
import KeyPressShortcutMixin from 'components/lib/keypress_shortcut_mixin';
import { ItemsContextProviderContainer } from 'components/customer/conversation_history/conversation_items_v2/items_context';
import OpenAnswerPanel from 'actions/answers/open_answer_panel';
import PhraseSuggestionsProvider from 'components/composer/contexts/phrase_suggestions_context';
import registerHotkey from 'components/hotkeys/register_hotkey';
import SwitchToInbox from 'actions/inbox/switch_to_inbox';
import TaskCommentPanel from 'components/customer/task_comments/task_comment_panel';
import TaskCommentPanelToggleButton from 'components/customer/task_comments/task_comment_panel_toggle_button';
import ToggleAnswerPanel from 'actions/answers/toggle_answer_panel';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';
import VariablesProvider from 'components/composer/contexts/variables_context';
import withShortcuts from 'scripts/presentation/decorators/keypress_shortcut_decorator';

const Customer = createReactClass({
  mixins: [new KeyPressShortcutMixin()],

  propTypes: {
    currentAgentId: PropTypes.string,
    customerExists: PropTypes.bool,
    customerId: PropTypes.string.isRequired,
    isAnswerPanelOpen: PropTypes.bool,
    isCustomAttributesEnabled: PropTypes.bool,
    latestConversationId: PropTypes.string,
    onInboxShortcut: PropTypes.func,
    openAnswerPanel: PropTypes.func,
    toggleAnswerPanel: PropTypes.func,
  },

  render() {
    return (
      <div className="customerV2Container" id="customer">
        {this.renderCustomer()}
      </div>
    );
  },

  renderCustomer() {
    if (this.props.mergedCustomerId) {
      return <CustomerWasCombined customerId={this.props.mergedCustomerId} />;
    } else if (!this.props.customerExists) {
      return <CustomerNotFound />;
    }

    return (
      <CustomerProviders>
        <CustomerPage>
          <CustomerPageViewedTracker customerId={this.props.customerId} />
          <CustomerAnswerWrapper>
            <CustomerAssignmentBannerWrapper>
              <CustomerNotAssignedToCurrentAgentBanner />
              <CustomerContent>
                <CustomerSummary />
                <ConversationFeed
                  currentCompositionId={this.props.currentCompositionId}
                  customerId={this.props.customerId}
                  isAnswerPanelOpen={this.props.isAnswerPanelOpen}
                  isCommentPanelOpen={this.props.isCommentPanelOpen}
                  onToggleAnswerPanel={this.handleToggleAnswerPanel}
                />
              </CustomerContent>
            </CustomerAssignmentBannerWrapper>
            {this.props.isAnswerPanelOpen ? <AnswerPanel /> : null}
            {this.props.isCommentPanelOpen && !this.props.isAnswerPanelOpen ? <TaskCommentPanel /> : null}
          </CustomerAnswerWrapper>
          <CustomerFooter isCustomAttributesEnabled={this.props.isCustomAttributesEnabled} />
        </CustomerPage>
      </CustomerProviders>
    );
  },

  handleInboxShortcut() {
    this.props.onInboxShortcut();
  },

  handleOpenAnswerPanel() {
    this.props.openAnswerPanel({
      customerId: this.props.customerId,
      conversationId: this.props.latestConversationId,
      compositionId: undefined,
    });
  },

  handleToggleAnswerPanel() {
    this.props.toggleAnswerPanel({
      conversationId: this.props.latestConversationId,
      customerId: this.props.customerId,
      compositionId: undefined,
    });
  },
});

const CustomerPage = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0;
  height: inherit;
  min-width: 0;
  position: relative;
`;

const CustomerAnswerWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100%;
  min-height: 0;
  position: relative;
`;

const CustomerAssignmentBannerWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  min-width: 0;
  position: relative;
`;

const CustomerContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100%;
  min-height: 0;
  position: relative;
`;

const CustomerWithShortcuts = withShortcuts(Customer, [
  registerHotkey({
    key: 'alt+i',
    callback: component => component.handleInboxShortcut(),
    label: 'Go back to inbox',
    group: 'Customer',
  }),
  registerHotkey({
    key: 'ctrl+/',
    callback: component => component.handleOpenAnswerPanel(),
    label: 'Open answers side panel',
    group: 'Customer',
  }),
]);

export { Customer, CustomerWithShortcuts };

const CustomerContainer = connect(mapStateToProps, mapExecuteToProps)(CustomerWithShortcuts);

CustomerContainer.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default CustomerContainer;

function mapStateToProps({ getProvider, isFeatureEnabled }, { customerId }) {
  const customerMerge = getProvider('customerMerge').get();
  const mergedCustomerId =
    customerMerge && _.get(customerMerge, 'sourceCustomerId') === customerId && _.get(customerMerge, 'destCustomerId');

  let latestConversationId = getLatestConversationId(getProvider('conversations'));
  const profileProvider = getProvider('profile');
  const customerProfileExists = profileProvider.isLoading() || !!profileProvider.get();
  const answerPanelProvider = getProvider('answerPanel');
  const commentPanelProvider = getProvider('commentPanel');

  return {
    activeCall: getProvider('activeCall').get(),
    currentAgentId: getProvider('currentAgent').get().id,
    currentCompositionId: getProvider('currentLocation').get().currentCompositionId,
    customerExists: customerProfileExists,
    customerId,
    isAnswerPanelOpen: !!answerPanelProvider.get().lastOpenedAt,
    isCommentPanelOpen: !!commentPanelProvider.get().lastOpenedAt,
    isCustomAttributesEnabled: isFeatureEnabled('customAttributes'),
    latestConversationId,
    mergedCustomerId,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onInboxShortcut: () => executeAction(SwitchToInbox),
    openAnswerPanel,
    toggleAnswerPanel,
  };

  function toggleAnswerPanel(attrs) {
    executeAction(ToggleAnswerPanel, attrs);
  }

  function openAnswerPanel(attrs) {
    executeAction(OpenAnswerPanel, attrs);
  }
}

function CustomerProviders({ children }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  if (isFeatureEnabled('gclDemo2023')) {
    children = <DemoProviders>{children}</DemoProviders>;
  }

  return (
    <CustomerContextProvider>
      <VariablesProvider>
        <GreetingSuggestionsProvider>
          <PhraseSuggestionsProvider>{children}</PhraseSuggestionsProvider>
        </GreetingSuggestionsProvider>
      </VariablesProvider>
    </CustomerContextProvider>
  );
}

function DemoProviders({ children }) {
  return <DemoSuggestedReplyContextProvider>{children}</DemoSuggestedReplyContextProvider>;
}

export function ConversationFeed({
  currentCompositionId,
  customerId,
  isAnswerPanelOpen,
  isCommentPanelOpen,
  onToggleAnswerPanel,
}) {
  return (
    <div className="customerV2-feed customerV2-flatFeed">
      <AnswerPanelToggleButton
        isActive={isAnswerPanelOpen || !isCommentPanelOpen}
        isOpen={isAnswerPanelOpen}
        isPanelOpen={isAnswerPanelOpen || isCommentPanelOpen}
        onToggleAnswerPanel={onToggleAnswerPanel}
      />
      <TaskCommentPanelToggleButton
        isActive={isCommentPanelOpen && !isAnswerPanelOpen}
        isVisible={isCommentPanelOpen}
      />
      <ItemsContextProviderContainer>
        <ConversationHistory customerId={customerId} />
        <Composition currentCompositionId={currentCompositionId} />
      </ItemsContextProviderContainer>
    </div>
  );
}

ConversationFeed.propTypes = {
  currentCompositionId: PropTypes.string,
  customerId: PropTypes.string.isRequired,
  isAnswerPanelOpen: PropTypes.bool,
  isCommentPanelOpen: PropTypes.bool,
  onToggleAnswerPanel: PropTypes.func.isRequired,
};
