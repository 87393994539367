import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import AbandonedCall from 'models/abandoned_call';
import { ActivityDisplayLabel } from 'components/customer/conversation_history/conversation_items_v2/widget_activity/widget_activity_item';
import AIActivityItem from 'models/ai_activity/ai_activity_item';
import MessageAutomationSummaryContent, {
  MessageAutomationItemContent,
  MessageAutomationContent,
} from 'models/message_automation_content';
import ChatCustomerUrl from 'models/chat_customer_url';
import ChatMessage from 'models/chat_message';
import ConversationItem from 'models/conversation_item';
import ConversationMessage from 'models/conversation_message';
import ConversationNote from 'models/conversation_note';
import CustomChannelMessage from 'models/custom_channel_message';
import CustomerActivity from 'models/customer_activity';
import EmailCampaignOutreach from 'models/email_campaign_outreach';
import EmailMessage from 'models/email_message';
import EmailPreviewModel from 'models/content_preview/email_preview';
import EmailSendFailure from 'models/email_send_failure';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import getClassName from 'scripts/lib/class_name';
import FbMessageIncoming from 'models/fb_message_incoming';
import FbMessageOutgoing from 'models/fb_message_outgoing';
import HotelReservation from 'models/hotel_reservation';
import htmlToText from 'components/lib/view_helpers';
import ItemLinkContent, { createConversationItemFromItemLink } from 'models/item_link_content';
import LongDate from 'components/lib/date/long_date';
import PaymentStatusEventContent from 'models/payment_status_event_content';
import PhoneCall from 'models/phone_call';
import PhoneCallback from 'models/phone_callback';
import PhoneDuration from 'components/lib/phone_duration';
import ReportDuration from 'components/reporting/report/lib/report_duration';
import ShortTime from 'components/lib/date/short_time';
import SmsCampaignOutreach from 'models/sms_campaign_outreach';
import SmsMessage from 'models/sms_message';
import Task from 'models/task';
import TaskCompletionContent from 'models/task_completion_content';
import TaskReopenedContent from 'models/task_reopened_content';
import Tweet from 'models/tweet';
import SecureDataRequest from 'models/secure_data_request';
import VoiceAIRecording from 'models/voiceai_recording';
import Voicemail from 'models/voicemail';
import WidgetActivity from 'models/widget_activity';
import WebForm from 'models/web_form';

import { formatPhoneNumber } from 'models/phone_number';
import { getAIActivityItemPreview } from 'components/customer/conversation_history/conversation_items_v2/ai_activity/ai_activity_item_preview';
import { isDevelopment } from 'scripts/application/lib/environment';
import { emailHtmlToText } from 'models/conversation_item/email_html_to_text';

const ConversationItemPreviewSeperator = '\xa0-\xa0';

function ItemPreviewText({ conversationItem, hasTopic }) {
  const devMode = isDevelopment();
  if (!conversationItem) {
    return null;
  }

  const content = conversationItem.content;

  if (content instanceof AbandonedCall) {
    return <React.Fragment>Call from {formatPhoneNumber(content.customerNumber)}</React.Fragment>;
  } else if (content instanceof AIActivityItem) {
    const preview = getAIActivityItemPreview(content);
    return preview !== null ? <React.Fragment>{preview}</React.Fragment> : null;
  } else if (content instanceof MessageAutomationSummaryContent) {
    return <React.Fragment>Message automation summary</React.Fragment>;
  } else if (content instanceof MessageAutomationItemContent) {
    return <React.Fragment>Message automation item</React.Fragment>;
  } else if (content instanceof MessageAutomationContent) {
    return <React.Fragment>Message automation content</React.Fragment>;
  } else if (content instanceof ConversationNote) {
    return <React.Fragment>{htmlToText(content.content)}</React.Fragment>;
  } else if (content instanceof ChatMessage) {
    return <React.Fragment>{content.text}</React.Fragment>;
  } else if (content instanceof ChatCustomerUrl) {
    return <React.Fragment>{content.customerUrl}</React.Fragment>;
  } else if (content instanceof ConversationMessage) {
    return <React.Fragment>{content.getBody()}</React.Fragment>;
  } else if (content instanceof CustomerActivity) {
    return <React.Fragment>{content.title || content.body}</React.Fragment>;
  } else if (content instanceof CustomChannelMessage) {
    return <React.Fragment>{content.getSnippetText()}</React.Fragment>;
  } else if (content instanceof EmailMessage) {
    let emailSubject = content.subject ? `${content.subject}${ConversationItemPreviewSeperator}` : '';
    const emailPreview =
      content.getNonQuotedPlainTextFromHtml() || content.getNonQuotedPlainText() || content.bodyPlain;
    return <React.Fragment>{`${emailSubject}${emailPreview}`}</React.Fragment>;
  } else if (content instanceof EmailCampaignOutreach) {
    let emailSubject = content.subject ? `${content.subject}${ConversationItemPreviewSeperator}` : '';
    const emailPreview =
      content.getNonQuotedPlainTextFromHtml() || content.getNonQuotedPlainText() || content.bodyPlain;
    return <React.Fragment>{`${emailSubject}${emailPreview}`}</React.Fragment>;
  } else if (content instanceof EmailPreviewModel) {
    let emailSubject = content.subject ? `${content.subject}${ConversationItemPreviewSeperator}` : '';
    const emailPreview = _.trim(emailHtmlToText(content.excerpt)) || '';
    return <React.Fragment>{`${emailSubject}${emailPreview}`}</React.Fragment>;
  } else if (content instanceof EmailSendFailure) {
    return (
      <React.Fragment>
        {content.recipient ? `Email not delivered for ${content.recipient}.` : 'Email not delivered.'}
      </React.Fragment>
    );
  } else if (content instanceof FbMessageIncoming) {
    return <React.Fragment>{content.message.text}</React.Fragment>;
  } else if (content instanceof FbMessageOutgoing) {
    return <React.Fragment>{content.request.message.text}</React.Fragment>;
  } else if (content instanceof HotelReservation) {
    return (
      <React.Fragment>
        {content.hotel} on <LongDate timestamp={content.timestamp} /> at <ShortTime timestamp={content.timestamp} />
      </React.Fragment>
    );
  } else if (content instanceof ItemLinkContent) {
    const originalItem = createConversationItemFromItemLink(conversationItem);
    return <ItemPreviewText conversationItem={originalItem} />;
  } else if (content instanceof PhoneCall) {
    return <PhoneDuration content={content} initiatorType={conversationItem.initiator.type} />;
  } else if (content instanceof PhoneCallback) {
    return (
      <React.Fragment>
        Scheduled a call back at <ShortTime timestamp={content.requestedTime} />
      </React.Fragment>
    );
  } else if (content instanceof SmsMessage) {
    return <React.Fragment>{content.body}</React.Fragment>;
  } else if (content instanceof SmsCampaignOutreach) {
    return <React.Fragment>{content.body}</React.Fragment>;
  } else if (content instanceof Task) {
    return <React.Fragment>{htmlToText(content.note)}</React.Fragment>;
  } else if (content instanceof TaskCompletionContent) {
    return (
      <React.Fragment>
        {hasTopic ? 'Closed Task - ' : ''}
        {htmlToText(content.note)}
      </React.Fragment>
    );
  } else if (content instanceof TaskReopenedContent) {
    return (
      <React.Fragment>
        {hasTopic ? 'Task Reopened - ' : ''}
        {htmlToText(content.note)}
      </React.Fragment>
    );
  } else if (content instanceof Tweet) {
    return <React.Fragment>{content.text}</React.Fragment>;
  } else if (content instanceof SecureDataRequest) {
    return <React.Fragment>{content.metadata.memo}</React.Fragment>;
  } else if (content instanceof VoiceAIRecording) {
    return (
      <React.Fragment>
        Voice AI recording from {formatPhoneNumber(content.customerNumber)}
        {content.recordingDuration && ConversationItemPreviewSeperator}
        {content.recordingDuration && <ReportDuration duration={content.recordingDuration * 1000} />}
      </React.Fragment>
    );
  } else if (content instanceof Voicemail) {
    return (
      <React.Fragment>
        Voicemail from {formatPhoneNumber(content.customerNumber)}
        {content.recordingDuration && ConversationItemPreviewSeperator}
        {content.recordingDuration && <ReportDuration duration={content.recordingDuration * 1000} />}
      </React.Fragment>
    );
  } else if (content instanceof WebForm) {
    return <React.Fragment>{_.values(content.content).join(' ')}</React.Fragment>;
  } else if (content instanceof WidgetActivity) {
    return <React.Fragment>{ActivityDisplayLabel[content.activityType] || 'widget'} activity</React.Fragment>;
  } else if (content instanceof PaymentStatusEventContent) {
    return <React.Fragment>{content.getText()}</React.Fragment>;
  } else if (content === null) {
    return <div className="preview">New Conversation</div>;
  }

  // Report the fact that we can't render the item
  const unknownItemErr = new Error(`could not render item preview from [${getClassName(content)}]`);
  ErrorReporter.reportError(unknownItemErr, {
    message: 'Unexpected Conversation Item type - ignoring',
  });
  if (!devMode) return null; // Do not break UI in prod over some unknown conversation item. Doing it in dev is OK

  throw unknownItemErr;
}

ItemPreviewText.propTypes = {
  conversationItem: PropTypes.instanceOf(ConversationItem),
  hasTopic: PropTypes.bool,
};

export default ItemPreviewText;
