import AbandonedCall from 'models/abandoned_call';
import AIActivity from 'models/ai_activity/ai_activity_item';
import CallForward from 'models/call_forward';
import ChatCustomerUrl from 'models/chat_customer_url';
import ChatEnded from 'models/chat_ended';
import ChatMessage from 'models/chat_message';
import ChatSelfServiceMetadata from 'models/chat_self_service_metadata';
import ContentPlaceholder from 'models/content_placeholder';
import ConversationCustomAttributeChange from 'models/conversation_custom_attribute_change';
import ConversationItemType from 'models/conversation_item_type';
import ConversationMessage from 'models/conversation_message';
import ConversationNote from 'models/conversation_note';
import ConversationStatusChange from 'models/conversation_status_change';
import ConversationTopicChange from 'models/conversation_topic_change';
import CustomChannelMessage from 'models/custom_channel_message';
import CustomerActivity from 'models/customer_activity';
import CustomerAssigneeChange from 'models/customer_assignee_change';
import CustomerMerge from 'models/customer_merge';
import EmailCampaignOutreach from 'models/email_campaign_outreach';
import EmailCampaignOutreachResult from 'models/email_campaign_outreach_result';
import EmailMessage from 'models/email_message';
import EmailPreview from 'models/content_preview/email_preview';
import EmailSendFailure from 'models/email_send_failure';
import FbMessageIncoming from 'models/fb_message_incoming';
import FbMessageOutgoing from 'models/fb_message_outgoing';
import HelpAppContext from 'models/help_app_context';
import HotelReservation from 'models/hotel_reservation';
import ItemLinkContent from 'models/item_link_content';
import MarketingEmail from 'models/marketing_email';
import MessageAutomationSummaryContent, {
  MessageAutomationItemContent,
  MessageAutomationContent,
} from 'models/message_automation_content';
import MessagingSession from 'models/messaging_session';
import MobileContext from 'models/mobile_context';
import NoReplyNeeded from 'models/no_reply_needed';
import PaymentStatusEventContent from 'models/payment_status_event_content';
import PhoneCall from 'models/phone_call';
import PhoneCallback from 'models/phone_callback';
import ProactiveChatContent from 'models/proactive_chat_content';
import RoutingItem from 'models/routing_item';
import RuleDisabledLoop from 'models/rule_disabled_loop';
import SecureDataRequest from 'models/secure_data_request';
import SessionEnded from 'models/session_ended';
import SmsCampaignOutreach from 'models/sms_campaign_outreach';
import SmsCampaignOutreachResult from 'models/sms_campaign_outreach_result';
import SmsMessage from 'models/sms_message';
import Task from 'models/task';
import TaskCompletionContent from 'models/task_completion_content';
import TaskDueContent from 'models/task_due_content';
import TaskEditedContent from 'models/task_edited_content';
import TaskReopenedContent from 'models/task_reopened_content';
import TaskRouting from 'models/task_routing';
import Tweet from 'models/tweet';
import VoiceCampaignOutreach from 'models/voice_campaign_outreach';
import VoiceCampaignOutreachResult from 'models/voice_campaign_outreach_result';
import VoiceAIRecording from 'models/voiceai_recording';
import Voicemail from 'models/voicemail';
import VoiceRecordingDeleted from 'models/voice_recording_deleted';
import WebContext from 'models/web_context';
import WebForm from 'models/web_form';
import WidgetActivity from 'models/widget_activity';

export default [
  [ConversationItemType.ABANDONED_CALL, AbandonedCall],
  [ConversationItemType.AI_ACTIVITY, AIActivity],
  [ConversationItemType.CALL_FORWARD, CallForward],
  [ConversationItemType.CHAT_CUSTOMER_URL, ChatCustomerUrl],
  [ConversationItemType.CHAT_ENDED, ChatEnded],
  [ConversationItemType.CHAT_MESSAGE, ChatMessage],
  [ConversationItemType.CHAT_SELF_SERVICE_METADATA, ChatSelfServiceMetadata],
  [ConversationItemType.CONVERSATION_CUSTOM_ATTRIBUTE_CHANGE, ConversationCustomAttributeChange],
  [ConversationItemType.CONVERSATION_MESSAGE, ConversationMessage],
  [ConversationItemType.CONVERSATION_NOTE, ConversationNote],
  [ConversationItemType.CONVERSATION_STATUS_CHANGE, ConversationStatusChange],
  [ConversationItemType.CUSTOM_CHANNEL_MESSAGE, CustomChannelMessage],
  [ConversationItemType.CUSTOMER_ACTIVITY, CustomerActivity],
  [ConversationItemType.CUSTOMER_ASSIGNEE_CHANGE, CustomerAssigneeChange],
  [ConversationItemType.CUSTOMER_MERGE, CustomerMerge],
  [ConversationItemType.EMAIL_CAMPAIGN_OUTREACH_RESULT, EmailCampaignOutreachResult],
  [ConversationItemType.EMAIL_CAMPAIGN_OUTREACH, EmailCampaignOutreach],
  [ConversationItemType.EMAIL_PREVIEW, EmailPreview],
  [ConversationItemType.EMAIL_SEND_FAILURE, EmailSendFailure],
  [ConversationItemType.EMAIL, EmailMessage],
  [ConversationItemType.FB_MESSAGE_INCOMING, FbMessageIncoming],
  [ConversationItemType.FB_MESSAGE_OUTGOING, FbMessageOutgoing],
  [ConversationItemType.HELP_APP_CONTEXT, HelpAppContext],
  [ConversationItemType.HOTEL_RESERVATION, HotelReservation],
  [ConversationItemType.ITEM_LINK, ItemLinkContent],
  [ConversationItemType.MARKETING_EMAIL, MarketingEmail],
  [ConversationItemType.MESSAGE_AUTOMATION_MESSAGE, MessageAutomationItemContent],
  [ConversationItemType.MESSAGE_AUTOMATION_SUMMARY, MessageAutomationSummaryContent],
  [ConversationItemType.MESSAGE_AUTOMATION, MessageAutomationContent], // deprecated
  [ConversationItemType.MESSAGING_SESSION, MessagingSession],
  [ConversationItemType.MOBILE_CONTEXT, MobileContext],
  [ConversationItemType.NO_REPLY_NEEDED, NoReplyNeeded],
  [ConversationItemType.PAYMENT_STATUS_EVENT, PaymentStatusEventContent],
  [ConversationItemType.PHONE_CALL, PhoneCall],
  [ConversationItemType.PHONE_CALLBACK, PhoneCallback],
  [ConversationItemType.PLACEHOLDER, ContentPlaceholder],
  [ConversationItemType.PROACTIVE_CHAT_CONTENT, ProactiveChatContent],
  [ConversationItemType.ROUTING_ITEM, RoutingItem],
  [ConversationItemType.RULE_DISABLED_LOOP, RuleDisabledLoop],
  [ConversationItemType.SECURE_DATA_REQUEST, SecureDataRequest],
  [ConversationItemType.SESSION_ENDED, SessionEnded],
  [ConversationItemType.SMS_CAMPAIGN_OUTREACH_RESULT, SmsCampaignOutreachResult],
  [ConversationItemType.SMS_CAMPAIGN_OUTREACH, SmsCampaignOutreach],
  [ConversationItemType.SMS, SmsMessage],
  [ConversationItemType.TASK_COMPLETION, TaskCompletionContent],
  [ConversationItemType.TASK_DUE, TaskDueContent],
  [ConversationItemType.TASK_EDITED, TaskEditedContent],
  [ConversationItemType.TASK_REOPENED, TaskReopenedContent],
  [ConversationItemType.TASK_ROUTING, TaskRouting],
  [ConversationItemType.TASK, Task],
  [ConversationItemType.TOPIC_CHANGE, ConversationTopicChange],
  [ConversationItemType.TWITTER, Tweet],
  [ConversationItemType.VOICE_CAMPAIGN_OUTREACH_RESULT, VoiceCampaignOutreachResult],
  [ConversationItemType.VOICE_CAMPAIGN_OUTREACH, VoiceCampaignOutreach],
  [ConversationItemType.VOICE_RECORDING_DELETED, VoiceRecordingDeleted],
  [ConversationItemType.VOICE_AI_RECORDING, VoiceAIRecording],
  [ConversationItemType.VOICEMAIL, Voicemail],
  [ConversationItemType.WEB_CONTEXT, WebContext],
  [ConversationItemType.WEB_FORM, WebForm],
  [ConversationItemType.WIDGET_ACTIVITY, WidgetActivity],
];
