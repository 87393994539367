import _ from 'lodash';

import { addPendingRequestIdForConversation } from 'actions/composition/lib/update_conversation_workflow';
import AttachmentSourceType from 'models/attachment/attachment_source_type';
import ConversationItemType from 'models/conversation_item_type';
import createAgentFbMessage from 'scripts/domain/factories/conversation_item/create_agent_fb_message';
import CreateFbMessageComposition from './create_fb_message_composition';
import Err from 'models/err';
import { getLatestManualItem } from 'scripts/application/lib/conversation_history_helpers';
import FbMessageOutgoingAttachment from 'models/fb_message_outgoing_attachment';
import FbMessageOutgoing from 'models/fb_message_outgoing';
import FbMessengerUser from 'models/fb_messenger_user';
import FbPage from 'models/fb_page';
import { isEmpty } from 'models/composition/composition_content_shared';
import SubmitComposition, { getOutgoingItemDto } from 'actions/composition/lib/submit_composition';
import Upload from 'models/upload';

export default class SendFbMessage extends SubmitComposition {
  createOutgoingItem(composition, composedContent) {
    let compositionErrors = composition.validateSingleAttachment();
    compositionErrors = compositionErrors.concat(this.constructor.validateContent(composedContent.messageText));
    compositionErrors = compositionErrors.concat(composition.validateAttachmentStatus());
    compositionErrors = compositionErrors.concat(
      this.constructor.validateTextAndAttachmentExclusivity(composedContent.messageText, composition)
    );
    if (isEmpty(composedContent.messageText) && composition.attachments.length === 0) {
      compositionErrors = compositionErrors.concat([
        new Err({
          attr: 'messageHtml',
          code: Err.Code.BLANK,
          detail: 'Message cannot be empty',
        }),
      ]);
    }
    if (compositionErrors.length) {
      return { compositionErrors };
    }

    let attachment = this.constructor.createAttachment(composition);
    composedContent.recipient = this.getRecipient();
    composedContent.sender = this.getSender();

    let fbMessageItem = createAgentFbMessage({
      agentProfile: this.currentAgent,
      attachment,
      conversationId: this.activeConversation.id,
      customerId: composition.customerId,
      snippetIds: composition.snippetIds,
      relatedSnippetIds: composition.relatedSnippetIds,
      ...composedContent,
      translation: composition.translation,
    });

    return { compositionErrors: [], conversationItem: fbMessageItem };
  }

  getConversationUpdates(conversation) {
    const updates = super.getConversationUpdates(conversation);
    return conversation.getUpdatedAttributesForRealtimeCommunication(this.currentAgent.id, updates);
  }

  sendMessage(conversationUpdates, fbMessageItem) {
    let { correlationId } = this.context.gateways.outgoingFbMessage.add(this.currentAgent.id, {
      id: fbMessageItem.id,
      conversation: conversationUpdates,
      conversationItem: getOutgoingItemDto(fbMessageItem),
      customerId: this.currentCustomerId,
    });
    addPendingRequestIdForConversation(this.context, fbMessageItem.conversationId, correlationId);
  }

  afterSend() {
    // create a new Facebook message to continue messaging
    this.context.executeAction(CreateFbMessageComposition, {
      conversationId: this.activeConversation.id,
    });
  }

  getRecipient() {
    let lastFbMessageItem = this.getLastFBMessage(this.activeConversation);
    if (lastFbMessageItem) {
      // sender becomes recipient because we are sending reply
      return lastFbMessageItem.content.sender;
    }
    let lastFbMessengerUserId = _.last(this.customerStores.profile.get().fbMessengerUserIds);
    return FbMessengerUser.create({ id: lastFbMessengerUserId.userId });
  }

  getSender() {
    let lastFbMessageItem = this.getLastFBMessage(this.activeConversation);
    if (lastFbMessageItem) {
      // recipient becomes the sender because we are sending a reply
      return lastFbMessageItem.content.recipient;
    }
    let lastFbMessengerUserId = _.last(this.customerStores.profile.get().fbMessengerUserIds);
    return FbPage.create({ id: lastFbMessengerUserId.pageId });
  }

  getLastFBMessage() {
    const { conversationHistory } = this.customerStores;
    return getLatestManualItem({
      conversationHistory,
      conversationId: this.activeConversation.id,
      filter: item => item.content.type === ConversationItemType.FB_MESSAGE_INCOMING,
    });
  }

  static createAttachment(composition) {
    if (!composition.attachments.length) {
      return undefined;
    }

    let attachment = _.head(composition.attachments);
    let fileDescriptor = attachment.fileDescriptor();
    let source = attachment instanceof Upload ? createUploadSource(composition, attachment.id) : attachment.source;

    return FbMessageOutgoingAttachment.create({
      id: attachment.id,
      contentType: fileDescriptor.contentType,
      filename: fileDescriptor.filename,
      fileSize: fileDescriptor.contentLength,
      source,
    });
  }

  static validateTextAndAttachmentExclusivity(messageText, composition) {
    if (messageText.length && composition.attachments.length) {
      return [
        new Err({
          attr: 'attachments',
          code: Err.Code.TOO_LONG,
          detail: 'Message can be sent with either text or an attachment, but not both',
        }),
      ];
    }
    return [];
  }

  static validateContent(messageText) {
    let errors = [];
    if (messageText.length > FbMessageOutgoing.MAX_MESSAGE_LENGTH) {
      errors.push(
        new Err({
          attr: 'messageHtml',
          code: Err.Code.TOO_LONG,
          detail: `Your message can contain a maximum of ${FbMessageOutgoing.MAX_MESSAGE_LENGTH} characters`,
        })
      );
    }
    return errors;
  }

  get customerStores() {
    return this.context.stores.customers.storesFor(this.currentCustomerId);
  }
}

function createUploadSource(composition, attachmentId) {
  return {
    type: AttachmentSourceType.UPLOAD,
    path: composition.getUploadPath(attachmentId),
  };
}
