import AgentActivity from 'models/agent_activity';
import AnswerPerformanceView from 'models/location/answer_performance_view';
import { AnswerPerformanceDateRange } from 'models/answer_performance';
import changeCurrentLocation from 'actions/lib/change_current_location';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';

export default class OpenAnswerPerformance {
  constructor(context) {
    this.context = context;
  }

  run({ audienceId, dateRange }) {
    const appFeatures = this.context.stores.appFeatures.get();
    if (!appFeatures.isEnabled('viewAnswerPerformance') || !appFeatures.isEnabled('answerPerformance')) {
      this.context.router.navigateHome();
      return;
    }

    if (!Object.values(AnswerPerformanceDateRange).includes(dateRange)) {
      this.context.router.navigateTo(
        AnswerPerformanceView.create({ audienceId, dateRange: AnswerPerformanceDateRange.LAST_1_DAY })
      );
      return;
    }

    changeCurrentLocation(this.context, AnswerPerformanceView.create({ audienceId, dateRange }));
    this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.ANSWER_PERFORMANCE });

    this.context.stores.answerPerformances.setLoading();
    this.context.gateways.answerPerformance.fetchAll({ audienceId, dateRange });

    this.context.stores.recommendedAnswersCounts.setLoading();
    this.context.gateways.recommendedAnswersCount.request();
  }
}
