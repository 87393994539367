import _ from 'lodash';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Input from './input';
import XIcon from 'components/lib/icons/x_icon';

/**
 * SearchInput is a debounced input that includes a search icon and a search clearing button
 *
 * @visibleName Search Input
 */
export default class SearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSearchFocused: false, value: props.initialValue };

    _.bindAll(this, ['handleBlur', 'handleChange', 'handleClearText', 'handleFocus', 'handleKeyDown', 'clearValue']);

    this.createNotifier();
  }

  componentWillUnmount() {
    this.cancel && this.cancel();
  }

  focusAtEnd() {
    this.input.focusAtEnd();
    this.setState({ isSearchFocused: true });
  }

  focusAll() {
    this.input.focusAll();
    this.setState({ isSearchFocused: true });
  }

  blur() {
    this.input.blur();
  }

  render() {
    return (
      <SearchInputWrapper
        className={this.props.className}
        focused={this.state.isSearchFocused}
        hasError={this.props.hasError}
      >
        <IconWrapper>
          <FontAwesomeIcon color="#999999" icon={faSearch} />
        </IconWrapper>
        <StyledInput
          autoFocus={this.props.autoFocus}
          data-aid={this.props['data-aid'] || 'searchInput-input'}
          disabled={this.props.disabled}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onClick={this.props.onClick}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          placeholder={this.props.placeholder}
          ref={node => {
            if (!node) {
              return;
            }

            this.input = node;
            this.props.setInputRef && this.props.setInputRef(node.input);
            if (this.props.setSearchRef) {
              this.props.setSearchRef.current = { clearValue: this.clearValue };
            }
          }}
          value={this.state.value}
        />
        {this.renderClearSearch()}
      </SearchInputWrapper>
    );
  }

  renderClearSearch() {
    if (!this.state.value) {
      return null;
    }

    return (
      <ClearInputIcon data-aid="searchInput-clear" onClick={this.handleClearText} onMouseDown={this.preventDefault}>
        <XIcon />
      </ClearInputIcon>
    );
  }

  handleBlur(evt) {
    this.setState({ isSearchFocused: false });
    this.props.onBlur && this.props.onBlur(evt);
  }
  handleChange(evt) {
    evt.persist();

    if (evt.target.value) {
      this.setState({ value: evt.target.value }, () => {
        this.notify(evt);
      });
    } else {
      this.handleClearText(evt);
    }
  }
  handleClearText(evt) {
    evt.persist();
    this.cancel && this.cancel();
    this.setState({ value: '' }, () => {
      this.notify(evt);
    });

    this.props.onClearText && this.props.onClearText(evt);
  }
  handleFocus(evt) {
    this.setState({ isSearchFocused: true });
    this.props.onFocus && this.props.onFocus(evt);
  }
  handleKeyDown(evt) {
    evt.stopPropagation();
    if (evt.key === 'Enter') {
      this.forceNotify(evt);
    }
    this.props.onKeyDown && this.props.onKeyDown(evt);
  }

  forceNotify(evt) {
    if (!this.isDebouncing) {
      return;
    }
    this.cancel && this.cancel();

    this.props.onChange(evt);
  }

  createNotifier() {
    if (this.props.delay === 0) {
      this.notify = this.props.onChange;
    } else {
      const debounceOnChange = _.debounce(evt => {
        this.isDebouncing = false;
        this.props.onChange(evt);
      }, this.props.delay);

      this.notify = evt => {
        this.isDebouncing = true;
        debounceOnChange(evt);
      };
      this.cancel = () => {
        this.isDebouncing = false;
        debounceOnChange.cancel();
      };
    }
  }

  clearValue() {
    this.cancel && this.cancel();
    this.setState({ value: '' });
  }

  preventDefault(evt) {
    evt.preventDefault();
  }
}

SearchInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  delay: PropTypes.number, // ms to delay input submit by
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  initialValue: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClearText: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  setInputRef: PropTypes.func,
  'data-aid': PropTypes.string,
};

SearchInput.defaultProps = {
  autoFocus: true,
  delay: 100,
  initialValue: '',
  placeholder: 'Search',
};

const ClearInputIcon = styled.div`
  color: ${p => p.theme.colors.gray400};
  cursor: pointer;
  height: 16px;
  margin-right: 8px;
  width: 16px;
  z-index: 1;
  svg {
    fill: ${p => p.theme.colors.gray600};
    height: 16px;
    width: 16px;
  }
`;

const SearchInputWrapper = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid
    ${p => (p.hasError ? p.theme.colors.red400 : p.focused ? p.theme.colors.gray600 : p.theme.colors.gray300)};
  border-radius: 18px;
  display: flex;
  height: 32px;
  position: relative;
  transition: border-color 100ms ease-in;
  width: inherit;
  .inputWrapper {
    width: 100%;
  }
  .fa-search {
    height: 16px;
    margin-left: 10px;
    margin-right: 12px;
    width: 16px;
  }
`;

const StyledInput = styled(Input)`
  background-color: transparent;
  border: 0;
  font-size: ${p => p.theme.fontSize.base};
  padding-left: 34px;
  width: 100%;
  padding-top: 0;
  position: relative;
  height: inherit;
  &::placeholder {
    color: ${p => p.theme.colors.gray500};
  }
`;

const IconWrapper = styled.span`
  align-items: center;
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
