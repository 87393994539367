import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import connect from 'components/lib/connect';
import ExternalAppCardData from 'models/external_apps/external_app_card_data';
import RequestAppCardData from 'actions/external_apps/request_app_card_data';

/**
 * This pseudo-component manages App Card data requests. It requests data
 * for all App Cards at once, so that each individual card would not have
 * to deal with it. The component makes sure to request the data once for
 * a given customer, even if it gets re-rendered multiple times.
 *
 * @return {null}
 * @constructor
 */
function ExternalAppCardDataLoader({ customerId, isLoading, isLoadingError, onAppCardDataRequest }) {
  const dataRequestedFlag = useRef(false);
  const lastCustomerId = useRef('');

  useEffect(() => {
    if (isLoadingError || !customerId) return;

    // Check if we have started reloading the stores
    if (isLoading) {
      if (dataRequestedFlag.current) dataRequestedFlag.current = false;
    } else {
      if (dataRequestedFlag.current && customerId === lastCustomerId.current) return;

      // If we are here, we just finished loading the configs and need to request
      // the card data
      onAppCardDataRequest({
        gladlyEntityId: customerId,
        gladlyEntityType: ExternalAppCardData.EntityType.CUSTOMER,
      });
      dataRequestedFlag.current = true;
      lastCustomerId.current = customerId;
    }
  });

  return null;
}

ExternalAppCardDataLoader.propTypes = {
  customerId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isLoadingError: PropTypes.bool,
  onAppCardDataRequest: PropTypes.func.isRequired,
};

function mapStateToProps({ getProvider }) {
  const profileProvider = getProvider('profile');
  const profileDefProvider = getProvider('customerProfileDef');
  const appCardTemplates = getProvider('externalAppCardTemplates');

  const templatesLoading = appCardTemplates.isLoading();
  const templatesLoadingError = !isEmpty(appCardTemplates.getErrorForLoading());
  const profileLoading = profileProvider.isLoading();
  const profileLoadingError = !isEmpty(profileProvider.getErrors());
  const profileDefLoading = profileDefProvider.isLoading();
  const profileDefLoadingError = !isEmpty(profileDefProvider.getErrors());

  const isLoading = templatesLoading || profileDefLoading || profileLoading;
  const isLoadingError = templatesLoadingError || profileDefLoadingError || profileLoadingError;

  const profile = profileLoading || profileLoadingError ? undefined : profileProvider.get();
  const customerId = profile?.id;

  return {
    customerId,
    isLoading,
    isLoadingError,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onAppCardDataRequest: params => executeAction(RequestAppCardData, params),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(ExternalAppCardDataLoader);
