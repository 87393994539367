import React from 'react';
import Icon from 'components/lib/icons/icon';

export default function RecommendedAnswersSparkleStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        d="M8.303 2.309 9.75 1.75l.533-1.422c.026-.127.152-.203.28-.203.101 0 .228.076.253.203l.559 1.422 1.422.559c.127.025.203.152.203.253 0 .127-.076.254-.203.28l-1.422.533-.559 1.447c-.025.102-.152.178-.254.178-.127 0-.253-.076-.279-.178L9.75 3.375l-1.447-.533c-.102-.026-.178-.153-.178-.28 0-.101.076-.228.178-.253M.229 6.219.66 6.04l.203-.102H.89L3.123 4.9l1.041-2.26.102-.203.203-.432a.38.38 0 0 1 .355-.254c.153 0 .305.102.381.254l.203.432.076.203.026.025 1.015 2.234 2.26 1.041.203.102.432.203c.152.05.254.203.254.356a.44.44 0 0 1-.254.38l-.432.178-.203.102-2.26 1.04-1.04 2.235v.025l-.102.204-.178.431a.44.44 0 0 1-.38.254.38.38 0 0 1-.356-.254l-.203-.431-.102-.203v-.026l-1.04-2.234L.888 7.26H.863L.66 7.158.23 6.98A.42.42 0 0 1 0 6.6c0-.153.076-.305.229-.381m2.107.38 1.295.61c.254.102.482.33.61.584l.583 1.295.61-1.295c.127-.254.33-.482.584-.584l1.295-.61-1.295-.609c-.254-.101-.457-.33-.584-.584l-.61-1.295-.584 1.295a1.24 1.24 0 0 1-.61.584zM9.75 9.876l.533-1.422c.026-.127.152-.203.28-.203.101 0 .228.076.253.203l.559 1.422 1.422.559c.127.025.203.152.203.254 0 .127-.076.253-.203.279l-1.422.533-.559 1.447c-.025.102-.152.178-.254.178-.127 0-.253-.076-.279-.178L9.75 11.5l-1.447-.533c-.102-.026-.178-.152-.178-.28 0-.101.076-.228.178-.253z"
        fill="#8C69F0"
      />
    </Icon>
  );
}
