const LANGUAGES = Object.freeze({
  af: 'Afrikaans',
  ar: 'Arabic',
  hy: 'Armenian',
  az: 'Azerbaijani',
  be: 'Belarusian',
  bs: 'Bosnian',
  bg: 'Bulgarian',
  ca: 'Catalan',
  zh: 'Chinese',
  'zh-hans': 'Chinese - Simplified',
  'zh-hant': 'Chinese - Traditional',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch',
  'nl-be': 'Dutch - Belgium',
  en: 'English',
  'en-au': 'English - Australia',
  'en-ca': 'English - Canada',
  'en-150': 'English - Europe',
  'en-gb': 'English - United Kingdom',
  'en-ie': 'English - Ireland',
  'en-nz': 'English - New Zealand',
  'en-us': 'English - United States',
  et: 'Estonian',
  fil: 'Filipino',
  fi: 'Finnish',
  fr: 'French',
  'fr-be': 'French - Belgium',
  'fr-ca': 'French - Canada',
  gl: 'Galician',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek',
  he: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  is: 'Icelandic',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  kn: 'Kannada',
  kk: 'Kazakh',
  ko: 'Korean',
  lv: 'Latvian',
  lt: 'Lithuanian',
  mk: 'Macedonian',
  ms: 'Malay',
  mr: 'Marathi',
  mi: 'Maori',
  ne: 'Nepali',
  no: 'Norwegian',
  fa: 'Persian',
  pl: 'Polish',
  pt: 'Portuguese',
  'pt-br': 'Portuguese - Brazil',
  'pt-pt': 'Portuguese - Portugal',
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  'sr-me': 'Serbian - Montenegro',
  sidekick: 'Sidekick',
  sk: 'Slovak',
  sl: 'Slovenian',
  es: 'Spanish',
  'es-419': 'Spanish - Latin America',
  'es-mx': 'Spanish - Mexico',
  'es-es': 'Spanish - Spain',
  'es-us': 'Spanish - United States',
  sw: 'Swahili',
  sv: 'Swedish',
  tl: 'Tagalog',
  ta: 'Tamil',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  ur: 'Urdu',
  vi: 'Vietnamese',
  cy: 'Welsh',
});

export default LANGUAGES;
