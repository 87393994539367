import { addPendingRequestIdForConversation } from 'actions/composition/lib/update_conversation_workflow';
import createAgentSms from 'scripts/domain/factories/conversation_item/create_agent_sms';
import CreateSmsComposition from 'actions/composition/create_sms_composition';
import Err from 'models/err';
import { isEmpty } from 'models/composition/composition_content_shared';
import SmsMessage from 'models/sms_message';
import SmsMessageAttachment from 'models/sms_message_attachment';
import SubmitComposition, { getOutgoingItemDto } from 'actions/composition/lib/submit_composition';
import Upload from 'models/upload';

export default class SendSms extends SubmitComposition {
  createOutgoingItem(composition, composedContent) {
    let compositionErrors = composition.validateAttachmentStatus();
    compositionErrors = compositionErrors.concat(composition.validateTotalAttachmentSize());

    if (composition.attachments.length > 10) {
      compositionErrors.push(
        new Err({ attr: 'attachments', code: Err.INVALID, detail: 'there is a maximum of 10 attachments' })
      );
    }
    if (compositionErrors.length) {
      return { compositionErrors };
    }

    if (isEmpty(composedContent.body) && !composition.hasAttachments()) {
      return {
        compositionErrors: [
          new Err({
            attr: 'bodyHtml',
            code: Err.Code.BLANK,
            detail: `Message cannot be empty`,
          }),
        ],
      };
    }

    if ((composedContent?.body || '').length > SmsMessage.MAX_MESSAGE_LENGTH) {
      return {
        compositionErrors: [
          new Err({
            attr: 'bodyHtml',
            code: Err.Code.TOO_LONG,
            detail: `Your message can contain a maximum of ${SmsMessage.MAX_MESSAGE_LENGTH} characters`,
          }),
        ],
      };
    }

    let attachments = this.createSmsAttachments(composition);
    let agentSmsItem = createAgentSms(this.currentAgent, this.activeConversation.id, composition.customerId, {
      attachments,
      ...composedContent,
      snippetIds: composition.snippetIds,
      relatedSnippetIds: composition.relatedSnippetIds,
      translation: composition.translation,
    });

    return { compositionErrors: [], conversationItem: agentSmsItem };
  }

  getConversationUpdates(conversation) {
    const updates = super.getConversationUpdates(conversation);
    return conversation.getUpdatedAttributesForRealtimeCommunication(this.currentAgent.id, updates);
  }

  sendMessage(conversationUpdates, agentSmsItem) {
    let { correlationId } = this.context.gateways.outgoingSms.add(this.currentAgent.id, {
      id: agentSmsItem.id,
      customerId: this.currentCustomerId,
      conversation: conversationUpdates,
      conversationItem: getOutgoingItemDto(agentSmsItem),
    });

    addPendingRequestIdForConversation(this.context, agentSmsItem.conversationId, correlationId);
  }

  afterSend() {
    this.context.executeAction(CreateSmsComposition, { conversationId: this.activeConversation.id });
  }

  createSmsAttachments(composition) {
    return composition.attachments.map(attachment => asSmsAttachment(composition, attachment));
  }
}

function asSmsAttachment(composition, attachment) {
  let fileDescriptor = attachment.fileDescriptor();
  let source = attachment instanceof Upload ? createUploadSource(composition, attachment.id) : attachment.source;

  return SmsMessageAttachment.create({
    id: attachment.id,
    contentType: fileDescriptor.contentType,
    filename: fileDescriptor.filename,
    fileSize: fileDescriptor.contentLength,
    source,
  });
}

function createUploadSource(composition, attachmentId) {
  return {
    type: SmsMessageAttachment.SourceType.UPLOAD,
    path: composition.getUploadPath(attachmentId),
  };
}
