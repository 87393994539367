import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SearchableMenu from 'components/lib/assignment_menu/searchable_menu';
import SearchResults from 'components/lib/assignment_menu/search_results';

export default function TranslateIntoSearchMenu({
  className,
  availableLanguages,
  searchText,
  onSearchChange,
  onClearSearch,
  onSelectLanguage,
}) {
  const safeSearchText = searchText || '';

  const allLanguages = useMemo(() => {
    return Object.entries(availableLanguages).map(([code, name]) => ({
      code,
      name,
    }));
  }, [availableLanguages]);

  const trimmed = safeSearchText.trim().toLowerCase();
  const filteredLanguages = useMemo(() => {
    if (!trimmed) return allLanguages;
    return allLanguages.filter(lang => {
      return lang.code.toLowerCase().includes(trimmed) || lang.name.toLowerCase().includes(trimmed);
    });
  }, [trimmed, allLanguages]);

  let placeholder = null;
  if (!trimmed) {
    placeholder = 'Begin typing to find a language.';
  } else if (!filteredLanguages.length) {
    placeholder = 'No results. Try refining your search.';
  }

  const renderRow = ({ code, name }) => (
    <div className="translateIntoSearchResult" key={code} onClick={() => onSelectLanguage(code)}>
      <div className="translateIntoSearchResult-name">{name}</div>
      <div className="translateIntoSearchResult-code">{code}</div>
    </div>
  );

  return (
    <SearchableMenu
      className={className}
      data-aid="translateIntoSearchableMenu"
      footer={null}
      isLoading={false}
      onClearSearch={onClearSearch}
      onSearch={onSearchChange}
      placeholder={placeholder}
      searchPlaceholder="Begin typing to find a language."
      searchResults={
        filteredLanguages.length ? (
          <SearchResults
            className="searchableMenu-results-language"
            renderRow={renderRow}
            results={filteredLanguages}
          />
        ) : null
      }
      title=""
    />
  );
}

TranslateIntoSearchMenu.propTypes = {
  className: PropTypes.string,
  availableLanguages: PropTypes.object.isRequired,
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func,
  onSelectLanguage: PropTypes.func.isRequired,
};
