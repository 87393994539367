import AITextCompletion from 'models/ai_text_completion';
import analytics from 'scripts/lib/analytics';
import HtmlToText from 'html-to-text';
import postAITextCompletionRequest from './_post_ai_text_completion_request';
import qconsole from 'scripts/lib/qconsole';
import TranslateComposition from 'scripts/application/actions/composition/translate_composition';

export default class RetryAITextCompletion {
  constructor(context) {
    this.context = context;
  }

  run({ customerId, requestorId, composition }) {
    if (!this.context.stores.customers.has({ id: customerId })) {
      qconsole.log(`RetryAITextCompletion: received request for unloaded customer [${customerId}]. Ignoring.`);
      return;
    }
    if (!requestorId) {
      qconsole.error('RetryAITextCompletion: must include "requestorId". Ignoring.');
      return;
    }

    const { aiTextCompletions } = this.context.stores.customers.storesFor(customerId);
    const completion = aiTextCompletions.findBy({ id: requestorId });
    if (!completion) {
      return;
    }

    const selectedLength = composition
      ? HtmlToText.fromString(composition.content.bodyHtml, { wordwrap: null }).length
      : undefined;

    if (completion.type === AITextCompletion.Types.translate) {
      const textToTranslate = completion.data.initialText;
      const targetLanguage = composition.translation?.parentLanguage;

      if (!targetLanguage) {
        qconsole.error('RetryAITextCompletion: translation must include "targetLanguage". Ignoring.');
        return;
      }

      this.context.executeAction(TranslateComposition, {
        composition,
        customerId,
        requestorId,
        targetLanguage,
        text: textToTranslate,
      });
    } else {
      postAITextCompletionRequest(this.context, {
        customerId,
        requestorId,
        rewriteId: completion.rewriteId,
        text: completion.data.text,
        initialText: completion.data.initialText,
        type: completion.type,
      });
    }

    analytics.track('Rewrite Retried', {
      customerId,
      conversationId: composition?.conversationId,
      compositionId: composition?.id,
      contentType: composition?.contentType(),
      rewriteId: completion?.rewriteId,
      rewriteType: completion?.type,
      selectedLength,
      totalLength: completion?.data?.text?.length,
    });
  }
}
