import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AnswerPerformanceDateRange } from 'models/answer_performance';
import connect from 'components/lib/connect';
import DropdownMenu from 'components/common/dropdown_menu_v2';
import FilterAnswerPerformance from 'actions/answer_performance/filter_answer_performance';

export function AnswerPerformanceFilters({ audiences, onChangeFilter, selectedAudienceId, selectedDateRange }) {
  const [audienceId, setAudience] = useState(selectedAudienceId || '');
  const [dateRange, setDateRange] = useState(selectedDateRange || '1d');

  const audienceText = audiences.find(o => o.value === audienceId)?.label;
  const onChangeAudienceFilter = useCallback(
    newAudienceId => {
      onChangeFilter({ audienceId: newAudienceId, dateRange });
      setAudience(newAudienceId);
    },
    [dateRange, onChangeFilter]
  );

  const dateRangeText = dateRangeOptions.find(o => o.value === dateRange)?.label;
  const onChangeDateRangeFilter = useCallback(
    newDateRange => {
      onChangeFilter({ audienceId, dateRange: newDateRange });
      setDateRange(newDateRange);
    },
    [audienceId, onChangeFilter]
  );

  return (
    <Filters>
      <StyledDropdownWrapper>
        <DropdownMenu
          className="date-range-filter"
          onSelect={onChangeDateRangeFilter}
          options={dateRangeOptions}
          text={dateRangeText}
        />
      </StyledDropdownWrapper>
      {!!audiences.length && (
        <StyledDropdownWrapper>
          <DropdownMenu
            className="audience-filter"
            onSelect={onChangeAudienceFilter}
            options={audiences}
            text={audienceText}
          />
        </StyledDropdownWrapper>
      )}
    </Filters>
  );
}

AnswerPerformanceFilters.propTypes = {
  audiences: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onChangeFilter: PropTypes.func,
  selectedAudienceId: PropTypes.string,
  selectedDateRange: PropTypes.string,
};

export const Filters = styled.div`
  display: flex;
  padding: ${p => p.theme.spacing.medium} 0;
`;

const StyledDropdownWrapper = styled.div`
  margin-right: ${p => p.theme.spacing.inlineMedium};
`;

const dateRangeOptions = [
  { label: 'Last 1 day', value: AnswerPerformanceDateRange.LAST_1_DAY },
  { label: 'Last 7 days', value: AnswerPerformanceDateRange.LAST_7_DAYS },
  { label: 'Last 30 days', value: AnswerPerformanceDateRange.LAST_30_DAYS },
  { label: 'Last 90 days', value: AnswerPerformanceDateRange.LAST_90_DAYS },
];

function mapStateToProps({ getProvider }) {
  const audiences = getProvider('audiences')
    .findAll()
    .map(audience => ({
      value: audience.id,
      label: audience.name,
    }))
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  if (audiences?.length) {
    audiences.unshift({ label: 'All Audiences', value: '' });
  }

  const location = getProvider('currentLocation').get();

  return {
    audiences,
    selectedAudienceId: location.audienceId,
    selectedDateRange: location.dateRange,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onChangeFilter: ({ audienceId, dateRange }) => {
      executeAction(FilterAnswerPerformance, { audienceId, dateRange });
    },
  };
}

const AnswerPerformanceFiltersContainer = connect(mapStateToProps, mapExecuteToProps)(AnswerPerformanceFilters);

export default AnswerPerformanceFiltersContainer;
