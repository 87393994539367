import _ from 'lodash';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import qconsole from 'scripts/lib/qconsole';

/**
 * This service supports both "legacy" route `/api/v2/orgs/:orgId/external-data` and
 * new graphQL-based route `/api/v1/orgs/:orgId/external-data/graphql/ui-template-data`
 */
export default class ExternalDataObjectService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  getLegacyData(payload, callback, path, params, query) {
    const { orgId } = params || {};
    const { context, gladlyEntityType, gladlyEntityId, namespace, page, pageSize, refresh, requestor } = query || {};

    if (!orgId) {
      return callback(Error(statusText(400)), {
        status: 400,
        statusText: 'Missing or invalid parameter "orgId"',
      });
    }

    if (refresh) {
      qconsole.log('ExternalDataObjectService.findLegacy: data refresh requested');
    }

    // Check whether we received all required params
    const requiredQueryParams = {
      context,
      namespace,
      gladlyEntityType,
      gladlyEntityId,
      requestor,
    };

    const missingParam = _.findKey(requiredQueryParams, value => !value);
    if (missingParam) {
      return callback(Error(statusText(400)), {
        status: 400,
        statusText: `Missing or invalid query parameter '${missingParam}'`,
      });
    }

    let database;
    try {
      database = this.getDatabase(orgId);
      if (!database) {
        return callback(Error(statusText(500)), { status: 500, statusText: `No database for org ${orgId}` });
      }
    } catch (err) {
      return callback(Error(statusText(500)), {
        status: 500,
        statusText: `Unable to get database for org ${orgId}: ${err.message}`,
      });
    }

    const dataObject = _.get(database, `externalAppData.${namespace}`);
    if (!dataObject) {
      return callback(Error(statusText(404)), { status: 404, statusText: statusText(404) });
    }

    setTimeout(() => {
      const externalDataItems = _.isArray(dataObject) ? dataObject : [dataObject];
      const envelope = {
        context,
        namespace,
        page,
        pageSize,
        requestor,

        data: { externalDataItems, errors: null },
      };

      callback(null, { status: 200, statusText: statusText(200), response: envelope });
    }, Math.ceil(5000 * Math.random()));
  }

  getGraphQLData(payload, callback, path, params) {
    const { orgId } = params || {};
    const { namespace, configurationId, uiTemplateId, queryField, forceDataRefresh } = payload || {};

    if (!orgId) {
      return callback(Error(statusText(404)), {
        status: 404,
        statusText: statusText(404),
      });
    }

    if (!configurationId || !uiTemplateId || !queryField || !namespace) {
      return callback(Error(statusText(400)), {
        status: 400,
        statusText: statusText(400),
      });
    }

    if (forceDataRefresh) {
      qconsole.log('ExternalDataObjectService.findGraphQL: data refresh requested');
    }

    let database;
    try {
      database = this.getDatabase(orgId);
      if (!database) {
        return callback(Error(statusText(500)), { status: 500, statusText: `No database for org ${orgId}` });
      }
    } catch (err) {
      return callback(Error(statusText(500)), {
        status: 500,
        statusText: `Unable to get database for org ${orgId}: ${err.message}`,
      });
    }

    const dataObject = _.get(database, `externalAppData.graphQL.${namespace}`);
    setTimeout(() => {
      const items = makeArray(dataObject);
      callback(null, {
        status: 200,
        statusText: statusText(200),
        response: {
          data: items,
          errors: dataObject ? null : [{ code: 'NOT_EXIST' }],
        },
      });
    }, Math.ceil(5000 * Math.random()));
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v2/orgs/:orgId/external-data': {
          GET: this.getLegacyData,
        },
        '/api/v1/orgs/:orgId/external-data/graphql/ui-template-data': {
          POST: this.getGraphQLData,
        },
      },
      this
    );
  }
}

function makeArray(value) {
  if (!value) return [];
  return _.isArray(value) ? value : [value];
}
