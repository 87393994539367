import {
  addPendingRequestIdForConversation,
  clearPendingRequest,
} from 'actions/composition/lib/update_conversation_workflow';
import Attachment from 'models/attachment';
import CustomChannelMessageAttachment from 'models/custom_channel_message_attachment';
import createAgentCustomChannelMessage from 'scripts/domain/factories/conversation_item/create_agent_custom_channel_message';
import CreateCustomChannelComposition from './create_custom_channel_composition';
import CustomChannelMessageModel, {
  CustomChannelMessageContentFiles,
  CustomChannelMessageContentText,
  CustomChannelMessageStatus,
  customChannelMessageStatusCode,
  messageType as MessageType,
} from 'scripts/domain/models/custom_channel_message';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import SubmitComposition from 'actions/composition/lib/submit_composition';

export default class CustomChannelMessage extends SubmitComposition {
  createOutgoingItem(composition, composedContent) {
    let messageContent = this.createCustomChannelContent(composition, composedContent);

    let compositionErrors = messageContent.validate();

    if (compositionErrors.length) {
      return { compositionErrors };
    }
    const conversationItem = createAgentCustomChannelMessage({
      agentProfile: this.currentAgent,
      content: messageContent,
      conversationId: this.activeConversation.id,
      customerId: composition.customerId,
      snippetIds: composition.snippetIds,
      relatedSnippetIds: composition.relatedSnippetIds,
      translation: composition.translation,
    });

    return { compositionErrors: [], conversationItem };
  }

  createCustomChannelContent(composition, composedContent) {
    const uploadedAttachments = composition.attachments.map(attachment =>
      asCustomChannelAttachment(composition, attachment)
    );

    const content = getMessageContent(composedContent.body, uploadedAttachments);

    const { customChannelId, customerAddress, companyAddress, groupId, groupName } = composedContent;
    const messageType = content instanceof CustomChannelMessageContentFiles ? MessageType.FILES : MessageType.TEXT;

    return CustomChannelMessageModel.create({
      companyAddress,
      content,
      customChannelId,
      customerAddress,
      groupId,
      groupName,
      messageType,
      status: CustomChannelMessageStatus.create({
        value: customChannelMessageStatusCode.SENDING,
      }),
    });
  }

  sendMessage(conversationUpdates, conversationItem) {
    const requestId = IdGenerator.newId();
    addPendingRequestIdForConversation(this.context, conversationItem.conversationId, requestId);
    this.context.gateways.outgoingCustomChannelHttp
      .add(
        {
          conversationId: conversationItem.conversationId,
          customerId: this.currentCustomerId,
        },
        {
          id: conversationItem.id,
          content: conversationItem.content,
          snippetIds: conversationItem.snippetIds,
          relatedSnippetIds: conversationItem.relatedSnippetIds,
        }
      )
      .finally(() => {
        clearPendingRequest(this.context, requestId);
      });
  }

  afterSend(conversation) {
    this.context.executeAction(CreateCustomChannelComposition, {
      conversationId: this.activeConversation.id,
      ...conversation.content,
    });
  }
}

function getMessageContent(body, attachments) {
  return attachments.length
    ? CustomChannelMessageContentFiles.create({ caption: body, attachments })
    : CustomChannelMessageContentText.create({ text: body });
}

function asCustomChannelAttachment(composition, attachment) {
  if (attachment instanceof CustomChannelMessageAttachment) {
    return attachment;
  }

  let fileDescriptor = attachment.fileDescriptor();

  return CustomChannelMessageAttachment.create({
    id: attachment.id,
    contentType: fileDescriptor.contentType,
    filename: fileDescriptor.filename,
    fileSize: fileDescriptor.contentLength,
    source: {
      type: Attachment.SourceType.UPLOAD,
      path: composition.getUploadPath(attachment.id),
    },
  });
}
