import AgentActivity from 'models/agent_activity';
import changeCurrentLocation from 'actions/lib/change_current_location';
import { handleCommonErrors } from 'scripts/application/lib/gateway_error_interactive_handler';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import Snippet, { SnippetChannels } from 'models/answers/snippet';
import SnippetContent from 'models/answers/snippet_content';
import SnippetEditor from 'models/location/snippet_editor';

export default class SwitchSnippetRecommendedAnswer {
  constructor(context) {
    this.context = context;
  }

  run({ recommendedAnswerId, referencedAnswerId }) {
    if (!recommendedAnswerId || !referencedAnswerId) {
      this.context.router.navigateTo(SnippetEditor.create());
      return;
    }

    return this.context.gateways.recommendedAnswers
      .fetch({ recommendedAnswerId })
      .then(response => {
        if (response.errors) {
          this.context.router.navigateTo(SnippetEditor.create());
          return;
        }

        const audienceIds = response.audiences.map(audience => audience.id);
        const snippetWithPublicContent = Snippet.create({
          id: response.id,
          name: response.name,
          audienceIds,
          contents: [
            SnippetChannels.create({
              selfService: SnippetContent.create({
                name: response.name,
                bodyHtml: response.content,
              }),
            }),
          ],
        });

        this.context.stores.snippetComposition.set(snippetWithPublicContent);
        this.context.stores.snippetComposition.clearErrors();
        this.context.stores.snippetComposition.resetPending();

        changeCurrentLocation(this.context, SnippetEditor.create({ recommendedAnswerId, referencedAnswerId }));
        this.context.executeAction(SetAndLogActivity, { type: AgentActivity.Type.KNOWLEDGE_BASE });
      })
      .catch(errDto => {
        this.context.router.navigateTo(SnippetEditor.create());
        handleCommonErrors(this.context, errDto);
      });
  }
}
