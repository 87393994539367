import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'SnippetEditor',
  properties: {
    recommendedAnswerId: String,
    referencedAnswerId: String,
    snippetId: String,
  },

  statics: {
    breadcrumb: 'Answer',
  },
});
