import _ from 'lodash';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import Attachments from 'components/admin/knowledge_base/snippet/snippet_attachments';
import { AnswerDescription, AnswerDescriptionsList } from 'components/admin/knowledge_base/answer_descriptions';
import AnswerPanelContext from 'components/customer/answer_panel/answer_panel_context';
import Body, { useEditorValue } from './answer_preview_content_body';
import Editor from 'components/composer/editors/super';
import { lngDirection } from 'models/languages/answer_languages';
import { SnippetChannel, SnippetContentType } from 'models/answers/snippet';
import TrackAnswerPanelCopy from 'actions/knowledge_base/track_answer_panel_copy';
import UpdateSnippetCount from 'actions/knowledge_base/update_snippet_count';
import { useExecuteAction } from 'components/hooks/connect_hooks';

export default function Content() {
  const { selectedAnswer, selectedAnswerLanguage, selectedAnswerType } = useContext(AnswerPanelContext);
  const onCopy = useOnCopy(selectedAnswer, selectedAnswerType, selectedAnswerLanguage);
  const languageDir = lngDirection(selectedAnswerLanguage);

  return (
    <ContentStyled dir={languageDir} onCopy={onCopy}>
      <Description text={selectedAnswer.description} type={selectedAnswerType} />
      <Subject answer={selectedAnswer} language={selectedAnswerLanguage} type={selectedAnswerType} />
      <PublicTitle answer={selectedAnswer} language={selectedAnswerLanguage} type={selectedAnswerType} />
      <Body
        answer={selectedAnswer}
        data-aid="answerPanel-content-body"
        language={selectedAnswerLanguage}
        type={selectedAnswerType}
      />
      <StyledAttachments
        contentChannelType={SnippetContentType[selectedAnswerType]}
        isRemoveable={false}
        language={selectedAnswerLanguage}
        snippet={selectedAnswer}
      />
    </ContentStyled>
  );
}

const ContentStyled = styled.div`
  direction: ${p => p.dir};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
`;

const StyledAttachments = styled(Attachments)`
  flex-shrink: 0;
  margin: 0 8px;
  padding: 0;
  width: inherit;
`;

function useOnCopy(answer, answerType, language) {
  const executeAction = useExecuteAction();

  return useCallback(() => {
    executeAction(UpdateSnippetCount, answer.id);
    executeAction(TrackAnswerPanelCopy, { answer, channel: answerType, language });
  }, [answer, answerType, language]);
}

function Description({ text, type }) {
  if (!text || type === SnippetChannel.INFO || type === SnippetChannel.SELF_SERVICE) {
    return null;
  }

  return (
    <AnswerDescriptionsList description={text} isAnswerPanel>
      <AnswerDescription description={text} />
    </AnswerDescriptionsList>
  );
}

function Subject({ answer, language, type }) {
  let content = answer.findContentByLanguage(language);
  let text = _.get(content, 'anyChannel.subject');

  const html = `Email Subject - ${text}`;
  const [value, onChange] = useEditorValue(html);
  if (!text || type !== SnippetChannel.ANY_CHANNEL) {
    return null;
  }

  return (
    <SubjectStyled>
      <Editor autoFocus={false} onChange={onChange} placeholder="There is no content" readOnly value={value} />
    </SubjectStyled>
  );
}

const SubjectStyled = styled.div`
  font-weight: ${p => p.theme.fontWeight.heavy};
  padding: 0 16px;
`;

function PublicTitle({ answer, language, type }) {
  if (type !== SnippetChannel.SELF_SERVICE) {
    return null;
  }

  let content = answer.findContentByLanguage(language);
  let name = _.get(content, 'selfService.name');
  if (!name) {
    return null;
  }

  return <Public>{name}</Public>;
}

const Public = styled.div`
  font-weight: ${p => p.theme.fontWeight.heavy};
  margin-top: 16px;
  padding: 0 16px;
`;
