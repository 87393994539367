import createModel, { prop } from './lib/create_model';

const Voicemail = createModel({
  modelName: 'VoiceAIRecording',

  properties: {
    automationSessionId: prop(String),
    callId: prop(String),
    companyNumber: prop(String),
    customerNumber: prop(String),
    recordingUrl: prop(String),
    recordingDuration: prop(Number), // in seconds
    recordingId: prop(String),
    recordingStatus: prop(String),
    recordingStartTime: prop(String),
  },

  getMessageText() {
    return 'VoiceAI Recording';
  },

  fileDescriptor() {
    let filename = `${this.customerNumber}-voiceai-recording`;
    return {
      filename: `${filename}.mp3`,
    };
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default Voicemail;
