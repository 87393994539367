import { noop } from 'lodash';
import NoopOnReconnectGateway from 'scripts/adapters/gateways/noop_on_reconnect_gateway';

export default class ExternalAppCardTemplateGateway extends NoopOnReconnectGateway {
  constructor(backend) {
    /**
     * Even though we will never receive a `broadcastTopic` event, we have to
     * specify the pattern because the Endpoint uses it to parse out the parameters
     * that are used while handling the collection broadcasts.
     */
    super(backend, {
      fetchAllUrl: '/api/v1/orgs/:orgId/external-app-card/ui-templates',
      broadcastTopic: 'v1/orgs/:orgId/configured-external-apps',
      collectionBroadcastTopic: 'v1/orgs/:orgId/configured-external-apps/+',
      collectionBroadcastDeleteTopic: 'v1/orgs/:orgId/configured-external-apps/+/event/delete',
    });
  }

  request(params, query, httpOptions) {
    return super.request(params, query, httpOptions).catch(noop);
  }

  requestAll(params, query, httpOptions) {
    return super.requestAll(params, query, httpOptions).catch(noop);
  }
}
