import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TranslateIntoSearchMenu from './translate_into_search_menu';

export default function TranslateIntoSearchMenuContainerWrapper(props) {
  const [searchText, setSearchText] = useState('');

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleClearSearch() {
    setSearchText('');
  }

  return (
    <TranslateIntoSearchMenu
      availableLanguages={props.availableLanguages}
      className="searchableMenu translateIntoMenu"
      onClearSearch={handleClearSearch}
      onSearchChange={handleSearchChange}
      onSelectLanguage={props.onSelectLanguage}
      searchText={searchText}
    />
  );
}

TranslateIntoSearchMenuContainerWrapper.propTypes = {
  availableLanguages: PropTypes.object.isRequired,
  onSelectLanguage: PropTypes.func.isRequired,
};
