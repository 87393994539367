import _ from 'lodash';
import faker from 'faker';
import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AITranslationService {
  constructor(getDatabase, pubsub) {
    this.getDatabase = getDatabase;
    this.pubsub = pubsub;
  }

  getRoutes() {
    return bindCallbacks({
      '/api/v1/orgs/:orgId/translation': {
        POST: this.handlePost.bind(this),
      },
    });
  }

  handlePost({ text, targetLanguage }, onReceive, path, { orgId }) {
    if (text === '') {
      const status = 400;
      onReceive(new Error(statusText(status)), {
        status,
        statusText: statusText(status),
        response: {
          errors: [{ attr: 'text', code: 'blank', detail: 'cannot be blank' }],
        },
      });
      return;
    }

    if (targetLanguage === '') {
      const status = 400;
      onReceive(new Error(statusText(status)), {
        status,
        statusText: statusText(status),
        response: {
          errors: [{ attr: 'targetLanguage', code: 'blank', detail: 'cannot be blank' }],
        },
      });
      return;
    }

    const translation = this.translate(orgId, { text, targetLanguage });
    const status = 200;
    onReceive(null, {
      status,
      statusText: statusText(status),
      response: {
        text: translation,
        sourceLanguage: 'en',
        targetLanguage,
        model: 'gpt-4o-mini',
        provider: 'openai',
        translatedAt: '2025-03-11T00:00:00Z',
      },
    });
  }

  translate(orgId, { text, targetLanguage }) {
    const translations = this.getDatabase(orgId).translations;
    return translations?.[text?.toLowerCase()]?.[targetLanguage] ?? createFakeSentences(3).join(' ');
  }
}

function createFakeSentences(numberOfSentences) {
  const ret = [];
  for (let i = 0; i < numberOfSentences; i++) {
    ret.push(_.capitalize(faker.lorem.sentence()));
  }
  return ret;
}
