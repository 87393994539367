import _ from 'lodash';

import AddEmailSuggestions from 'actions/email_suggestions/add_email_suggestions';
import {
  addPendingRequestIdForConversation,
  clearPendingRequest,
} from 'actions/composition/lib/update_conversation_workflow';
import Attachment from 'models/attachment';
import createAgentEmail from 'scripts/domain/factories/conversation_item/create_agent_email';
import { convertToQuotedHtmlBodyReply, convertToQuotedPlainBodyReply } from 'actions/composition/lib/email_body';
import EmailRecipientDeliveryStatus from 'models/email_recipient_delivery_status';
import EmailMessage, { createAttachmentsValidator, createTotalSizeValidator } from 'models/email_message';
import Err from 'models/err';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import isEmailAddress from 'scripts/lib/is_email_address';
import qconsole from 'scripts/lib/qconsole';
import SubmitComposition, { getOutgoingItemDto } from 'actions/composition/lib/submit_composition';
import TrackEmailRecipients from 'actions/email_suggestions/track_email_recipients';

const validateUploads = createAttachmentsValidator('send email');
const validateTotalSize = createTotalSizeValidator('Email');

export function getRecipientsList(recipientsString) {
  return recipientsString
    ? _.chain(recipientsString)
        .split(/\s*[,;]\s*/)
        .map(_.trim)
        .value()
    : [];
}

export default class SendEmail extends SubmitComposition {
  createOutgoingItem(composition, composedContent) {
    const deletedInlineImages = getDeletedInlineImages(composedContent.bodyHtml, composition.attachments);
    _.forEach(deletedInlineImages, a => {
      composition.removeAttachment(a.id);
    });

    let emailContent = this.createEmailContent(composition, composedContent);

    let compositionErrors = this.constructor.validateContent(emailContent, composition.content.isForward);
    compositionErrors = compositionErrors.concat(validateUploads(composition.attachments));

    let attachments = getInlineAttachments(emailContent.bodyHtml, composition.content.inlineAttachments).concat(
      composition.attachments
    );

    if (hasSizeValidationError(_.omit(emailContent, 'attachments'), attachments)) {
      emailContent.attachments = composition.attachments.map(attachment => asEmailAttachment(composition, attachment));
    }

    compositionErrors = compositionErrors.concat(
      validateTotalSize(JSON.stringify(_.omit(emailContent, 'attachments')), composition.attachments)
    );
    if (compositionErrors.length) {
      return { compositionErrors };
    }
    let emailItem = createAgentEmail({
      agentProfile: this.currentAgent,
      content: emailContent,
      conversationId: this.activeConversation.id,
      customerId: composition.customerId,
      snippetIds: composition.snippetIds,
      relatedSnippetIds: composition.relatedSnippetIds,
      translation: composition.translation,
    });

    return { compositionErrors: [], conversationItem: emailItem };
  }

  createEmailContent(composition, composedContent) {
    let uploadedAttachments = composition.attachments.map(attachment => asEmailAttachment(composition, attachment));

    let bodyHtml = convertToQuotedHtmlBodyReply(composedContent.bodyHtml, composedContent.quotedHtml);
    let bodyPlain = convertToQuotedPlainBodyReply(composedContent.bodyPlain, composedContent.quotedPlain);

    let recipientToArray = getRecipientsList(composedContent.to);
    let recipientCcArray = getRecipientsList(composedContent.cc);
    let recipientBccArray = getRecipientsList(composedContent.bcc);

    this.context.executeAction(AddEmailSuggestions, {
      customerId: composition.customerId,
      emails: [...recipientToArray, ...recipientCcArray, ...recipientBccArray],
    });
    this.context.executeAction(TrackEmailRecipients, {
      tos: recipientToArray,
      ccs: recipientCcArray,
      bccs: recipientBccArray,
    });

    let deliveryStatuses = {};
    _.forEach(_.union(recipientToArray, recipientCcArray, recipientBccArray), function(recipient) {
      deliveryStatuses[recipient] = new EmailRecipientDeliveryStatus({
        status: EmailRecipientDeliveryStatus.Status.INITIAL,
        detail: '',
        code: 0,
      });
    });
    return {
      ...composedContent,
      attachments: getInlineAttachments(bodyHtml, composedContent.inlineAttachments).concat(uploadedAttachments),
      bodyHtml,
      bodyHtmlStripped: composedContent.bodyHtml,
      bodyPlain,
      bodyPlainStripped: composedContent.bodyPlain,
      fromName: this.currentAgentName,
      headers: composedContent.headers,
      to: recipientToArray,
      cc: recipientCcArray,
      bcc: recipientBccArray,
      recipientStatuses: deliveryStatuses,
      sendStatus: EmailMessage.SendStatus.INITIAL,
    };
  }

  sendMessage(conversationUpdates, emailItem) {
    const requestId = IdGenerator.newId();
    addPendingRequestIdForConversation(this.context, emailItem.conversationId, requestId);
    this.context.gateways.outgoingEmailHttp
      .add({
        id: emailItem.id,
        customerId: this.currentCustomerId,
        conversation: conversationUpdates,
        conversationItem: getOutgoingItemDto(emailItem),
      })
      .catch(err =>
        qconsole.log(
          `Error sending outgoing email ${emailItem.id} to customerId ${this.currentCustomerId}, conversationId ${emailItem.conversationId}`,
          err
        )
      )
      .finally(() => {
        clearPendingRequest(this.context, requestId);
      });
  }

  get currentAgentName() {
    const isExternalNameEnabled = this.context.stores.agentExperienceConfig.get().allowExternalName;
    const currentAgent = this.context.stores.currentAgent.get();
    return isExternalNameEnabled ? currentAgent.externalName || currentAgent.name : currentAgent.name;
  }

  static validateContent(emailContent) {
    let errors = [];
    if (
      _(emailContent.to || [])
        .compact()
        .isEmpty()
    ) {
      errors.push(
        new Err({
          attr: 'to',
          code: Err.Code.BLANK,
          detail: 'The "to" field cannot be empty',
        })
      );
    }
    if (!emailContent.from) {
      errors.push(
        new Err({
          attr: 'from',
          code: Err.Code.BLANK,
          detail: 'The "from" field cannot be empty',
        })
      );
    }

    _.forEach(emailContent.to, addr => {
      if (!isEmailAddress(addr)) {
        errors.push(
          new Err({
            attr: 'to',
            code: Err.Code.INVALID,
            detail: `${addr} is not a valid email address`,
          })
        );
      }
    });

    _.forEach(emailContent.cc, addr => {
      if (!isEmailAddress(addr)) {
        errors.push(
          new Err({
            attr: 'cc',
            code: Err.Code.INVALID,
            detail: `${addr} is not a valid email address`,
          })
        );
      }
    });

    _.forEach(emailContent.bcc, addr => {
      if (!isEmailAddress(addr)) {
        errors.push(
          new Err({
            attr: 'bcc',
            code: Err.Code.INVALID,
            detail: `${addr} is not a valid email address`,
          })
        );
      }
    });

    if (emailContent.bodyPlain.length > EmailMessage.MAX_BODY_LENGTH) {
      errors.push(
        new Err({
          attr: 'bodyHtml',
          code: Err.Code.TOO_LONG,
          detail: `The email body cannot exceed ${EmailMessage.MAX_BODY_LENGTH.toFixed(0).replace(
            /(\d)(?=(\d{3})+$)/g,
            '$1,'
          )} characters`,
        })
      );
    }

    if (!emailContent.subject) {
      errors.push(new Err({ attr: 'subjectHtml', code: Err.Code.BLANK, detail: 'The email subject cannot be blank' }));
    }

    return errors;
  }
}

function getInlineAttachments(bodyHtml, attachments) {
  return (attachments || []).filter(a => bodyHtml.includes(a.contentId) && !a.isRedacted);
}

function getDeletedInlineImages(inlineImages, attachments) {
  let deletedInlineImages = [];
  _.forEach(attachments, a => {
    if (a.isInline && !_.includes(inlineImages, a.id)) {
      deletedInlineImages.push(a);
    }
  });
  return deletedInlineImages;
}

function asEmailAttachment(composition, attachment) {
  if (attachment instanceof Attachment) {
    return attachment;
  }

  let fileDescriptor = attachment.fileDescriptor();

  return Attachment.create({
    id: attachment.id,
    contentType: fileDescriptor.contentType,
    filename: fileDescriptor.filename,
    fileSize: fileDescriptor.contentLength,
    isInline: attachment.isInline,
    source: {
      type: Attachment.SourceType.UPLOAD,
      path: composition.getUploadPath(attachment.id),
    },
  });
}

function hasSizeValidationError(content, attachments) {
  const validationErrors = validateTotalSize(JSON.stringify(content), attachments).length;
  return validationErrors > 0;
}

export { validateUploads };
