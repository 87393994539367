import { getUrl } from 'scripts/adapters/lib/url';
import OpenRecommendedAnswers from 'actions/recommended_answers/open_recommended_answers';
import RecommendedAnswers from 'models/location/recommended_answers';
import { registerUrlConverter } from './location_url';
import UnloadRecommendedAnswers from 'actions/recommended_answers/unload_recommended_answers';

registerUrlConverter(RecommendedAnswers, {
  generateUrl: (path, params) => {
    return getUrl(path, params, { arrayFormat: 'comma' });
  },
  getPath: () => '/recommended-answers',
  getQueryParams: location => {
    const params = {};
    if (location.referencedAnswerId) {
      params.aid = location.referencedAnswerId;
    }
    return params;
  },
});

export default function(router, { runnerContext }) {
  return {
    on: () => {
      const queryParams = router.parseQueryParameters().toJS();
      runnerContext.executeAction(OpenRecommendedAnswers, { referencedAnswerId: queryParams.aid });
    },
    after: () => {
      runnerContext.executeAction(UnloadRecommendedAnswers);
    },
  };
}
