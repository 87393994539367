import classnames from 'classnames';
import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import BubbleContent from '../content/bubble_content';
import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import { formatPhoneNumber } from 'models/phone_number';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import ItemCommError from '../item_comm_error';
import ItemContent from '../item_content';
import ItemContext from '../item_context';
import ItemMetadata from '../item_metadata';
import SmsMessageAttachments from './attachments/sms_message_attachments';
import { STATUS as SmsStatus } from 'models/sms_message';
import { ERROR_CODE } from 'models/sms_message';
import useTranslatedContent from 'components/hooks/use_translated_content';

export default function SmsItem({ className, isFirstInStack, isLastInStack, isStacked, item }) {
  const tooltip = `sent from ${formatPhoneNumber(item.content.from)} to ${formatPhoneNumber(item.content.to)}`;

  return (
    <ItemContent
      isFirstInStack={isFirstInStack}
      isLastInStack={isLastInStack}
      isStacked={isStacked}
      item={item}
      showIcon={!isStacked}
    >
      <ItemMetadata item={item} showMetadata={!isStacked} status={getStatus()} tooltip={tooltip} />
      <SmsContent className={className} item={item} />
      <SmsError item={item} />
    </ItemContent>
  );

  function getStatus() {
    const status = item.content.getStatus();
    switch (status) {
      case SmsStatus.DELIVERED:
        return 'delivered';
      case SmsStatus.SENT:
        return 'sent';
      case SmsStatus.FAILED:
      case SmsStatus.UNDELIVERED:
      case SmsStatus.UNKNOWN:
        return 'delivery failed';
      case SmsStatus.NEW:
      case SmsStatus.SENDING:
        return 'sending';
      case SmsStatus.RECEIVED:
        return 'received';
      default:
        return undefined;
    }
  }
}

SmsItem.propTypes = {
  className: PropTypes.string,
  isFirstInStack: PropTypes.bool,
  isLastInStack: PropTypes.bool,
  isStacked: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};

export function SmsError({ item }) {
  if (
    [
      ERROR_CODE.SMS_NUMBER_IS_PENDING_A2P_10DLC_REGISTRATION,
      ERROR_CODE.SMS_NUMBER_IS_NOT_A2P_10DLC_REGISTERED,
    ].includes(item.content?.status?.errorCode)
  ) {
    return <A2P10DLCError className="smsCommItemError" item={item} />;
  }

  return <ItemCommError item={item} />;
}

function A2P10DLCError({ className, item }) {
  const message = item.content?.getErrorMessage() || 'An unknown error occurred.';
  const messageParts = message.split(/A2P 10DLC/);

  const helpDocHref = item.content?.getErrorMessageHelpDocHref() || '';

  return (
    <span className={classnames('communicationError', className)}>
      <span className="communicationError-message">
        {messageParts.map((part, index) => (
          <Fragment key={index}>
            {part}{' '}
            {index < messageParts.length - 1 ? (
              <a className="communicationError-helpDoc-link" href={helpDocHref} rel="noreferrer" target="_blank">
                A2P 10DLC
              </a>
            ) : null}
          </Fragment>
        ))}
      </span>
    </span>
  );
}

export function SmsContent({ className, item }) {
  const isAutoreply = item.initiator.type === Communicator.AUTOMATED;
  const { isViewOriginal } = useContext(ItemContext);

  return (
    <BubbleContent className={classnames('smsItem-content', className)} data-aid={`smsItem-${item.id}`} item={item}>
      {isAutoreply ? <div className="smsItem-autoreply">Autoreply</div> : null}
      <div dir="auto">
        <InlineAnnotatedContentContainer
          hasRedactedPaymentCard={item.content.hasRedactedPaymentCardNumber}
          id={item.id}
          isRedacted={item.content.isRedacted}
          text={useTranslatedContent(item, () => item.content.body, isViewOriginal)}
        />
      </div>
      <SmsMessageAttachments attachments={item.content.attachments || []} itemId={item.id} />
    </BubbleContent>
  );
}

SmsContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
