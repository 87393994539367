import _ from 'lodash';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Button, { ButtonTypes } from 'components/common/button';
import { H1, H4 } from 'components/common/headers';
import { default as Languages } from 'models/languages/answer_languages';
import { SnippetChannel } from 'models/answers/snippet';

export default function AnswerZeroState({ addChannel, language }) {
  const addReference = useCallback(() => addChannel(SnippetChannel.INFO), []);
  const addEmail = useCallback(() => addChannel(SnippetChannel.ANY_CHANNEL), []);
  const addMessaging = useCallback(() => addChannel(SnippetChannel.MESSAGE), []);
  const addPublic = useCallback(() => addChannel(SnippetChannel.SELF_SERVICE), []);

  const greeting = LanguageToGreeting[language] || 'Hello!';

  return (
    <Panel data-aid="emptyAnswer">
      <Greeting>{greeting}</Greeting>
      <Description>Add a channel in {Languages[language]}</Description>
      <div>
        <ChannelButton onClick={addReference}>
          <FontAwesomeIcon className="answerEditor-header-icon" icon={faBook} /> Reference
        </ChannelButton>
        <ChannelButton onClick={addEmail}>
          <i className="answerEditor-header-icon icon-email" /> Email
        </ChannelButton>
        <ChannelButton onClick={addMessaging}>
          <FontAwesomeIcon className="answerEditor-header-icon" icon={faComment} /> Messaging
        </ChannelButton>
        <ChannelButton onClick={addPublic}>
          <FontAwesomeIcon className="answerEditor-header-icon" icon={faGlobe} /> Public
        </ChannelButton>
      </div>
    </Panel>
  );
}

AnswerZeroState.propTypes = {
  addChannel: PropTypes.func.isRequired,
  language: PropTypes.oneOf(_.keys(Languages)).isRequired,
};

const Panel = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.gray100};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
const Greeting = styled(H1)`
  color: ${p => p.theme.colors.gray600};
  font-size: 48px;
`;
const Description = styled(H4)`
  font-size: 18px;
  color: ${p => p.theme.colors.gray600};
`;
export const ChannelButton = styled(Button).attrs({ buttonType: ButtonTypes.SECONDARY })`
  align-items: center;
  display: inline-flex;
  margin: 0 8px;
`;

const LanguageToGreeting = Object.freeze({
  da: 'Hej!',
  de: 'Hallo!',
  el: 'γεια σας!',
  'en-au': 'G’day!',
  'en-ca': 'Hello!',
  'en-gb': 'Hello!',
  'en-ie': 'Hello!',
  'en-nz': 'Hello!',
  'en-us': 'Hello!',
  'es-419': '¡Hola!',
  'es-es': '¡Hola!',
  'es-mx': '¡Hola!',
  'es-us': '¡Hola!',
  et: 'Tere!',
  fi: 'Hei!',
  fil: 'Kamusta!',
  fr: 'Salut!',
  'fr-be': 'Salut!',
  'fr-ca': 'Salut!',
  hi: 'नमस्ते!',
  hu: 'Szia!',
  id: 'Halo!',
  is: 'Halló!',
  it: 'Ciao!',
  ja: 'こんにちは！',
  ka: 'გამარჯობა!',
  ko: '여보세요!',
  lv: 'Sveiki!',
  lt: 'Sveiki!',
  ms: 'Helo!',
  nl: 'Hallo!',
  no: 'Hallo!',
  'nl-be': 'Hallo!',
  pl: 'Witaj!',
  'pt-br': 'Olá!',
  'pt-pt': 'Olá!',
  ro: 'Buna ziua!',
  ru: 'Здравствуйте!',
  sk: 'Ahoj!',
  sl: 'zdravo!',
  sr: 'Здраво!',
  'sr-ME': 'Здраво!',
  sv: 'Hejsan!',
  th: 'สวัสดี!',
  tr: 'Alo!',
  uk: 'Здравствуйте!',
  vi: 'xin chào!',
  'zh-hans': '你好！',
  'zh-hant': '你好！',
});
