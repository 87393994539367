import { Factory } from 'rosie';

Factory.define('RecommendedAnswer')
  .extend('Uuid')
  .attr('name', 'RECOMMENDED_ANSWER');

Factory.define('RecommendedAnswerWithDefaults')
  .extend('RecommendedAnswer')
  .attr('name', 'default-name')
  .attr('answer', { id: 'answer-id', name: 'answer-name' })
  .attr('audiences', () => [Factory.attributes('AudienceWithDefaults')])
  .attr('content', 'test content')
  .attr('createdAt', () => new Date().toISOString());
