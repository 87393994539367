import createModalModel, { prop } from './lib/create_modal_model';

export default createModalModel({
  modelName: 'DeleteVoiceRecording',
  properties: {
    conversationItemId: prop(String).isRequired,
    customerId: prop(String).isRequired,
    recordingType: prop(String).isRequired,
  },
});
