import StandardGateway from './lib/standard_gateway';

export default class RecommendedAnswersGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/recommended-answers/:recommendedAnswerId');
  }

  fetchAll(params) {
    const answerIdQuery = params?.answerId ? { 'f.aid': params.answerId } : {};
    return super.fetchAll(params, { ...answerIdQuery });
  }
}
