import CountsDtoConverter from 'scripts/application/dto_converters/recommended_answer_count_converter';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';

export default class RecommendedAnswersCountGatewayObserver {
  constructor(context) {
    this.context = context;
    this.errorHandler = new GatewayErrorSilentHandler(this.context);
  }

  get store() {
    return this.context.stores.recommendedAnswersCounts;
  }

  onBroadcast(payload) {
    this.updateStore(payload);
  }

  onFetchSuccess(responseDto) {
    this.store.resetLoading();
    this.updateStore(responseDto);
  }

  onFetchError(error) {
    this.store.resetLoading();
    this.errorHandler.handleCommonErrors(this.context, error);
  }

  updateStore(countsDto) {
    if (countsDto) {
      this.store.set(countsDto.map(count => CountsDtoConverter.fromDto(count)));
    } else {
      this.store.set([]);
    }
  }
}
