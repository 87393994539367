import SaveAndRemoveUnpersistedCompositions from 'actions/composition/save_and_remove_unpersisted_compositions';

/**
 * Logs out user
 *
 * @param {boolean} isManual Whether the agent manually triggers log out via the UI
 */
export default class BeginLogOut {
  constructor(context) {
    this.context = context;
  }

  run(attrs = { isManual: false }) {
    const currentAgent = this.context.stores.currentAgent.getPending() || this.context.stores.currentAgent.get();

    currentAgent && this.context.executeAction(SaveAndRemoveUnpersistedCompositions, { agentId: currentAgent.id });

    if (currentAgent) {
      this.context.analytics.track('Logged Out', { isManual: attrs.isManual });
      this.context.gateways.authentication.logout(currentAgent.id);
    } else {
      this.context.router.navigateHome();
    }
  }
}
