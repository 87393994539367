import React from 'react';
import styled from 'styled-components';

import AnswerPerformanceTable from 'components/answer_performance/answer_performance_table';
import { H1 } from 'components/common/headers';
import PageLayout from 'components/page_layout';

export default function AnswerPerformance() {
  return (
    <PageLayout className="answer-performance-pageWrapper" id="answer-performance">
      <AnswerPerformancePage>
        <H1>Answers Performance</H1>
        <p>
          The table below shows which Public Answers were referenced by Gladly’s AI as it generated a response to a
          Customer inquiry. {/* TODO sc-201756: Update href */}
          <a href="https://help.gladly.com/docs/what-is-answers/" rel="noopener noreferrer" target="_blank">
            Learn more.
          </a>
        </p>
        <AnswerPerformanceTable />
      </AnswerPerformancePage>
    </PageLayout>
  );
}

const AnswerPerformancePage = styled.div`
  margin: 40px 64px;
`;
