import _ from 'lodash';

import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class RecommendedAnswersService {
  constructor(getDatabase, pubsub) {
    this.getDatabase = getDatabase;
    this.pubsub = pubsub;
  }

  fetch(attrs, callback, path, { orgId, recommendedAnswerId }) {
    let recommendedAnswer = _.find(this.getDatabase(orgId).recommendedAnswers, { id: recommendedAnswerId });
    if (!recommendedAnswer) {
      callback(null, {
        status: 404,
        statusText: statusText(404),
        response: {
          errors: [
            {
              code: 'not_exist',
              detail: 'entity does not exist',
            },
          ],
        },
      });
      return;
    }

    callback(null, { status: 200, statusText: statusText(200), response: recommendedAnswer });
  }

  fetchAll(attrs, callback, path, { orgId }, query) {
    const answerId = query['f.aid'];
    const allRecommendedAnswers = this.getDatabase(orgId).recommendedAnswers || [];
    const response = allRecommendedAnswers.filter(recommendedAnswer => recommendedAnswer.answer.id === answerId);

    callback(null, { status: 200, statusText: statusText(200), response });
  }

  getCount(attrs, callback, path, { orgId }, query) {
    const recommendedAnswers = this.getDatabase(orgId).recommendedAnswers || [];
    const answerCount = recommendedAnswers.reduce((acc, recommendedAnswer) => {
      const answerId = recommendedAnswer.answer.id;
      acc[answerId] = (acc[answerId] || 0) + 1;
      return acc;
    }, {});

    const response = Object.keys(answerCount).map(id => ({
      id,
      count: answerCount[id],
    }));

    callback(null, { status: 200, statusText: statusText(200), response });
  }

  setDisposition({ outcome }, callback, path, { orgId, recommendedAnswerId }) {
    _.remove(this.getDatabase(orgId).recommendedAnswers || [], { id: recommendedAnswerId });

    this.pubsub.publish(`v1/orgs/${orgId}/recommended-answers/${recommendedAnswerId}/event/delete`);

    this.pubsub.publish(`v1/orgs/${orgId}/recommended-answers/${recommendedAnswerId}/disposition`, {
      payload: {
        outcome,
      },
    });

    callback(null, { status: 204, statusText: statusText(204) });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/recommended-answers': {
          GET: this.fetchAll,
        },
        '/api/v1/orgs/:orgId/recommended-answers/:recommendedAnswerId': {
          GET: this.fetch,
        },
        '/api/v1/orgs/:orgId/recommended-answers/:recommendedAnswerId/disposition': {
          POST: this.setDisposition,
        },
        '/api/v1/orgs/:orgId/recommended-answers-count': {
          GET: this.getCount,
        },
      },
      this
    );
  }
}
