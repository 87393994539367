import _ from 'lodash';
import createModel, { prop } from './lib/create_model';

import AttributeDef, { AttributePresentation, AttributeType } from './configuration/attribute_def';
import {
  FlightTransactionDef,
  GenericTransactionDef,
  OrderTransactionDef,
  StayTransactionDef,
  SubscriptionTransactionDef,
} from './configuration/transaction_def';
import ProfileCardDef from 'models/configuration/profile_card_def';
import ProfileCardType from 'models/configuration/profile_card_type';
import { transactionDefFromJs, transactionDefTypeReflect } from './configuration/transaction_def_from_js';

const EVENT_TABLE_DEF = {
  attr: String,
  label: String,
  title: { attr: String },
  detail: { attr: String },
  date: { attr: String },
  end: { attr: String },
  activationTopicId: String,
};

const ContactAttributeDef = createModel({
  modelName: 'ContactAttributeDef',

  properties: {
    attr: prop(String).isRequired,
    presentation: prop(String)
      .oneOf(..._.values(AttributePresentation))
      .default(AttributePresentation.EDITABLE),
  },
});

export { EVENT_TABLE_DEF, ContactAttributeDef };

const ExternalLookupResultAttributeDef = createModel({
  modelName: 'ExternalLookupResultAttributeDef',

  properties: {
    attr: prop(String).isRequired,
    label: prop(String).isRequired,
    type: prop(String)
      .oneOf(..._.values(AttributeType))
      .default(AttributeType.STRING),
  },
});

const ExternalLookupSearchFieldDef = createModel({
  modelName: 'ExternalLookupSearchFieldDef',

  properties: {
    attr: prop(String).isRequired,
    label: prop(String).isRequired,
    placeholder: prop(String),
  },
});

export { ExternalLookupResultAttributeDef, ExternalLookupSearchFieldDef };

export default createModel({
  modelName: 'CustomerProfileDef',

  properties: {
    attributes: [AttributeDef],
    cardAttributes: prop([String]),
    contactAttributes: [ContactAttributeDef],
    transaction: prop()
      .oneOf(
        GenericTransactionDef,
        FlightTransactionDef,
        OrderTransactionDef,
        StayTransactionDef,
        SubscriptionTransactionDef
      )
      .fromJs(transactionDefFromJs),

    externalLookupResultAttributes: [ExternalLookupResultAttributeDef],
    externalLookupSearchFields: [ExternalLookupSearchFieldDef],

    profileCards: [ProfileCardDef],

    /* Demo only */

    events: prop(Object).default({}),
    subscriptions: prop(Object).default({}),
    verificationQuestions: EVENT_TABLE_DEF,
    recentEvents: EVENT_TABLE_DEF,
    upcomingEvents: EVENT_TABLE_DEF,
    companyCards: prop(Object).default({}),
    relationships: prop(Object).default({}),
    bulkEvents: prop(Object).default({}),
    payouts: prop(Object).default({}),
  },

  overrideToJs(toJs) {
    return () => {
      let obj = toJs();
      if (this.transaction) {
        obj.transaction.type = transactionDefTypeReflect.instanceToType(this.transaction);
      }
      return obj;
    };
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },

    AttributeDetailLimit: 3,
  },
});

/**
 * Use a "getter" function instead of a static constant declaration in order to
 * make sure the "ID Generator" is fully initialized before we try to create
 * instances of `ProfileCardDef`
 */
function getDefaultProfileCards() {
  return [
    ProfileCardDef.create({ type: ProfileCardType.BASIC_PROFILE }),
    ProfileCardDef.create({ type: ProfileCardType.CONTACT_INFO }),
    ProfileCardDef.create({ type: ProfileCardType.CONVERSATION_LIST }),
    ProfileCardDef.create({ type: ProfileCardType.TRANSACTIONS }),
    ProfileCardDef.create({ type: ProfileCardType.DETAILS }),
    ProfileCardDef.create({ type: ProfileCardType.RELATIONSHIPS }),
    ProfileCardDef.create({ type: ProfileCardType.TASKS }),
  ];
}

export { getDefaultProfileCards };
