import { Factory } from 'rosie';

Factory.define('AITextCompletion')
  .attr('id')
  .attr('data')
  .attr('rewriteId')
  .attr('type');

Factory.define('AITextCompletionTranslate')
  .extend('AITextCompletion')
  .attr('id', 'example-translation-completion-id')
  .attr('data', {
    initialText: 'Hello world!',
    text: 'Bonjour le monde!',
  })
  .attr('rewriteId', 'example-rewrite-id')
  .attr('type', 'translate');
