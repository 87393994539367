import { get, isNil, map } from 'lodash';

import qconsole from 'scripts/lib/qconsole';
import ExternalAppCardData from 'models/external_apps/external_app_card_data';
import Err from 'models/err';
import RequestAppCardData from 'actions/external_apps/request_app_card_data';

/**
 * We debounce the broadcast handler in order to deal with the "feedback loop" that may occur
 * when the app platform runs side by side with the legacy Lookup Adapters.
 */
export const BROADCAST_DEBOUNCE_DELAY = 5 * 1000;

export default class ExternalAppCardDataGatewayObserver {
  constructor(context) {
    this.context = context;
    this.scheduledRequests = {};
  }

  onAddSuccess(dto, params) {
    const { gladlyEntityId: customerId, externalAppCardId } = params || {};

    if (!customerId || !externalAppCardId) {
      this.context.errorReporter.reportError(
        new Error('ExternalAppCardDataGatewayObserver.onAddSuccess: invalid request parameters'),
        {
          extra: { ...params },
          message: 'Missing or invalid parameters: both "gladlyEntityId" and "externalAppCardId" are required',
        }
      );
      return;
    }

    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(
        `ExternalAppCardDataGatewayObserver.onAddSuccess: received data for unloaded customer [${customerId}]. Ignoring.`
      );
      return;
    }

    const data = get(dto, 'data');
    const errors = get(dto, 'errors');
    if (isNil(data) && isNil(errors)) {
      const err = new Error('ExternalAppCardDataGatewayObserver.onAddSuccess: invalid payload');
      this.context.errorReporter.reportError(err, {
        extra: { ...params, payload: dto },
        message: 'Unexpected response: missing or invalid payload',
      });
      return this.onAddError(err, params);
    }

    const store = this.context.stores.customers.storesFor(customerId).externalAppCardData;
    const key = this._getEntityKey(params);
    const convertedErrors = map(errors, errData => new Err(errData));
    const entity = ExternalAppCardData.create({
      id: externalAppCardId,
      externalAppCardId,
      data,
      errors: convertedErrors.length ? convertedErrors : undefined,
    });

    store.addOrReplace(entity);
    store.resetLoading(key);
    store.clearErrorForLoading(key);
  }

  onAddError(err, params) {
    qconsole.error(
      `Failed to fetch External App Card Data. Request params [${JSON.stringify(params)}]`,
      JSON.stringify(err)
    );

    const { gladlyEntityId: customerId, externalAppCardId } = params || {};
    if (!customerId || !externalAppCardId) {
      this.context.errorReporter.reportError(
        new Error('ExternalAppCardDataGatewayObserver.onAddError: invalid request parameters'),
        {
          extra: { ...params },
          message: 'Missing or invalid parameters: both "gladlyEntityId" and "externalAppCardId" are required',
        }
      );
      return;
    }

    const store = this.context.stores.customers.storesFor(customerId).externalAppCardData;
    const key = this._getEntityKey(params);
    store.setErrorForLoading(
      new Err({
        code: Err.Code.UNEXPECTED_ERROR,
        detail: err?.message || err,
      }),
      key
    );
    store.resetLoading(key);
  }

  /**
   * This particular broadcast does not receive any payload so the first parameter
   * is always `null`
   */
  onBroadcastDelete(payload, topicParams) {
    const { gladlyEntityId, gladlyEntityType } = topicParams || {};
    if (!gladlyEntityId || !gladlyEntityType) {
      return this.context.errorReporter.reportError(
        new Error(
          'ExternalAppCardDataGatewayObserver.onBroadcastDelete: missing or invalid parameters. Ignoring broadcast.'
        ),
        {
          extra: { ...topicParams },
          message: 'Missing or invalid parameters',
        }
      );
    }

    // Express our interest in the data refresh for this ID
    this._scheduleRefresh(gladlyEntityId, gladlyEntityType);
  }

  _getEntityKey(params) {
    const { externalAppCardId } = params;
    return {
      requester: externalAppCardId,
    };
  }

  _isCustomerLoaded(customerId) {
    return !!customerId && this.context.stores.customers.has({ id: customerId });
  }

  _scheduleRefresh(gladlyEntityId, gladlyEntityType) {
    const key = `${gladlyEntityType}:${gladlyEntityId}`;
    const requestRefresh = (entityId, entityType) => {
      this.context.executeAction(RequestAppCardData, {
        forceDataRefresh: true,
        gladlyEntityId: entityId,
        gladlyEntityType: entityType,
      });

      delete this.scheduledRequests[key];
    };

    if (!this.scheduledRequests[key]) {
      this.scheduledRequests[key] = setTimeout(
        () => requestRefresh(gladlyEntityId, gladlyEntityType),
        BROADCAST_DEBOUNCE_DELAY
      );
    }
  }
}
