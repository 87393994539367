import React from 'react';
import connect from 'components/lib/connect';
import BubbleContent from '../content/bubble_content';
import classnames from 'classnames';
import ConversationItem from 'models/conversation_item';
import InlineAnnotatedContentContainer from 'components/lib/inline_annotated_content_container';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export function AutomationSummaryContent(props) {
  let summary = props.item.content.summary.description;

  try {
    summary = JSON.parse(summary);
    // eslint-disable-next-line
  } catch (e) {}

  if (typeof summary === 'object') {
    return (
      <BubbleContent className={classnames('automationItem-content', props.className)} item={props.item}>
        <Bold>
          <InlineAnnotatedContentContainer id={props.item.id} key={props.item.id} text="Summary" />
        </Bold>
        <InlineAnnotatedContentContainer
          id={props.item.id}
          key={props.item.id}
          text={summary.summary || 'Summary unavailable'}
        />
      </BubbleContent>
    );
  }

  return (
    <BubbleContent className={classnames('automationItem-content', props.className)} item={props.item}>
      <Bold>
        <InlineAnnotatedContentContainer id={props.item.id} key={props.item.id} text="Summary" />
      </Bold>
      <InlineAnnotatedContentContainer id={props.item.id} key={props.item.id} text={summary} />
    </BubbleContent>
  );
}

AutomationSummaryContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
};
export default connect(mapStateToProps)(AutomationSummaryContent);

function mapStateToProps(context, props) {
  return {
    ...props,
  };
}

const Bold = styled.span`
  font-weight: bold;
`;
