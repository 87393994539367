import { filter, find, forEach, isEmpty, isNil, get } from 'lodash';

import ExternalAppCardData from 'models/external_apps/external_app_card_data';
import ProfileCardType from 'models/configuration/profile_card_type';
import qconsole from 'scripts/lib/qconsole';

/**
 * This action tries to [re]request the data for all EXTERNAL_APP_CARD cards.
 *
 * It checks whether there is a corresponding entity in the `externalAppCardTemplates`
 * store (that would mean the card points to an app that has enabled configuration(s))
 * and then request the data for that card.
 *
 * Currently, we can only handle CUSTOMER data (i.e. `gladlyEntityType` === "CUSTOMER")
 */
export default class RequestAppCardData {
  constructor(context) {
    this.context = context;
  }

  run(params) {
    const { gladlyEntityId: customerId, gladlyEntityType, forceDataRefresh } = params || {};

    if (!customerId || gladlyEntityType !== ExternalAppCardData.EntityType.CUSTOMER) {
      this.context.errorReporter.reportError(Error('RequestAppCardData: invalid parameters'), {
        extra: {
          gladlyEntityId: customerId,
          gladlyEntityType,
        },
        message: 'Missing or invalid "gladlyEntityId" and/or "gladlyEntityType"',
      });
      return;
    }

    // Make sure we have all the stores we need before proceeding
    if (!this._isCustomerLoaded(customerId) || this._isLoading() || this._isLoadingError()) return;

    // Do we need to do anything?
    const profileDef = this.context.stores.customerProfileDef.get();
    const profileCards = get(profileDef, 'profileCards');
    const appCards = filter(profileCards, card => card.type === ProfileCardType.EXTERNAL_APP_CARD);
    const appCardTemplates = this.context.stores.externalAppCardTemplates.findAll();
    if (isEmpty(appCards) || isEmpty(appCardTemplates)) return;

    // Yes we do... Iterate through app card templates and request the data
    const dataStore = this.context.stores.customers.storesFor(customerId).externalAppCardData;
    forEach(appCardTemplates, cardTemplateInfo => {
      const templateHasErrors = !isEmpty(cardTemplateInfo.errors);
      if (templateHasErrors) return;

      const cardId = cardTemplateInfo.externalAppCardId;
      const entityKey = this._getEntityKey(cardId);
      if (dataStore.isLoading(entityKey)) return;

      // See if we already have data for this card
      if (!forceDataRefresh && dataStore.has({ id: cardId })) {
        const entity = dataStore.find(cardId); // This is an instance of ExternalAppCardData
        const hasData = !isNil(entity.data);
        const hasErrors = !isEmpty(entity.errors);

        // Looks like we don't need to reload this one
        if (hasData && !hasErrors) return;
      }

      // Cross-check if we have an app card for this template
      const profileCard = find(appCards, card => get(card, 'properties.cardParameters.externalAppCardId') === cardId);
      if (!profileCard) {
        qconsole.error(`RequestAppCardData: unable to find a profile card for ID ${cardId}`);
        return;
      }

      // Now request the data
      this.context.gateways.externalAppCardData.requestData(
        {
          externalAppCardId: cardId,
          gladlyEntityId: customerId,
          gladlyEntityType,
        },
        {
          externalAppCardId: cardId,
          forceRefresh: !!forceDataRefresh,
          variables: {
            gladlyEntityId: customerId,
            gladlyEntityType,
          },
        }
      );
      dataStore.setLoading({ requester: cardId });
    });
  }

  _getEntityKey(cardId) {
    return { requester: cardId };
  }

  _isCustomerLoaded(customerId) {
    return !!customerId && this.context.stores.customers.has({ id: customerId });
  }

  _isLoading() {
    return (
      this.context.stores.customerProfileDef.isLoading() || this.context.stores.externalAppCardTemplates.isLoading()
    );
  }

  _isLoadingError() {
    return (
      !isEmpty(this.context.stores.customerProfileDef.getErrors()) ||
      !isEmpty(this.context.stores.externalAppCardTemplates.getErrorForLoading())
    );
  }
}
