import classnames from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { GRAYS } from 'components/common/colors';
import LoadingSpinner from 'components/lib/loading_spinner';
import SearchInput from 'components/common/search_input';

export default class SearchableMenu extends PureComponent {
  render() {
    return (
      <div
        className={classnames('searchableMenu', this.props.className)}
        data-aid={this.props['data-aid'] || 'searchableMenu'}
      >
        <div className="searchableMenu-header">{this.props.title}</div>
        <div className="searchableMenu-searchInputWrapper">
          <SearchInput
            className="searchableMenu-searchInput"
            delay={300}
            onChange={this.props.onSearch}
            onClearText={this.props.onClearSearch}
            placeholder={this.props.searchPlaceholder}
          />
        </div>
        {this.renderResults()}
        {this.renderFooter()}
      </div>
    );
  }

  renderResults() {
    if (this.props.isLoading) {
      return (
        <div className="searchableMenu-loadingResults">
          <LoadingSpinner color={GRAYS.xlight} position="relative" size="32px" />
        </div>
      );
    } else if (this.props.placeholder) {
      return <div className="searchableMenu-resultsPlaceholder">{this.props.placeholder}</div>;
    }
    return React.cloneElement(this.props.searchResults, {
      className: 'searchableMenu-resultsContainer',
      ref: node => (this.results = node),
    });
  }

  renderFooter() {
    return this.props.footer;
  }

  resetScrolling() {
    if (this.results) {
      this.results.scrollTop = 0;
    }
  }
}

SearchableMenu.propTypes = {
  className: PropTypes.string,
  conversationId: PropTypes.string,
  footer: PropTypes.element,
  isLoading: PropTypes.bool,
  onClearSearch: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.node,
  searchPlaceholder: PropTypes.string,
  searchResults: PropTypes.element.isRequired,
  title: PropTypes.string,
};
