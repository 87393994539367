import AbandonedCall from 'models/abandoned_call';
import Account from 'models/account';
import ActiveCall from 'models/active_call';
import ActiveSession, { SessionCustomer } from 'models/active_session';
import Agent from 'models/agent';
import AgentAssistanceConfig from 'models/agent_assistance_config';
import AgentInboxItem from 'models/agent_inbox/agent_inbox_item';
import AgentProfile from 'models/agent_profile';
import AgentProfileEditor from 'models/location/agent_profile_editor';
import AgentRead from 'models/agent_read';
import AgentRoutingPreferences from 'models/agent_routing_preferences';
import AgentSearchHit from 'models/search/agent_search_hit';
import AgentSearchResult from 'models/search/agent_search_result';
import AgentsLiveboard from 'models/liveboards/agents_liveboard';
import AgentStatus from 'models/agent_status';
import AgentStatusReason from 'models/agent_status_reason';
import AgentVoiceConfiguration from 'models/agent_voice_configuration';
import AIActivityItem from 'models/ai_activity/ai_activity_item';
import AnswerPanel from 'models/answer_panel';
import AnswerPanelState, { DirectAnswerLink } from 'models/answer_panel_state';
import AnswerPerformance from 'models/answer_performance';
import AnswerPerformanceView from 'models/location/answer_performance_view';
import AnswerPreview from 'models/modal/answer_preview';
import AnswerSuggestions from 'models/answer_suggestions';
import ApiToken from 'models/api_token';
import ApiTokens from 'models/location/api_tokens';
import AppFeatures from 'models/app_features';
import Assignee from 'models/assignee';
import Attachment from 'models/attachment';
import Audience from 'models/audience';
import Auth from 'models/auth';
import AutoAcceptedSessionNotification from 'models/notification/auto_accepted_session_notification';
import AvailableLanguage from 'models/available_language';
import Boot from 'models/location/boot';
import CallForward from 'models/call_forward';
import ChannelConfiguration from 'models/channel_configuration';
import ChatCompositionContent from 'models/composition/chat_composition_content';
import ChatCustomerUrl from 'models/chat_customer_url';
import ChatEnded from 'models/chat_ended';
import ChatMessage from 'models/chat_message';
import ChatSelfServiceMetadata from 'models/chat_self_service_metadata';
import ChatWidgetConfiguration from 'models/chat_widget_configuration';
import CommunicationQueue from 'models/communication_queue';
import CompanyProfile from 'models/company_profile';
import Composition from 'models/composition';
import Conversation from 'models/conversation';
import ConversationCustomAttributeChange from 'models/conversation_custom_attribute_change';
import ConversationItem from 'models/conversation_item';
import ConversationItemSearchHit from 'models/search/conversation_item_search_hit';
import ConversationMessage from 'models/conversation_message';
import ConversationMessageAttachment from 'models/conversation_message_attachment';
import ConversationMessageCompositionContent from 'models/composition/conversation_message_composition_content';
import ConversationNote from 'models/conversation_note';
import ConversationNotification from 'models/notification/conversation_notification';
import ConversationPreview from 'models/conversation_preview';
import ConversationStatusChange from 'models/conversation_status_change';
import ConversationTopicChange from 'models/conversation_topic_change';
import ConversationWorkflowConfig from 'models/conversation_workflow_config';
import EmbedTokensReport from 'models/reports/embed_tokens_report';
import CreditCardCompositionContent from 'models/composition/credit_card_composition_content';
import CustomAttribute, { CustomAttributeValue } from 'models/custom_attribute';
import CustomChannel from 'models/custom_channel';
import CustomChannelMessage from 'models/custom_channel_message';
import CustomChannelMessageAttachment from 'models/custom_channel_message_attachment';
import Customer from 'models/customer';
import CustomerActivity from 'models/customer_activity';
import CustomerAssignee from 'models/customer_assignee';
import CustomerAssigneeChange, { CustomerAssigneeValue } from 'models/customer_assignee_change';
import CustomerCombine from 'models/location/customer_combine';
import CustomerEmailAddress from 'models/customer_email_address';
import CustomerUsernameAddress from 'models/customer_username_address';
import CustomerMatch from 'models/customer_match';
import CustomerMerge from 'models/customer_merge';
import CustomerNote from 'models/customer_note';
import CustomerProfile from 'models/customer_profile';
import CustomerProfileSearchHit from 'models/search/customer_profile_search_hit';
import CustomerView from 'models/location/customer_view';
import DeclinedReassignedSessionNotification from 'models/notification/declined_reassigned_session_notification';
import DisconnectNotification from 'models/notification/disconnect_notification';
import EditableUser from 'models/editable_user';
import EmailCampaignOutreach from 'models/email_campaign_outreach';
import EmailCompositionContent from 'models/composition/email_composition_content';
import EmailMessage from 'models/email_message';
import EmailPreview from 'models/content_preview/email_preview';
import EmailSendFailure from 'models/email_send_failure';
import EmailSuggestion from 'models/email_suggestion';
import EmbeddedReport from 'models/reports/embedded_report';
import Endpoint from 'models/endpoint';
import ErrorNotification from 'models/notification/error_notification';
import ExternalCustomerLookup from 'models/location/external_customer_lookup';
import ExternalCustomerProfile, { ExternalCustomerLookupResult } from 'models/external_customer_profile';
import ExternalDataObjectEnvelope from 'models/external_data_objects/external_data_object_envelope';
import ExternalFormCompositionContent from 'models/composition/external_form_composition_content';
import FbMessageCompositionContent from 'models/composition/fb_message_composition_content';
import FbMessageIncoming, { FbMessage } from 'models/fb_message_incoming';
import FbMessageIncomingAttachment from 'models/fb_message_incoming_attachment';
import FbMessageOutgoing, { FbMessageRequest, FbMessageResponse, FbMessageSent } from 'models/fb_message_outgoing';
import FbMessageOutgoingAttachment from 'models/fb_message_outgoing_attachment';
import FbMessengerUser from 'models/fb_messenger_user';
import FbMessengerUserId from 'models/customer_profile/fb_messenger_user_id';
import FbPage from 'models/fb_page';
import FbPageAccountContent from 'models/fb_page_account_content';
import FbPageAccountSecretContent from 'models/fb_page_account_secret_content';
import FbUserProfile from 'models/customer_profile/fb_user_profile';
import ForgotPassword from 'models/location/forgot_password';
import FocusModeDisabledNotification from 'models/notification/focus_mode_disabled_notification';
import GreetingSuggestion from 'models/greeting_suggestion';
import HelpAppContext from 'models/help_app_context';
import HotelReservation from 'models/hotel_reservation';
import InboundCommunicationSessionNotification from 'models/notification/inbound_communication_session_notification';
import InboundMessageNotification from 'models/notification/inbound_message_notification';
import Inbox from 'models/location/inbox';
import InboxCount, { UnreadConversation } from 'models/inbox_count';
import Integration from 'models/integration';
import ItemId from 'models/item_id';
import ItemLinkContent from 'models/item_link_content';
import Ivr from 'models/ivr/ivr';
import IvrNode from 'models/ivr/ivr_node';
import KbAdmin from 'models/location/kb_admin';
import KbVariable from 'models/kb_variable';
import KeywordGroup from 'models/keyword_group';
import Language from 'models/language';
import LinkedItem from 'models/linked_item';
import Liveboards from 'models/location/liveboards';
import LocationHistory from 'models/location_history';
import MarketingEmail from 'models/marketing_email';
import MessageAutomationSummaryContent, { MessageAutomationItemContent } from 'models/message_automation_content';
import MessageContentTypeFile from 'models/message_content_type_file';
import MessageContentTypeImage from 'models/message_content_type_image';
import MessageContentTypeLocation from 'models/message_content_type_location';
import MessageContentTypeText from 'models/message_content_type_text';
import MessagingConfiguration from 'models/messaging_configuration';
import MobileContext from 'models/mobile_context';
import NoReplyNeeded from 'models/no_reply_needed';
import NoteCompositionContent from 'models/composition/note_composition_content';
import PhoneCallObserverNotification from 'models/notification/phone_call_observer_notification';
import OfferedCallNotification from 'models/notification/offered_call_notification';
import OfferedWarmTransferNotification from 'models/notification/offered_warm_transfer_notification';
import OptionalUpgradeNotification from 'models/notification/optional_upgrade_notification';
import PhoneCall, { Transfer } from 'models/phone_call';
import PhoneCallback from 'models/phone_callback';
import PhoneCallbackCompositionContent from 'models/composition/phone_callback_composition_content';
import PhoneCallCompositionContent from 'models/composition/phone_call_composition_content';
import PhoneNumber from 'models/phone_number';
import PhraseSuggestion from 'models/phrase_suggestion';
import ProactiveChatContent from 'models/proactive_chat_content';
import RecommendedAnswer from 'models/recommended_answer';
import RecommendedAnswerCount from 'models/recommended_answer_count';
import Relationship from 'models/relationship';
import RelationshipLabel from 'models/relationship_label';
import ReportBuilderConfig from 'models/report_builder_config';
import { ReportConfig } from 'models/reports/report_configs';
import Reports from 'models/location/reports';
import RecordingSummary, { RecordingSummaryStatus } from 'models/recording_summary';
import RoutableCounts from 'models/routable_counts';
import RoutingGroup from 'models/routing_group';
import RoutingQueueItem, { RoutingSession } from 'models/routing_queue_item';
import RuleDisabledLoop from 'models/rule_disabled_loop';
import RuleScore from 'models/rule_score';
import Search from 'models/location/search';
import SearchAggregations from 'models/search/search_aggregations';
import SearchQuery from 'models/search/search_query';
import SearchResult from 'models/search/search_result';
import SecureDataRequest from 'models/secure_data_request';
import SelectedKbItem from 'models/selected_kb_item';
import SelectedSuggestedReply from 'models/selected_suggested_reply';
import SessionEnded from 'models/session_ended';
import Sla from 'models/sla';
import SmartMatchDetail from 'models/routing_item/smart_match_detail';
import SmartMatchLiveboard from 'models/liveboards/smart_match_liveboard';
import SmsCampaignOutreach from 'models/sms_campaign_outreach';
import SmsCompositionContent from 'models/composition/sms_composition_content';
import SmsMessage from 'models/sms_message';
import SmsMessageAttachment from 'models/sms_message_attachment';
import Snippet, { SnippetChannels, SnippetContent } from 'models/answers/snippet';
import SnippetEditor from 'models/location/snippet_editor';
import SnippetRevision from 'models/snippet_revision';
import SnippetSearchResult from 'models/search/snippet_search_result';
import StationConfiguration from 'models/station_configuration';
import SuggestedReply, { AppliedSnippetRefs, SnippetDetails } from 'models/suggested_reply';
import SummaryLiveboard from 'models/liveboards/summary_liveboard';
import SystemConfiguration from 'models/system_configuration';
import Task from 'models/task';
import TaskCompletionContent from 'models/task_completion_content';
import TaskComment from 'models/task_comment';
import TaskCompositionContent from 'models/composition/task_composition_content';
import TaskCompositionEditContent from 'models/composition/task_composition_edit_content';
import TaskDueContent from 'models/task_due_content';
import TaskEditedContent from 'models/task_edited_content';
import TaskFollower from 'models/task_follower';
import TaskInfo from 'models/task_info';
import TaskNotification from 'models/notification/task_notification';
import TaskPreview from 'models/task_preview';
import TaskReopenedContent from 'models/task_reopened_content';
import TaskRouting from 'models/task_routing';
import Team from 'models/team';
import TeamEditor from 'models/location/team_editor';
import Toast from 'models/toast_deprecated';
import Topic from 'models/topic';
import TopicAncestry from 'models/topic_ancestry';
import TopicsLiveboard, { TopicsStats } from 'models/liveboards/topics_liveboard';
import TopicSuggestions from 'models/topic_suggestions';
import Transaction from 'models/transaction';
import Translation from 'models/translation';
import Tweet from 'models/tweet';
import UnofferedCallNotification from 'models/notification/unoffered_call_notification';
import UnofferedWarmTransferNotification from 'models/notification/unoffered_warm_transfer_notification';
import Upload from 'models/upload';
import User from 'models/user';
import UserActivation from 'models/location/user_activation';
import UserEditor from 'models/location/user_editor';
import UserLogin from 'models/location/user_login';
import UserResetPassword from 'models/location/user_reset_password';
import UsersAdmin from 'models/location/users_admin';
import VgsAccountContent, { VgsSecretContent } from 'models/vgs_account_content';
import VoiceCampaignOutreach from 'models/voice_campaign_outreach';
import VoiceCampaignOutreachResult from 'models/voice_campaign_outreach_result';
import VoiceConfiguration from 'models/voice_configuration';
import Voicemail from 'models/voicemail';
import VoiceRecordingDeleted from 'models/voice_recording_deleted';
import WebContext from 'models/web_context';
import WebForm from 'models/web_form';
import Webhook from 'models/webhook';
import WidgetActivity from 'models/widget_activity';
import WidgetConfiguration, { WidgetConfigSettings, WidgetConfigParameter } from 'models/widget_configuration';
import AITextCompletion from 'models/ai_text_completion';

export default Object.freeze({
  AbandonedCall,
  AbandonedCallItem: ConversationItem,
  AbandonedCallItemWithDefaults: ConversationItem,
  AbandonedCallWithDefaults: AbandonedCall,
  Account,
  AccountWithDefaults: Account,
  ActiveCall,
  ActiveCallWithDefaults: ActiveCall,
  ActiveSession,
  ActiveSessionWithDefaults: ActiveSession,
  Agent,
  AgentAssistanceConfig,
  AgentInboxItem,
  AgentInboxItemWithDefaults: AgentInboxItem,
  AgentProfile,
  AgentProfileEditor,
  AgentProfileEditorWithDefaults: AgentProfileEditor,
  AgentProfileWithDefaults: AgentProfile,
  AgentRead,
  AgentReadWithDefaults: AgentRead,
  AgentRoutingPreferences,
  AgentRoutingPreferencesNone: AgentRoutingPreferences,
  AgentSearchHit,
  AgentSearchHitWithDefaults: AgentSearchHit,
  AgentSearchResult,
  AgentSearchResultWithDefaults: AgentSearchResult,
  AgentStatus,
  AgentStatusAway: AgentStatus,
  AgentStatusBusy: AgentStatus,
  AgentStatusOffline: AgentStatus,
  AgentStatusReady: AgentStatus,
  AgentStatusReadyForMessaging: AgentStatus,
  AgentStatusWithDefaults: AgentStatus,
  AgentStatusReason,
  ActiveAgentStatusReasonWithDefaults: AgentStatusReason,
  AwayAgentStatusReasonWithDefaults: AgentStatusReason,
  AgentVoiceConfiguration,
  AgentVoiceConfigurationDirectDial: AgentVoiceConfiguration,
  AgentVoiceConfigurationSipStation: AgentVoiceConfiguration,
  AgentVoiceConfigurationStation: AgentVoiceConfiguration,
  AgentVoiceConfigurationWebRtc: AgentVoiceConfiguration,
  AgentVoiceConfigurationWithDefaults: AgentVoiceConfiguration,
  AgentWithDefaults: Agent,
  AgentsLiveboard,
  AgentsLiveboardWithDefaults: AgentsLiveboard,
  AIConversationSummaryWithDefaults: AIActivityItem,
  AIConversationSummaryItemWithDefaults: ConversationItem,
  AITextCompletion,
  AITextCompletionTranslate: AITextCompletion,
  AnswerPanel,
  AnswerPanelWithDefaults: AnswerPanel,
  AnswerPanelState,
  AnswerPanelStateWithDefaults: AnswerPanelState,
  AnswerPerformance,
  AnswerPerformanceWithDefaults: AnswerPerformance,
  AnswerPerformanceView,
  AnswerPerformanceViewWithDefaults: AnswerPerformanceView,
  AnswerPreview,
  AnswerPreviewWithDefaults: AnswerPreview,
  AnswerSuggestions,
  AnswerSuggestionsWithDefaults: AnswerSuggestions,
  ApiToken,
  ApiTokenWithDefaults: ApiToken,
  ApiTokens,
  ApiTokensWithDefaults: ApiTokens,
  AppFeatures,
  AppFeaturesLoaded: AppFeatures,
  Assignee,
  AssigneeWithAgentDefaults: Assignee,
  AssigneeWithGroupDefaults: Assignee,
  Attachment,
  Audience,
  AudienceWithDefaults: Audience,
  Auth,
  AuthWithDefaults: Auth,
  AutoAcceptedSessionNotification,
  AutoAcceptedSessionNotificationWithDefaults: AutoAcceptedSessionNotification,
  MessageAutomationSummaryContent,
  MessageAutomationSummaryContentWithDefaults: MessageAutomationSummaryContent,
  MessageAutomationItemContent,
  MessageAutomationItemContentWithDefaults: MessageAutomationItemContent,
  AvailableLanguage,
  AvailableLanguageWithDefaults: AvailableLanguage,
  Boot,
  BootWithDefaults: Boot,
  BouncedEmailMessageItem: ConversationItem,
  CallForward,
  CallForwardItem: ConversationItem,
  CallForwardItemWithDefaults: ConversationItem,
  CallForwardWithDefaults: CallForward,
  ChannelConfiguration,
  ChannelConfigurationWithDefaults: ChannelConfiguration,
  ChatCompositionContent,
  ChatCustomerUrlItemWithDefaults: ConversationItem,
  ChatCustomerUrlWithDefaults: ChatCustomerUrl,
  ChatEnded,
  ChatEndedItemWithDefaults: ConversationItem,
  ChatEndedWithDefaults: ChatEnded,
  ChatEndpoint: Endpoint,
  ChatMessage,
  ChatMessageItem: ConversationItem,
  ChatMessageItemWithDefaults: ConversationItem,
  ChatMessageWithDefaults: ChatMessage,
  ChatRoutingSessionWithDefaults: RoutingSession,
  ChatSelfServiceMetadata,
  ChatSelfServiceMetadataItemWithDefaults: ConversationItem,
  ChatSelfServiceMetadataWithDefaults: ChatSelfServiceMetadata,
  ChatWidgetConfiguration,
  ChatWidgetConfigurationWithDefaults: ChatWidgetConfiguration,
  CommunicationQueue,
  CommunicationQueueWithDefaults: CommunicationQueue,
  CompanyProfile,
  CompanyProfileWithDefaults: CompanyProfile,
  Composition,
  CompositionWithDefaults: Composition,
  CompositionWithDefaultsAndAppliedSuggestedReply: Composition,
  ContentPlaceholderItem: ConversationItem,
  Conversation,
  ConversationCustomAttributeChange,
  ConversationCustomAttributeChangeItem: ConversationItem,
  ConversationCustomAttributeChangeItemWithDefaults: ConversationItem,
  ConversationCustomAttributeChangeItemWithAPIInitiator: ConversationItem,
  ConversationCustomAttributeChangeWithDefaults: ConversationCustomAttributeChange,
  ConversationItem,
  ConversationItemSearchHit,
  ConversationItemSearchHitWithDefaults: ConversationItemSearchHit,
  ConversationItemWithDefaults: ConversationItem,
  ConversationItemWithoutConversationId: ConversationItem,
  ConversationMessage,
  ConversationMessageAttachment,
  ConversationMessageAttachmentWithDefaults: ConversationMessageAttachment,
  ConversationMessageCompositionContent,
  ConversationMessageItem: ConversationItem,
  ConversationMessageItemWithDefaults: ConversationItem,
  ConversationMessageWithDefaults: ConversationMessage,
  ConversationNote,
  ConversationNoteItem: ConversationItem,
  ConversationNoteItemWithDefaults: ConversationItem,
  ConversationNoteWithDefaults: ConversationNote,
  ConversationNotification,
  ConversationNotificationWithAbandonedCall: ConversationNotification,
  ConversationNotificationWithChatMessage: ConversationNotification,
  ConversationNotificationWithCompletedCall: ConversationNotification,
  ConversationNotificationWithDefaults: ConversationNotification,
  ConversationNotificationWithEmail: ConversationNotification,
  ConversationNotificationWithEmailSendFailure: ConversationNotification,
  ConversationNotificationWithFbMessage: ConversationNotification,
  ConversationNotificationWithNote: ConversationNotification,
  ConversationNotificationWithPhone: ConversationNotification,
  ConversationNotificationWithSms: ConversationNotification,
  ConversationNotificationWithTask: ConversationNotification,
  ConversationNotificationWithVoicemail: ConversationNotification,
  ConversationPreview,
  ConversationPreviewWithDefaults: ConversationPreview,
  ConversationStatusChange,
  ConversationStatusChangeItem: ConversationItem,
  ConversationStatusChangeItemWithDefaults: ConversationItem,
  ConversationStatusChangeWithDefaults: ConversationStatusChange,
  ConversationTopicChange,
  ConversationTopicChangeItem: ConversationItem,
  ConversationTopicChangeItemWithDefaults: ConversationItem,
  ConversationTopicChangeItemWithRuleInitiator: ConversationItem,
  ConversationTopicChangeWithDefaults: ConversationTopicChange,
  ConversationWithDefaults: Conversation,
  ConversationWithEmail: Conversation,
  ConversationWorkflowConfig,
  EmbedTokensReport,
  EmbedTokensReportWithDefaults: EmbedTokensReport,
  CreditCardCompositionContent,
  CreditCardCompositionContentWithDefaults: CreditCardCompositionContent,
  CreditCardViewedContentItemWithDefaults: ConversationItem,
  CustomAttribute,
  CustomAttributeValue,
  CustomAttributeValueWithDefaults: CustomAttributeValue,
  CustomAttributeWithDefaults: CustomAttribute,
  CustomChannel,
  CustomChannelWithDefaults: CustomChannel,
  CustomChannelMessage,
  CustomChannelMessageItem: ConversationItem,
  CustomChannelMessageWithDefaults: CustomChannelMessage,
  CustomChannelMessageItemWithDefaults: ConversationItem,
  CustomChannelMessageIncomingAttachmentWithDefaults: CustomChannelMessageAttachment,
  CustomChannelMessageOutgoingAttachmentWithDefaults: CustomChannelMessageAttachment,
  CustomChannelEndpoint: Endpoint,
  Customer,
  CustomerActivityContent: CustomerActivity,
  CustomerActivityContentItem: ConversationItem,
  CustomerActivityContentItemWithDefaults: ConversationItem,
  CustomerActivityContentWithDefaults: CustomerActivity,
  CustomerAssignee,
  CustomerAssigneeChange,
  CustomerAssigneeChangeForAdd: CustomerAssigneeChange,
  CustomerAssigneeChangeForDelete: CustomerAssigneeChange,
  CustomerAssigneeChangeItem: ConversationItem,
  CustomerAssigneeChangeItemForAdd: ConversationItem,
  CustomerAssigneeChangeItemForDelete: ConversationItem,
  CustomerAssigneeChangeItemWithDefaults: ConversationItem,
  CustomerAssigneeChangeWithDefaults: CustomerAssigneeChange,
  CustomerAssigneeValue,
  CustomerAssigneeValueWithDefaults: CustomerAssigneeValue,
  CustomerAssigneeWithDefaults: CustomerAssignee,
  CustomerCombine,
  CustomerCombineWithDefaults: CustomerCombine,
  CustomerEmailAddress,
  CustomerEmailAddressPrimary: CustomerEmailAddress,
  CustomerEmailAddressWithDefaults: CustomerEmailAddress,
  CustomerMatch,
  CustomerMatchWithDefaults: CustomerMatch,
  CustomerMerge,
  CustomerMergeWithDefaults: CustomerMerge,
  CustomerMergeItem: ConversationItem,
  CustomerMergeItemWithDefaults: ConversationItem,
  CustomerNote,
  CustomerNoteWithDefaults: CustomerNote,
  CustomerProfile,
  CustomerProfileSearchHit,
  CustomerProfileSearchHitWithDefaults: CustomerProfileSearchHit,
  CustomerProfileWithDefaults: CustomerProfile,
  CustomerTransactionWithDefaults: Transaction,
  CustomerUsernameAddress,
  CustomerUsernameAddressWithDefaults: CustomerUsernameAddress,
  CustomerView,
  CustomerViewWithDefaults: CustomerView,
  CustomerWithDefaults: Customer,
  CustomerWithoutConversations: Customer,
  DeclinedReassignedSessionNotification,
  DirectAnswerLink,
  DirectAnswerLinkWithDefaults: DirectAnswerLink,
  DisconnectNotification,
  DisconnectNotificationNetwork: DisconnectNotification,
  DisconnectNotificationServer: DisconnectNotification,
  DisconnectNotificationWithDefaults: DisconnectNotification,
  EditableUser,
  EmailCompositionContent,
  EmailEndpoint: Endpoint,
  EmailCampaignOutreach,
  EmailCampaignOutreachItem: ConversationItem,
  EmailCampaignOutreachItemWithDefaults: ConversationItem,
  EmailCampaignOutreachWithDefaults: EmailCampaignOutreach,
  EmailMessage,
  EmailMessageItem: ConversationItem,
  EmailMessageItemWithDefaults: ConversationItem,
  EmailMessageWithDefaults: EmailMessage,
  EmailPreview,
  EmailPreviewItem: ConversationItem,
  EmailPreviewItemWithDefaults: ConversationItem,
  EmailPreviewWithDefaults: EmailPreview,
  EmailSendFailure,
  EmailSendFailureItem: ConversationItem,
  EmailSendFailureItemWithDefaults: ConversationItem,
  EmailSendFailureWithDefaults: EmailSendFailure,
  EmailSuggestion,
  EmailSuggestionWithDefaults: EmailSuggestion,
  EmbeddedReport,
  EmbeddedReportWithDefaults: EmbeddedReport,
  Endpoint,
  EndpointWithDefaults: Endpoint,
  ErrorNotification,
  ErrorNotificationWithDefaults: ErrorNotification,
  ExternalCustomerLookup,
  ExternalCustomerLookupResult,
  ExternalCustomerLookupResultWithDefaults: ExternalCustomerLookupResult,
  ExternalCustomerLookupWithDefaults: ExternalCustomerLookup,
  ExternalCustomerProfile,
  ExternalCustomerProfileWithDefaults: ExternalCustomerProfile,
  ExternalDataObjectWithDefaults: ExternalDataObjectEnvelope,
  ExternalFormCompositionContent,
  ExternalFormCompositionContentWithDefaults: ExternalFormCompositionContent,
  FbMessage,
  FbMessageCompositionContent,
  FbMessageCompositionContentWithDefaults: FbMessageCompositionContent,
  FbMessageIncoming,
  FbMessageIncomingAttachmentWithDefaults: FbMessageIncomingAttachment,
  FbMessageIncomingItem: ConversationItem,
  FbMessageIncomingItemWithDefaults: ConversationItem,
  FbMessageIncomingItemWithText: ConversationItem,
  FbMessageIncomingWithDefaults: FbMessageIncoming,
  FbMessageIncomingWithText: FbMessageIncoming,
  FbMessageOutgoing,
  FbMessageOutgoingAttachment,
  FbMessageOutgoingAttachmentWithDefaults: FbMessageOutgoingAttachment,
  FbMessageOutgoingItem: ConversationItem,
  FbMessageOutgoingItemWithDefaults: ConversationItem,
  FbMessageOutgoingItemWithText: ConversationItem,
  FbMessageOutgoingWithDefaults: FbMessageOutgoing,
  FbMessageOutgoingWithText: FbMessageOutgoing,
  FbMessageRequest,
  FbMessageRequestWithDefaults: FbMessageRequest,
  FbMessageRequestWithText: FbMessageRequest,
  FbMessageResponse,
  FbMessageResponseSent: FbMessageResponse,
  FbMessageResponseWithDefaults: FbMessageResponse,
  FbMessageSent,
  FbMessageSentWithText: FbMessageSent,
  FbMessageWithText: FbMessage,
  FbMessengerEndpointWithDefaults: Endpoint,
  FbMessengerUser,
  FbMessengerUserId,
  FbMessengerUserIdWithDefaults: FbMessengerUserId,
  FbMessengerUserWithDefaults: FbMessengerUser,
  FbPage,
  FbPageAccountContent,
  FbPageAccountContentWithDefaults: FbPageAccountContent,
  FbPageAccountSecretContent,
  FbPageAccountSecretContentWithDefaults: FbPageAccountSecretContent,
  FbPageWithDefaults: FbPage,
  FbUserProfile,
  FbUserProfileWithDefaults: FbUserProfile,
  FocusModeDisabledNotification,
  FocusModeDisabledNotificationWithDefaults: FocusModeDisabledNotification,
  ForgotPassword,
  ForgotPasswordWithDefaults: ForgotPassword,
  GreetingSuggestion,
  GreetingSuggestionWithDefaults: GreetingSuggestion,
  HelpAppContext,
  HelpAppContextItem: ConversationItem,
  HelpAppContextItemWithDefaults: ConversationItem,
  HelpAppContextWithDefaults: HelpAppContext,
  HotelReservation,
  HotelReservationItem: ConversationItem,
  HotelReservationItemWithDefaults: ConversationItem,
  HotelReservationWithDefaults: HotelReservation,
  InboundCommunicationSessionNotification,
  InboundCommunicationSessionNotificationWithAbandonedCall: InboundCommunicationSessionNotification,
  InboundCommunicationSessionNotificationWithChat: InboundCommunicationSessionNotification,
  InboundCommunicationSessionNotificationWithDefaults: InboundCommunicationSessionNotification,
  InboundCommunicationSessionNotificationWithEmail: InboundCommunicationSessionNotification,
  InboundCommunicationSessionNotificationWithFBMessage: InboundCommunicationSessionNotification,
  InboundCommunicationSessionNotificationWithSMS: InboundCommunicationSessionNotification,
  InboundCommunicationSessionNotificationWithVoicemail: InboundCommunicationSessionNotification,
  InboundCommunicationSessionNotificationWithWhatsApp: InboundCommunicationSessionNotification,
  InboundMessageNotification,
  InboundMessageNotificationWithChatMessage: InboundMessageNotification,
  InboundMessageNotificationWithDefaults: InboundMessageNotification,
  InboundMessageNotificationWithEmailMessage: InboundMessageNotification,
  InboundMessageNotificationWithFBMessage: InboundMessageNotification,
  InboundMessageNotificationWithSMS: InboundMessageNotification,
  InboundMessageNotificationWithWhatsApp: InboundMessageNotification,
  Inbox,
  InboxCount,
  InboxWithDefaults: Inbox,
  InstagramDirectEndpoint: Endpoint,
  InstagramDirectEndpointWithDefaults: Endpoint,
  Integration,
  IntegrationWithDefaults: Integration,
  ItemId,
  ItemIdWithDefaults: ItemId,
  ItemLink: ItemLinkContent,
  ItemLinkItem: ConversationItem,
  ItemLinkItemWithDefaults: ConversationItem,
  ItemLinkWithDefaults: ItemLinkContent,
  IvrNodeWithDefaults: IvrNode,
  IvrWithDefaults: Ivr,
  KbAdmin,
  KbAdminWithDefaults: KbAdmin,
  KbVariable,
  KbVariableWithDefaults: KbVariable,
  KeywordGroup,
  KeywordGroupWithDefaults: KeywordGroup,
  Language,
  LanguageWithDefaults: Language,
  LinkedItem,
  LinkedItemWithDefaults: LinkedItem,
  Liveboards,
  LiveboardsAgents: Liveboards,
  LiveboardsWithDefaults: Liveboards,
  LocationHistory,
  LocationHistoryWithDefaults: LocationHistory,
  MarketingEmail,
  MarketingEmailItem: ConversationItem,
  MarketingEmailItemWithDefaults: ConversationItem,
  MarketingEmailWithDefaults: MarketingEmail,
  MessageContentTypeFileWithDefaults: MessageContentTypeFile,
  MessageContentTypeImage,
  MessageContentTypeLocation,
  MessageContentTypeText,
  MessagingConfiguration,
  MessagingConfigurationWithDefaults: MessagingConfiguration,
  MobileContext,
  MobileContextItem: ConversationItem,
  MobileContextItemWithDefaults: ConversationItem,
  MobileContextWithDefaults: MobileContext,
  NoReplyNeeded,
  NoReplyNeededItem: ConversationItem,
  NoteCompositionContent,
  PhoneCallObserverNotification,
  PhoneCallObserverNotificationWithDefaults: PhoneCallObserverNotification,
  OfferedCallNotification,
  OfferedCallNotificationWithDefaults: OfferedCallNotification,
  OfferedWarmTransferNotification,
  OfferedWarmTransferNotificationWithDefaults: OfferedWarmTransferNotification,
  OptionalUpgradeNotification,
  OptionalUpgradeNotificationWithDefaults: OptionalUpgradeNotification,
  OutgoingEmailMessageItemWithDefaults: ConversationItem,
  PaymentCompletedContentItemWithDefaults: ConversationItem,
  PaymentCompletedContentItem: ConversationItem,
  PaymentStatusEventContentItemWithDefaults: ConversationItem,
  PaymentStatusEventContentItem: ConversationItem,
  PersonalInbox: Inbox,
  PersonalInboxCountWithDefaults: InboxCount,
  PhoneCall,
  PhoneCallCompositionContent,
  PhoneCallIncoming: PhoneCall,
  PhoneCallItem: ConversationItem,
  PhoneCallItemIncoming: ConversationItem,
  PhoneCallItemWithDefaults: ConversationItem,
  PhoneCallTransfer: Transfer,
  PhoneCallTransferWithDefaults: Transfer,
  PhoneCallWithDefaults: PhoneCall,
  PhoneCallback,
  PhoneCallbackCompositionContent,
  PhoneCallbackItem: ConversationItem,
  PhoneCallbackItemWithDefaults: ConversationItem,
  PhoneCallbackWithDefaults: PhoneCallback,
  PhoneNumber,
  PhoneNumberWithDefaults: PhoneNumber,
  PhraseSuggestion,
  PhraseSuggestionWithDefaults: PhraseSuggestion,
  PostponedOptionalUpgradeNotification: OptionalUpgradeNotification,
  ProactiveChatContent,
  ProactiveChatContentWithDefaults: ProactiveChatContent,
  ProactiveChatContentWithApiSource: ProactiveChatContent,
  ProactiveChatItem: ConversationItem,
  ProactiveChatItemWithDefaults: ConversationItem,
  ProactiveChatItemWithApiSource: ConversationItem,
  RecommendedAnswer,
  RecommendedAnswerWithDefaults: RecommendedAnswer,
  RecommendedAnswerCount,
  RecommendedAnswerCountWithDefaults: RecommendedAnswerCount,
  Relationship,
  RelationshipLabel,
  RelationshipWithDefaults: Relationship,
  ReportBuilderConfig,
  ReportConfigSummary: ReportConfig,
  ReportConfigActiveConversationHandleTimeByAgent: ReportConfig,
  ReportConfigConversationSummary: ReportConfig,
  ReportConfigRestrictedTimeAggregation: ReportConfig,
  ReportConfigChannelMix: ReportConfig,
  ReportConfigDailyTrends: ReportConfig,
  ReportConfigActiveConversationHandleTimeByCustomer: ReportConfig,
  ReportConfigIvrSummary: ReportConfig,
  ReportConfigActiveConversationHandleTimeByTopic: ReportConfig,
  ReportConfigTopics: ReportConfig,
  ReportConfigIvrEndStates: ReportConfig,
  Reports,
  ReportsWithDefaults: Reports,
  RecordingSummary,
  RecordingSummaryWithDefaults: RecordingSummary,
  RecordingSummaryStatus,
  RoutableCounts,
  RoutingGroup,
  RoutingGroupWithDefaults: RoutingGroup,
  RoutingItem: ConversationItem,
  RoutingItemForAgentRequest: ConversationItem,
  RoutingItemForAutomatedAssignment: ConversationItem,
  RoutingItemForBlindTransfer: ConversationItem,
  RoutingItemForInbound: ConversationItem,
  RoutingItemForInboundMatch: ConversationItem,
  RoutingItemForIncomingTask: ConversationItem,
  RoutingItemForManualAssignment: ConversationItem,
  RoutingItemForOfferConflict: ConversationItem,
  RoutingItemForOfferDecline: ConversationItem,
  RoutingItemForOfferMiss: ConversationItem,
  RoutingItemForOutbound: ConversationItem,
  RoutingItemForSmartMatch: ConversationItem,
  RoutingItemForVoicemail: ConversationItem,
  RoutingQueueItem,
  RoutingQueueItemWithChatSession: RoutingQueueItem,
  RoutingQueueItemWithDefaults: RoutingQueueItem,
  RoutingQueueItemWithFacebookMessengerSession: RoutingQueueItem,
  RoutingQueueItemWithWhatsAppSession: RoutingQueueItem,
  RoutingSession,
  RoutingSessionWithDefaults: RoutingSession,
  RuleDisabledLoop,
  RuleDisabledLoopItem: ConversationItem,
  RuleScore,
  RuleScoreWithDefaults: RuleScore,
  Search,
  SearchAggregations,
  SearchQuery,
  SearchResult,
  SearchWithDefaults: Search,
  SecureDataRequest,
  SecureDataRequestItemWithDefaults: ConversationItem,
  SecureDataRequestWithDefaults: SecureDataRequest,
  SelectedKbItem,
  SelectedKbItemWithDefaults: SelectedKbItem,
  SessionCustomer,
  SessionCustomerWithDefaults: SessionCustomer,
  SessionEnded,
  SessionEndedItem: ConversationItem,
  SessionEndedItemWithChat: ConversationItem,
  SessionEndedItemWithDefaults: ConversationItem,
  SessionEndedItemWithSMS: ConversationItem,
  SessionEndedWithChat: SessionEnded,
  SessionEndedWithDefaults: SessionEnded,
  SessionEndedWithSMS: SessionEnded,
  SelectedSuggestedReplyDefaults: SelectedSuggestedReply,
  SharedReportConfig: ReportConfig,
  Sla,
  SlaWithDefaults: Sla,
  SmartMatchDetailWithDefaults: SmartMatchDetail,
  SmartMatchLiveboard,
  SmartMatchLiveboardWithDefaults: SmartMatchLiveboard,
  SmsCompositionContent,
  SmsEndpoint: Endpoint,
  SmsMessage,
  SmsMessageAttachment,
  SmsMessageAttachmentWithDefaults: SmsMessageAttachment,
  SmsMessageItem: ConversationItem,
  SmsMessageItemIncomingWithDefaults: ConversationItem,
  SmsMessageItemOutgoingWithDefaults: ConversationItem,
  SmsMessageItemWithDefaults: ConversationItem,
  SmsMessageWithDefaults: SmsMessage,
  SmsCampaignOutreach,
  SmsCampaignOutreachWithDefaults: SmsCampaignOutreach,
  SmsCampaignOutreachItem: ConversationItem,
  SmsCampaignOutreachItemWithDefaults: ConversationItem,
  Snippet,
  SnippetAttachment: Attachment,
  SnippetChannels,
  SnippetChannelsWithDefaults: SnippetChannels,
  SnippetContent,
  SnippetContentWithDefaults: SnippetContent,
  SnippetEditor,
  SnippetEditorWithDefaults: SnippetEditor,
  SnippetRevision,
  SnippetRevisionWithDefaults: SnippetRevision,
  SnippetSearchResult,
  SnippetWithDefaults: Snippet,
  StationConfiguration,
  SuggestedReply,
  SuggestedReplyAppliedSnippetRef: AppliedSnippetRefs,
  SuggestedReplyAppliedSnippetRefsDefaults: AppliedSnippetRefs,
  SuggestedReplySnippetDetails: SnippetDetails,
  SuggestedReplyWithDefaults: SuggestedReply,
  SummaryLiveboard,
  SummaryLiveboardWithDefaults: SummaryLiveboard,
  SystemConfigurationWithDefaults: SystemConfiguration,
  Task,
  TaskComment,
  TaskCommentWithDefaults: TaskComment,
  TaskCompletionContent,
  TaskCompletionContentItem: ConversationItem,
  TaskCompletionContentItemWithDefaults: ConversationItem,
  TaskCompletionContentWithDefaults: TaskCompletionContent,
  TaskCompositionContent,
  TaskCompositionEditContent,
  TaskDueContent,
  TaskDueContentItem: ConversationItem,
  TaskDueContentItemWithDefaults: ConversationItem,
  TaskDueContentWithDefaults: TaskDueContent,
  TaskEditedContent: ConversationItem,
  TaskEditedContentItemWithDefaults: ConversationItem,
  TaskEditedContentWithDefaults: TaskEditedContent,
  TaskFollower,
  TaskFollowerWithDefaults: TaskFollower,
  TaskInfo,
  TaskInfoWithDefaults: TaskInfo,
  TaskItem: ConversationItem,
  TaskItemNotDue: ConversationItem,
  TaskItemWithDefaults: ConversationItem,
  TaskItemWithoutConversationId: ConversationItem,
  TaskNotificationNotDue: TaskNotification,
  TaskNotificationWithDefaults: TaskNotification,
  TaskPreview,
  TaskPreviewWithDefaults: TaskPreview,
  TaskReopenedContent: ConversationItem,
  TaskReopenedContentItemWithDefaults: ConversationItem,
  TaskReopenedContentWithDefaults: TaskReopenedContent,
  TaskRouting,
  TaskRoutingForAgentRequest: ConversationItem,
  TaskRoutingForManualAssignment: ConversationItem,
  TaskRoutingItemForAgentRequest: ConversationItem,
  TaskWithDefaults: Task,
  Team,
  TeamEditor,
  TeamEditorWithDefaults: TeamEditor,
  TeamInbox: Inbox,
  TeamInboxCountWithDefaults: InboxCount,
  TeamWithDefaults: Team,
  Toast,
  ToastWIthDefaults: Toast,
  Topic,
  TopicAncestry,
  TopicAncestryWithDefaults: TopicAncestry,
  TopicSuggestions,
  TopicSuggestionsWithDefaults: TopicSuggestions,
  TopicWithAncestryDefaults: Topic,
  TopicWithDefaults: Topic,
  TopicsLiveboard,
  TopicsLiveboardTopic: TopicsStats,
  TopicsLiveboardTopicWithDefaults: TopicsStats,
  TopicsLiveboardWithDefaults: TopicsLiveboard,
  Transaction,
  TranslationFromEsToEn: Translation,
  Tweet,
  TweetWithDefaults: Tweet,
  TwitterEndpoint: Endpoint,
  TwitterItem: ConversationItem,
  TwitterItemWithDefaults: ConversationItem,
  UnofferedCallNotification,
  UnofferedCallNotificationWithDefaults: UnofferedCallNotification,
  UnofferedWarmTransferNotification,
  UnofferedWarmTransferNotificationWithDefaults: UnofferedWarmTransferNotification,
  UnreadConversation,
  UnreadConversationWithDefaults: UnreadConversation,
  Upload,
  User,
  UserActivation,
  UserActivationWithDefaults: UserActivation,
  UserEditor,
  UserEditorWithDefaults: UserEditor,
  UserLogin,
  UserLoginWithDefaults: UserLogin,
  UserResetPassword,
  UserResetPasswordWithDefaults: UserResetPassword,
  UserWithActivation: User,
  UserWithDefaults: User,
  UsersAdmin,
  UsersAdminWithDefaults: UsersAdmin,
  VgsAccountContent,
  VgsAccountContentWithDefaults: VgsAccountContent,
  VgsAccountSecretContent: VgsSecretContent,
  VoiceCampaignOutreach,
  VoiceCampaignOutreachWithDefaults: VoiceCampaignOutreach,
  VoiceCampaignOutreachItem: ConversationItem,
  VoiceCampaignOutreachItemWithDefaults: ConversationItem,
  VoiceCampaignOutreachResult,
  VoiceCampaignOutreachResultWithDefaults: VoiceCampaignOutreachResult,
  VoiceCampaignOutreachResultItem: ConversationItem,
  VoiceCampaignOutreachResultItemWithDefaults: ConversationItem,
  VoiceConfiguration,
  VoiceConfigurationWithDefaults: VoiceConfiguration,
  VoiceEndpoint: Endpoint,
  VoiceRecordingDeleted,
  VoiceRecordingDeletedItem: ConversationItem,
  VoiceRecordingDeletedItemWithDefaults: ConversationItem,
  VoiceRecordingDeletedWithDefaults: VoiceRecordingDeleted,
  VoiceAIRecordingItemWithDefaults: ConversationItem,
  Voicemail,
  VoicemailItem: ConversationItem,
  VoicemailItemWithDefaults: ConversationItem,
  VoicemailWithDefaults: Voicemail,
  WebContext,
  WebContextItem: ConversationItem,
  WebContextItemWithDefaults: ConversationItem,
  WebContextWithDefaults: WebContext,
  WebForm,
  WebFormItem: ConversationItem,
  WebFormItemWithDefaults: ConversationItem,
  WebFormWithDefaults: WebForm,
  Webhook,
  WebhookWithDefaults: Webhook,
  WhatsAppEndpoint: Endpoint,
  WhatsApp: ConversationMessage,
  WhatsAppItem: ConversationItem,
  WhatsAppItemWithDefaults: ConversationItem,
  WhatsAppWithDefaults: ConversationMessage,
  WidgetActivity,
  WidgetActivityWithDefaults: WidgetActivity,
  WidgetActivityItem: ConversationItem,
  WidgetActivityItemWithDefaults: ConversationItem,
  WidgetConfiguration,
  WidgetConfigurationWithDefaults: WidgetConfiguration,
  WidgetConfigParameter,
  WidgetConfigParameterWithDefaults: WidgetConfigParameter,
  WidgetConfigSettings,
  WidgetConfigSettingsWithDefaults: WidgetConfigSettings,
});
