import RecommendedAnswers from 'models/location/recommended_answers';

export default class NavigateToRecommendedAnswers {
  constructor(context) {
    this.context = context;
  }

  run({ referencedAnswerId }) {
    if (referencedAnswerId) {
      this.context.router.navigateTo(RecommendedAnswers.create({ referencedAnswerId }));
    }
  }
}
