export default class UnloadRecommendedAnswers {
  constructor(context) {
    this.context = context;
  }

  run() {
    this.context.stores.recommendedAnswers.set([]);
    this.context.gateways.recommendedAnswers.unsubscribeAll();
  }
}
